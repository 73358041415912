"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Url = void 0;
var Url = function () {
  function Url(hash, host, hostname, _href, origin, pathname, port) {
    var protocol = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : 'http';
    var search = arguments.length > 8 && arguments[8] !== undefined ? arguments[8] : '';
    (0, _classCallCheck2.default)(this, Url);
    this.port = 80;
    this.hash = hash;
    this.host = host;
    this.hostname = hostname;
    this.origin = origin;
    this.pathname = pathname;
    this.port = port == null ? 80 : port;
    this.protocol = protocol;
    this.search = search;
  }
  (0, _createClass2.default)(Url, [{
    key: "href",
    get: function get() {
      if (this.protocol != null && this.protocol.length > 0) return `${this.protocol}//${this.host}${this.getFullPathname()}`;
      return `${this.host}${this.getFullPathname()}`;
    }
  }, {
    key: "toString",
    value: function toString() {
      return this.href;
    }
  }, {
    key: "getFullPathname",
    value: function getFullPathname() {
      var s = `${this.pathname}`;
      if (this.search != null && this.search.length > 0) {
        s += this.search.substr(0, 1) === '?' ? this.search : `?${this.search}`;
      }
      if (this.hash != null && this.hash.length > 0) {
        s += this.hash.substr(0, 1) === '#' ? this.hash : `#${this.hash}`;
      }
      return s;
    }
  }], [{
    key: "getURLfromString",
    value: function getURLfromString(url) {
      var m = url.match(/^(([^:\/?#]+:)?(?:\/\/(([^\/?#:]*)(?::([^\/?#:]*))?)))?([^?#]*)(\?[^#]*)?(#.*)?$/);
      if (m != null) {
        var pathname = m[1] != null ? '/' : '';
        var r = new Url(m[8] != null ? m[8] : '', m[3] != null ? m[3] : '', m[4] != null ? m[4] : '', m[0] != null ? m[0] : '', m[1] != null ? m[1] : '', m[6] != null ? m[6] : pathname, m[5] != null ? parseInt(m[5], 10) : undefined, m[2] != null ? m[2] : '', m[7] != null ? m[7] : '');
        if (r.protocol.length === 2) {
          r.protocol = `file:///${r.protocol.toUpperCase()}`;
          r.origin = `${r.protocol}//${r.host}`;
        }
        return r;
      }
      throw new Error('no URL');
    }
  }]);
  return Url;
}();
exports.Url = Url;