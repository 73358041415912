var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Measurement = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var Measurement = exports.Measurement = function () {
  function Measurement() {
    (0, _classCallCheck2.default)(this, Measurement);
  }
  (0, _createClass2.default)(Measurement, null, [{
    key: "measure",
    value: function () {
      var _measure = (0, _asyncToGenerator2.default)(function* (ref) {
        return new Promise(function (resolve, _reject) {
          try {
            ref.measure(function (x, y, width, height, pageX, pageY) {
              if (x != null && y != null && width != null && height != null) {
                return resolve({
                  x: x,
                  y: y,
                  width: width,
                  height: height,
                  pageX: pageX,
                  pageY: pageY
                });
              }
              return resolve({
                x: 0,
                y: 0,
                width: 0,
                height: 0,
                pageX: 0,
                pageY: 0
              });
            });
          } catch (e) {
            resolve({
              x: 0,
              y: 0,
              width: 0,
              height: 0,
              pageX: 0,
              pageY: 0
            });
          }
        });
      });
      function measure(_x) {
        return _measure.apply(this, arguments);
      }
      return measure;
    }()
  }, {
    key: "measureInLayout",
    value: function measureInLayout(ref, relativeToNativeNode) {
      return new Promise(function (resolve, _reject) {
        try {
          var r = (0, _reactNativeWeb.findNodeHandle)(relativeToNativeNode);
          if (r != null && typeof ref.measureLayout === 'function') {
            ref.measureLayout(r, function (x, y, width, height) {
              if (x != null && y != null && width != null && height != null) {
                return resolve({
                  x: x,
                  y: y,
                  width: width,
                  height: height
                });
              }
              return resolve({
                x: 0,
                y: 0,
                width: 0,
                height: 0
              });
            }, function (e) {
              return resolve({
                x: 0,
                y: 0,
                width: 0,
                height: 0
              });
            });
          } else {
            resolve({
              x: 0,
              y: 0,
              width: 0,
              height: 0
            });
          }
        } catch (e) {
          resolve({
            x: 0,
            y: 0,
            width: 0,
            height: 0
          });
        }
      });
    }
  }]);
  return Measurement;
}();