"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeTimeTracking = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var ServerEnvirement_1 = require("cqrs-core/build/src/server/webserver/ServerEnvirement");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var Config_1 = require("../../../../Config");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var ClientStore_1 = require("../../../ClientStore");
var TimeTrackingChanged_1 = require("../../../events/company/timetracking/TimeTrackingChanged");
var TimeTrackingEntity_1 = require("../../../query/entities/TimeTrackingEntity");
var CreateTimeTracking_1 = require("./CreateTimeTracking");
var ChangeTimeTracking = function (_CommandRoot_1$Comman) {
  (0, _inherits2.default)(ChangeTimeTracking, _CommandRoot_1$Comman);
  var _super = _createSuper(ChangeTimeTracking);
  function ChangeTimeTracking(data, entityId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ChangeTimeTracking);
    _this = _super.call(this, data, entityId, token);
    _this.aggregate = 'company';
    _this.commandName = 'ChangeTimeTracking';
    _this.datesToMinutes();
    return _this;
  }
  (0, _createClass2.default)(ChangeTimeTracking, [{
    key: "datesToMinutes",
    value: function datesToMinutes() {
      if (this.data.changeTime != null) this.data.changeTime = CreateTimeTracking_1.CreateTimeTracking.roundDateToMinute(this.data.changeTime);
      if (this.data.starts != null) this.data.starts = CreateTimeTracking_1.CreateTimeTracking.roundDateToMinute(this.data.starts);
      if (this.data.ends != null) this.data.ends = CreateTimeTracking_1.CreateTimeTracking.roundDateToMinute(this.data.ends);
    }
  }, {
    key: "getTimeTrackingEntry",
    value: function () {
      var _getTimeTrackingEntry = (0, _asyncToGenerator2.default)(function* () {
        if (this._timeTrackingEntry == null) this._timeTrackingEntry = yield CommandReadModels_1.CommandReadModels.timeTracking.getById(this.entityId);
        return this._timeTrackingEntry;
      });
      function getTimeTrackingEntry() {
        return _getTimeTrackingEntry.apply(this, arguments);
      }
      return getTimeTrackingEntry;
    }()
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        var timeTrackingEntry = yield this.getTimeTrackingEntry();
        var company = yield CommandReadModels_1.CommandReadModels.company.getById(timeTrackingEntry.companyId);
        var companyMember = yield CommandReadModels_1.CommandReadModels.companyMember.get({
          filter: `companyId eq '${company.id}' and userId eq '${userId}' and deleted ne true`,
          top: 1
        });
        if (companyMember.length === 1) {
          if (timeTrackingEntry.status !== 'Exported' && companyMember[0].payroll) {
            return true;
          }
          if (timeTrackingEntry.status === 'Locked' && !companyMember[0].payroll) {
            throw {
              message: 'Keine Berechtigung',
              messageCode: 'forbiddenCommand'
            };
          }
          if (timeTrackingEntry.status === 'Exported') {
            throw {
              message: 'Der Eintrag ist für Veränderungen gesperrt',
              messageCode: 'timeTrackingEntryLocked'
            };
          }
          if (timeTrackingEntry.createdBy === userId || timeTrackingEntry.userId === userId) {
            if (companyMember[0].role !== 'inactive' && companyMember[0].payroll) {
              return true;
            }
            var companyMember2 = yield timeTrackingEntry.getMember();
            if (companyMember != null) {
              var period = yield TimeTrackingEntity_1.TimeTrackingEntity.getRecordingPeriod(companyMember2);
              var starts = new Date(this.data.starts != null ? this.data.starts : timeTrackingEntry.starts);
              if (starts.getTime() < period.start.getTime() || starts.getTime() > period.end.getTime()) {
                throw {
                  message: 'Das Startdatum liegt nicht im möglichen Erfassungszeitraum',
                  messageCode: 'timeTrackingNotInRecordingPeriod'
                };
              }
              if (companyMember[0].role !== 'inactive') {
                return true;
              }
            }
          }
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var timeTrackingEntry = yield this.getTimeTrackingEntry();
        if (timeTrackingEntry.status === 'Exported') return [];
        var hasChanged = this.data.starts !== timeTrackingEntry.starts || this.data.ends !== timeTrackingEntry.ends || this.data.taskId !== timeTrackingEntry.taskId || this.data.note !== timeTrackingEntry.note || this.data.projectId !== timeTrackingEntry.projectId || this.data.costCenterId !== timeTrackingEntry.costCenterId || this.data.pause !== timeTrackingEntry.pause;
        if (!hasChanged) return null;
        var events = [];
        var data = {};
        if (this.data.starts != null && this.data.starts !== timeTrackingEntry.starts) data.starts = new Date(this.data.starts);
        if (this.data.ends != null && this.data.ends !== timeTrackingEntry.ends) data.ends = new Date(this.data.ends);
        if (this.data.note != null && this.data.note !== timeTrackingEntry.note) data.note = this.data.note;
        if (this.data.taskId != null && this.data.taskId !== timeTrackingEntry.taskId) data.taskId = this.data.taskId;
        if (this.data.pause != null && this.data.pause !== timeTrackingEntry.pause) data.pause = this.data.pause;
        if (this.data.projectId != null && this.data.projectId !== timeTrackingEntry.projectId) data.projectId = this.data.projectId;
        if (this.data.costCenterId != null && this.data.costCenterId !== timeTrackingEntry.costCenterId) data.costCenterId = this.data.costCenterId;
        if (timeTrackingEntry.ends == null && data.ends == null && (data.taskId != null && data.taskId !== timeTrackingEntry.taskId || data.projectId != null && data.projectId !== timeTrackingEntry.projectId || data.costCenterId != null && data.costCenterId !== timeTrackingEntry.costCenterId)) {
          var newStart = this.data.starts != null && this.data.starts.getTime() !== new Date(timeTrackingEntry.starts).getTime() ? this.data.starts : this.data.changeTime;
          var newEnd = new Date(newStart);
          newEnd.setMinutes(newStart.getMinutes() - 1);
          events.push(new TimeTrackingChanged_1.TimeTrackingChanged(this.entityId, {
            ends: newEnd
          }, timeTrackingEntry.companyId));
          var memberId = yield timeTrackingEntry.getMemberId();
          var create = new CreateTimeTracking_1.CreateTimeTracking({
            costCenterId: data.costCenterId != null ? data.costCenterId : timeTrackingEntry.costCenterId != null ? timeTrackingEntry.costCenterId : '',
            ends: data.ends,
            note: data.note,
            taskId: data.taskId != null ? data.taskId : timeTrackingEntry.taskId,
            pause: data.pause != null ? data.pause : undefined,
            projectId: data.projectId != null ? data.projectId : timeTrackingEntry.projectId,
            starts: newStart,
            companyId: timeTrackingEntry.companyId,
            userId: timeTrackingEntry.userId,
            memberId: memberId
          });
          yield create.execute(ServerEnvirement_1.ServerEnvirement.writeStore != null ? ServerEnvirement_1.ServerEnvirement.writeStore : ClientStore_1.ClientStore.commandStore);
          return events;
        }
        events.push(new TimeTrackingChanged_1.TimeTrackingChanged(this.entityId, data, timeTrackingEntry.companyId));
        return events;
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        if (key == null || key === 'note') {
          if (this.data.note != null && this.data.note.length > Config_1.ConfigHandler.config.string_Large) {
            throw {
              key: 'note',
              messageCode: 'stringToLong',
              message: 'Eingabe ist zu lang'
            };
          }
        }
        yield ChangeTimeTracking.validateTimeTrackingEntity(new TimeTrackingEntity_1.TimeTrackingEntity(Object.assign({
          id: this.entityId
        }, this.data)), key);
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }], [{
    key: "validateTimeTrackingEntity",
    value: function () {
      var _validateTimeTrackingEntity = (0, _asyncToGenerator2.default)(function* (data, key) {
        var errors = [];
        if (key == null || key === 'costCenterId') {
          if (data.costCenterId != null && data.costCenterId.length > 0) {
            try {
              var cc = yield CommandReadModels_1.CommandReadModels.costCenter.getById(data.costCenterId);
              if (cc.costType === 'costUnit') {
                if (data.projectId == null) {
                  errors.push({
                    message: 'Wenn ein Kostenträger gewählt ist, wird die Projekt id benötigt',
                    messageCode: 'costUnitNeedsProject',
                    key: 'projectId'
                  });
                }
                var isIn = cc.projectIds == null ? -1 : cc.projectIds.findIndex(function (p) {
                  return p === data.projectId;
                });
                if (isIn === -1) {
                  errors.push({
                    message: 'Das Projekt ist dem Kostenträger nicht zugeordnet',
                    messageCode: 'projectNotInCostUnit',
                    key: 'projectId'
                  });
                }
              }
            } catch (e) {
              errors.push({
                message: 'Diese Kostenstelle existiert nicht',
                messageCode: 'costCenterIdNotFound',
                key: 'costCenterId'
              });
            }
          }
        }
        if (errors.length > 0) throw errors;
      });
      function validateTimeTrackingEntity(_x2, _x3) {
        return _validateTimeTrackingEntity.apply(this, arguments);
      }
      return validateTimeTrackingEntity;
    }()
  }]);
  return ChangeTimeTracking;
}(CommandRoot_1.CommandRoot);
exports.ChangeTimeTracking = ChangeTimeTracking;