var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MaterialText = MaterialText;
exports.getStyleForType = exports.MaterialTextTypes = void 0;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _reactNativeHyperlink = _interopRequireDefault(require("react-native-hyperlink"));
var _UseComponentSize = _interopRequireDefault(require("../../hooks/UseComponentSize"));
var _ThemeManager = require("../../ThemeManager");
var _Ripple = require("../utils/Ripple");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var MaterialTextTypes;
(function (MaterialTextTypes) {
  MaterialTextTypes[MaterialTextTypes["Body1"] = 0] = "Body1";
  MaterialTextTypes[MaterialTextTypes["Body2"] = 1] = "Body2";
  MaterialTextTypes[MaterialTextTypes["Button"] = 2] = "Button";
  MaterialTextTypes[MaterialTextTypes["H1"] = 3] = "H1";
  MaterialTextTypes[MaterialTextTypes["H2"] = 4] = "H2";
  MaterialTextTypes[MaterialTextTypes["H3"] = 5] = "H3";
  MaterialTextTypes[MaterialTextTypes["H4"] = 6] = "H4";
  MaterialTextTypes[MaterialTextTypes["H5"] = 7] = "H5";
  MaterialTextTypes[MaterialTextTypes["H6"] = 8] = "H6";
  MaterialTextTypes[MaterialTextTypes["Subtitle1"] = 9] = "Subtitle1";
  MaterialTextTypes[MaterialTextTypes["Subtitle2"] = 10] = "Subtitle2";
  MaterialTextTypes[MaterialTextTypes["Overline"] = 11] = "Overline";
  MaterialTextTypes[MaterialTextTypes["Caption"] = 12] = "Caption";
})(MaterialTextTypes || (exports.MaterialTextTypes = MaterialTextTypes = {}));
var getStyleForType = exports.getStyleForType = function getStyleForType(type) {
  var color = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _ThemeManager.ThemeManager.style.defaultTextColor;
  var backgroundColor = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'transparent';
  var centeredText = arguments.length > 3 ? arguments[3] : undefined;
  var centerBlock = arguments.length > 4 ? arguments[4] : undefined;
  var textPadding = arguments.length > 5 ? arguments[5] : undefined;
  var bold = arguments.length > 6 ? arguments[6] : undefined;
  var fontSize = arguments.length > 7 ? arguments[7] : undefined;
  var width = arguments.length > 8 ? arguments[8] : undefined;
  var textAlign = arguments.length > 9 ? arguments[9] : undefined;
  var textStyle = [_ThemeManager.ThemeManager.style.text];
  if (type == null) {
    textStyle.push(_ThemeManager.ThemeManager.style.Body1);
  } else if (_ThemeManager.ThemeManager.style[MaterialTextTypes[type]] != null) {
    textStyle.push(_ThemeManager.ThemeManager.style[MaterialTextTypes[type]]);
  } else {
    textStyle.push(_ThemeManager.ThemeManager.style.Body1);
  }
  textStyle.push({
    textAlign: textAlign != null ? textAlign : centeredText ? 'center' : 'left'
  });
  textStyle.push({
    alignSelf: centerBlock ? 'center' : 'flex-start'
  });
  if (bold === true) {
    textStyle.push({
      fontWeight: 'bold'
    });
  }
  if (textPadding != null) {
    textStyle.push({
      padding: textPadding
    });
  }
  if (backgroundColor != null) {
    textStyle.push({
      backgroundColor: backgroundColor
    });
  }
  if (fontSize != null) {
    textStyle.push({
      fontSize: fontSize,
      lineHeight: fontSize * 1.2
    });
  }
  textStyle.push({
    color: color
  });
  if (width != null) {
    textStyle.push({
      width: width
    });
  }
  textStyle.push({
    maxWidth: '100%'
  });
  var returnedObject = textStyle.reduce(function (obj, item) {
    var propsCollector = Object.assign({}, obj, item);
    return propsCollector;
  }, {});
  if (returnedObject.lineHeight == null && returnedObject.fontSize != null) {
    returnedObject.lineHeight = returnedObject.fontSize * 1.2;
  }
  return returnedObject;
};
function MaterialText(props) {
  var _useState = (0, _react.useState)(true),
    _useState2 = (0, _slicedToArray2.default)(_useState, 2),
    ellipsize = _useState2[0],
    setEllipsize = _useState2[1];
  var _useState3 = (0, _react.useState)(''),
    _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
    tooltip = _useState4[0],
    setTooltip = _useState4[1];
  var _checkedToolTip = false;
  var _useComponentSize = (0, _UseComponentSize.default)(),
    _useComponentSize2 = (0, _slicedToArray2.default)(_useComponentSize, 2),
    rootSize = _useComponentSize2[0],
    onRootLayout = _useComponentSize2[1];
  var _useComponentSize3 = (0, _UseComponentSize.default)(),
    _useComponentSize4 = (0, _slicedToArray2.default)(_useComponentSize3, 2),
    toolTipSize = _useComponentSize4[0],
    onToolTipLayout = _useComponentSize4[1];
  var getTextObject = function getTextObject(textStyle, onPress) {
    var recognizeUrl = props.recognizeUrl,
      children = props.children,
      additionalTextStyle = props.additionalTextStyle;
    textStyle.padding = 0;
    if (_reactNativeWeb.Platform.OS === 'web' && props.numberOfLines === 1) {
      textStyle.whiteSpace = 'nowrap';
    }
    var t = (0, _jsxRuntime.jsx)(_reactNativeWeb.Text, {
      onPress: onPress,
      ellipsizeMode: !ellipsize ? 'clip' : props.ellipsizeMode ? props.ellipsizeMode : 'tail',
      style: additionalTextStyle != null ? Object.assign({}, textStyle, additionalTextStyle) : textStyle,
      numberOfLines: props.numberOfLines,
      allowFontScaling: !!props.allowFontScaling,
      adjustsFontSizeToFit: !!props.adjustsFontSizeToFit,
      children: children
    });
    return recognizeUrl ? (0, _jsxRuntime.jsx)(_reactNativeHyperlink.default, {
      linkStyle: {
        color: _ThemeManager.ThemeManager.style.brandPrimary
      },
      linkDefault: true,
      children: t
    }) : t;
  };
  (0, _react.useEffect)(function () {
    if (props.numberOfLines === 1 && props.fixedWidth != null) {
      if (rootSize != null && toolTipSize != null && !_checkedToolTip) {
        _checkedToolTip = true;
        if (Math.floor(rootSize.width) > 0 && Math.floor(toolTipSize.width) > 0) {
          var onToolTipCreated = props.onToolTipCreated;
          if (Math.floor(rootSize.width) >= Math.floor(toolTipSize.width)) {
            setEllipsize(false);
            if (onToolTipCreated != null) {
              onToolTipCreated('');
            }
          } else if (rootSize.width < toolTipSize.width) {
            if (props.children != null && typeof props.children === 'string') {
              setTooltip(props.children);
            }
            if (onToolTipCreated != null) {
              onToolTipCreated(props.children);
            }
          } else {
            setTooltip('');
            if (onToolTipCreated != null) {
              onToolTipCreated('');
            }
          }
        }
      }
    }
  }, [toolTipSize, rootSize]);
  var onPress = function onPress(e) {
    if (props.onPress != null && typeof props.onPress === 'function') {
      props.onPress(e);
    }
  };
  var createRippleViewTooltip = function createRippleViewTooltip(textStyle) {
    var hasTooltip = tooltip != null && tooltip.length > 0;
    var key = typeof props.children === 'string' ? props.children.substr(0, 20) : 'text';
    return (0, _jsxRuntime.jsxs)(_Ripple.Ripple, {
      toolTip: tooltip,
      style: {
        position: 'relative',
        alignSelf: textStyle.alignSelf,
        width: textStyle.width != null ? textStyle.width : 'auto',
        maxWidth: '100%'
      },
      rippleEffectEnabled: false,
      disabled: !hasTooltip,
      onPress: onPress,
      children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        collapsable: false,
        style: {
          position: 'absolute',
          top: 0,
          height: 23,
          alignSelf: textStyle.alignSelf,
          width: 10000,
          opacity: 0
        },
        pointerEvents: "none",
        children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          collapsable: false,
          onLayout: onToolTipLayout,
          style: {
            alignSelf: textStyle.alignSelf,
            width: 'auto',
            padding: props.textPadding
          },
          children: getTextObject(Object.assign({}, textStyle, {
            width: 'auto'
          }))
        })
      }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        collapsable: false,
        onLayout: onRootLayout,
        style: {
          alignSelf: textStyle.alignSelf,
          width: textStyle.width != null ? textStyle.width : 'auto',
          maxWidth: '100%',
          padding: props.textPadding
        },
        children: getTextObject(textStyle)
      })]
    }, `${key}_${tooltip != null ? tooltip.length : '0'}`);
  };
  var render = function render() {
    if (props.children == null || typeof props.children === 'string' && props.children.length === 0) return null;
    var numberOfLines = props.numberOfLines,
      strong = props.strong,
      fontSize = props.fontSize,
      fixedWidth = props.fixedWidth,
      textAlign = props.textAlign,
      onPress = props.onPress;
    var type = props.type ? props.type : MaterialTextTypes.Body1;
    var color = props.color ? props.color : _ThemeManager.ThemeManager.style.defaultTextColor;
    var backgroundColor = props.backgroundColor ? props.backgroundColor : 'transparent';
    var centeredText = props.centeredText ? props.centeredText : false;
    var centeredBox = props.centeredBox ? props.centeredBox : false;
    var textPadding = props.textPadding ? props.textPadding : 2;
    var textStyle = getStyleForType(type, color, backgroundColor, centeredText, centeredBox, textPadding, strong, fontSize, fixedWidth, textAlign);
    if (_reactNativeWeb.Platform.OS === 'web' && numberOfLines != null && numberOfLines > 0) {
      if (textStyle.lineHeight != null && textStyle.height == null) {
        textStyle.height = textStyle.lineHeight * numberOfLines;
      } else if (textStyle.fontSize != null && textStyle.height == null) {
        textStyle.height = textStyle.fontSize * 1.2 * numberOfLines;
      }
    }
    if (_reactNativeWeb.Platform.OS === 'web' && numberOfLines === 1 && props.showToolTipOnCutText && fixedWidth != null) {
      return createRippleViewTooltip(textStyle);
    }
    return getTextObject(textStyle, onPress);
  };
  return render();
}