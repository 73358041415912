"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserMails = void 0;
var ReadModelClientRemote_1 = require("cqrs-core/build/src/client/query/ReadModelClientRemote");
var UserMailsEntity_1 = require("upmesh-auth-core/build/src/client/query/entities/UserMailsEntity");
var UserMails = function (_ReadModelClientRemot) {
  (0, _inherits2.default)(UserMails, _ReadModelClientRemot);
  var _super = _createSuper(UserMails);
  function UserMails() {
    var _this;
    (0, _classCallCheck2.default)(this, UserMails);
    _this = _super.apply(this, arguments);
    _this.exampleEntity = new UserMailsEntity_1.UserMailsEntity();
    _this.onEvents = [];
    return _this;
  }
  return (0, _createClass2.default)(UserMails);
}(ReadModelClientRemote_1.ReadModelClientRemote);
exports.UserMails = UserMails;