"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Filter = void 0;
var Filter = function () {
  function Filter() {
    (0, _classCallCheck2.default)(this, Filter);
  }
  (0, _createClass2.default)(Filter, null, [{
    key: "escapeRegExp",
    value: function escapeRegExp(s) {
      return s.replace(/([.*+?^${}()|\[\]\/\\])/g, '\\$1');
    }
  }]);
  return Filter;
}();
exports.Filter = Filter;