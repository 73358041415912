var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FilePicker = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var FilePicker = exports.FilePicker = function () {
  function FilePicker() {
    (0, _classCallCheck2.default)(this, FilePicker);
  }
  (0, _createClass2.default)(FilePicker, null, [{
    key: "show",
    value: function show(id, _filter, _callback) {
      var chooser = document.getElementById(id);
      if (chooser != null) {
        chooser.click();
      }
    }
  }]);
  return FilePicker;
}();