var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _scrollbarWidth = require("@xobotyi/scrollbar-width");
var _react = require("../../../../../upmesh-support/node_modules/react");
var useScrollbarWidth = function useScrollbarWidth() {
  var _useState = (0, _react.useState)((0, _scrollbarWidth.scrollbarWidth)()),
    _useState2 = (0, _slicedToArray2.default)(_useState, 2),
    sbw = _useState2[0],
    setSbw = _useState2[1];
  (0, _react.useEffect)(function () {
    if (typeof sbw !== 'undefined') {
      return;
    }
    var raf = requestAnimationFrame(function () {
      setSbw((0, _scrollbarWidth.scrollbarWidth)());
    });
    return function () {
      return cancelAnimationFrame(raf);
    };
  }, []);
  return sbw;
};
var _default = exports.default = useScrollbarWidth;