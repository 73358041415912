import { Card } from 'materialTheme/src/theme/components/Card';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { TableOdata } from 'materialTheme/src/theme/components/TableOdata';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { ConnectionHandler } from '../ConnectionHandler';
import { I18n } from '../i18n/I18n';
import { Page } from '../Page';
import { AddPartnerDialog } from './partner/AddPartnerDialog';
export class PartnerView extends PureComponent {
    constructor(props, context) {
        super(props, context);
        this.mounted = false;
        this.userBase = [];
        this.tableProps = [
            {
                title: I18n.m.getMessage('company'),
                keyInData: 'company',
                style: { width: 250 },
                sortable: true,
                dataType: 'string',
                minWidth: 180,
            },
            {
                title: I18n.m.getMessage('contact'),
                keyInData: 'contactName',
                style: { width: 250 },
                sortable: true,
                dataType: 'string',
                minWidth: 180,
            },
            {
                title: I18n.m.getMessage('createdAt'),
                keyInData: 'createdAt',
                style: { width: 250 },
                dataType: 'DateWithTime',
            },
        ];
        this.convert = async (partner) => {
            return partner;
        };
        this.openPartner = (partner) => {
            Routing.instance.openDialog('partner', { id: partner.id })(null);
        };
        this.addPartner = (_e) => {
            Dialog.instance?.open({
                contentPadding: false,
                fullscreenResponsive: true,
                scrollable: false,
                fullscreenButtonsBottom: true,
                buttons: [],
                showCloseIcon: false,
                showCloseButton: false,
                content: <AddPartnerDialog />,
            });
        };
        this.state = {
            user: [],
        };
    }
    componentDidMount() {
        this.mounted = true;
        if (Fab.instance != null) {
            Fab.instance.open({
                fabIcon: 'plus',
                onPressFab: this.addPartner,
            });
        }
    }
    componentWillUnmount() {
        this.mounted = false;
        if (Fab.instance != null) {
            Fab.instance.close();
        }
    }
    render() {
        const { search } = this.props;
        return (<Page pageTitle="Partner">
        <View style={{
                width: '100%',
                marginTop: 4,
            }}>
          <Card style={{ width: '100%', height: 'auto' }}>
            <TableOdata searchField search={search} convert={this.convert} readModel="UpmeshPartner" eventDispatcher={ConnectionHandler.getClient()?.eventDispatcher} tableName="Partners" maxHeight={ResizeEvent.current.contentHeight - 196} actionItemsLength={0} emptyTableText="" emptyTableHint="" onRowPress={this.openPartner} columns={this.tableProps}/>
          </Card>
        </View>
      </Page>);
    }
}
