var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Alert = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _Dialog2 = require("./Dialog");
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var Alert = exports.Alert = function (_Dialog) {
  (0, _inherits2.default)(Alert, _Dialog);
  var _super = _createSuper(Alert);
  function Alert(props) {
    var _this;
    (0, _classCallCheck2.default)(this, Alert);
    _this = _super.call(this, props);
    _this.zIndex = 1500;
    return _this;
  }
  (0, _createClass2.default)(Alert, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      Alert._instance = this;
    }
  }], [{
    key: "instance",
    get: function get() {
      if (Alert._instance != null) {
        return Alert._instance;
      }
      return null;
    }
  }]);
  return Alert;
}(_Dialog2.Dialog);