var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OpacityAnimated = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _BaseAnimated2 = require("./BaseAnimated");
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var OpacityAnimated = exports.OpacityAnimated = function (_BaseAnimated) {
  (0, _inherits2.default)(OpacityAnimated, _BaseAnimated);
  var _super = _createSuper(OpacityAnimated);
  function OpacityAnimated(props) {
    var _this;
    (0, _classCallCheck2.default)(this, OpacityAnimated);
    _this = _super.call(this, props);
    _this.state = Object.assign({}, _this.state, {
      fromOpacity: props.from,
      toOpacity: props.to
    });
    return _this;
  }
  (0, _createClass2.default)(OpacityAnimated, [{
    key: "getAnimated",
    value: function getAnimated() {
      var opacity = this.animationState.interpolate({
        inputRange: [0, 1],
        outputRange: this.props.inverted === true ? [this.state.toOpacity, this.state.fromOpacity] : [this.state.fromOpacity, this.state.toOpacity]
      });
      return {
        opacity: opacity
      };
    }
  }], [{
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.to !== prevState.toOpacity || nextProps.from !== prevState.fromOpacity || nextProps.animationState !== prevState.animationState) {
        return {
          toOpacity: nextProps.to,
          fromOpacity: nextProps.from,
          animationState: nextProps.animationState
        };
      }
      return null;
    }
  }]);
  return OpacityAnimated;
}(_BaseAnimated2.BaseAnimated);