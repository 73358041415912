"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _wrapNativeSuper2 = _interopRequireDefault(require("@babel/runtime/helpers/wrapNativeSuper"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ApplicationError = exports.ApplicationErrorStatusCode = void 0;
var ApplicationErrorStatusCode;
(function (ApplicationErrorStatusCode) {
  ApplicationErrorStatusCode[ApplicationErrorStatusCode["WaitForCreation"] = 202] = "WaitForCreation";
  ApplicationErrorStatusCode[ApplicationErrorStatusCode["BadRequestError"] = 400] = "BadRequestError";
  ApplicationErrorStatusCode[ApplicationErrorStatusCode["UnauthorizedError"] = 401] = "UnauthorizedError";
  ApplicationErrorStatusCode[ApplicationErrorStatusCode["ForbidenError"] = 403] = "ForbidenError";
  ApplicationErrorStatusCode[ApplicationErrorStatusCode["NotFoundError"] = 404] = "NotFoundError";
  ApplicationErrorStatusCode[ApplicationErrorStatusCode["MethodNotAllowedError"] = 405] = "MethodNotAllowedError";
  ApplicationErrorStatusCode[ApplicationErrorStatusCode["NotAcceptableError"] = 406] = "NotAcceptableError";
  ApplicationErrorStatusCode[ApplicationErrorStatusCode["TimeoutError"] = 408] = "TimeoutError";
  ApplicationErrorStatusCode[ApplicationErrorStatusCode["ConflictError"] = 409] = "ConflictError";
  ApplicationErrorStatusCode[ApplicationErrorStatusCode["InternalServerError"] = 500] = "InternalServerError";
  ApplicationErrorStatusCode[ApplicationErrorStatusCode["NotImplementedError"] = 501] = "NotImplementedError";
  ApplicationErrorStatusCode[ApplicationErrorStatusCode["Unavailable"] = 503] = "Unavailable";
})(ApplicationErrorStatusCode || (exports.ApplicationErrorStatusCode = ApplicationErrorStatusCode = {}));
var ApplicationError = function (_Error) {
  (0, _inherits2.default)(ApplicationError, _Error);
  var _super = _createSuper(ApplicationError);
  function ApplicationError(errorMessage, messageCode) {
    var _this;
    var statusCode = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ApplicationErrorStatusCode.InternalServerError;
    var messageData = arguments.length > 3 ? arguments[3] : undefined;
    (0, _classCallCheck2.default)(this, ApplicationError);
    _this = _super.call(this, errorMessage);
    _this.errorMessage = errorMessage;
    _this.name = messageCode.toString();
    _this.messageCode = messageCode;
    _this.statusCode = statusCode;
    _this.messageData = messageData;
    return _this;
  }
  (0, _createClass2.default)(ApplicationError, null, [{
    key: "returnApplicationError",
    value: function returnApplicationError(e) {
      if (e == null) {
        return new ApplicationError('unknown Error', 'internalServer', ApplicationErrorStatusCode.InternalServerError);
      }
      if (e instanceof ApplicationError) {
        return e;
      }
      if (e.statusCode != null && e.errorMessage != null && e.messageCode != null) {
        return new ApplicationError(e.errorMessage, e.messageCode, e.statusCode);
      }
      return new ApplicationError(JSON.stringify(e), 'internalServer', ApplicationErrorStatusCode.InternalServerError);
    }
  }]);
  return ApplicationError;
}((0, _wrapNativeSuper2.default)(Error));
exports.ApplicationError = ApplicationError;