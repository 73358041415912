import * as _ from 'lodash';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { TableOdata } from 'materialTheme/src/theme/components/TableOdata';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { DefaultErrorHandler } from 'upmesh-client/src/components/DefaultErrorHandler';
import { ConnectionHandler } from '../ConnectionHandler';
import { I18n } from '../i18n/I18n';
import { Page } from '../Page';
import { AddCompanyDialog } from './company/AddCompanyDialog';
import { ChangeCompanyDialog } from './company/ChangeCompanyDialog';
import { CompanyChart } from './CompanyChart';
export class CompanyView extends PureComponent {
    constructor(props, context) {
        super(props, context);
        this.mounted = false;
        this.tableProps = [
            {
                title: I18n.m.getMessage('company'),
                keyInData: 'company',
                style: { width: 250 },
                sortable: true,
                dataType: 'string',
                minWidth: 180,
            },
            {
                title: I18n.m.getMessage('contact'),
                keyInData: 'contactName',
                style: { width: 250 },
                sortable: true,
                dataType: 'string',
                minWidth: 180,
            },
            {
                title: I18n.m.getMessage('createdAt'),
                keyInData: 'createdAt',
                style: { width: 250 },
                dataType: 'DateWithTime',
            },
        ];
        this.changeCompany = (e) => () => {
            Dialog.instance?.open({
                showCloseIcon: false,
                showCloseButton: false,
                contentPadding: false,
                fullscreenResponsive: true,
                scrollable: false,
                fullscreenButtonsBottom: true,
                buttons: [],
                onClose: () => {
                    this.lastCompany = '';
                    this.forceUpdate();
                },
                content: <ChangeCompanyDialog data={e}/>,
            });
        };
        this.partners = {};
        this.openCompany = (company) => {
            Routing.instance.openDialog('company', { id: company.id })(null);
        };
        this.filter = _.debounce((text) => {
            Routing.instance.changeQueryParameter({ search: text }, true);
        }, 300);
        this.openHistoryDialog = async () => {
            LoadingEvents.instance.startLoading();
            try {
                const client = ConnectionHandler.getClient();
                if (client != null) {
                    const companies = await client.company.get({
                        select: ['createdAt'],
                    });
                    LoadingEvents.instance.stopLoading();
                    Dialog.instance?.open({
                        showCloseIcon: true,
                        fullscreen: true,
                        content: <CompanyChart companies={companies}/>,
                    });
                }
            }
            catch (e) {
                console.debug('cant get online users', e);
                LoadingEvents.instance.stopLoading();
            }
        };
        this.convert = async (projects) => {
            let projects2 = [];
            const client = ConnectionHandler.getClient();
            if (client != null) {
                const p = [];
                projects.forEach((project) => {
                    p.push(new Promise((resolve) => {
                        this.getPartner(project)
                            .then((c) => {
                            project['partner'] = c;
                            resolve(project);
                        })
                            .catch((err) => {
                            console.debug('cant get creator', err);
                            resolve(project);
                        });
                    }));
                });
                projects2 = await Promise.all(p);
            }
            return projects2;
        };
        this.addCompany = (_e) => {
            Dialog.instance?.open({
                contentPadding: false,
                fullscreenResponsive: true,
                scrollable: false,
                fullscreenButtonsBottom: true,
                buttons: [],
                showCloseIcon: false,
                showCloseButton: false,
                content: <AddCompanyDialog />,
            });
        };
        if (!ConnectionHandler.isPartner) {
            this.tableProps.push({
                title: I18n.m.getMessage('partner'),
                keyInData: 'partner',
                style: { width: 250 },
                sortable: false,
                dataType: 'string',
                minWidth: 180,
            });
        }
        this.state = {
            user: [],
        };
    }
    componentDidMount() {
        this.mounted = true;
        if (Fab.instance != null) {
            Fab.instance.open({
                fabIcon: 'plus',
                onPressFab: this.addCompany,
            });
        }
    }
    componentWillUnmount() {
        this.mounted = false;
        if (Fab.instance != null) {
            Fab.instance.close();
        }
    }
    async getPartner(item) {
        if (item.partnerId == null)
            return 'upmesh';
        if (this.partners[item.partnerId] != null)
            return this.partners[item.partnerId];
        const partner = await ConnectionHandler.getClient()?.upmeshPartner.getById(item.partnerId);
        if (partner != null) {
            this.partners[item.partnerId] = partner.company;
        }
        return partner != null ? partner.company : '?';
    }
    render() {
        const { search } = this.props;
        return (<Page pageTitle={`${I18n.m.getMessage('companyCustomers')}`} rightButtons={[
                <Icon toolTip="history" icon="history" key="info" onPress={() => {
                        this.openHistoryDialog().catch(DefaultErrorHandler.showDefaultErrorAlert);
                    }}/>,
            ]}>
        <View style={{
                width: '100%',
                marginTop: 4,
            }}>
          <Card style={{ width: '100%', height: 'auto' }}>
            <TableOdata searchField search={search} convert={this.convert} readModel="Company" eventDispatcher={ConnectionHandler.getClient()?.eventDispatcher} tableName="Companies" maxHeight={ResizeEvent.current.contentHeight - 196} actionItemsLength={0} emptyTableText="" emptyTableHint="" onRowPress={this.openCompany} columns={this.tableProps}/>
          </Card>
        </View>
      </Page>);
    }
}
