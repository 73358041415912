var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VectorCalc = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var VectorCalc = exports.VectorCalc = function () {
  function VectorCalc() {
    (0, _classCallCheck2.default)(this, VectorCalc);
  }
  (0, _createClass2.default)(VectorCalc, [{
    key: "calcCenter",
    value: function calcCenter(point1, point2) {
      return {
        x: this.middle(point1.x, point2.x),
        y: this.middle(point1.y, point2.y)
      };
    }
  }, {
    key: "middle",
    value: function middle(p1, p2) {
      return p1 > p2 ? p1 - (p1 - p2) / 2 : p2 - (p2 - p1) / 2;
    }
  }, {
    key: "calcDistance",
    value: function calcDistance(point1, point2) {
      var dx = Math.abs(point1.x - point2.x);
      var dy = Math.abs(point1.y - point2.y);
      return Math.sqrt(dx ** 2 + dy ** 2);
    }
  }], [{
    key: "instance",
    get: function get() {
      if (VectorCalc._instance == null) {
        VectorCalc._instance = new VectorCalc();
      }
      return VectorCalc._instance;
    }
  }]);
  return VectorCalc;
}();