var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DeviceManager = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _ErrorReporter = require("odatarepos/build/src/reporting/ErrorReporter");
var uuid = _interopRequireWildcard(require("uuid"));
var _SecureLocalStoreB = require("./SecureLocalStoreB");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var DeviceManager = exports.DeviceManager = function () {
  function DeviceManager() {
    (0, _classCallCheck2.default)(this, DeviceManager);
  }
  (0, _createClass2.default)(DeviceManager, null, [{
    key: "deviceId",
    get: function get() {
      return DeviceManager._deviceId;
    }
  }, {
    key: "loadDeviceId",
    value: function () {
      var _loadDeviceId = (0, _asyncToGenerator2.default)(function* () {
        try {
          if (DeviceManager._deviceId != null) return DeviceManager._deviceId;
          var deviceId = yield _SecureLocalStoreB.SecureLocalStoreB.instance.getItem('device');
          if (deviceId != null) {
            DeviceManager._deviceId = deviceId;
            return deviceId;
          }
          DeviceManager._deviceId = uuid.v1();
          yield _SecureLocalStoreB.SecureLocalStoreB.instance.setItem('device', DeviceManager._deviceId, new Date(9999, 1, 1));
          return DeviceManager._deviceId;
        } catch (e) {
          _ErrorReporter.ErrorReporter.sendReport({
            subject: 'cant save deviceId',
            data: e,
            type: 'warn'
          });
        }
        return uuid.v1();
      });
      function loadDeviceId() {
        return _loadDeviceId.apply(this, arguments);
      }
      return loadDeviceId;
    }()
  }]);
  return DeviceManager;
}();