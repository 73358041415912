"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ErrorReporter = exports.SENTRY_CONFIG_KEY = void 0;
var utils_1 = require("@sentry/utils");
exports.SENTRY_CONFIG_KEY = 'https://b1b7dd1e52d848728968e55b476ec864@s.upmesh.de/2';
var ErrorReporter = function () {
  function ErrorReporter() {
    (0, _classCallCheck2.default)(this, ErrorReporter);
  }
  (0, _createClass2.default)(ErrorReporter, null, [{
    key: "setUser",
    value: function setUser(id) {
      if (ErrorReporter._Reporter != null) {
        try {
          ErrorReporter._Reporter.setUser({
            id: id
          });
        } catch (e) {}
      }
    }
  }, {
    key: "setENVIRONMENTExtra",
    value: function setENVIRONMENTExtra(extra) {
      ErrorReporter._ENVIRONMENTEXTRA = extra;
    }
  }, {
    key: "ENVIRONMENT",
    get: function get() {
      return ErrorReporter._ENVIRONMENT;
    },
    set: function set(e) {
      ErrorReporter._ENVIRONMENT = e;
    }
  }, {
    key: "Reporter",
    get: function get() {
      return ErrorReporter._Reporter;
    },
    set: function set(sentry) {
      ErrorReporter._Reporter = sentry;
    }
  }, {
    key: "sendReport",
    value: function sendReport(report) {
      try {
        var type = report.type.toUpperCase();
        if (ErrorReporter._Reporter != null) {
          if ((0, utils_1.isInstanceOf)(report.data, Error)) {
            report.data.message = `${type}: ${report.subject} - ${report.data['message']}`;
            ErrorReporter._Reporter.captureException(report.data);
          } else {
            var reportedError = new Error(`${type}: ${report.subject} - ${report.data['message']}`);
            reportedError.stack += `\nCaused by: ${report.data['stack']}`;
            ErrorReporter._Reporter.captureException(reportedError);
          }
          return;
        }
        var e = report.data;
        var err = e.message == null ? Object.assign({}, e, {
          message: e.toString()
        }) : Object.assign({}, e, {
          message: e.message
        });
      } catch (e) {}
    }
  }]);
  return ErrorReporter;
}();
exports.ErrorReporter = ErrorReporter;
ErrorReporter.routingInstrumentation = null;
ErrorReporter._ENVIRONMENT = 'development';
ErrorReporter.limitPerDay = 50;
ErrorReporter.perDayCounter = 0;
ErrorReporter.lastResetDay = undefined;