var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProOnlyDialogContent = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-support/node_modules/react-native-web");
var _ResizeEvent = require("../ResizeEvent");
var _ThemeManager = require("../ThemeManager");
var _ContainedButton = require("./button/ContainedButton");
var _MaterialText = require("./text/MaterialText");
var _jsxRuntime = require("../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var ProOnlyDialogContent = exports.ProOnlyDialogContent = function (_PureComponent) {
  (0, _inherits2.default)(ProOnlyDialogContent, _PureComponent);
  var _super = _createSuper(ProOnlyDialogContent);
  function ProOnlyDialogContent() {
    var _this;
    (0, _classCallCheck2.default)(this, ProOnlyDialogContent);
    _this = _super.apply(this, arguments);
    _this.renderImage = function () {
      var imageSource = _this.props.imageSource;
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.Image, {
        source: imageSource,
        style: {
          width: Math.min(300, _ResizeEvent.ResizeEvent.current.contentWidth - 80),
          height: Math.min(300, _ResizeEvent.ResizeEvent.current.contentWidth - 80),
          resizeMode: 'contain',
          marginTop: 16,
          marginBottom: 16,
          alignSelf: 'center'
        },
        resizeMode: "contain"
      });
    };
    return _this;
  }
  (0, _createClass2.default)(ProOnlyDialogContent, [{
    key: "render",
    value: function render() {
      var _this$props = this.props,
        headline = _this$props.headline,
        description = _this$props.description,
        buttonFunction = _this$props.buttonFunction,
        buttonText = _this$props.buttonText,
        imagePosition = _this$props.imagePosition;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          justifyContent: 'center',
          padding: _ThemeManager.ThemeManager.style.contentPaddingValue,
          paddingBottom: 8
        },
        children: [imagePosition === 'above' ? this.renderImage() : undefined, (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            height: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(8)
          }
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          type: _MaterialText.MaterialTextTypes.H6,
          centeredText: true,
          centeredBox: true,
          children: headline
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            height: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(8)
          }
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          type: _MaterialText.MaterialTextTypes.Body1,
          centeredText: true,
          centeredBox: true,
          children: description
        }), imagePosition !== 'above' ? this.renderImage() : undefined, (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            alignSelf: 'center',
            paddingTop: 36
          },
          children: (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            title: buttonText,
            onPress: buttonFunction
          })
        })]
      });
    }
  }]);
  return ProOnlyDialogContent;
}(_react.PureComponent);