import * as _ from 'lodash';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { TableOdata } from 'materialTheme/src/theme/components/TableOdata';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { ConnectionHandler } from '../ConnectionHandler';
import { I18n } from '../i18n/I18n';
import { Page } from '../Page';
import { ProjectChart } from './ProjectChart';
const bytes = require('bytes');
export class ProjectsView extends PureComponent {
    constructor(props, context) {
        super(props, context);
        this.mounted = false;
        this.tableProps = [
            {
                title: I18n.m.getMessage('projects'),
                keyInData: 'title',
                style: { width: 250 },
                sortable: true,
                dataType: 'string',
                minWidth: 180,
            },
            {
                title: I18n.m.getMessage('creator'),
                keyInData: 'creatorName',
                style: { width: 250 },
                sortable: true,
                dataType: 'string',
                minWidth: 180,
            },
            {
                title: I18n.m.getMessage('version'),
                keyInData: 'projectSubscription',
                style: { width: 250 },
                sortable: true,
                dataType: 'string',
                minWidth: 180,
            },
            {
                title: I18n.m.getMessage('createdAt'),
                keyInData: 'createdAt',
                style: { width: 250 },
                dataType: 'Date',
            },
            {
                title: I18n.m.getMessage('lastModifiedAt'),
                keyInData: 'lastModifiedAt',
                style: { width: 250 },
                dataType: 'Date',
            },
            {
                title: I18n.m.getMessage('size'),
                keyInData: 'storageUsageInBytes',
                style: { width: 250 },
                sortable: true,
                dataType: 'number',
                minWidth: 180,
                format: (item) => {
                    if (item.storageUsageInBytes == null)
                        return '0';
                    return bytes(item.storageUsageInBytes);
                },
            },
            {
                title: I18n.m.getMessage('status'),
                keyInData: 'deleted',
                style: { width: 250 },
                sortable: true,
                dataType: 'string',
                minWidth: 180,
                format: (item) => {
                    try {
                        if (item.deleted)
                            return I18n.m.getMessage('deleted');
                        if (item.archived)
                            return I18n.m.getMessage('archived');
                    }
                    catch (e) {
                        console.debug('cant get status', e);
                    }
                    return I18n.m.getMessage('active');
                },
            },
        ];
        this.base = [];
        this.setFilter = _.debounce((text) => {
            Routing.instance.changeQueryParameter({ search: text }, true);
        }, 300);
        this.openHistoryDialog = () => {
            LoadingEvents.instance.startLoading();
            const asyncNow = async () => {
                try {
                    const client = ConnectionHandler.getClient();
                    if (client != null) {
                        const projects = await client.project.get({
                            select: ['createdAt'],
                            filter: `deletedFromServer ne true and title ne 'Willkommens-Projekt 👋'`,
                        });
                        LoadingEvents.instance.stopLoading();
                        Dialog.instance?.open({
                            showCloseIcon: true,
                            fullscreen: true,
                            content: <ProjectChart projects={projects}/>,
                        });
                    }
                }
                catch (e) {
                    console.debug('cant get online users', e);
                    LoadingEvents.instance.stopLoading();
                }
            };
            asyncNow().catch((err) => console.error(err));
        };
        this.convert = async (projects) => {
            let projects2 = [];
            const client = ConnectionHandler.getClient();
            if (client != null) {
                const p = [];
                projects.forEach((project) => {
                    p.push(new Promise((resolve) => {
                        this.getCreator(project)
                            .then((c) => {
                            project['creatorName'] = c;
                            resolve(project);
                        })
                            .catch((err) => {
                            console.debug('cant get creator', err);
                            resolve(project);
                        });
                    }));
                });
                projects2 = await Promise.all(p);
            }
            return projects2;
        };
        this.getCreator = async (item) => {
            try {
                const client = ConnectionHandler.getClient();
                if (client != null) {
                    if (item.creator == null)
                        return '';
                    if (item.creator.startsWith('company')) {
                        const companyId = item.creator.slice(7);
                        const company = await client.company.getById(companyId);
                        return company.company;
                    }
                    const user = await client.user.getById(item.creator);
                    return user.getFullName();
                }
            }
            catch (e) {
                console.debug('cant get owner', e);
            }
            return '';
        };
        this.openProject = (project) => {
            Routing.instance.openDialog('project', { id: project.id })(null);
        };
        this.state = {
            projects: [],
        };
    }
    componentDidMount() {
        this.mounted = true;
        this.listenerId = ConnectionHandler.getClient()?.eventDispatcher.attach({
            callback: (_e) => {
            },
            readModelName: 'Project',
        });
        if (ConnectionHandler.isPartner) {
            Routing.instance.goTo('/companies', true);
        }
        else {
        }
    }
    componentWillUnmount() {
        this.mounted = false;
        if (this.listenerId) {
            ConnectionHandler.getClient()?.eventDispatcher.detach('Project', this.listenerId);
        }
    }
    render() {
        const { search } = this.props;
        return (<Page pageTitle={`${I18n.m.getMessage('projects')}`} rightButtons={[<Icon toolTip="history" icon="history" key="info" onPress={this.openHistoryDialog}/>]}>
        <View style={{
                width: '100%',
                marginTop: 4,
            }}>
          <Card style={{ width: '100%', height: 'auto' }}>
            <TableOdata readModel="Project" searchField search={search} convert={this.convert} tableName="Projects" eventDispatcher={ConnectionHandler.getClient()?.eventDispatcher} maxHeight={ResizeEvent.current.contentHeight - 196} actionItemsLength={0} emptyTableText="" emptyTableHint="" onRowPress={this.openProject} columns={this.tableProps}/>
          </Card>
        </View>
      </Page>);
    }
}
