var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ScrollContainer = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _lodash = _interopRequireDefault(require("lodash"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var uuid = _interopRequireWildcard(require("uuid"));
var _ResizeEvent = require("../../ResizeEvent");
var _ThemeManager = require("../../ThemeManager");
var _Measurement = require("../utils/Measurement");
var _ScrollEvents = require("./ScrollEvents");
var _ScrollHandler = require("./ScrollHandler");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var ScrollContainer = exports.ScrollContainer = function (_PureComponent) {
  (0, _inherits2.default)(ScrollContainer, _PureComponent);
  var _super = _createSuper(ScrollContainer);
  function ScrollContainer(props, context) {
    var _this;
    (0, _classCallCheck2.default)(this, ScrollContainer);
    _this = _super.call(this, props, context);
    _this.scrollEvent = new _ScrollEvents.ScrollEvents();
    _this.scrollPosition = {
      x: 0,
      y: 0
    };
    _this.enableScroll = function (e) {
      var enabled = _this.state.enabled;
      if ((e.containerId == null || e.containerId === _this.containerId) && enabled !== e.enabled) {
        _this.setState({
          enabled: e.enabled
        });
      }
    };
    _this.onResize = function (e) {
      _this.setState({
        keyboardHeight: e.currentKeyboardHeight
      }, function () {
        _this.ensureScroll().catch(function (err) {
          return void 0;
        });
      });
    };
    _this.setRef = function (r) {
      _this.scrollContainer = r;
    };
    _this.handleScroll = function (e) {
      if (e != null && e.nativeEvent != null && e.nativeEvent.contentOffset != null && e.nativeEvent.contentOffset.y != null && e.nativeEvent.contentOffset.x != null) {
        _this.scrollPosition = {
          y: e.nativeEvent.contentOffset.y,
          x: e.nativeEvent.contentOffset.x
        };
        _this.scrollEvent.scrollPostion = _this.scrollPosition;
        _ScrollHandler.ScrollHandler.scrolledEvent.post(Object.assign({
          containerId: _this.containerId
        }, _this.scrollPosition));
        if (_this.props.onScroll != null) {
          _this.props.onScroll(e);
        }
      }
    };
    _this.scrollTo = _lodash.default.debounce(function (props) {
      if (_this.containerId === props.containerId) {
        var asyncNow = function () {
          var _ref = (0, _asyncToGenerator2.default)(function* () {
            try {
              _this.hasScrolled = true;
              if (props.ref != null) {
                var elementPosition = yield _Measurement.Measurement.measureInLayout(props.ref, _this.scrollContainer);
                _this.ensureIsVisible = elementPosition;
                _this.ensureScroll().catch(function (err) {
                  return void 0;
                });
              } else if (_this.scrollContainer != null && typeof _this.scrollContainer.scrollTo === 'function') {
                _this.scrollContainer.scrollTo(props);
              }
            } catch (e) {}
          });
          return function asyncNow() {
            return _ref.apply(this, arguments);
          };
        }();
        asyncNow().catch(function (err) {
          return void 0;
        });
      }
    }, 66, {
      leading: false
    });
    _this.scrollToEnd = function (props) {
      if (_this.containerId !== props.containerId) return;
      if (_this.scrollContainer == null || typeof _this.scrollContainer.scrollToEnd !== 'function') return;
      _this.hasScrolled = true;
      _this.scrollContainer.scrollToEnd({
        animated: props.animated
      });
    };
    _this.sendScrollPosition = function (containerId) {
      if (containerId === _this.containerId) {
        _ScrollHandler.ScrollHandler.scrollPosition.post(Object.assign({
          containerId: containerId
        }, _this.scrollPosition));
      }
    };
    _this.state = {
      enabled: props.scrollEnabled != null ? props.scrollEnabled : true,
      keyboardHeight: _ResizeEvent.ResizeEvent.current.currentKeyboardHeight
    };
    return _this;
  }
  (0, _createClass2.default)(ScrollContainer, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.containerId = this.props.containerId != null ? this.props.containerId : uuid.v1();
      _ScrollHandler.ScrollHandler.currentScrollContainer.push(this.containerId);
      _ScrollHandler.ScrollHandler.scrollEvent.attach(this.scrollTo);
      _ScrollHandler.ScrollHandler.scrollEnableEvent.attach(this.enableScroll);
      _ScrollHandler.ScrollHandler.scrollToEndEvent.attach(this.scrollToEnd);
      _ScrollHandler.ScrollHandler.containerScrollPosition.attach(this.sendScrollPosition);
      _ScrollHandler.ScrollHandler.scrolledEvents[this.containerId] = this.scrollEvent;
      _ResizeEvent.ResizeEvent.onResize.attach(this.onResize);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      _ScrollHandler.ScrollHandler.scrollEvent.detach(this.scrollTo);
      _ScrollHandler.ScrollHandler.scrollEnableEvent.detach(this.enableScroll);
      _ScrollHandler.ScrollHandler.containerScrollPosition.detach(this.sendScrollPosition);
      var index = _ScrollHandler.ScrollHandler.currentScrollContainer.indexOf(this.containerId);
      _ScrollHandler.ScrollHandler.scrolledEvents[this.containerId] = undefined;
      _ScrollHandler.ScrollHandler.currentScrollContainer.splice(index, 1);
      _ResizeEvent.ResizeEvent.onResize.detach(this.onResize);
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(_prevProps, prevState) {
      if (prevState.keyboardHeight !== this.state.keyboardHeight && this.hasScrolled) {
        if (this.state.keyboardHeight > 0 && this.ensureIsVisible != null) {
          this.ensureScroll().catch(function (err) {
            return void 0;
          });
        }
      }
    }
  }, {
    key: "render",
    value: function render() {
      var scrollViewProps = this.props;
      var _this$state = this.state,
        enabled = _this$state.enabled,
        keyboardHeight = _this$state.keyboardHeight;
      var contentContainerStyle = {};
      if (scrollViewProps != null && scrollViewProps.contentContainerStyle != null) {
        contentContainerStyle = Object.assign({}, scrollViewProps.contentContainerStyle);
      }
      contentContainerStyle['paddingBottom'] = contentContainerStyle['paddingBottom'] != null ? contentContainerStyle['paddingBottom'] + keyboardHeight : keyboardHeight + 32;
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.ScrollView, Object.assign({
        collapsable: false
      }, scrollViewProps, {
        bounces: false,
        onScroll: this.handleScroll,
        keyboardDismissMode: "none",
        keyboardShouldPersistTaps: "always",
        scrollEventThrottle: 16,
        scrollEnabled: enabled,
        ref: this.setRef,
        contentContainerStyle: contentContainerStyle
      }));
    }
  }, {
    key: "ensureScroll",
    value: function () {
      var _ensureScroll = (0, _asyncToGenerator2.default)(function* () {
        var _this2 = this;
        if (this.ensureIsVisible != null) {
          var elementPosition = this.ensureIsVisible;
          var y = this.ensureIsVisible.y;
          if (_reactNativeWeb.Platform.OS === 'web') {
            var getScrollPosition = yield _ScrollHandler.ScrollHandler.getScrollPosition(this.containerId);
            y = getScrollPosition.y + elementPosition.y;
          }
          var safeAreaHeight = _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(64);
          requestAnimationFrame(function () {
            var min = _this2.scrollPosition.y + safeAreaHeight;
            var max = _this2.scrollPosition.y + _ResizeEvent.ResizeEvent.current.contentHeight - elementPosition.height - 128 - _ResizeEvent.ResizeEvent.current.currentKeyboardHeight - safeAreaHeight;
            var isVisible = y > min && y < max;
            if (!isVisible && _this2.scrollContainer != null && typeof _this2.scrollContainer.scrollTo === 'function') {
              _this2.oldScrollPosition = _this2.scrollPosition;
              _this2.scrollContainer.scrollTo({
                y: y - safeAreaHeight
              });
            }
          });
        }
      });
      function ensureScroll() {
        return _ensureScroll.apply(this, arguments);
      }
      return ensureScroll;
    }()
  }]);
  return ScrollContainer;
}(_react.PureComponent);