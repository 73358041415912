import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { DateInputFormFilled } from 'materialTheme/src/theme/components/forminput/DateInputFormFilled';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import NumberPicker from 'materialTheme/src/theme/components/NumberPicker';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useRef, useState } from 'react';
import { DefaultErrorHandler } from 'upmesh-client/src/components/DefaultErrorHandler';
import { AddPayment } from 'upmesh-support-core/src/commands/AddPayment';
import { ChangePayment } from 'upmesh-support-core/src/commands/ChangePayment';
import { PaymentsEntity } from 'upmesh-support-core/src/entities/PaymentsEntity';
import * as uuid from 'uuid';
import { ConnectionHandler } from '../../ConnectionHandler';
import { I18n } from '../../i18n/I18n';
export function PaymentAdOrChangeDialog(props) {
    const newOne = useRef(props.payment == null);
    const itemId = useRef(props.payment != null ? props.payment.id : undefined);
    const t = new Date();
    const [isLoading, setIsLoading] = useState(true);
    const [fromList, setFromList] = useState([]);
    const [toList, setToList] = useState([]);
    const [fromSelectedIndex, setFromSelectedIndex] = useState(0);
    const [toSelectedIndex, setToSelectedIndex] = useState(0);
    const [percentUpmesh, setPercentUpmesh] = useState(undefined);
    const [currentSubAddData, setCurrentSubAddData] = useState(props.payment
        ? { ...props.payment }
        : {
            to: props.to,
            due: t,
            from: ConnectionHandler.isPartner && ConnectionHandler.partnerId ? ConnectionHandler.partnerId : undefined,
            amount: 1,
        });
    const getToList = async () => {
        const filter = currentSubAddData.from ? `partnerId eq '${currentSubAddData.from}'` : undefined;
        const company = await ConnectionHandler.getClient()?.company.get({ filter, orderby: 'company ASC' });
        const l = [];
        const { isPartner } = ConnectionHandler;
        company?.forEach((c) => {
            l.push({ title: `${c.company}${!isPartner && ` (Firma)`}`, data: { type: 'company', id: c.id } });
        });
        if (!isPartner && !currentSubAddData.from) {
            const partner = await ConnectionHandler.getClient()?.upmeshPartner.get({ orderby: 'company ASC' });
            partner?.forEach((c) => {
                l.push({ title: `${c.company}${!isPartner && ` (Partner)`}`, data: { type: 'partner', id: c.id } });
            });
        }
        setToSelectedIndex(0);
        if (l.length > 0)
            setToList(l);
        setCurrentSubAddData(new PaymentsEntity({
            ...currentSubAddData,
            to: l[0].data,
        }));
    };
    const getFromList = async () => {
        const { isPartner } = ConnectionHandler;
        const l = [{ title: 'upmesh', data: undefined }];
        if (!isPartner) {
            if (props.to == null) {
                const partner = await ConnectionHandler.getClient()?.upmeshPartner.get({ orderby: 'company ASC' });
                partner?.forEach((c) => {
                    l.push({ title: `${c.company}${!isPartner && `(Partner)`}`, data: c.id });
                });
            }
            else if (props.to != null && props.to.type === 'company') {
                const c = await ConnectionHandler.getClient()?.company.getById(props.to.id);
                if (c?.partnerId != null) {
                    const p = await ConnectionHandler.getClient()?.upmeshPartner.getById(c.partnerId);
                    if (p != null) {
                        l.push({ title: `${p.company}${!isPartner && `(Partner)`}`, data: p.id });
                    }
                }
            }
            if (fromSelectedIndex > l.length)
                setFromSelectedIndex(0);
            if (l.length > 0)
                setFromList(l);
        }
    };
    useEffect(() => {
        if (newOne.current) {
            if (props.to == null) {
                getToList().catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
            }
            if (currentSubAddData.from == null) {
                getFromList().catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
            }
        }
        setIsLoading(false);
    }, []);
    useEffect(() => {
        if (newOne.current) {
            if (props.to == null) {
                getToList().catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
            }
        }
        setIsLoading(false);
    }, [fromSelectedIndex]);
    const closeDialog = () => {
        Dialog.instance?.close();
    };
    const submitChangePayment = async () => {
        const id = itemId.current != null ? itemId.current : uuid.v1();
        setIsLoading(true);
        try {
            const data = {
                ...currentSubAddData,
            };
            const add = new ChangePayment(data, id, ConnectionHandler.getConnection().token);
            await add.execute(ConnectionHandler.getStore());
            closeDialog();
        }
        catch (e) {
            console.error(e);
            Routing.instance.alert.post({ text: DefaultErrorHandler.getDefaultErrorMessages(e, I18n.m) });
            setIsLoading(false);
        }
    };
    const submitAddPayment = async () => {
        const id = itemId.current != null ? itemId.current : uuid.v1();
        setIsLoading(true);
        try {
            const data = {
                ...currentSubAddData,
                percentUpmesh,
            };
            const add = new AddPayment(data, id, ConnectionHandler.getConnection().token);
            await add.execute(ConnectionHandler.getStore());
            closeDialog();
        }
        catch (e) {
            console.error(e);
            Routing.instance.alert.post({ text: DefaultErrorHandler.getDefaultErrorMessages(e, I18n.m) });
            setIsLoading(false);
        }
    };
    const change = () => {
        submitChangePayment().catch((err) => console.debug(err));
    };
    const add = () => {
        submitAddPayment().catch((err) => console.debug(err));
    };
    return (<>
      <DialogTitle key="title">{newOne.current ? 'Einmalzahlung hinzufügen' : 'Beleg bearbeiten'}</DialogTitle>
      <DialogContent key="content">
        {newOne.current && !ConnectionHandler.isPartner && (<FormInputPicker labelText="Von" selectedIndex={fromSelectedIndex} list={fromList} onChange={(value) => {
                setCurrentSubAddData(new PaymentsEntity({
                    ...currentSubAddData,
                    from: value.value.data,
                }));
                setFromSelectedIndex(value.index);
            }}/>)}
        {newOne.current && !props.to && (<FormInputPicker labelText="An" selectedIndex={toSelectedIndex} list={toList} onChange={(value) => {
                setCurrentSubAddData(new PaymentsEntity({
                    ...currentSubAddData,
                    to: value.value.data,
                }));
                setToSelectedIndex(value.index);
            }}/>)}
        <FormInputFilled numberOfLines={1} initValue={currentSubAddData.itemNumber} labelText="Artikelnummer" onChange={(value) => {
            setCurrentSubAddData(new PaymentsEntity({
                ...currentSubAddData,
                itemNumber: value,
            }));
        }}/>
        <NumberPicker title="Anzahl" value={currentSubAddData.amount} initValue={currentSubAddData.amount ? currentSubAddData.amount : 1} onChange={(value) => {
            setCurrentSubAddData(new PaymentsEntity({
                ...currentSubAddData,
                amount: value,
            }));
        }} formatTime={false}/>
        <NumberPicker title="Einzelpreis" initValue={currentSubAddData.unitPrice ? currentSubAddData.unitPrice : 0} step={0.01} onChange={(value) => {
            setCurrentSubAddData(new PaymentsEntity({
                ...currentSubAddData,
                unitPrice: Math.round(value * 100) / 100,
            }));
        }} formatTime={false}/>
        {newOne.current && (<DateInputFormFilled labelText="Fällig" key={`expireDate_${currentSubAddData.due.getTime()}`} value={currentSubAddData.due} onChange={(value) => {
                if (value)
                    setCurrentSubAddData(new PaymentsEntity({ ...currentSubAddData, due: value }));
            }} selectDate selectTime={false} clearButton={false}/>)}
        {!newOne.current && (<DateInputFormFilled labelText="In Rechnung gestellt am" key={`billedOn_${currentSubAddData.billedOn ? currentSubAddData.billedOn.getTime() : '0'}`} value={currentSubAddData.billedOn} onChange={(value) => {
                setCurrentSubAddData(new PaymentsEntity({ ...currentSubAddData, billedOn: value }));
            }} selectDate selectTime={false} clearButton={false}/>)}
        {!newOne.current && (<DateInputFormFilled labelText="Bezahlt am" key={`paidOn_${currentSubAddData.paidOn ? currentSubAddData.paidOn.getTime() : '0'}`} value={currentSubAddData.paidOn} onChange={(value) => {
                setCurrentSubAddData(new PaymentsEntity({ ...currentSubAddData, paidOn: value }));
            }} selectDate selectTime={false} clearButton={false}/>)}
        {newOne.current && currentSubAddData.from != null && (<NumberPicker title="Anteil upmesh in Prozent" initValue={percentUpmesh || 50} step={1} min={50} max={100} onChange={(value) => {
                setPercentUpmesh(value);
            }} formatTime={false}/>)}
        <FormInputFilled numberOfLines={5} initValue={currentSubAddData.description} labelText="Beschreibung" onChange={(value) => {
            setCurrentSubAddData(new PaymentsEntity({
                ...currentSubAddData,
                description: value,
            }));
        }}/>
        
      </DialogContent>
      <DialogActions key="actions">
        <ContainedButton disabled={isLoading} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} key="abort" title="abbrechen" onPress={closeDialog}/>
        <ContainedButton key="yes" loading={isLoading} title={!newOne.current ? 'ändern' : 'hinzufügen'} onPress={!newOne.current ? change : add}/>
      </DialogActions>
    </>);
}
export const openpaymentDialog = (props) => (e) => {
    let openPosition;
    if (e != null && e.nativeEvent != null && e.nativeEvent.pageX != null && e.nativeEvent.pageY != null) {
        openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
    }
    Dialog.instance?.open({
        content: <PaymentAdOrChangeDialog {...props}/>,
        fullscreenResponsive: true,
        contentPadding: false,
        openPosition,
    });
};
