"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CommandRoot = void 0;
var uuid = require("uuid");
var CommandOfflineRegister_1 = require("../../client/commands/CommandOfflineRegister");
var CommandRoot = function () {
  function CommandRoot(data, entityId, token) {
    (0, _classCallCheck2.default)(this, CommandRoot);
    this.timeout = 20000;
    this.executed = false;
    this.entityId = entityId === '' ? uuid.v1() : entityId;
    this.token = token;
    this.data = data;
  }
  (0, _createClass2.default)(CommandRoot, [{
    key: "result",
    get: function get() {
      return this._result;
    },
    set: function set(r) {
      this._result = r;
    }
  }, {
    key: "offlineUsage",
    get: function get() {
      return CommandOfflineRegister_1.CommandOfflineRegister.isRegistered(this);
    }
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        return true;
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "clone",
    value: function clone(newData, entityId, token) {
      return new this.constructor(newData, entityId, token);
    }
  }, {
    key: "execute",
    value: function () {
      var _execute = (0, _asyncToGenerator2.default)(function* (onStore) {
        if (this.executed) {
          return;
        }
        this.executed = true;
        var canI = yield this.canI();
        if (canI !== true) {
          throw canI;
        }
        yield this.validate();
        this.result = yield onStore.save(this);
      });
      function execute(_x) {
        return _execute.apply(this, arguments);
      }
      return execute;
    }()
  }]);
  return CommandRoot;
}();
exports.CommandRoot = CommandRoot;