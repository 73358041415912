import { Icon } from 'materialTheme/src/theme/components/Icon';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React from 'react';
import { View } from 'react-native';
import { openControlNoteDialog } from '../controlnotes/ControlNoteAddOrChangeDialog';
import { ControlNoteList } from '../controlnotes/ControlNotesList';
export function PartnerNotes(props) {
    return (<View style={{ width: '100%', height: props.height, position: 'relative' }}>
      <ControlNoteList height={props.height} tableName="partner" forTo={{ id: props.partner.id, type: 'partner' }}/>
      <View style={{ position: 'absolute', right: 16, bottom: 24 }}>
        <Icon onPress={openControlNoteDialog({ for: { type: 'partner', id: props.partner.id } })} icon="plus" color="#FFFFFF" backgroundColor={ThemeManager.style.brandPrimary} radius={24} outerSize={48} toolTip="add"/>
      </View>
    </View>);
}
