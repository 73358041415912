"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ReadModels = void 0;
var ReadModels = function () {
  function ReadModels() {
    (0, _classCallCheck2.default)(this, ReadModels);
  }
  (0, _createClass2.default)(ReadModels, null, [{
    key: "all",
    value: function all() {
      return ReadModels.registeredReadModels;
    }
  }, {
    key: "registerReadModel",
    value: function registerReadModel(readmodel) {
      if (readmodel['readModelName'] != null) {
        var readModelName = readmodel['readModelName'].toLowerCase();
        if (!ReadModels.isRegistered(readModelName)) {
          ReadModels.registeredReadModels.set(readModelName, readmodel);
        }
      }
    }
  }, {
    key: "isRegistered",
    value: function isRegistered(readModelName) {
      return ReadModels.registeredReadModels.has(readModelName.toLowerCase());
    }
  }, {
    key: "get",
    value: function get(name) {
      return ReadModels.registeredReadModels.get(name.toLowerCase());
    }
  }]);
  return ReadModels;
}();
exports.ReadModels = ReadModels;
ReadModels.registeredReadModels = new Map();