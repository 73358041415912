import { StringUtils } from 'cqrs-core/src/core/StringUtils';
import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React from 'react';
import { View } from 'react-native';
import { DefaultErrorHandler } from 'upmesh-client/src/components/DefaultErrorHandler';
import { AddPaymentToCompany } from 'upmesh-support-core/src/commands/company/AddPaymentToCompany';
import { PaymentsEntity } from 'upmesh-support-core/src/entities/PaymentsEntity';
import { ConnectionHandler } from '../../ConnectionHandler';
import { I18n } from '../../i18n/I18n';
import { openpaymentDialog } from '../payments/PaymentAddOrChangeDialog';
import { PaymentTable } from '../payments/PaymentTable';
export function CompanyPayments(props) {
    const checkForPayments = async () => {
        const missingAll = [];
        const client = ConnectionHandler.getClient();
        if (client == null)
            return missingAll;
        const today = new Date();
        const subscriptions = await client.subscription.get({
            filter: `companyId eq '${props.company.id}' and testAccount ne true and expireDate gt ${today.toISOString()}`,
        });
        let partnerSettings;
        if (props.company.partnerId != null) {
            partnerSettings = await client.upmeshPartnerSettings.getById(props.company.partnerId);
        }
        for (const s of subscriptions) {
            const payments = [];
            const start = new Date(s.startDate);
            let newDate = new Date(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0, 0, 0);
            let i = 0;
            while (newDate.getTime() < today.getTime()) {
                const endDate = new Date(newDate.getFullYear(), newDate.getMonth() + s.paymentIntervallInMonth, newDate.getDate(), 0, 0, 0, -1);
                const licenses = s.licenses ? s.licenses : 1;
                const licenseText = s.licenceType === 'collaborator'
                    ? 'Mitarbeiterlizenz'
                    : s.licenceType === 'timeTracking'
                        ? 'Zeiterfasserlizenz'
                        : 'Projektleiterlizenz';
                let { itemNumber } = s;
                if (itemNumber == null || itemNumber.length === 0) {
                    if (partnerSettings != null) {
                        itemNumber =
                            s.licenceType === 'timeTracking'
                                ? partnerSettings.sellingItemNumberTimeTracking
                                : s.licenceType === 'collaborator'
                                    ? partnerSettings.sellingItemNumberCollaborator
                                    : partnerSettings.sellingItemNumber;
                    }
                }
                const unitPrice = s.price * s.paymentIntervallInMonth;
                let description = `${licenses} * ${licenseText} vom ${I18n.m.date().localeDateString(newDate)} bis zum ${I18n.m
                    .date()
                    .localeDateString(endDate)} (${s.paymentIntervallInMonth} Monate)`;
                if (partnerSettings) {
                    if (s.licenceType === 'collaborator' &&
                        partnerSettings.billingTextCollaborator != null &&
                        partnerSettings.billingTextCollaborator.length >= 0) {
                        description = StringUtils.templateString(partnerSettings.billingTextCollaborator, {
                            from: I18n.m.date().localeDateString(newDate),
                            to: I18n.m.date().localeDateString(endDate),
                            amount: licenses,
                            unitPrice,
                            type: licenseText,
                        });
                    }
                    else if (s.licenceType === 'timeTracking' &&
                        partnerSettings.billingTextTimeTracking != null &&
                        partnerSettings.billingTextTimeTracking.length >= 0) {
                        description = StringUtils.templateString(partnerSettings.billingTextTimeTracking, {
                            from: I18n.m.date().localeDateString(newDate),
                            to: I18n.m.date().localeDateString(endDate),
                            amount: licenses,
                            unitPrice,
                            type: licenseText,
                        });
                    }
                    else if (s.licenceType == null &&
                        partnerSettings.billingText != null &&
                        partnerSettings.billingText.length >= 0) {
                        description = StringUtils.templateString(partnerSettings.billingText, {
                            from: I18n.m.date().localeDateString(newDate),
                            to: I18n.m.date().localeDateString(endDate),
                            amount: licenses,
                            unitPrice,
                            type: licenseText,
                        });
                    }
                }
                payments.push(new PaymentsEntity({
                    due: newDate,
                    unitPrice,
                    amount: licenses,
                    totalPrice: licenses * s.price * s.paymentIntervallInMonth,
                    to: { id: props.company.id, type: 'company' },
                    from: props.company.partnerId,
                    subscriptionId: s.id,
                    itemNumber,
                    description,
                }));
                i += 1;
                newDate = new Date(newDate.getFullYear(), newDate.getMonth() + s.paymentIntervallInMonth, newDate.getDate(), 0, 0, 0, 0);
            }
            const get = await client?.payments.get({
                select: ['id', 'due', 'deleted'],
                filter: `to/id eq '${props.company.id}' and subscriptionId eq '${s.id}' and deleted ne true`,
                orderby: 'due ASC',
            });
            if (get.length < i) {
                payments.forEach((p) => {
                    const found = get.find((a) => {
                        return new Date(a.due).getTime() === new Date(p.due).getTime();
                    });
                    if (found == null) {
                        missingAll.push(p);
                    }
                });
            }
        }
        return missingAll;
    };
    const addPayments = async (payments) => {
        for (const p of payments) {
            const c = new AddPaymentToCompany({
                due: p.due,
                unitPrice: p.unitPrice,
                from: p.from,
                amount: p.amount,
                companyId: props.company.id,
                subscriptionId: p.subscriptionId,
                itemNumber: p.itemNumber,
                description: p.description,
            }, p.id, ConnectionHandler.getConnection().token);
            await c.execute(ConnectionHandler.getStore());
        }
    };
    const addDataButFirstCheck = async (checkForMissingFirst = true) => {
        const missing = checkForMissingFirst ? await checkForPayments() : [];
        if (missing && missing.length > 0) {
            Routing.instance.alert.post({
                text: `Es wurden ${missing.length} fehlende Einträge gefunden. Sollen sie erstellt werden?`,
                buttons: [
                    <ContainedButton key="discard" title={I18n.m.getMessage('cancel')} backgroundColor="#FFFFFF" textColor={ThemeManager.style.brandPrimary} onPress={() => {
                            Alert.instance?.close(() => {
                                addDataButFirstCheck(false).catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
                            });
                        }}/>,
                    <ContainedButton key="save" title={I18n.m.getMessage('create')} onPress={() => {
                            Alert.instance?.close(() => {
                                addPayments(missing).catch((e) => {
                                    DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m);
                                });
                            });
                        }}/>,
                ],
            });
        }
        else {
            openpaymentDialog({ to: { type: 'company', id: props.company.id } })();
        }
    };
    const add = () => {
        addDataButFirstCheck().catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
    };
    return (<View style={{ width: '100%', position: 'relative', height: props.height, overflow: 'visible' }}>
      <PaymentTable showSum tableName="company" preFilter={`to/id eq '${props.company.id}'`} height={props.height} hideFields={['fromName', 'toName']} onlyFrom={ConnectionHandler.partnerId
            ? [ConnectionHandler.partnerId]
            : props.company.partnerId
                ? ['', props.company.partnerId]
                : ['']}/>
      <View style={{ position: 'absolute', right: 16, bottom: 24 }}>
        <Icon onPress={add} icon="plus" color="#FFFFFF" backgroundColor={ThemeManager.style.brandPrimary} radius={24} outerSize={48} toolTip="add"/>
      </View>
    </View>);
}
