var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormInputAnimation = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _Animation2 = _interopRequireDefault(require("./Animation"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var FormInputAnimation = exports.FormInputAnimation = function (_Animation) {
  (0, _inherits2.default)(FormInputAnimation, _Animation);
  var _super = _createSuper(FormInputAnimation);
  function FormInputAnimation() {
    var _this;
    var toValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var animationDuration = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 195;
    (0, _classCallCheck2.default)(this, FormInputAnimation);
    _this = _super.call(this, toValue);
    _this.animationDuration = animationDuration;
    _this.animations = _this.createAnimations();
    return _this;
  }
  (0, _createClass2.default)(FormInputAnimation, [{
    key: "createAnimations",
    value: function createAnimations() {
      return {
        opacity: this.animate,
        width: this.animate.interpolate({
          inputRange: [0, 1],
          outputRange: ['0%', '100%']
        })
      };
    }
  }, {
    key: "toValue",
    value: function toValue(_toValue, onFinished) {
      _reactNativeWeb.Animated.timing(this.animate, {
        toValue: _toValue,
        duration: this.animationDuration,
        useNativeDriver: false
      }).start(function () {
        if (onFinished != null) {
          onFinished();
        }
      });
    }
  }]);
  return FormInputAnimation;
}(_Animation2.default);