var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LoadingEvents = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _tsEvents = require("ts-events");
var LoadingEvents = exports.LoadingEvents = function () {
  function LoadingEvents() {
    (0, _classCallCheck2.default)(this, LoadingEvents);
    this.loadingStarted = new _tsEvents.AsyncEvent();
    this.loadingStopped = new _tsEvents.AsyncEvent();
    if (LoadingEvents._instance != null) {
      return LoadingEvents._instance;
    }
  }
  (0, _createClass2.default)(LoadingEvents, [{
    key: "startLoading",
    value: function startLoading() {
      var _this = this;
      var loadingMessage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      if (this.timeOutLoading != null) window.clearTimeout(this.timeOutLoading);
      LoadingEvents.currentState = {
        show: true,
        text: loadingMessage
      };
      this.loadingStarted.post(loadingMessage);
      this.timeOutLoading = window.setTimeout(function () {
        return _this.stopLoading();
      }, 30000);
    }
  }, {
    key: "stopLoading",
    value: function stopLoading() {
      if (this.timeOutLoading != null) window.clearTimeout(this.timeOutLoading);
      LoadingEvents.currentState = {
        show: false,
        text: ''
      };
      this.loadingStopped.post('');
    }
  }], [{
    key: "instance",
    get: function get() {
      if (LoadingEvents._instance == null) {
        LoadingEvents._instance = new LoadingEvents();
      }
      return LoadingEvents._instance;
    }
  }]);
  return LoadingEvents;
}();
LoadingEvents.currentState = {
  show: false,
  text: ''
};