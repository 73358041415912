import { Card } from 'materialTheme/src/theme/components/Card';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { DefaultErrorHandler } from 'upmesh-client/src/components/DefaultErrorHandler';
import { ConnectionHandler } from '../ConnectionHandler';
import { I18n } from '../i18n/I18n';
import { Page } from '../Page';
import { OnlineChart } from './OnlineChart';
export class OnlineView extends PureComponent {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.updateOnlineUser = () => {
            this.init().catch((err) => console.debug('cant init', err));
        };
        this.openUser = (e) => {
            console.log('openuser', e);
            Routing.instance.openDialog('user', { id: e.id })(null);
        };
        this.gotoUser = (id) => (e) => {
            Routing.instance.openDialog('user', { id })(e);
        };
        this.openOnlineDialog = async () => {
            try {
                const f = await fetch(`${ConnectionHandler.apiUrl}/supportroles/cores`, {
                    headers: { authorization: `Bearer ${ConnectionHandler.serverConnection.token}` },
                });
                const o = await f.json();
                const cores = new Map(o);
                const contents = [];
                let i = 0;
                cores.forEach((v, k) => {
                    i += 1;
                    contents.push(<View key={`core_${k}`}>
            <MaterialText>{`core ${i}: ${v.online} Benutzer (${v.connections} Verbindungen)`}</MaterialText>
          </View>);
                });
                Dialog.instance?.open({
                    showCloseIcon: true,
                    content: <View>{contents}</View>,
                });
            }
            catch (e) {
                console.debug('cant get online users', e);
            }
        };
        this.openHistoryDialog = () => {
            try {
                Dialog.instance?.open({
                    showCloseIcon: true,
                    fullscreen: true,
                    content: <OnlineChart />,
                });
            }
            catch (e) {
                console.debug('cant get online users', e);
            }
        };
        this.state = {
            onlineCores: [],
            onlineUser: [],
        };
    }
    componentDidMount() {
        this.mounted = true;
        this.init().catch((err) => console.debug('cant init', err));
        this.onlineTo = setInterval(this.updateOnlineUser, 10000);
    }
    async init() {
        try {
            const onlineCores = [];
            const onlineUser = [];
            const f = await fetch(`${ConnectionHandler.apiUrl}/supportroles/cores`, {
                headers: { authorization: `Bearer ${ConnectionHandler.serverConnection.token}` },
            });
            const o = await f.json();
            const cores = new Map(o);
            let i = 0;
            const keys = Array.from(cores.keys());
            for (const k of keys) {
                const v = cores.get(k);
                if (v != null) {
                    i += 1;
                    onlineCores.push({ coreId: i.toString(), online: v.online });
                    for (const s of v.userOnline) {
                        try {
                            const u = await ConnectionHandler.getClient()?.user.getById(s);
                            if (u != null)
                                onlineUser.push(u);
                        }
                        catch (e) {
                            console.debug('cant get user', e);
                        }
                    }
                }
            }
            if (this.mounted)
                this.setState({ onlineCores, onlineUser });
        }
        catch (e) {
            console.debug('cant get online users', e);
        }
    }
    componentWillUnmount() {
        this.mounted = false;
        if (this.onlineTo)
            clearInterval(this.onlineTo);
    }
    render() {
        const { onlineUser } = this.state;
        return (<Page pageTitle={`Online (${onlineUser.length})`} rightButtons={[
                <Icon toolTip="history" icon="history" key="info" onPress={this.openHistoryDialog}/>,
                <Icon toolTip="info" icon="information-outline" key="info" onPress={() => {
                        this.openOnlineDialog().catch(DefaultErrorHandler.showDefaultErrorAlert);
                    }}/>,
            ]}>
        <View style={{
                width: '100%',
                alignItems: 'center',
                alignContent: 'center',
                marginTop: 24,
            }}>
          <Card style={{ width: '100%', height: 'auto' }}>
            <Table tableName="OnlineUser" maxHeight={ResizeEvent.current.contentHeight - 156} actionItemsLength={0} emptyTableText="" emptyTableHint="" data={onlineUser} onRowPress={this.openUser} columns={[
                {
                    title: I18n.m.getMessage('firstname'),
                    keyInData: 'firstname',
                    style: { width: 250 },
                    sortable: true,
                    dataType: 'string',
                    minWidth: 180,
                },
                {
                    title: I18n.m.getMessage('lastname'),
                    keyInData: 'lastname',
                    style: { width: 250 },
                    sortable: true,
                    dataType: 'string',
                    minWidth: 180,
                },
                {
                    title: I18n.m.getMessage('email'),
                    keyInData: 'emails',
                    style: { width: 250 },
                    dataType: 'string',
                },
                {
                    title: I18n.m.getMessage('registered'),
                    keyInData: 'createdAt',
                    style: { width: 250 },
                    dataType: 'DateWithTime',
                },
            ]}/>
          </Card>
        </View>
      </Page>);
    }
}
