"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _get3 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ReadModelClientOfflineFirst = exports.ReadModelClientOfflineFirstConfig = exports.ResyncOptions = exports.ReadOfflineModel = void 0;
var ApplicationError_1 = require("odatarepos/build/src/entities/ApplicationError");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var ts_events_1 = require("ts-events");
var ReadModelEntity_1 = require("../../core/query/ReadModelEntity");
var ReadModelRoot_1 = require("../../core/query/ReadModelRoot");
var ReadModelChangedDefaultClientDispatcher_1 = require("./ReadModelChangedDefaultClientDispatcher");
var ReadModelClientOnly_1 = require("./ReadModelClientOnly");
var ReadModelClientRemote_1 = require("./ReadModelClientRemote");
var ReadOfflineModel = function (_ReadModelEntity_1$Re) {
  (0, _inherits2.default)(ReadOfflineModel, _ReadModelEntity_1$Re);
  var _super = _createSuper(ReadOfflineModel);
  function ReadOfflineModel(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, ReadOfflineModel);
    _this = _super.call(this, obj);
    _this.entityName = 'ReadOfflineModel';
    _this.lastSync = new Date(1);
    if (obj != null) {
      _this.fill(obj);
    }
    return _this;
  }
  (0, _createClass2.default)(ReadOfflineModel, [{
    key: "createOne",
    value: function createOne(obj) {
      return new ReadOfflineModel(obj);
    }
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {
        return true;
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('lastSync', new OdataEntity_1.OdataIndex(true));
      return baseMap;
    }
  }]);
  return ReadOfflineModel;
}(ReadModelEntity_1.ReadModelEntity);
exports.ReadOfflineModel = ReadOfflineModel;
var ResyncOptions = (0, _createClass2.default)(function ResyncOptions() {
  (0, _classCallCheck2.default)(this, ResyncOptions);
  this.deleteBefore = true;
});
exports.ResyncOptions = ResyncOptions;
var ReadModelClientOfflineFirstConfig = function (_ReadModelRoot_1$Read) {
  (0, _inherits2.default)(ReadModelClientOfflineFirstConfig, _ReadModelRoot_1$Read);
  var _super2 = _createSuper(ReadModelClientOfflineFirstConfig);
  function ReadModelClientOfflineFirstConfig() {
    (0, _classCallCheck2.default)(this, ReadModelClientOfflineFirstConfig);
    return _super2.apply(this, arguments);
  }
  return (0, _createClass2.default)(ReadModelClientOfflineFirstConfig);
}(ReadModelRoot_1.ReadModelRootConfig);
exports.ReadModelClientOfflineFirstConfig = ReadModelClientOfflineFirstConfig;
var ReadModelClientOfflineFirst = function (_ReadModelClientOnly_) {
  (0, _inherits2.default)(ReadModelClientOfflineFirst, _ReadModelClientOnly_);
  var _super3 = _createSuper(ReadModelClientOfflineFirst);
  function ReadModelClientOfflineFirst() {
    var _this2;
    (0, _classCallCheck2.default)(this, ReadModelClientOfflineFirst);
    _this2 = _super3.apply(this, arguments);
    _this2.syncStatusEvent = new ts_events_1.AsyncEvent();
    _this2.authNeeded = true;
    _this2.hasSynced = false;
    _this2.onceSynced = false;
    _this2.shouldSynced = true;
    _this2.lastSyncInitValue = new Date(1);
    _this2.lastSyncFirstValue = new Date(-1);
    _this2.reSyncFilter = [''];
    _this2.initialized = false;
    _this2.onConnect = function (_socket) {
      if (_this2.reSyncFilter.indexOf('') === -1) {
        _this2.reSyncFilter.push('');
      }
      _this2.startSync();
    };
    _this2.onDisconnect = function (_socket) {
      _this2.stopSync();
      _this2.hasSynced = false;
    };
    _this2.onRemoteUpdate = function (e) {
      var lastSync = new Date(-1);
      e.entities.forEach(function (en) {
        _this2.updateOrInsert(en.entity, 0, true).catch(function (err) {
          return void 0;
        });
        if (en.entity.lastModifiedAt.getTime() > lastSync.getTime()) lastSync = new Date(en.entity.lastModifiedAt);
      });
      if (_this2.hasSynced && _this2.onceSynced && !_this2.isSyncing && lastSync.getTime() > 10) {
        var _this2$offlineStateRe;
        var ent = new ReadOfflineModel({
          lastSync: lastSync,
          id: _this2.readModelName
        });
        (_this2$offlineStateRe = _this2.offlineStateRepo) == null || _this2$offlineStateRe.patch(ent).catch(function (err) {
          return void 0;
        });
      }
    };
    _this2.onSyncCouldStart = function () {
      if (_this2.reSyncFilter.indexOf('') === -1) {
        _this2.reSyncFilter.push('');
      }
      _this2.startSync();
    };
    _this2.startSync = function () {
      var timeOut = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 30;
      if (_this2.syncTO != null) clearTimeout(_this2.syncTO);
      _this2.syncTO = setTimeout(_this2.startSyncNow, timeOut);
    };
    _this2.startSyncNow = function () {
      _this2.hasSynced = false;
      if (_this2.initialized && !_this2.isSyncing && _this2.reSyncFilter.length > 0 && _this2.config != null && _this2.offlineStateRepo != null) {}
    };
    _this2.moreCounter = 0;
    _this2.reSynced = 0;
    _this2.updateOrInsert = function () {
      var _ref = (0, _asyncToGenerator2.default)(function* (eOrg, _index) {
        var dispatch = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _this2.onceSynced || _this2.hasSynced;
        var secondTime = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
        if (_this2.config != null && _this2.offlineStateRepo != null && _this2.localRepo != null) {
          var e = _this2.exampleEntity.createOne(eOrg);
          try {
            var update = yield _this2.localRepo.getById(e.id);
            if (update.lastModifiedAt.getTime() <= e.lastModifiedAt.getTime()) {
              var patch = Object.assign({}, update, e);
              yield _this2.localRepo.patch(patch);
              if (dispatch) yield _this2.dispatchChangedEvent([], e).catch(function (err) {
                return void 0;
              });
            }
            return true;
          } catch (error) {
            if (error['statusCode'] === 404) {
              try {
                yield _this2.localRepo.post(e);
                if (dispatch) _this2.dispatchChangedEvent([], e).catch(function (err) {
                  return void 0;
                });
                return true;
              } catch (err) {
                if (err['statusCode'] === 409 && !secondTime) {
                  return yield _this2.updateOrInsert(eOrg, _index, dispatch, true);
                }
                yield _this2.localRepo.deleteFilter('id ne null');
                if (dispatch) _this2.dispatchChangedEvent([], e).catch(function (err) {
                  return void 0;
                });
                return true;
              }
            } else {
              throw error;
            }
          }
        } else if (dispatch) {
          var _e = _this2.exampleEntity.createOne(eOrg);
          _this2.dispatchChangedEvent([], _e).catch(function (err) {
            return void 0;
          });
        }
        return false;
      });
      return function (_x2, _x3) {
        return _ref.apply(this, arguments);
      };
    }();
    return _this2;
  }
  (0, _createClass2.default)(ReadModelClientOfflineFirst, [{
    key: "getRemote",
    get: function get() {
      return this.remoteClient;
    }
  }, {
    key: "localRepo",
    get: function get() {
      var _this$config;
      if (!this.shouldSynced) return undefined;
      return (_this$config = this.config) == null || (_this$config = _this$config.syncDispatcher.db) == null ? void 0 : _this$config.getRepos(this.exampleEntity);
    }
  }, {
    key: "offlineStateRepo",
    get: function get() {
      var _this$config2;
      return (_this$config2 = this.config) == null || (_this$config2 = _this$config2.syncDispatcher.db) == null ? void 0 : _this$config2.getRepos(new ReadOfflineModel());
    }
  }, {
    key: "count",
    value: function () {
      var _count = (0, _asyncToGenerator2.default)(function* (filter, userId) {
        if (this.localRepo == null || !this.onceSynced) {
          return this.remoteClient.count(filter, userId);
        }
        return this.localRepo.count(filter);
      });
      function count(_x4, _x5) {
        return _count.apply(this, arguments);
      }
      return count;
    }()
  }, {
    key: "distinct",
    value: function () {
      var _distinct = (0, _asyncToGenerator2.default)(function* (select, filter, userId) {
        if (this.localRepo == null || !this.onceSynced) {
          return this.remoteClient.distinct(select, filter, userId);
        }
        return this.localRepo.distinct(select, filter);
      });
      function distinct(_x6, _x7, _x8) {
        return _distinct.apply(this, arguments);
      }
      return distinct;
    }()
  }, {
    key: "get",
    value: function () {
      var _get2 = (0, _asyncToGenerator2.default)(function* (options, userId) {
        if (this.config == null) {
          return [];
        }
        if (this.localRepo == null || !this.onceSynced) {
          return this.remoteClient.get(options, userId);
        }
        try {
          return yield this.localRepo.get(options);
        } catch (e) {
          if (this.config.serverConnection().isConnected) {
            return (0, _get3.default)((0, _getPrototypeOf2.default)(ReadModelClientOfflineFirst.prototype), "get", this).call(this, options, userId);
          }
          throw e;
        }
      });
      function get(_x9, _x10) {
        return _get2.apply(this, arguments);
      }
      return get;
    }()
  }, {
    key: "getById",
    value: function () {
      var _getById = (0, _asyncToGenerator2.default)(function* (id, select, userId) {
        if (id == null || id.length === 0) {
          throw new ApplicationError_1.ApplicationError('not found', `${this.exampleEntity.entityName}notFound`, ApplicationError_1.ApplicationErrorStatusCode.NotFoundError);
        }
        if (this.localRepo == null || !this.onceSynced) {
          var result = yield this.remoteClient.getById(id, select, userId);
          return result;
        }
        return this.localRepo.getById(id, select);
      });
      function getById(_x11, _x12, _x13) {
        return _getById.apply(this, arguments);
      }
      return getById;
    }()
  }, {
    key: "getByIdAt",
    value: function () {
      var _getByIdAt = (0, _asyncToGenerator2.default)(function* (id, time, userId) {
        if (this.localRepo == null) {
          throw new ApplicationError_1.ApplicationError('not found', `${this.exampleEntity.entityName}notFound`, ApplicationError_1.ApplicationErrorStatusCode.NotFoundError);
        }
        return this.remoteClient.getByIdAt(id, time, userId);
      });
      function getByIdAt(_x14, _x15, _x16) {
        return _getByIdAt.apply(this, arguments);
      }
      return getByIdAt;
    }()
  }, {
    key: "getEventsById",
    value: function () {
      var _getEventsById = (0, _asyncToGenerator2.default)(function* (id) {
        var withEntity = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        var userId = arguments.length > 2 ? arguments[2] : undefined;
        if (this.localRepo == null) {
          throw new ApplicationError_1.ApplicationError('not found', `${this.exampleEntity.entityName}notFound`, ApplicationError_1.ApplicationErrorStatusCode.NotFoundError);
        }
        return this.remoteClient.getEventsById(id, withEntity, userId);
      });
      function getEventsById(_x17) {
        return _getEventsById.apply(this, arguments);
      }
      return getEventsById;
    }()
  }, {
    key: "init",
    value: function () {
      var _init = (0, _asyncToGenerator2.default)(function* (config) {
        var _this3 = this;
        yield (0, _get3.default)((0, _getPrototypeOf2.default)(ReadModelClientOfflineFirst.prototype), "init", this).call(this, config);
        this.config = config;
        yield this.reset();
        this.remoteClient = new ReadModelClientRemote_1.ReadModelClientRemote();
        if (this.shouldSynced) {
          this.config.serverConnection().onConnect.attach(this.onConnect);
          this.config.serverConnection().onSyncCouldStart.attach(this.onSyncCouldStart);
          this.config.serverConnection().onDisconnect.attach(this.onDisconnect);
        }
        var remoteOptions = {
          exampleEntity: this.exampleEntity,
          serverConnection: this.config.serverConnection,
          eventDispatcher: ReadModelClientOfflineFirst.remoteEventDispatcher,
          remoteDB: this.config.remoteDB
        };
        ReadModelClientOfflineFirst.remoteEventDispatcher.attach({
          readModelName: this.readModelName,
          callback: this.onRemoteUpdate,
          attachKey: `${this.readModelName}ReadModelClientUpdate`
        });
        if (this.shouldSynced) {
          this.config.syncDispatcher.registerSync(this.readModelName, function () {
            _this3.isSyncing = true;
            return _this3.sync();
          }, this.syncStatusEvent);
        }
        yield this.remoteClient.init(remoteOptions);
        this.initialized = true;
        yield this.getLastSyncFromDatabase(new Date(-1));
        if (this.config.serverConnection().isConnected) {}
      });
      function init(_x18) {
        return _init.apply(this, arguments);
      }
      return init;
    }()
  }, {
    key: "repo",
    get: function get() {
      var _this$config3;
      if (this.localRepo) return this.localRepo;
      return (_this$config3 = this.config) == null ? void 0 : _this$config3.repo;
    }
  }, {
    key: "reSync",
    value: function () {
      var _reSync = (0, _asyncToGenerator2.default)(function* (options) {
        if (this.config == null || !this.shouldSynced) {
          return;
        }
        if (options != null && options.idOnly != null && options.idOnly.length > 0) {
          try {
            var count = yield this.remoteClient.count(`id eq '${options.idOnly}'`);
            if (!count) {
              var _this$localRepo;
              (_this$localRepo = this.localRepo) == null || _this$localRepo.delete(options.idOnly).catch(function (err) {});
            }
            return;
          } catch (err) {}
        }
        this.hasSynced = false;
        var f = '';
        if (options != null && options.deleteBefore) {
          try {
            var _this$config$eventDis;
            this.onceSynced = false;
            f = options.filter;
            yield this.localRepo.deleteFilter(f === '' ? `id ne 'noOne'` : f);
            (_this$config$eventDis = this.config.eventDispatcher) == null || _this$config$eventDis.post({
              readModelName: this.readModelName,
              entities: new Map()
            });
          } catch (e) {}
          f = options.filter != null && options.filter.length > 0 ? options.filter : '';
        } else {
          f = '';
        }
        if (this.reSyncFilter.indexOf(f) === -1) {
          this.reSyncFilter.push(f);
          yield this.sync();
        }
      });
      function reSync(_x19) {
        return _reSync.apply(this, arguments);
      }
      return reSync;
    }()
  }, {
    key: "reset",
    value: function () {
      var _reset = (0, _asyncToGenerator2.default)(function* () {
        this.initialized = false;
        this.stopSync();
        this.onceSynced = false;
        this.reSyncFilter = [];
        if (this.remoteClient != null) {
          yield this.remoteClient.reset();
        }
        ReadModelClientOfflineFirst.remoteEventDispatcher.detach(this.readModelName, `${this.readModelName}ReadModelClientUpdate`);
        if (this.config != null) {
          this.config.serverConnection().onConnect.detach(this.onConnect);
          this.config.serverConnection().onSyncCouldStart.detach(this.onSyncCouldStart);
          this.config.serverConnection().onDisconnect.detach(this.onDisconnect);
        }
        this.hasSynced = false;
      });
      function reset() {
        return _reset.apply(this, arguments);
      }
      return reset;
    }()
  }, {
    key: "stopSync",
    value: function stopSync() {
      this.isSyncing = false;
      this.hasSynced = true;
      this.onceSynced = true;
    }
  }, {
    key: "getLastSyncFromDatabase",
    value: function () {
      var _getLastSyncFromDatabase = (0, _asyncToGenerator2.default)(function* (lastSync) {
        if (this.config != null && this.offlineStateRepo != null) {
          try {
            var ls = yield this.offlineStateRepo.getById(this.readModelName);
            this.onceSynced = true;
            return new Date(ls.lastSync);
          } catch (e) {
            if (e.statusCode != null && e.statusCode === 404) {
              var ent = new ReadOfflineModel({
                lastSync: lastSync,
                id: this.readModelName
              });
              yield this.offlineStateRepo.post(ent);
            }
          }
        }
        return lastSync;
      });
      function getLastSyncFromDatabase(_x20) {
        return _getLastSyncFromDatabase.apply(this, arguments);
      }
      return getLastSyncFromDatabase;
    }()
  }, {
    key: "syncPartial",
    value: function () {
      var _syncPartial = (0, _asyncToGenerator2.default)(function* (shouldUseDoMany, lastModifiedAt, reSyncFilter) {
        var _this4 = this;
        if (!this.shouldSynced) return 0;
        var returner = 0;
        if (this.config != null && this.config.serverConnection().isConnected && this.offlineStateRepo != null) {
          var filter = reSyncFilter != null && reSyncFilter.length > 0 ? `${reSyncFilter} and lastModifiedAt gt ${new Date(-1).toISOString()}` : `lastModifiedAt gt ${lastModifiedAt.toISOString()}`;
          var getSync = yield this.config.serverConnection().fetch(`/${this.exampleEntity.entityName.toLowerCase()}?$filter=${filter}&$orderby=lastModifiedAt ASC&${new Date().getTime().toString()}`, {
            method: 'GET'
          });
          var lastSync = new Date(lastModifiedAt);
          this.syncStatusEvent.post({
            percent: getSync.length === 0 ? 100 : 0,
            current: 0,
            total: getSync.length
          });
          returner = getSync.length;
          if (getSync.length === 0 || this.moreCounter > 20) {
            this.moreCounter = 0;
          } else {
            var usedMany = false;
            if (shouldUseDoMany) {
              try {
                if (getSync.length > 5000) {
                  for (var i = 0; i < getSync.length; i += 5000) {
                    var ins = getSync.slice(i, Math.min(i + 5000, getSync.length));
                    yield this.localRepo.postOrPatchMany(ins);
                    var percent = Math.ceil((i + ins.length) / getSync.length * 100);
                    this.syncStatusEvent.post({
                      percent: percent,
                      current: i + ins.length,
                      total: getSync.length
                    });
                    yield new Promise(function (r) {
                      setTimeout(function () {
                        r();
                      }, 16);
                    });
                  }
                } else {
                  yield this.localRepo.postOrPatchMany(getSync);
                }
                lastSync = new Date(getSync[getSync.length - 1].lastModifiedAt);
                if (this.hasSynced) {
                  getSync.forEach(function (e) {
                    _this4.dispatchChangedEvent([], e).catch(function (err) {
                      return void 0;
                    });
                  });
                }
                usedMany = true;
              } catch (err) {}
            }
            if (!usedMany) {
              var d = new Date();
              var results = [];
              var currentCounter = 0;
              var statusIntervall;
              if (getSync.length > 1000) {
                statusIntervall = setInterval(function () {
                  var percent = Math.ceil(currentCounter / getSync.length * 100);
                  _this4.syncStatusEvent.post({
                    percent: percent,
                    current: currentCounter,
                    total: getSync.length
                  });
                }, 500);
              }
              for (var _i = 0; _i < getSync.length; _i += 1) {
                var r = getSync[_i];
                var f = false;
                f = yield this.updateOrInsert(r);
                currentCounter += 1;
                results.push(f);
              }
              if (statusIntervall) clearInterval(statusIntervall);
              var allSaved = true;
              for (var _i2 = 0; _i2 < results.length; _i2 += 1) {
                if (!results[_i2]) {
                  allSaved = false;
                  break;
                }
              }
              if (allSaved) lastSync = new Date(getSync[getSync.length - 1].lastModifiedAt);
            }
            var ent = new ReadOfflineModel({
              lastSync: lastSync,
              id: this.readModelName
            });
            yield this.offlineStateRepo.patch(ent);
            this.lastSyncInitValue = lastSync;
            var filterCheck = reSyncFilter != null && reSyncFilter.length > 0 ? `${reSyncFilter} and lastModifiedAt gt ${lastSync.toISOString()}` : `lastModifiedAt gt ${lastSync.toISOString()}`;
            this.moreCounter += 1;
            yield this.syncPartial(shouldUseDoMany, lastSync);
          }
        }
        return returner;
      });
      function syncPartial(_x21, _x22, _x23) {
        return _syncPartial.apply(this, arguments);
      }
      return syncPartial;
    }()
  }, {
    key: "sync",
    value: function () {
      var _sync = (0, _asyncToGenerator2.default)(function* () {
        var _this5 = this;
        if (!this.shouldSynced) return {
          total: 0,
          updated: 0
        };
        var returner = {
          total: 0,
          updated: 0
        };
        if (this.config != null && this.config.serverConnection().isConnected && this.offlineStateRepo != null) {
          var lastSync = new Date(this.lastSyncInitValue == null ? this.lastSyncFirstValue : this.lastSyncInitValue);
          if (this.lastSyncInitValue != null) {
            lastSync = yield this.getLastSyncFromDatabase(lastSync);
          }
          try {
            var _this$localRepo2;
            var reSyncFilter = this.reSyncFilter.shift();
            var count = yield (_this$localRepo2 = this.localRepo) == null ? void 0 : _this$localRepo2.get({
              top: 1
            });
            var shouldUseDoMany = count == null || count.length === 0;
            returner.updated = yield this.syncPartial(shouldUseDoMany, lastSync, reSyncFilter);
          } catch (e) {}
        }
        try {
          var _this$localRepo3;
          var f = `lastModifiedAt gt ${this.lastSyncFirstValue.toISOString()}`;
          var countendOnLocal = yield (_this$localRepo3 = this.localRepo) == null ? void 0 : _this$localRepo3.count(f);
          var countedOnServer = yield this.remoteClient.count(f);
          if (countendOnLocal != null && countendOnLocal !== countedOnServer) {
            this.reSynced += 1;
            if (this.reSynced <= 3) {
              this.lastSyncInitValue = undefined;
              yield this.reSync({
                filter: ``,
                deleteBefore: true
              });
            }
          } else {
            this.reSynced = 0;
            if (countendOnLocal) returner.total = countendOnLocal;
          }
        } catch (e) {}
        if (this.reSyncFilter.length > 0 && this.config != null && this.offlineStateRepo != null && this.config.serverConnection().isConnected) {
          setTimeout(function () {
            _this5.sync().catch(function (err) {
              return void 0;
            });
          }, 100);
        } else {
          this.stopSync();
        }
        return returner;
      });
      function sync() {
        return _sync.apply(this, arguments);
      }
      return sync;
    }()
  }], [{
    key: "remoteEventDispatcher",
    get: function get() {
      if (ReadModelClientOfflineFirst._remoteEventDispatcher == null) ReadModelClientOfflineFirst._remoteEventDispatcher = new ReadModelChangedDefaultClientDispatcher_1.ReadModelChangedDefaultClientDispatcher();
      return ReadModelClientOfflineFirst._remoteEventDispatcher;
    }
  }]);
  return ReadModelClientOfflineFirst;
}(ReadModelClientOnly_1.ReadModelClientOnly);
exports.ReadModelClientOfflineFirst = ReadModelClientOfflineFirst;