"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.JournalSettingsEntity = exports.JournalLogo = exports.JournalSettingsOwner = void 0;
var ReadModelEntity_1 = require("cqrs-core/build/src/core/query/ReadModelEntity");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var JournalSettingsOwner = (0, _createClass2.default)(function JournalSettingsOwner() {
  (0, _classCallCheck2.default)(this, JournalSettingsOwner);
});
exports.JournalSettingsOwner = JournalSettingsOwner;
var JournalLogo = (0, _createClass2.default)(function JournalLogo() {
  (0, _classCallCheck2.default)(this, JournalLogo);
});
exports.JournalLogo = JournalLogo;
var JournalSettingsEntity = function (_ReadModelEntity_1$Re) {
  (0, _inherits2.default)(JournalSettingsEntity, _ReadModelEntity_1$Re);
  var _super = _createSuper(JournalSettingsEntity);
  function JournalSettingsEntity(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, JournalSettingsEntity);
    _this = _super.call(this, obj);
    _this.entityName = 'JournalSettings';
    _this.selectedTemplateId = '';
    _this.fill(obj);
    return _this;
  }
  (0, _createClass2.default)(JournalSettingsEntity, [{
    key: "createOne",
    value: function createOne(obj) {
      return new JournalSettingsEntity(obj);
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('owner', new OdataEntity_1.OdataIndex(true));
      baseMap.set('projectId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('filename', new OdataEntity_1.OdataIndex());
      baseMap.set('logo', new OdataEntity_1.OdataIndex());
      baseMap.set('journalCompany', new OdataEntity_1.OdataIndex());
      baseMap.set('ownEmployeeHeader', new OdataEntity_1.OdataIndex());
      baseMap.set('externalEmployeeHeader', new OdataEntity_1.OdataIndex());
      baseMap.set('weatherHeader', new OdataEntity_1.OdataIndex());
      baseMap.set('workDoneHeader', new OdataEntity_1.OdataIndex());
      baseMap.set('specialOccurrencesHeader', new OdataEntity_1.OdataIndex());
      baseMap.set('additionalFields', new OdataEntity_1.OdataIndex());
      baseMap.set('builderHeader', new OdataEntity_1.OdataIndex());
      baseMap.set('builderContent', new OdataEntity_1.OdataIndex());
      baseMap.set('selectedTemplateId', new OdataEntity_1.OdataIndex());
      return baseMap;
    }
  }]);
  return JournalSettingsEntity;
}(ReadModelEntity_1.ReadModelEntity);
exports.JournalSettingsEntity = JournalSettingsEntity;