var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IconGroup = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-support/node_modules/react-native-web");
var _ThemeManager = require("../ThemeManager");
var _Icon = require("./Icon");
var _jsxRuntime = require("../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var IconGroup = exports.IconGroup = function (_PureComponent) {
  (0, _inherits2.default)(IconGroup, _PureComponent);
  var _super = _createSuper(IconGroup);
  function IconGroup() {
    (0, _classCallCheck2.default)(this, IconGroup);
    return _super.apply(this, arguments);
  }
  (0, _createClass2.default)(IconGroup, [{
    key: "render",
    value: function render() {
      var _this$props = this.props,
        buttonColor = _this$props.buttonColor,
        horizontal = _this$props.horizontal,
        buttons = _this$props.buttons,
        small = _this$props.small;
      var height;
      var width;
      var size = small ? 36 : 48;
      if (!horizontal) {
        height = size * buttons.length;
        width = size;
      } else {
        width = size * buttons.length;
        height = size;
      }
      height = _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(height);
      width = _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(width);
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: [{
          height: height,
          width: width,
          backgroundColor: buttonColor,
          borderRadius: _ThemeManager.ThemeManager.style.borderRadius,
          flexDirection: horizontal ? 'row' : 'column',
          overflow: 'visible'
        }, _ThemeManager.ThemeManager.style.elevation2],
        children: this.renderButtons(size)
      });
    }
  }, {
    key: "renderButtons",
    value: function renderButtons(size) {
      var _this$props2 = this.props,
        buttons = _this$props2.buttons,
        iconColor = _this$props2.iconColor,
        horizontal = _this$props2.horizontal;
      var renderedButtons = [];
      if (buttons == null) {
        return null;
      }
      for (var i = 0; i < buttons.length; i += 1) {
        var button = (0, _jsxRuntime.jsx)(_Icon.Icon, Object.assign({
          backgroundColor: "transparent",
          color: iconColor
        }, buttons[i].icon, {
          iconSize: size / 1.5,
          outerSize: size,
          radius: 4
        }), `buttonGroup Button${i}`);
        if (buttons[i].borderTop) {
          renderedButtons.push((0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              width: !horizontal ? 'auto' : _ThemeManager.ThemeManager.style.borderWidth,
              backgroundColor: iconColor,
              height: !horizontal ? _ThemeManager.ThemeManager.style.borderWidth : 'auto'
            }
          }, `borderTop${i}`));
        }
        renderedButtons.push(button);
        if (buttons[i].borderBelow) {
          renderedButtons.push((0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              width: !horizontal ? 'auto' : _ThemeManager.ThemeManager.style.borderWidth,
              backgroundColor: iconColor,
              height: !horizontal ? _ThemeManager.ThemeManager.style.borderWidth : 'auto'
            }
          }, `borderBelow${i}`));
        }
      }
      return renderedButtons;
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        buttons: [],
        horizontal: false,
        small: false,
        buttonColor: '#FFFFFF',
        iconColor: _ThemeManager.ThemeManager.style.black54
      };
    }
  }]);
  return IconGroup;
}(_react.PureComponent);