"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MoveStoredFile = void 0;
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var StoredFileLinked_1 = require("../../events/storedfiles/StoredFileLinked");
var FolderEntity_1 = require("../../query/entities/FolderEntity");
var ClientProjectCommands_1 = require("../_root/ClientProjectCommands");
var MoveStoredFile = function (_ClientProjectCommand) {
  (0, _inherits2.default)(MoveStoredFile, _ClientProjectCommand);
  var _super = _createSuper(MoveStoredFile);
  function MoveStoredFile(data, entityId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, MoveStoredFile);
    _this = _super.call(this, data, entityId, token);
    _this.aggregate = 'project';
    _this.commandName = 'MoveStoredFile';
    return _this;
  }
  (0, _createClass2.default)(MoveStoredFile, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        return true;
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var getFile = yield CommandReadModels_1.CommandReadModels.storedFile.getById(this.entityId);
        yield (0, _get2.default)((0, _getPrototypeOf2.default)(MoveStoredFile.prototype), "checkProjectStatusAndGeneralAccess", this).call(this, getFile.projectId, userId);
        if (getFile.forEntityId != null && getFile.forEntityId.length > 0) {
          throw {
            message: 'Keine Berechtigung',
            messageCode: 'forbiddenCommand'
          };
        }
        var newFolder = this.data.folder;
        if (this.data.folder === 'private' || this.data.folder === '') {
          newFolder = '';
          if (getFile.userId !== userId) {
            throw {
              message: 'Keine Berechtigung',
              messageCode: 'forbiddenCommand'
            };
          }
        }
        if (getFile.folder != null && getFile.folder !== 'public' && getFile.folder !== 'private' && getFile.folder !== '') {
          yield FolderEntity_1.FolderEntity.hasFolderDeleteRights(getFile.folder, userId);
        }
        if (this.data.folder != null && this.data.folder !== 'public' && this.data.folder !== 'private' && this.data.folder !== '') {
          yield FolderEntity_1.FolderEntity.hasFolderWriteRights(this.data.folder, userId);
        }
        return [new StoredFileLinked_1.StoredFileLinked(this.entityId, {
          folder: newFolder,
          forEntity: '',
          forEntityId: ''
        }, getFile.projectId)];
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {});
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
  return MoveStoredFile;
}(ClientProjectCommands_1.ClientProjectCommands);
exports.MoveStoredFile = MoveStoredFile;