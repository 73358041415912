var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CacheableImageLoadingProgress = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _Uploads = require("../../../file/upload/Uploads");
var _I18n = require("../../i18n/I18n");
var _ThemeManager = require("../../ThemeManager");
var _MaterialText = require("../text/MaterialText");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var CacheableImageLoadingProgress = exports.CacheableImageLoadingProgress = function (_PureComponent) {
  (0, _inherits2.default)(CacheableImageLoadingProgress, _PureComponent);
  var _super = _createSuper(CacheableImageLoadingProgress);
  function CacheableImageLoadingProgress(props, context) {
    var _this;
    (0, _classCallCheck2.default)(this, CacheableImageLoadingProgress);
    _this = _super.call(this, props, context);
    _this.uploadProgress = function (data) {
      if (_this.props.tempFileId != null && data.tempFile.id === _this.props.tempFileId) {
        if (data.percent >= 100) {
          _this.setState({
            uploadProgress: undefined
          });
        } else {
          _this.setState({
            uploadProgress: Math.round(data.percent)
          });
        }
      }
    };
    _this.state = {
      loading: false
    };
    return _this;
  }
  (0, _createClass2.default)(CacheableImageLoadingProgress, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      if (this.props.tempFileId != null) {
        _Uploads.Uploads.instance.evtProgressProgress.attach(this.uploadProgress);
      }
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      _Uploads.Uploads.instance.evtProgressProgress.detach(this.uploadProgress);
    }
  }, {
    key: "render",
    value: function render() {
      var _this$state = this.state,
        uploadProgress = _this$state.uploadProgress,
        loading = _this$state.loading;
      if (uploadProgress != null) {
        return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: Object.assign({}, _ThemeManager.ThemeManager.style.absoluteStyle, {
            justifyContent: 'center',
            backgroundColor: 'rgba(0,0,0,0.25)'
          }),
          children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              position: 'absolute',
              alignSelf: 'flex-end',
              backgroundColor: 'rgba(0,0,0,0.25)',
              width: `${100 - uploadProgress}%`,
              height: '100%'
            }
          }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              alignSelf: 'center',
              borderRadius: _ThemeManager.ThemeManager.style.borderRadius,
              minWidth: 48
            },
            children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              centeredBox: true,
              centeredText: true,
              color: "#FFFFFF",
              children: `${uploadProgress}%`
            })
          })]
        });
      }
      if (loading) {
        return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: Object.assign({}, _ThemeManager.ThemeManager.style.absoluteStyle, {
            justifyContent: 'center',
            backgroundColor: 'rgba(0,0,0,0.25)'
          }),
          children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              alignSelf: 'center',
              borderRadius: _ThemeManager.ThemeManager.style.borderRadius,
              minWidth: 48
            },
            children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              centeredBox: true,
              centeredText: true,
              color: "#FFFFFF",
              children: _I18n.I18n.m.getMessage('loading')
            })
          })
        });
      }
      return null;
    }
  }]);
  return CacheableImageLoadingProgress;
}(_react.PureComponent);