var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Lightbox = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var uuid = _interopRequireWildcard(require("uuid"));
var _I18n = require("../../i18n/I18n");
var _ResizeEvent = require("../../ResizeEvent");
var _ThemeManager = require("../../ThemeManager");
var _Icon = require("../Icon");
var _Measurement = require("../utils/Measurement");
var _Ripple = require("../utils/Ripple");
var _LightBoxHandler = require("./LightBoxHandler");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var Lightbox = exports.Lightbox = function (_PureComponent) {
  (0, _inherits2.default)(Lightbox, _PureComponent);
  var _super = _createSuper(Lightbox);
  function Lightbox(props) {
    var _this;
    (0, _classCallCheck2.default)(this, Lightbox);
    _this = _super.call(this, props);
    _this.getSize = (0, _asyncToGenerator2.default)(function* () {
      try {
        if (_this.content == null) yield new Promise(function (resolve) {
          setTimeout(function () {
            resolve();
          }, 3000);
        });
        var _ref2 = _this.l != null ? _this.l : yield _Measurement.Measurement.measure(_this.content),
          x = _ref2.x,
          y = _ref2.y,
          width = _ref2.width,
          height = _ref2.height,
          pageX = _ref2.pageX,
          pageY = _ref2.pageY;
        if (width && height) {
          if (_this.props.getSize != null) {
            var newSize = _this.props.getSize();
            return Object.assign({
              x: x + (width - newSize.width) / 2,
              y: y + (height - newSize.height) / 2
            }, newSize);
          }
          return {
            x: pageX || x,
            y: pageY || y,
            width: width,
            height: height
          };
        }
        return {
          x: _ResizeEvent.ResizeEvent.current.windowWidth / 2,
          y: _ResizeEvent.ResizeEvent.current.windowHeight / 2,
          width: 200,
          height: 200
        };
      } catch (err) {
        return new Promise(function (resolve) {
          if (_this.content != null) {
            _this.content.measure(function (_x, _y, width, height, pageX, pageY) {
              var x = pageX;
              var y = pageY;
              if (x != null && y != null) {
                if (_this.props.getSize != null) {
                  var _newSize = _this.props.getSize();
                  resolve(Object.assign({
                    x: x + (width - _newSize.width) / 2,
                    y: y + (height - _newSize.height) / 2
                  }, _newSize));
                  return;
                }
                resolve({
                  x: x,
                  y: y,
                  width: width,
                  height: height
                });
                return;
              }
              resolve({
                x: _ResizeEvent.ResizeEvent.current.windowWidth / 2,
                y: _ResizeEvent.ResizeEvent.current.windowHeight / 2,
                width: 200,
                height: 200
              });
            });
          } else {
            resolve({
              x: _ResizeEvent.ResizeEvent.current.windowWidth / 2,
              y: _ResizeEvent.ResizeEvent.current.windowHeight / 2,
              width: 200,
              height: 200
            });
          }
        });
      }
    });
    _this.onClose = function () {
      if (_this.props.onClose != null) {
        _this.props.onClose();
      }
    };
    _this.onClosed = function () {
      if (_this.props.onClosed != null) {
        _this.props.onClosed();
      }
    };
    _this.onOpen = function () {
      if (_this.props.onOpen != null) {
        _this.props.onOpen();
      }
    };
    _this.onOpened = function (scaleFactor) {
      if (_this.props.onOpened != null) {
        _this.props.onOpened(scaleFactor);
      }
    };
    _this.setLayout = function (e) {
      _this.l = Object.assign({}, e.nativeEvent.layout);
      if (e.nativeEvent.layout['top']) _this.l.pageY = e.nativeEvent.layout['top'];
      if (e.nativeEvent.layout['left']) _this.l.pageX = e.nativeEvent.layout['left'];
    };
    _this.openLB = function () {
      _LightBoxHandler.LightBoxHandler.open(_this.group, _this.laId);
    };
    _this.setContent = function (cont) {
      _this.content = cont;
    };
    _this.laId = props.id != null ? props.id : uuid.v1();
    _this.group = props.group != null ? props.group : 'none';
    _this.index = props.index != null ? props.index : 0;
    _this.parrallelAnimationId = props.parrallelAnimationId;
    _this.metaData = props.metaData;
    _this.downloadFunction = props.downloadFunction;
    _this.editSettings = props.editSettings;
    _this.panoramaImageSource = props.panoramaImageSource;
    _this.auth = props.auth;
    _this.imageURL = props.imageURL;
    return _this;
  }
  (0, _createClass2.default)(Lightbox, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      _LightBoxHandler.LightBoxHandler.register(this.group, this.laId, this, this.index);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (this.laId != null) {
        _LightBoxHandler.LightBoxHandler.remove(this.group, this.laId);
      }
    }
  }, {
    key: "getChilds",
    value: function getChilds() {
      return this.props.children;
    }
  }, {
    key: "render",
    value: function render() {
      var rippleProps = this.props.rippleProps != null ? Object.assign({
        onPress: this.openLB
      }, this.props.rippleProps) : {
        onPress: this.openLB
      };
      if (this.props.expandButton === true) {
        var children = this.props.rippleProps == null ? this.props.children : (0, _jsxRuntime.jsx)(_Ripple.Ripple, Object.assign({}, rippleProps, {
          style: {
            width: '100%',
            height: '100%'
          },
          children: this.props.children
        }));
        return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            onLayout: this.setLayout,
            collapsable: false,
            style: {
              opacity: 1,
              position: 'relative'
            },
            ref: this.setContent,
            children: children
          }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            pointerEvents: "box-none",
            collapsable: false,
            style: {
              position: 'absolute',
              top: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(1),
              right: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(1),
              width: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(36),
              height: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(36),
              padding: 0,
              backgroundColor: 'transparent'
            },
            children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
              toolTip: _I18n.I18n.m.getMessage('expand'),
              disabled: this.props.disabled === true,
              backgroundColor: _ThemeManager.ThemeManager.style.black54,
              color: "#FFFFFF",
              icon: "arrow-expand",
              iconSize: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(24),
              outerSize: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(36),
              radius: 0,
              onPress: this.openLB
            })
          })]
        });
      }
      if (this.props.disabled === true) {
        return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          collapsable: false,
          style: {
            opacity: 1
          },
          onLayout: this.setLayout,
          ref: this.setContent,
          children: this.props.children
        });
      }
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        collapsable: false,
        style: {
          opacity: 1,
          position: 'relative'
        },
        onLayout: this.setLayout,
        ref: this.setContent,
        children: (0, _jsxRuntime.jsx)(_Ripple.Ripple, Object.assign({}, rippleProps, {
          style: {
            width: '100%',
            height: '100%'
          },
          children: this.props.children
        }))
      });
    }
  }]);
  return Lightbox;
}(_react.PureComponent);