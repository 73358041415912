var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DragHandler = exports.DragElement = void 0;
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _tsEvents = require("ts-events");
var uuid = _interopRequireWildcard(require("uuid"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var DragElement = exports.DragElement = (0, _createClass2.default)(function DragElement(elementPosition, child, dropData, onDropped, onDropNotInside) {
  var opacity = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 0.5;
  var onStartDrag = arguments.length > 6 ? arguments[6] : undefined;
  var onStartedDrag = arguments.length > 7 ? arguments[7] : undefined;
  (0, _classCallCheck2.default)(this, DragElement);
  this.child = child;
  this.dropData = dropData;
  this.elementPosition = elementPosition;
  this.onDropped = onDropped;
  this.onDropNotInside = onDropNotInside;
  this.id = uuid.v1();
  this.opacity = opacity;
  this.onStartDrag = onStartDrag;
  this.onStartedDrag = onStartedDrag;
});
var DragHandler = exports.DragHandler = function () {
  function DragHandler() {
    (0, _classCallCheck2.default)(this, DragHandler);
  }
  (0, _createClass2.default)(DragHandler, null, [{
    key: "lastStart",
    get: function get() {
      return DragHandler._lastStart;
    },
    set: function set(value) {
      DragHandler._lastStart = value;
    }
  }, {
    key: "lastDrop",
    get: function get() {
      return DragHandler._lastDrop;
    },
    set: function set(value) {
      DragHandler._lastDrop = value;
    }
  }, {
    key: "startDrag",
    value: function startDrag(dragElement) {
      DragHandler.dragEvent.post(dragElement);
    }
  }, {
    key: "cancelDrag",
    value: function cancelDrag() {
      DragHandler.dragEvent.post(undefined);
    }
  }]);
  return DragHandler;
}();
DragHandler.dragEvent = new _tsEvents.AsyncEvent();
DragHandler.startDragEvent = new _tsEvents.AsyncEvent();
DragHandler._lastStart = {
  x: 0,
  y: 0
};
DragHandler._lastDrop = {
  x: 0,
  y: 0
};
DragHandler.startDragById = function (id, startPosition) {
  return function () {
    DragHandler.startDragEvent.post({
      id: id,
      startPosition: startPosition
    });
  };
};