"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LinkStoredFileToTicket = void 0;
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var StoredFileLinked_1 = require("../../events/storedfiles/StoredFileLinked");
var TicketCommentAdded_1 = require("../../events/ticketcomments/TicketCommentAdded");
var ClientProjectCommands_1 = require("../_root/ClientProjectCommands");
var LinkStoredFileToTicket = function (_ClientProjectCommand) {
  (0, _inherits2.default)(LinkStoredFileToTicket, _ClientProjectCommand);
  var _super = _createSuper(LinkStoredFileToTicket);
  function LinkStoredFileToTicket(data, entityId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, LinkStoredFileToTicket);
    _this = _super.call(this, data, entityId, token);
    _this.aggregate = 'project';
    _this.commandName = 'LinkStoredFileToTicket';
    _this.waitForResult = false;
    return _this;
  }
  (0, _createClass2.default)(LinkStoredFileToTicket, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        return true;
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var getFile = yield CommandReadModels_1.CommandReadModels.storedFile.getById(this.entityId, undefined, userId);
        if (!this.data.wasOffline) {
          return [];
        }
        yield (0, _get2.default)((0, _getPrototypeOf2.default)(LinkStoredFileToTicket.prototype), "checkProjectStatusAndGeneralAccess", this).call(this, getFile.projectId, userId);
        var commentId = uuid.v1();
        return [new StoredFileLinked_1.StoredFileLinked(this.entityId, {
          forEntityId: this.data.forEntityId,
          forEntity: 'Ticket',
          folder: ''
        }, getFile.projectId), new TicketCommentAdded_1.TicketCommentAdded(commentId, {
          ticketId: this.data.forEntityId,
          attachedFileId: this.entityId,
          attachedFileType: getFile.fileType,
          comment: '',
          creator: userId,
          projectId: getFile.projectId,
          clientCreation: this.data.clientCreation
        })];
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        if (key == null || key === 'forEntityId') {
          if (this.data.forEntityId.length <= 0) {
            throw {
              key: 'forEntityId',
              messageCode: 'badRequest',
              message: 'forEntityId muss eine gültige Id sein'
            };
          }
        }
        if (key == null || key === 'clientCreation') {
          var offset = 1000 * 60 * 60;
          if (new Date(this.data.clientCreation).getTime() > new Date().getTime() + offset) {
            throw {
              key: 'clientCreation',
              messageCode: 'badRequest',
              message: 'Erstellungsdatum darf nicht in der Zukunft liegen'
            };
          }
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
  return LinkStoredFileToTicket;
}(ClientProjectCommands_1.ClientProjectCommands);
exports.LinkStoredFileToTicket = LinkStoredFileToTicket;