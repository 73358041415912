import { PaymentsEntity } from 'upmesh-support-core/src/entities/PaymentsEntity';
import { ConnectionHandler } from '../../ConnectionHandler';
export class PaymentExtended extends PaymentsEntity {
    static async getToName(item) {
        if (item.to.type === 'partner') {
            if (PaymentExtended.partners[item.to.id] != null)
                return PaymentExtended.partners[item.to.id];
            const partner = await ConnectionHandler.getClient()?.upmeshPartner.getById(item.to.id);
            if (partner != null) {
                PaymentExtended.partners[item.to.id] = partner.company;
            }
            return partner != null ? partner.company : '?';
        }
        if (PaymentExtended.companies[item.to.id] != null)
            return PaymentExtended.companies[item.to.id];
        const company = await ConnectionHandler.getClient()?.company.getById(item.to.id);
        if (company != null) {
            PaymentExtended.companies[item.to.id] = company.company;
        }
        return company != null ? company.company : '?';
    }
    static async getFromName(item) {
        if (item.from == null)
            return 'upmesh';
        if (PaymentExtended.partners[item.from] != null)
            return PaymentExtended.partners[item.from];
        const partner = await ConnectionHandler.getClient()?.upmeshPartner.getById(item.from);
        if (partner != null) {
            PaymentExtended.partners[item.from] = partner.company;
        }
        return partner != null ? partner.company : '?';
    }
    static async paymentToExtended(p) {
        const p2 = p;
        p2.toName = await PaymentExtended.getToName(p);
        p2.fromName = await PaymentExtended.getFromName(p);
        return p2;
    }
}
PaymentExtended.partners = {};
PaymentExtended.companies = {};
