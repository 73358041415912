var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _react = _interopRequireDefault(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _ThemeManager = require("../../ThemeManager");
var _MaterialText = require("../text/MaterialText");
var _Ripple = require("../utils/Ripple");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var YearButton = exports.default = function (_React$Component) {
  (0, _inherits2.default)(YearButton, _React$Component);
  var _super = _createSuper(YearButton);
  function YearButton(props) {
    (0, _classCallCheck2.default)(this, YearButton);
    return _super.call(this, props);
  }
  (0, _createClass2.default)(YearButton, [{
    key: "render",
    value: function render() {
      var _this = this;
      return (0, _jsxRuntime.jsx)(_Ripple.Ripple, {
        style: {
          width: 102,
          height: 52,
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: this.props.marginLeft
        },
        onPress: function onPress() {
          return _this.props.selectYear(_this.props.year);
        },
        children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            height: 36,
            width: 72,
            borderRadius: 18,
            backgroundColor: this.props.selected ? _ThemeManager.ThemeManager.style.brandPrimary : undefined,
            justifyContent: 'center'
          },
          children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            fixedWidth: 72,
            textAlign: "center",
            color: this.props.selected ? 'white' : undefined,
            children: this.props.year
          })
        })
      });
    }
  }]);
  return YearButton;
}(_react.default.Component);