"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StringUtils = void 0;
var StringUtils = function () {
  function StringUtils() {
    (0, _classCallCheck2.default)(this, StringUtils);
  }
  (0, _createClass2.default)(StringUtils, null, [{
    key: "isTrue",
    value: function isTrue(value) {
      var testValue = typeof value === 'string' ? value.trim().toLowerCase() : value;
      switch (testValue) {
        case true:
        case 'true':
        case 1:
        case '1':
        case 'on':
        case 'yes':
          return true;
        default:
          return false;
      }
    }
  }, {
    key: "templateString",
    value: function templateString(string, keys) {
      var s = string;
      for (var key in keys) {
        var r = new RegExp(`{${key}}`, 'g');
        s = s.replace(r, keys[key]);
      }
      return s;
    }
  }, {
    key: "deepStringify",
    value: function deepStringify(c) {
      var deep = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      try {
        if (deep > 3) return 'deep > 3';
        if (typeof c === 'string') return c;
        if (Array.isArray(c)) {
          var items = [];
          if (c.length > 100) return '[Array > 100]';
          c.forEach(function (m, i) {
            items.push(`${i} : ${StringUtils.deepStringify(m, deep + 1)}`);
          });
          return `[${items.join(',')}]`;
        }
        if (typeof c === 'number') return c.toString(10);
        if (typeof c === 'object') {
          if (c['toString'] && typeof c['toString'] === 'function') {
            var st = c.toString();
            if (st !== '[object Object]') return st;
          }
          var _items = [];
          var t = Object.assign({}, c);
          for (var k in t) {
            if (_items.length > 100) {
              break;
            }
            if (t[k]) _items.push(`${k} : ${StringUtils.deepStringify(t[k], deep + 1)}`);
          }
          return `{ ${_items.join(',')} }`;
        }
        return '';
      } catch (err) {
        return '';
      }
    }
  }]);
  return StringUtils;
}();
exports.StringUtils = StringUtils;