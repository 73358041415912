var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormInputBase = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var uuid = _interopRequireWildcard(require("uuid"));
var _I18n = require("../../i18n/I18n");
var _ResizeEvent = require("../../ResizeEvent");
var _ThemeManager = require("../../ThemeManager");
var _Icon = require("../Icon");
var _ScrollHandler = require("../scroll/ScrollHandler");
var _MaterialText = require("../text/MaterialText");
var _Ripple = require("../utils/Ripple");
var _AutoComplete = require("./autocomplete/AutoComplete");
var _FormElement = require("./FormElement");
var _FormInputLabel = require("./FormInputLabel");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var FormInputBase = exports.FormInputBase = function (_PureComponent) {
  (0, _inherits2.default)(FormInputBase, _PureComponent);
  var _super = _createSuper(FormInputBase);
  function FormInputBase(props) {
    var _this;
    (0, _classCallCheck2.default)(this, FormInputBase);
    _this = _super.call(this, props);
    _this._avoidOnBlurOnce = false;
    _this.helperTextHeight = _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(21) + 2;
    _this.inputTopDelta = 0;
    _this.labelTop = _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(12);
    _this.labelTopDownDelta = _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(0);
    _this.mounted = false;
    _this.blur = function () {
      if (_this.textField != null && typeof _this.textField.blur === 'function') {
        _this.textField.blur();
      }
    };
    _this.clearText = function () {
      _this.avoidOnBlurOnce = _this.state.focused;
      _this.onChangeText('', function () {
        if (_this.textField != null && typeof _this.textField.clear === 'function') {
          _this.textField.clear();
          if (typeof _this.textField.forceUpdate === 'function') {
            _this.textField.forceUpdate();
          }
        }
        if (_this.props.focusAfterClear === true) {
          requestAnimationFrame(function () {
            _this.avoidOnBlurOnce = false;
            _this.focus();
          });
        }
      });
    };
    _this.focus = function () {
      var overrideOnPressFocus = _this.props.overrideOnPressFocus;
      if (overrideOnPressFocus != null) {
        overrideOnPressFocus();
      } else if (_this.textField != null && typeof _this.textField.focus === 'function') {
        _this.textField.focus();
      }
    };
    _this.onPressAutoComplete = function () {
      var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      return function (_e) {
        if (_this.props.onPressAutoComplete != null) {
          _this.props.onPressAutoComplete(text);
        }
        _this.onChangeText(text, function () {
          if (_this.props.nextField != null) {
            _this.focusNextField();
          }
          if (_this.props.blurAfterSubmit === false) {
            _this.focus();
          } else {
            _this.avoidOnBlurOnce = false;
            _this.blur();
          }
        });
      };
    };
    _this.setTextfield = function (e) {
      _this.textField = e;
      if (_reactNativeWeb.Platform.OS === 'web' && e != null && e.setNativeProps != null) {
        try {
          var _this$props = _this.props,
            name = _this$props.name,
            accessibilityLabel = _this$props.accessibilityLabel,
            labelText = _this$props.labelText,
            disabled = _this$props.disabled,
            tabIndex = _this$props.tabIndex,
            autoCompleteList = _this$props.autoCompleteList,
            autoCompleteWeb = _this$props.autoCompleteWeb;
          _this.tId = accessibilityLabel != null ? accessibilityLabel : labelText;
          var autoComplete = autoCompleteList != null ? 'off' : autoCompleteWeb;
          var tabInd = tabIndex != null ? tabIndex : 100;
          e.setNativeProps({
            name: name,
            autoComplete: autoComplete,
            id: _this.tId,
            tabIndex: disabled === true ? undefined : tabInd
          });
        } catch (err) {}
      }
    };
    _this.changeSecureTextEntry = function () {
      _this.avoidOnBlurOnce = _this.state.focused;
      var secureTextEntry = false;
      if (_this.state.secureTextEntry != null) {
        secureTextEntry = _this.state.secureTextEntry;
      } else if (_this.props.secureTextEntry != null) {
        secureTextEntry = _this.props.secureTextEntry;
      }
      secureTextEntry = !secureTextEntry;
      _this.setState({
        secureTextEntry: secureTextEntry
      }, function () {
        _this.avoidOnBlurOnce = false;
      });
    };
    _this.filterText = function (value) {
      var autoCompleteList = _this.props.autoCompleteList;
      if (autoCompleteList != null) {
        var autoCompleteSuggestions = _this.createAutoCompleteSuggestions(autoCompleteList, value);
        return autoCompleteSuggestions;
      }
      return null;
    };
    _this.focusNextField = function () {
      if (_this.props.nextField != null) {
        if (typeof _this.props.nextField === 'function') {
          _this.props.nextField();
        } else if (typeof _this.props.nextField['focus'] === 'function') {
          _this.props.nextField.focus();
        }
      }
    };
    _this.getColor = function () {
      var _this$props2 = _this.props,
        color = _this$props2.color,
        errorColor = _this$props2.errorColor,
        error = _this$props2.error,
        unfocusedColor = _this$props2.unfocusedColor;
      var focused = _this.state.focused;
      if (error === true) {
        return errorColor != null ? errorColor : _ThemeManager.ThemeManager.style.brandDanger;
      }
      if (focused === true) {
        return color != null ? color : _ThemeManager.ThemeManager.style.brandPrimary;
      }
      return unfocusedColor != null ? unfocusedColor : _ThemeManager.ThemeManager.style.black42;
    };
    _this.getHeight = function () {
      return 45.8 + _this.getTextInputHeight();
    };
    _this.getTextInputHeight = function () {
      var height = 19.2;
      if (_this.props.numberOfLines != null) {
        height = Math.max(_this.props.numberOfLines, 1) * 19.2;
      }
      return height;
    };
    _this.getTextInputTop = function () {
      var marginTop = 10 + _this.inputTopDelta;
      if (_reactNativeWeb.Platform.OS.toString() === 'web') {
        marginTop = 10 + _this.inputTopDelta;
      }
      return marginTop;
    };
    _this.getWidth = function () {
      var fieldWidth = _this.state.fieldWidth;
      var wid = '100%';
      if (fieldWidth != null) {
        wid = fieldWidth;
        var maxWidth = _ResizeEvent.ResizeEvent.current.windowWidth - 2 * _ThemeManager.ThemeManager.style.contentPaddingValue;
        if (wid > maxWidth) {
          wid = maxWidth;
        }
      }
      return wid;
    };
    _this.keyboardDidHide = function () {
      _this.blur();
    };
    _this.onChangeText = function (v, callBack) {
      if (_this.mounted) {
        var _value = _this.props.filterText != null ? _this.props.filterText(v) : v;
        if (_value !== _this.state.value) {
          var autoCompleteSuggestions = _this.filterText(_value);
          _this.setState({
            value: _value,
            autoCompleteSuggestions: autoCompleteSuggestions
          }, function () {
            if (_this.props.onChange != null) {
              _this.props.onChange(_value);
            }
            if (_this.props.onChangeText != null) {
              _this.props.onChangeText(_value);
            }
            if (callBack != null) {
              callBack();
            }
          });
        }
      }
    };
    _this.onSubmitEditing = function () {
      _this.avoidOnBlurOnce = false;
      _this.focusNextField();
      if (_this.props.onSubmitEditing != null) {
        _this.props.onSubmitEditing(_this.state.value);
      }
      if (_this.props.blurAfterSubmit !== false) {
        _this.blur();
      }
    };
    _this.setRef = function (r) {
      _this.rootElement = r;
    };
    _this.onBlurArrow = function () {
      _this.onBlur();
    };
    _this.onFocusArrow = function () {
      if (_this.props.disabled !== true) {
        _FormElement.FormElementsHandler.setCurrentFocus(_this._id);
        _this.onFocus();
      }
    };
    _this.onHelperTextLayout = function (e) {
      if (e != null && e.nativeEvent != null && e.nativeEvent.layout != null) {
        _this.helperTextHeight = e.nativeEvent.layout.height + 2;
        _this.forceUpdate();
      }
    };
    _this._id = uuid.v1();
    var value = props.value == null && props.initValue != null ? props.initValue.toString() : props.value == null ? '' : props.value.toString();
    _this.state = {
      value: value,
      autoCompleteSuggestions: [],
      fieldWidth: props.fieldWidth,
      focused: false,
      secureTextEntry: props.secureTextEntry,
      multiline: props.numberOfLines > 1
    };
    return _this;
  }
  (0, _createClass2.default)(FormInputBase, [{
    key: "avoidOnBlurOnce",
    get: function get() {
      return this._avoidOnBlurOnce;
    },
    set: function set(b) {
      var _this2 = this;
      this._avoidOnBlurOnce = b;
      if (this.blurTimeout != null) clearTimeout(this.blurTimeout);
      this.blurTimeout = setTimeout(function () {
        _this2._avoidOnBlurOnce = false;
      }, 100);
    }
  }, {
    key: "clear",
    value: function clear() {
      this.clearText();
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      this.mounted = true;
      if (this.props.autoFocus === true) {
        this.focus();
      }
      this.updateAutoCompleteTO();
    }
  }, {
    key: "updateAutoCompleteTO",
    value: function updateAutoCompleteTO() {
      var _this3 = this;
      var to = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 300;
      setTimeout(function () {
        if (_this3.mounted) _this3.setState(function (prevstate) {
          var autoCompleteList = _this3.props.autoCompleteList;
          var autoCompleteSuggestions = _this3.createAutoCompleteSuggestions(autoCompleteList, prevstate.value);
          return {
            autoCompleteSuggestions: autoCompleteSuggestions
          };
        });
      }, to);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      this.mounted = false;
      this.removekeyboardDidHideListener();
    }
  }, {
    key: "getId",
    value: function getId() {
      return this._id;
    }
  }, {
    key: "getValue",
    value: function getValue() {
      return this.state.value;
    }
  }, {
    key: "render",
    value: function render() {
      try {
        return this.renderBase();
      } catch (e) {}
      return null;
    }
  }, {
    key: "renderTextInput",
    value: function renderTextInput() {
      var _this$props3 = this.props,
        numberOfLines = _this$props3.numberOfLines,
        autoCorrect = _this$props3.autoCorrect,
        maxLength = _this$props3.maxLength,
        name = _this$props3.name,
        autoCompleteWeb = _this$props3.autoCompleteWeb,
        autoCompleteList = _this$props3.autoCompleteList,
        nextField = _this$props3.nextField,
        returnKeyType = _this$props3.returnKeyType,
        disabled = _this$props3.disabled,
        tabIndex = _this$props3.tabIndex,
        keyboardType = _this$props3.keyboardType,
        accessibilityLabel = _this$props3.accessibilityLabel,
        labelText = _this$props3.labelText,
        autoFocus = _this$props3.autoFocus;
      var _this$state = this.state,
        value = _this$state.value,
        multiline = _this$state.multiline,
        secureTextEntry = _this$state.secureTextEntry;
      var height = _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(this.getTextInputHeight());
      this.tId = accessibilityLabel != null ? accessibilityLabel : labelText;
      if (_reactNativeWeb.Platform.OS === 'web') {
        var TI = _reactNativeWeb.TextInput;
        var autoComplete = autoCompleteList != null ? '' : autoCompleteWeb;
        var tabInd = tabIndex != null ? tabIndex : 100;
        var webProps = {
          tabIndex: disabled === true ? undefined : tabInd,
          name: name,
          autoComplete: autoComplete,
          autofocus: autoFocus,
          id: this.tId,
          autoFill: false
        };
        if (keyboardType === 'numeric') webProps['type'] = 'number';
        return (0, _jsxRuntime.jsx)(TI, Object.assign({
          testID: this.tId,
          accessibilityLabel: this.tId,
          ref: this.setTextfield,
          underlineColorAndroid: "transparent",
          onSubmitEditing: multiline ? undefined : this.onSubmitEditing,
          selectionColor: _ThemeManager.ThemeManager.style.brandPrimary,
          onFocus: this.onFocusArrow,
          onBlur: this.onBlurArrow,
          blurOnSubmit: false,
          onChangeText: this.onChangeText,
          value: value,
          multiline: multiline,
          secureTextEntry: secureTextEntry,
          autoCorrect: autoCorrect,
          numberOfLines: numberOfLines,
          maxLength: maxLength,
          keyboardType: keyboardType,
          returnKeyType: nextField != null ? 'next' : returnKeyType,
          editable: disabled !== true,
          style: [{
            height: height,
            position: 'absolute',
            top: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(23),
            padding: 0,
            width: '100%',
            backgroundColor: 'transparent',
            overflow: 'visible'
          }, (0, _MaterialText.getStyleForType)(_MaterialText.MaterialTextTypes.Body1)]
        }, webProps));
      }
      var keyboardType2 = _reactNativeWeb.Platform.OS === 'android' && this.props.secureTextEntry === true && secureTextEntry === false ? 'visible-password' : keyboardType;
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.TextInput, {
        testID: this.tId,
        accessibilityLabel: this.tId,
        selectionColor: _ThemeManager.ThemeManager.style.brandPrimary,
        ref: this.setTextfield,
        underlineColorAndroid: "transparent",
        onSubmitEditing: multiline ? undefined : this.onSubmitEditing,
        onFocus: this.onFocusArrow,
        onBlur: this.onBlurArrow,
        blurOnSubmit: false,
        onChangeText: this.onChangeText,
        value: value,
        multiline: multiline,
        secureTextEntry: secureTextEntry,
        keyboardType: keyboardType2,
        autoCorrect: this.props.secureTextEntry === true ? false : autoCorrect,
        numberOfLines: numberOfLines,
        maxLength: maxLength,
        disableFullscreenUI: true,
        returnKeyType: nextField != null ? 'next' : returnKeyType,
        editable: disabled !== true,
        style: [{
          height: height,
          position: 'absolute',
          top: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(23),
          padding: 0,
          width: '100%',
          backgroundColor: 'transparent',
          overflow: 'visible'
        }, (0, _MaterialText.getStyleForType)(_MaterialText.MaterialTextTypes.Body1), {
          fontSize: _ThemeManager.ThemeManager.style.getTextInputFontSize()
        }]
      }, "TextInput");
    }
  }, {
    key: "setValue",
    value: function setValue(value) {
      if (value != null) {
        this.setState({
          value: value
        });
      } else {
        this.setState({
          value: ''
        });
      }
    }
  }, {
    key: "sortAutoComplete",
    value: function sortAutoComplete(autoCompleteList) {
      if (typeof autoCompleteList[0] !== 'string') {
        autoCompleteList.sort(function (a, b) {
          var x = a.title.toLowerCase();
          var y = b.title.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });
      } else {
        autoCompleteList.sort();
      }
      return autoCompleteList;
    }
  }, {
    key: "createAutoCompleteSuggestions",
    value: function createAutoCompleteSuggestions(autoCompleteList, value) {
      var suggestions = [];
      var autoCompleteSearchEnabled = this.props.autoCompleteSearchEnabled;
      if (autoCompleteList != null) {
        if (!autoCompleteSearchEnabled) {
          return autoCompleteList;
        }
        var autoCompletes = this.sortAutoComplete(autoCompleteList);
        if (value != null && value.length > 0) {
          for (var i = 0; i < autoCompletes.length; i += 1) {
            var autoCompleteString = typeof autoCompletes[i] !== 'string' ? autoCompletes[i].title : autoCompletes[i];
            var regexp = new RegExp(this.escapeRegExp(value), 'i');
            var match = autoCompleteString.match(regexp);
            if (match != null && match.length > 0 && autoCompletes[i] !== value) {
              suggestions.push(autoCompletes[i]);
            }
          }
          return suggestions;
        }
        return autoCompletes;
      }
      return [];
    }
  }, {
    key: "escapeRegExp",
    value: function escapeRegExp(s) {
      return s.replace(/([.*+?^${}()|\[\]\/\\])/g, '\\$1');
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(_prevProps, _prevState) {
      if (this.props.autoCompleteList != null && _prevProps.autoCompleteList != null && JSON.stringify(this.props.autoCompleteList) !== JSON.stringify(_prevProps.autoCompleteList)) {
        this.updateAutoCompleteTO();
      }
    }
  }, {
    key: "onBlur",
    value: function onBlur() {
      var _this4 = this;
      var submitOnBlur = this.props.submitOnBlur;
      if (submitOnBlur) this.onSubmitEditing();
      if (this.avoidOnBlurOnce) {
        this.avoidOnBlurOnce = false;
        return;
      }
      this.removekeyboardDidHideListener();
      if (this.mounted) {
        this.setState({
          focused: false
        });
      }
      if (this.props.onBlur != null) {
        this.props.onBlur();
      }
      setTimeout(function () {
        if (_FormElement.FormElementsHandler.getCurrentFocus() === _this4._id) {
          if (!_this4.state.focused) {
            _FormElement.FormElementsHandler.setCurrentFocus('');
            if (_this4.props.nextField == null && _reactNativeWeb.Keyboard != null && typeof _reactNativeWeb.Keyboard.dismiss === 'function') {
              _reactNativeWeb.Keyboard.dismiss();
            }
          }
        }
      }, 1000);
    }
  }, {
    key: "removekeyboardDidHideListener",
    value: function removekeyboardDidHideListener() {
      if (this.keyboardDidHideListener != null) {
        this.keyboardDidHideListener.remove();
        this.keyboardDidHideListener = undefined;
      }
    }
  }, {
    key: "onFocus",
    value: function onFocus() {
      var _this5 = this;
      this.scrollTo().catch(function (err) {
        return void 0;
      });
      if (this.keyboardDidHideListener == null) {
        this.keyboardDidHideListener = _reactNativeWeb.Keyboard.addListener('keyboardDidHide', this.keyboardDidHide);
      }
      this.setState({
        autoCompleteSuggestions: [],
        focused: true
      }, function () {
        if (_this5.props.onFocus != null) {
          _this5.props.onFocus();
        }
        _this5.updateAutoCompleteTO();
      });
    }
  }, {
    key: "scrollTo",
    value: function () {
      var _scrollTo = (0, _asyncToGenerator2.default)(function* () {
        var _this$props4 = this.props,
          scrollContainerId = _this$props4.scrollContainerId,
          disableScrollTo = _this$props4.disableScrollTo;
        if (disableScrollTo !== true) {
          var containerId = scrollContainerId != null ? scrollContainerId : _ScrollHandler.ScrollHandler.getLastAddedScrollContainer();
          if (containerId != null && this.rootElement != null) {
            _ScrollHandler.ScrollHandler.scrollTo({
              containerId: containerId,
              animated: true,
              x: 0,
              y: 0,
              ref: this.rootElement
            });
          }
        }
      });
      function scrollTo() {
        return _scrollTo.apply(this, arguments);
      }
      return scrollTo;
    }()
  }, {
    key: "renderAutoComplete",
    value: function renderAutoComplete() {
      var _this$props5 = this.props,
        iconLeft = _this$props5.iconLeft,
        numberOfLines = _this$props5.numberOfLines,
        maxAutoCompleteHeight = _this$props5.maxAutoCompleteHeight;
      var _this$state2 = this.state,
        focused = _this$state2.focused,
        autoCompleteSuggestions = _this$state2.autoCompleteSuggestions;
      var top = this.getHeight();
      if (focused !== true || autoCompleteSuggestions == null || autoCompleteSuggestions.length === 0 || numberOfLines != null && numberOfLines > 1) {
        return null;
      }
      var scrollEvent = _ScrollHandler.ScrollHandler.getScrollEvent(this.props.scrollContainerId);
      return (0, _jsxRuntime.jsx)(_AutoComplete.AutoComplete, {
        style: {
          position: 'absolute',
          zIndex: 0,
          top: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(top),
          height: 270,
          left: iconLeft != null ? _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(36) : _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(0),
          right: 0,
          opacity: 0
        },
        maxHeight: maxAutoCompleteHeight,
        autoCompleteSuggestions: autoCompleteSuggestions,
        onPressAutoComplete: this.onPressAutoComplete,
        scrollEvent: scrollEvent
      }, "autoComplete");
    }
  }, {
    key: "renderBase",
    value: function renderBase() {
      var _this$props6 = this.props,
        labelText = _this$props6.labelText,
        iconLeft = _this$props6.iconLeft,
        iconRight = _this$props6.iconRight,
        backgroundColor = _this$props6.backgroundColor,
        disabled = _this$props6.disabled;
      var _this$state3 = this.state,
        focused = _this$state3.focused,
        value = _this$state3.value;
      var height = this.getHeight();
      height = _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(height);
      var rightIcons = iconRight != null ? [iconRight] : [];
      var rightButtons = this.renderRightButton(rightIcons);
      var width = this.getWidth();
      var ripple = focused || disabled ? null : (0, _jsxRuntime.jsx)(_Ripple.Ripple, {
        style: _ThemeManager.ThemeManager.style.absoluteStyle,
        backgroundColor: "transparent",
        onPress: this.focus,
        disabled: focused
      });
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          width: width,
          height: height + this.helperTextHeight,
          position: 'relative'
        },
        collapsable: false,
        ref: this.setRef,
        children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            height: height + this.helperTextHeight,
            width: '100%',
            position: 'absolute'
          },
          children: [(0, _jsxRuntime.jsx)(_FormInputLabel.FormInputLabel, {
            backgroundColor: backgroundColor,
            focused: focused,
            topDownDelta: this.labelTopDownDelta,
            color: this.getColor(),
            labelText: labelText,
            up: focused === true || value != null && value.length > 0,
            style: {
              position: 'absolute',
              top: this.labelTop,
              height: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(48),
              left: iconLeft != null ? _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(36) : _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(11),
              right: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(rightButtons.length * 36) + _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(12)
            }
          }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              flexDirection: 'row',
              position: 'absolute',
              top: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(this.getTextInputTop()),
              bottom: 0,
              left: iconLeft != null ? _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(36) : _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(12),
              right: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(rightButtons.length * 36) + _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(12)
            },
            children: this.renderTextInput()
          }), ripple, (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              position: 'absolute',
              flexDirection: 'row',
              alignItems: 'center',
              height: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(56),
              width: iconLeft != null ? _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(36) : 0,
              top: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(10),
              left: 0
            },
            children: this.renderIcon(iconLeft)
          }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              position: 'absolute',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              height: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(56),
              top: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(10),
              right: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(6)
            },
            children: rightButtons
          }), this.renderHelperText(), this.renderMaxLengthText(), this.renderAutoComplete()]
        })
      });
    }
  }, {
    key: "renderHelperText",
    value: function renderHelperText() {
      var _this$props7 = this.props,
        iconLeft = _this$props7.iconLeft,
        helperText = _this$props7.helperText,
        maxLength = _this$props7.maxLength;
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        onLayout: this.onHelperTextLayout,
        style: {
          minHeight: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(21),
          position: 'absolute',
          padding: 0,
          margin: 0,
          top: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(this.getHeight() + 2),
          left: iconLeft != null ? _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(36) : _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(12),
          right: maxLength != null ? _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(36) : 0,
          alignItems: 'flex-end',
          overflow: 'visible'
        },
        children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          textPadding: 0,
          type: _MaterialText.MaterialTextTypes.Body2,
          color: this.getColor(),
          children: helperText
        })
      });
    }
  }, {
    key: "renderIcon",
    value: function renderIcon(icon) {
      if (icon == null) {
        return null;
      }
      if (typeof icon === 'string') {
        return (0, _jsxRuntime.jsx)(_Icon.Icon, {
          icon: icon,
          iconSize: 24,
          color: this.getColor(),
          outerSize: 36,
          toolTip: ""
        });
      }
      return (0, _jsxRuntime.jsx)(_Icon.Icon, Object.assign({}, icon, {
        iconSize: 24,
        color: this.getColor(),
        outerSize: 36,
        toolTip: ""
      }));
    }
  }, {
    key: "renderMaxLengthText",
    value: function renderMaxLengthText() {
      var maxLength = this.props.maxLength;
      var value = this.state.value;
      if (maxLength == null) {
        return null;
      }
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          minHeight: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(21),
          position: 'absolute',
          top: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(this.getHeight() + 2),
          right: 0
        },
        children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          type: _MaterialText.MaterialTextTypes.Body2,
          color: this.getColor(),
          children: `${value.length}/${maxLength}`
        })
      });
    }
  }, {
    key: "renderRightButton",
    value: function renderRightButton(rightButtons) {
      var buttons = [];
      if (this.props.disabled !== true && this.props.clearButton === true && (_reactNativeWeb.Platform.OS === 'web' || this.props.clearButtonAlwaysVisible === true || this.state.focused === true) && this.state.value.length > 0) {
        buttons.push((0, _jsxRuntime.jsx)(_Icon.Icon, {
          toolTip: _I18n.I18n.m.getMessage('clear'),
          outerSize: 36,
          icon: "close",
          onPress: this.clearText,
          disabled: this.props.disabled
        }, "clearButton"));
      }
      if (rightButtons != null && rightButtons.length > 0) {
        for (var icon of rightButtons) {
          buttons.push((0, _jsxRuntime.jsx)(_Icon.Icon, Object.assign({}, icon, {
            iconSize: 24,
            toolTip: "",
            color: this.getColor(),
            outerSize: 36,
            disabled: this.props.disabled
          }), `button_${icon.icon}`));
        }
      }
      if (this.props.secureTextEntry === true) {
        var secureTextEntry = this.state.secureTextEntry;
        buttons.push((0, _jsxRuntime.jsx)(_Icon.Icon, {
          toolTip: secureTextEntry === true ? _I18n.I18n.m.getMessage('hide') : _I18n.I18n.m.getMessage('show'),
          outerSize: 36,
          icon: secureTextEntry === true ? 'eye' : 'eye-off',
          onPress: this.changeSecureTextEntry
        }, "secureTextEntry"));
      }
      return buttons;
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        color: _ThemeManager.ThemeManager.style.brandPrimary,
        errorColor: _ThemeManager.ThemeManager.style.brandDanger,
        unfocusedColor: _ThemeManager.ThemeManager.style.black42,
        maxAutoCompleteHeight: 270,
        clearButton: true,
        helperText: '',
        error: false,
        secureTextEntry: false,
        disabled: false,
        numberOfLines: 1,
        blurAfterSubmit: true,
        autoCompleteSearchEnabled: true,
        focusAfterClear: true,
        disableScrollTo: false
      };
    }
  }, {
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, prevState) {
      var updateNeeded = false;
      var newState = prevState != null ? prevState : {
        focused: false
      };
      if (prevState.width !== nextProps.width) {
        newState.width = nextProps.width;
        updateNeeded = true;
      }
      if (nextProps.value != null && prevState.value !== nextProps.value) {
        newState.value = nextProps.value;
        updateNeeded = true;
      }
      var multiline = nextProps.numberOfLines > 1;
      if (prevState.multiline !== multiline) {
        newState.multiline = multiline;
        updateNeeded = true;
      }
      if (updateNeeded) {
        return newState;
      }
      return null;
    }
  }]);
  return FormInputBase;
}(_react.PureComponent);