import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useRef, useState } from 'react';
import { DefaultErrorHandler } from 'upmesh-client/src/components/DefaultErrorHandler';
import { AddControlNote } from 'upmesh-support-core/src/commands/controlnotes/AddControlNote';
import { ChangeControlNote, } from 'upmesh-support-core/src/commands/controlnotes/ChangeControlNote';
import { ControlNotesEntity } from 'upmesh-support-core/src/entities/ControlNotesEntity';
import { ConnectionHandler } from '../../ConnectionHandler';
import { I18n } from '../../i18n/I18n';
import { ControlNoteExtended } from './ControlNoteExtended';
export function ControlNoteAdOrChangeDialog(props) {
    const newOne = useRef(props.controlNote == null);
    const [isLoading, setIsLoading] = useState(false);
    const [currentSubAddData, setCurrentSubAddData] = useState(props.controlNote
        ? props.controlNote
        : new ControlNoteExtended({
            text: '',
            for: props.for,
        }));
    const closeDialog = () => {
        Dialog.instance?.close();
    };
    const submitChangeControlNote = async () => {
        setIsLoading(true);
        try {
            const data = {
                ...currentSubAddData,
            };
            const add = new ChangeControlNote(data, currentSubAddData.id, ConnectionHandler.getConnection().token);
            await add.execute(ConnectionHandler.getStore());
            closeDialog();
        }
        catch (e) {
            console.error(e);
            Routing.instance.alert.post({ text: DefaultErrorHandler.getDefaultErrorMessages(e, I18n.m) });
            setIsLoading(false);
        }
    };
    const submitAddControlNote = async () => {
        setIsLoading(true);
        try {
            const data = {
                ...currentSubAddData,
            };
            const add = new AddControlNote(data, currentSubAddData.id, ConnectionHandler.getConnection().token);
            await add.execute(ConnectionHandler.getStore());
            closeDialog();
        }
        catch (e) {
            console.error(e);
            Routing.instance.alert.post({ text: DefaultErrorHandler.getDefaultErrorMessages(e, I18n.m) });
            setIsLoading(false);
        }
    };
    const change = () => {
        submitChangeControlNote().catch((err) => console.debug(err));
    };
    const add = () => {
        submitAddControlNote().catch((err) => console.debug(err));
    };
    return (<>
      <DialogTitle key="title">{newOne.current ? 'Notiz hinzufügen' : 'Notiz bearbeiten'}</DialogTitle>
      <DialogContent key="content">
        <FormInputFilled numberOfLines={10} initValue={currentSubAddData.text} labelText="Notiz" onChange={(value) => {
            setCurrentSubAddData(new ControlNotesEntity({
                ...currentSubAddData,
                text: value,
            }));
        }}/>
        
      </DialogContent>
      <DialogActions key="actions">
        <ContainedButton disabled={isLoading} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} key="abort" title="abbrechen" onPress={closeDialog}/>
        <ContainedButton key="yes" loading={isLoading} title={!newOne.current ? 'ändern' : 'hinzufügen'} onPress={!newOne.current ? change : add}/>
      </DialogActions>
    </>);
}
export const openControlNoteDialog = (props) => (e) => {
    let openPosition;
    if (e != null && e.nativeEvent != null && e.nativeEvent.pageX != null && e.nativeEvent.pageY != null) {
        openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
    }
    Dialog.instance?.open({
        content: <ControlNoteAdOrChangeDialog {...props}/>,
        fullscreenResponsive: true,
        contentPadding: false,
        openPosition,
    });
};
