import ApexCharts from 'apexcharts';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
export class ProjectChart extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.loadData().catch((err) => console.debug('cant load Data', err));
    }
    async loadData() {
        const { projects } = this.props;
        projects.sort((a, b) => {
            if (a.createdAt > b.createdAt)
                return -1;
            return 1;
        });
        const dataSet = [];
        if (projects.length > 0) {
            const c = new Date(projects[0].createdAt);
            let lastDay = new Date(c.getFullYear(), c.getMonth());
            let y = 0;
            projects.forEach((c, i) => {
                y += 1;
                const d = new Date(c.createdAt);
                const currentDay = new Date(d.getFullYear(), d.getMonth());
                if (lastDay.getTime() !== currentDay.getTime()) {
                    dataSet.push({ x: lastDay.getTime(), y });
                    lastDay = new Date(currentDay.getTime());
                    if (i < projects.length)
                        y = 0;
                }
            });
            dataSet.push({ x: lastDay.getTime(), y: 1 });
        }
        const options = {
            chart: {
                type: 'bar',
                stacked: false,
                width: ResizeEvent.current.contentWidth - 32,
                height: ResizeEvent.current.contentHeight - 64,
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true,
                },
                toolbar: {
                    show: true,
                    offsetX: -24,
                    offsetY: 0,
                    tools: {
                        download: false,
                        selection: true,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        reset: true,
                        customIcons: [],
                    },
                    autoSelected: 'zoom',
                },
            },
            title: {
                text: 'Projektzuwachs pro Monat',
                align: 'left',
            },
            yaxis: {
                labels: {
                    formatter(val) {
                        return val.toFixed(0);
                    },
                },
            },
            xaxis: {
                type: 'datetime',
            },
            series: [
                {
                    name: 'Projekte',
                    data: dataSet,
                },
            ],
            tooltip: {
                shared: false,
                y: {
                    formatter(val) {
                        return val.toFixed(0);
                    },
                },
                x: {
                    formatter(val) {
                        const d = new Date(val);
                        return `${d.getFullYear()}.${d.getMonth() + 1}`;
                    },
                },
            },
        };
        const chart = new ApexCharts(document.querySelector('#chart'), options);
        chart.render().catch((err) => console.debug('cant render chart!', err));
    }
    render() {
        return (<View>
        <View style={{ width: ResizeEvent.current.contentWidth, height: ResizeEvent.current.contentHeight, paddingTop: 24 }}>
          <div style={{ width: '100%', height: '100%' }} id="chart"/>
        </View>
      </View>);
    }
}
