import ApexCharts from 'apexcharts';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { ConnectionHandler } from '../ConnectionHandler';
import { I18n } from '../i18n/I18n';
export class OnlineChart extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.loadData().catch((err) => console.debug('cant load Data', err));
    }
    async loadData() {
        const from = new Date();
        from.setDate(from.getDate() - 7);
        const f = await fetch(`${ConnectionHandler.apiUrl}/supportroles/onlinehistory?from=${from.getTime()}&to=${new Date().getTime()}`, {
            headers: { authorization: `Bearer ${ConnectionHandler.serverConnection.token}` },
        });
        const o = await f.json();
        const cores = o;
        const perDay = new Map();
        const dataSet = [];
        cores.forEach((c) => {
            if (c.userOnline && c.userOnline.length > 0) {
                const d = new Date(c.createdAt);
                const k = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 12, 0, 0, 0).toISOString();
                const users = perDay.has(k) ? perDay.get(k) : new Set();
                c.userOnline.forEach((id) => users.add(id.userId));
                perDay.set(k, users);
            }
        });
        perDay.forEach((y, x) => {
            dataSet.push({ x: new Date(x).getTime(), y: y.size });
        });
        const options = {
            chart: {
                type: 'area',
                stacked: false,
                width: ResizeEvent.current.contentWidth - 32,
                height: ResizeEvent.current.contentHeight - 64,
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true,
                },
                toolbar: {
                    show: true,
                    offsetX: -24,
                    offsetY: 0,
                    tools: {
                        download: false,
                        selection: true,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        reset: true,
                        customIcons: [],
                    },
                    autoSelected: 'zoom',
                },
            },
            title: {
                text: 'Nutzer Online',
                align: 'left',
            },
            yaxis: {
                labels: {
                    formatter(val) {
                        return val.toFixed(0);
                    },
                },
            },
            xaxis: {
                type: 'datetime',
            },
            series: [
                {
                    name: 'online',
                    data: dataSet,
                },
            ],
            tooltip: {
                shared: false,
                y: {
                    formatter(val) {
                        return val.toFixed(0);
                    },
                },
                x: {
                    formatter(val) {
                        return I18n.m.date().localeDateWithTimeString(new Date(val));
                    },
                },
            },
        };
        const chart = new ApexCharts(document.querySelector('#chart'), options);
        chart.render().catch((err) => console.debug('cant render chart!', err));
    }
    render() {
        return (<View>
        <View style={{ width: ResizeEvent.current.contentWidth, paddingTop: 24 }}>
          <div id="chart"/>
        </View>
      </View>);
    }
}
