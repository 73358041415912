import { Icon } from 'materialTheme/src/theme/components/Icon';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React from 'react';
import { View } from 'react-native';
import { openpaymentDialog } from '../payments/PaymentAddOrChangeDialog';
import { PaymentTable } from '../payments/PaymentTable';
export function PartnerPayments(props) {
    const add = () => {
        openpaymentDialog({ to: { type: 'partner', id: props.partner.id } })();
    };
    return (<View style={{ width: '100%', position: 'relative', height: props.height, overflow: 'visible' }}>
      <PaymentTable showSum tableName="partner" preFilter={`from eq '${props.partner.id}' or (from eq null and to/id eq '${props.partner.id}')`} height={props.height} onlyFrom={['', props.partner.id]}/>
      <View style={{ position: 'absolute', right: 16, bottom: 24 }}>
        <Icon onPress={add} icon="plus" color="#FFFFFF" backgroundColor={ThemeManager.style.brandPrimary} radius={24} outerSize={48} toolTip="add"/>
      </View>
    </View>);
}
