import { SubscriptionEntity } from 'upmesh-core/src/client/query/entities/SubscriptionEntity';
import { ConnectionHandler } from '../../ConnectionHandler';
export class SubscriptionExtended extends SubscriptionEntity {
    static async fromSubscription(s) {
        const e = s;
        e.creatorName = await SubscriptionExtended.getCreatorName(e.createdBy);
        return e;
    }
    static async getCreatorName(id) {
        try {
            const user = await ConnectionHandler.getClient()?.user.getById(id);
            if (user)
                return user.getFullName();
        }
        catch (e) {
            console.error(e);
        }
        return '?';
    }
}
