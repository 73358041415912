var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _color = _interopRequireDefault(require("color"));
var _react = _interopRequireDefault(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _CurrentUser = require("upmesh-auth-core/build/src/client/CurrentUser");
var _I18n = require("../../i18n/I18n");
var _ThemeManager = require("../../ThemeManager");
var _FormInputFilled = require("../forminput/FormInputFilled");
var _MaterialText = require("../text/MaterialText");
var _Ripple = require("../utils/Ripple");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var TimeInput = exports.default = function (_React$Component) {
  (0, _inherits2.default)(TimeInput, _React$Component);
  var _super = _createSuper(TimeInput);
  function TimeInput(props) {
    var _this;
    (0, _classCallCheck2.default)(this, TimeInput);
    _this = _super.call(this, props);
    _this.updateDate = function () {
      var _this$props = _this.props,
        updateDate = _this$props.updateDate,
        selectedDate = _this$props.selectedDate;
      var _this$state = _this.state,
        hourInput = _this$state.hourInput,
        minuteInput = _this$state.minuteInput,
        selectedFormat = _this$state.selectedFormat;
      var timeFormat = _this.props.timeFormat != null ? _this.props.timeFormat : _CurrentUser.CurrentUser.settings.timeFormat;
      var newDate = new Date(selectedDate.getTime());
      var hours = Number.parseInt(hourInput, 10);
      var minutes = Number.parseInt(minuteInput, 10);
      if (Number.isNaN(hours) || Number.isNaN(minutes)) return;
      if (timeFormat === '12' && selectedFormat === 'pm') hours += 12;
      newDate.setHours(hours);
      newDate.setMinutes(minutes);
      updateDate(newDate);
    };
    var selectedDate = props.selectedDate;
    var timeFormat = _this.props.timeFormat != null ? _this.props.timeFormat : _CurrentUser.CurrentUser.settings.timeFormat;
    var hourInput = selectedDate.getHours();
    hourInput -= hourInput > 12 && timeFormat === '12' ? 12 : 0;
    _this.state = {
      error: undefined,
      hourInput: hourInput.toString(),
      minuteInput: selectedDate.getMinutes().toString(),
      selectedFormat: selectedDate.getHours() >= 12 ? 'pm' : 'am'
    };
    return _this;
  }
  (0, _createClass2.default)(TimeInput, [{
    key: "render",
    value: function render() {
      var _this2 = this;
      var _this$state2 = this.state,
        selectedFormat = _this$state2.selectedFormat,
        hourInput = _this$state2.hourInput,
        minuteInput = _this$state2.minuteInput;
      var selectedDate = this.props.selectedDate;
      var timeFormat = this.props.timeFormat != null ? this.props.timeFormat : _CurrentUser.CurrentUser.settings.timeFormat;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          marginRight: 24,
          marginLeft: 24,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          height: 100
        },
        children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: 72,
            marginRight: 12,
            height: 100
          },
          children: (0, _jsxRuntime.jsx)(_FormInputFilled.FormInputFilled, {
            onFocus: function onFocus() {
              return _this2.setState({
                hourInput: ''
              });
            },
            keyboardType: "number-pad",
            fieldWidth: 72,
            value: hourInput,
            onChange: function onChange(e) {
              return _this2.setState(function (prevState) {
                return {
                  hourInput: timeFormat === '12' ? /^([1-9]|1[0-2])$|^$/.test(e) ? e : prevState.hourInput : /^([0-9]|1[0-9]|2[0-3])$|^$/.test(e) ? e : prevState.hourInput
                };
              }, _this2.updateDate);
            },
            onBlur: function onBlur() {
              return _this2.setState({
                hourInput: hourInput === '' ? selectedDate.getHours().toString() : hourInput
              });
            },
            error: !!this.state.error,
            helperText: this.state.error ? this.state.error : _I18n.I18n.m.getMessage('hour'),
            clearButton: false
          })
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            fontSize: 40,
            children: ":"
          })
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: 72,
            marginLeft: 12,
            height: 100
          },
          children: (0, _jsxRuntime.jsx)(_FormInputFilled.FormInputFilled, {
            onFocus: function onFocus() {
              return _this2.setState({
                minuteInput: ''
              });
            },
            fieldWidth: 72,
            value: minuteInput,
            keyboardType: "number-pad",
            onChange: function onChange(e) {
              return _this2.setState(function (prevState) {
                return {
                  minuteInput: /^([0-9]|[1-5][0-9])$|^$/.test(e) ? e : prevState.minuteInput
                };
              }, _this2.updateDate);
            },
            onBlur: function onBlur() {
              return _this2.setState({
                minuteInput: minuteInput === '' ? selectedDate.getMinutes().toString() : minuteInput
              });
            },
            error: !!this.state.error,
            helperText: this.state.error ? this.state.error : _I18n.I18n.m.getMessage('minute'),
            clearButton: false
          })
        }), timeFormat === '12' && (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            height: 72,
            marginLeft: 12,
            marginVertical: 12
          },
          children: [(0, _jsxRuntime.jsx)(_Ripple.Ripple, {
            style: {
              width: 52,
              height: '50%',
              borderTopLeftRadius: 3,
              borderTopRightRadius: 3,
              borderWidth: 1,
              borderBottomWidth: 0,
              borderColor: '#aaa',
              justifyContent: 'center',
              backgroundColor: selectedFormat === 'am' ? (0, _color.default)(_ThemeManager.ThemeManager.style.brandPrimary).lighten(0.75).toString() : undefined
            },
            onPress: function onPress() {
              _this2.setState({
                selectedFormat: 'am'
              }, _this2.updateDate);
            },
            children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              fixedWidth: "100%",
              textAlign: "center",
              children: "AM"
            })
          }), (0, _jsxRuntime.jsx)(_Ripple.Ripple, {
            style: {
              width: 52,
              height: '50%',
              borderBottomLeftRadius: 3,
              borderBottomRightRadius: 3,
              borderWidth: 1,
              borderColor: '#aaa',
              justifyContent: 'center',
              backgroundColor: selectedFormat === 'pm' ? (0, _color.default)(_ThemeManager.ThemeManager.style.brandPrimary).lighten(0.75).toString() : undefined
            },
            onPress: function onPress() {
              _this2.setState({
                selectedFormat: 'pm'
              }, _this2.updateDate);
            },
            children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              fixedWidth: "100%",
              textAlign: "center",
              children: "PM"
            })
          })]
        })]
      });
    }
  }]);
  return TimeInput;
}(_react.default.Component);