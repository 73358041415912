import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Form } from 'materialTheme/src/theme/components/forminput/Form';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { ChangeUpmeshPartnerSettings, } from 'upmesh-support-core/src/commands/partner/ChangeUpmeshPartnerSettings';
import { UpmeshPartnerSettingsEntity } from 'upmesh-support-core/src/entities/UpmeshPartnerSettingsEntity';
import { ConnectionHandler } from '../../ConnectionHandler';
export class ChangePartnerSettingsDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.init = async () => {
            const client = ConnectionHandler.getClient();
            const { data } = this.props;
            if (client != null && data != null && data.id != null) {
                let settings = new UpmeshPartnerSettingsEntity({ id: data.id });
                try {
                    settings = await client.upmeshPartnerSettings.getById(data.id);
                }
                catch (e) {
                    console.debug('cant get Partner Settings', e);
                }
                this.setState({ settings });
            }
        };
        this.close = (_e) => {
            Dialog.instance?.close();
        };
        this.catchError = (e) => {
            Routing.instance.alert.post({
                text: `error: ${JSON.stringify(e)}`,
            });
            return true;
        };
        this.onSaved = () => {
            this.close();
        };
        this.state = {
            error: '',
        };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug('cant init Dialog', err));
    }
    render() {
        const { settings } = this.state;
        if (settings == null)
            return <Spinner />;
        const dataOptions = new Map();
        dataOptions.set('customerNumber', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Kundennummer',
            },
        });
        dataOptions.set('sellingItemNumber', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Artikelnummer Projektleiter',
            },
        });
        dataOptions.set('sellingPrice', {
            formType: 'numberPicker',
            cols: 1,
            props: {
                labelText: 'Verkauspreis Projektleiter',
                step: 0.1,
                min: 29,
                max: 150,
                formatTime: false,
            },
        });
        dataOptions.set('sellingItemNumberCollaborator', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Artikelnummer Mitarbeiter',
            },
        });
        dataOptions.set('sellingPriceCollaborator', {
            formType: 'numberPicker',
            cols: 1,
            props: {
                labelText: 'Verkauspreis Mitarbeiter',
                step: 0.1,
                min: 9,
                max: 100,
                formatTime: false,
            },
        });
        dataOptions.set('sellingItemNumberTimeTracking', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Artikelnummer Zeiterfasser',
            },
        });
        dataOptions.set('sellingPriceTimeTracking', {
            formType: 'numberPicker',
            cols: 1,
            props: {
                labelText: 'Verkauspreis Zeiterfasser',
                step: 0.1,
                min: 2,
                max: 30,
                formatTime: false,
            },
        });
        dataOptions.set('pricePercent', {
            formType: 'numberPicker',
            cols: 1,
            props: {
                labelText: 'Prozent Einkaufspreis Projektleiter',
                step: 1,
                min: 50,
                max: 100,
                formatTime: false,
            },
        });
        dataOptions.set('price', {
            formType: 'numberPicker',
            cols: 1,
            props: {
                labelText: 'Min. Einkaufspreis Projektleiter',
                step: 0.1,
                min: 25,
                max: 100,
                formatTime: false,
            },
        });
        dataOptions.set('priceCollaboratorPercent', {
            formType: 'numberPicker',
            cols: 1,
            props: {
                labelText: 'Prozent Einkaufspreis Mitarbeiter',
                step: 1,
                min: 50,
                max: 100,
                formatTime: false,
            },
        });
        dataOptions.set('priceCollaborator', {
            formType: 'numberPicker',
            cols: 1,
            props: {
                labelText: 'Min. Einkaufspreis Mitarbeiter',
                step: 0.1,
                min: 9,
                max: 30,
                formatTime: false,
            },
        });
        dataOptions.set('priceTimeTrackingPercent', {
            formType: 'numberPicker',
            cols: 1,
            props: {
                labelText: 'Prozent Einkaufspreis Zeiterfasser',
                step: 1,
                min: 50,
                max: 100,
                formatTime: false,
            },
        });
        dataOptions.set('priceTimeTracking', {
            formType: 'numberPicker',
            cols: 1,
            props: {
                labelText: 'Min. Einkaufspreis Zeiterfasser',
                step: 0.1,
                min: 2,
                max: 20,
                formatTime: false,
            },
        });
        dataOptions.set('itemNumber', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Einkauf Artikelnummer Projektleiter (upmesh)',
            },
        });
        dataOptions.set('itemNumberCollaborator', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Einkauf Artikelnummer Mitarbeiter (upmesh)',
            },
        });
        dataOptions.set('itemNumberTimeTracking', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Einkauf Artikelnummer Zeiterfasser (upmesh)',
            },
        });
        dataOptions.set('partnerCostumerNumberForUpmesh', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Upmesh Kundennummer',
            },
        });
        dataOptions.set('billingText', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Abrechnungstext Projektleiter',
                numberOfLines: 3,
                helperText: 'Variablen: {{from}}, {{to}}, {{amount}}, {{type}}, {{unitPrice}}',
            },
        });
        dataOptions.set('billingTextCollaborator', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Abrechnungstext Mitarbeiter',
                numberOfLines: 3,
                helperText: 'Variablen: {{from}}, {{to}}, {{amount}}, {{type}}, {{unitPrice}}',
            },
        });
        dataOptions.set('billingTextTimeTracking', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Abrechnungstext Zeiterfasser',
                numberOfLines: 3,
                helperText: 'Variablen: {{from}}, {{to}}, {{amount}}, {{type}}, {{unitPrice}}',
            },
        });
        return (<Form baseCols={1} catchError={this.catchError} dataOptions={dataOptions} formHeaderProps={{
                formTitle: 'Partner ändern',
            }} store={ConnectionHandler.getStore()} command={new ChangeUpmeshPartnerSettings({ ...settings }, this.props.data.id, ConnectionHandler.serverConnection.token)} additionalButtons={[
                <ContainedButton key="abort" onPress={this.close} title="abbrechen" backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
            ]} onSaved={this.onSaved} saveButtonLabel="speichern" stickyHeader stickyActions resetButton serverConnection={ConnectionHandler.getConnection()}/>);
    }
}
ChangePartnerSettingsDialog.openDialog = (partnerId) => (e) => {
    const openPosition = { x: e ? e.nativeEvent.pageX : 0, y: e ? e.nativeEvent.pageY : 0 };
    Dialog.instance?.open({
        openPosition,
        fullscreenResponsive: true,
        scrollable: false,
        contentPadding: false,
        content: <ChangePartnerSettingsDialog data={{ id: partnerId }}/>,
    });
};
