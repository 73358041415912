import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { FlatList, View } from 'react-native';
import { DefaultErrorHandler } from 'upmesh-client/src/components/DefaultErrorHandler';
import { DeleteControlNote } from 'upmesh-support-core/src/commands/controlnotes/DeleteControlNote';
import { ConnectionHandler } from '../../ConnectionHandler';
import { I18n } from '../../i18n/I18n';
import { openControlNoteDialog } from './ControlNoteAddOrChangeDialog';
import { ControlNoteExtended } from './ControlNoteExtended';
export function ControlNoteList(props) {
    const [controlNotes, setControlNotes] = useState([]);
    const updateData = () => {
        const client = ConnectionHandler.getClient();
        if (client == null)
            return;
        const { forTo } = props;
        const fFor = forTo ? `for/id eq '${forTo.id}' and ` : ``;
        const filter = `${fFor}deleted ne true`;
        client.controlNotes
            .get({
            select: ['id', 'text', 'for', 'createdBy', 'createdAt'],
            filter: props.preFilter ? `(${props.preFilter}) and ${filter}` : filter,
            orderby: 'createdAt DESC',
        })
            .then((p) => {
            const promises = [];
            p.forEach((ControlNote) => {
                promises.push(new Promise((resolve) => {
                    ControlNoteExtended.ControlNoteToExtended(ControlNote)
                        .then((p) => resolve(p))
                        .catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
                }));
            });
            return Promise.all(promises);
        })
            .then((p2) => {
            setControlNotes(p2);
        })
            .catch((err) => {
            console.debug('cant get data', err);
            DefaultErrorHandler.showDefaultErrorAlert(err);
        });
    };
    useEffect(() => {
        updateData();
    }, [props.preFilter]);
    useEffect(() => {
        const l = ConnectionHandler.getClient()?.eventDispatcher.attach({
            callback: (en) => {
                const { forTo } = props;
                let hasId = forTo == null;
                if (!hasId) {
                    en.entities.forEach((e) => {
                        if (e.entity?.for.id === forTo.id)
                            hasId = true;
                    });
                }
                if (hasId) {
                    updateData();
                }
            },
            readModelName: 'ControlNotes',
        });
        return () => {
            if (l)
                ConnectionHandler.getClient()?.eventDispatcher.detach('ControlNotes', l);
        };
    }, []);
    const deleteControlNoteNow = async (p) => {
        const c = new DeleteControlNote({}, p.id, ConnectionHandler.getConnection().token);
        await c.execute(ConnectionHandler.getStore());
    };
    const deleteControlNote = (p) => (_e) => {
        Routing.instance.alert.post({
            text: `Möchten Sie den Eintrag wirklich löschen?`,
            buttons: [
                <ContainedButton key="discard" title={I18n.m.getMessage('cancel')} backgroundColor="#FFFFFF" textColor={ThemeManager.style.brandPrimary} onPress={() => {
                        Alert.instance?.close();
                    }}/>,
                <ContainedButton key="save" backgroundColor={ThemeManager.style.brandDanger} title={I18n.m.getMessage('delete')} onPress={() => {
                        Alert.instance?.close(() => {
                            deleteControlNoteNow(p).catch((e) => {
                                DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m);
                            });
                        });
                    }}/>,
            ],
        });
    };
    const editControlNote = (p) => (e) => {
        if (props.forTo == null)
            return;
        openControlNoteDialog({ controlNote: p, for: props.forTo })(e);
    };
    const renderItem = ({ item, index }) => {
        const backgroundColor = index % 2 === 0 ? '#FFFFFF' : '#fafafa';
        return (<View style={{ width: '100%', padding: 12 }}>
        <View style={{ width: '100%', flexDirection: 'row', padding: 4, backgroundColor }}>
          <View style={{ flex: 1 }}>
            <View style={{ width: '100%', flexDirection: 'row' }}>
              <MaterialText type={MaterialTextTypes.Caption}>
                {I18n.m.dateCurrent.localeDateWithTimeString(new Date(item.createdAt))}
              </MaterialText>
              <MaterialText type={MaterialTextTypes.Caption}>{item.fromName}</MaterialText>
            </View>
            <View style={{ width: '100%', flexDirection: 'row' }}>
              <MaterialText>{item.text}</MaterialText>
            </View>
          </View>
          <View style={{ width: 96, flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Icon icon="pencil-outline" toolTip={I18n.m.getMessage('edit')} onPress={editControlNote(item)}/>
            <Icon icon="delete-outline" toolTip={I18n.m.getMessage('delete')} onPress={deleteControlNote(item)}/>
          </View>
        </View>
      </View>);
    };
    return (<View style={{ width: '100%', position: 'relative', height: props.height, overflow: 'visible' }}>
      {controlNotes != null && controlNotes.length === 0 ? (<View style={{ padding: 16, width: '100%' }}>
          <MaterialText fixedWidth="100%" textAlign="center">
            Keine Notizen vorhanden
          </MaterialText>
        </View>) : (<FlatList data={controlNotes} keyExtractor={(item) => `t_${item.id}_${item.lastModifiedAt.toISOString()}`} renderItem={renderItem}/>)}
    </View>);
}
