var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Uploads = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _TempFile = require("cqrs-core/build/src/client/commands/TempFile");
var _WaitFor = require("cqrs-shared/build/src/WaitFor");
var _reactNativeWeb = require("../../../../../upmesh-support/node_modules/react-native-web");
var _tsEvents = require("ts-events");
var _AuthClient = require("upmesh-auth-core/build/src/client/AuthClient");
var _CurrentUser = require("upmesh-auth-core/build/src/client/CurrentUser");
var _DateLocale = require("upmesh-i18n/build/src/i18n/DateLocale");
var _I18n = require("../../theme/i18n/I18n");
var _UploadFile = require("./impl/UploadFile");
var bytes = require('bytes');
var Uploads = exports.Uploads = function () {
  function Uploads() {
    var _this = this;
    (0, _classCallCheck2.default)(this, Uploads);
    this.evtProgressEnd = new _tsEvents.AsyncEvent();
    this.evtProgressProgress = new _tsEvents.AsyncEvent();
    this.evtProgressStart = new _tsEvents.AsyncEvent();
    this.onChange = new _tsEvents.AsyncEvent();
    this._maxUploading = 2;
    this.currentUploads = 0;
    this.files = [];
    this.isFileCheckInProgress = false;
    this.uploadStarted = false;
    this.uploadingFiles = [];
    this.fileUploads = {};
    this.onChanged = function (e) {
      Uploads.instance.onChange.post(e);
    };
    this.onConnect = function () {
      var asynNow = function () {
        var _ref = (0, _asyncToGenerator2.default)(function* () {
          try {
            if (Uploads.tempfileRepo != null) {
              var tfs = yield Uploads.tempfileRepo.get();
              for (var f of tfs) {
                if (f.error === 'xhr.status: 0') {
                  f.error = undefined;
                  yield Uploads.tempfileRepo.patch(f);
                }
              }
            }
          } catch (e) {}
          yield _this.getFiles();
        });
        return function asynNow() {
          return _ref.apply(this, arguments);
        };
      }();
      asynNow().catch(function (err) {
        return void 0;
      });
    };
    this.fileUploadComplete = function () {
      var _ref2 = (0, _asyncToGenerator2.default)(function* (tempfileId) {
        try {
          yield _WaitFor.WaitFor.instance.waitFor(function () {
            return Uploads.localDb != null;
          }, 100, 5000);
          if (Uploads.tempfileRepo != null) yield Uploads.tempfileRepo.delete(tempfileId);
        } catch (e) {}
        _this.removeFileAndUploadingFile(tempfileId);
        _this.decreaseCurrentUploads();
        _this.startUpload();
      });
      return function (_x) {
        return _ref2.apply(this, arguments);
      };
    }();
    this.fileUploadError = function () {
      var _ref3 = (0, _asyncToGenerator2.default)(function* (tempfileId, error) {
        try {
          _this.removeFileAndUploadingFile(tempfileId);
          if (error === 'notFound') {
            yield _this.removeUpload(tempfileId);
          } else if (Uploads.tempfileRepo != null) {
            var getFile = yield Uploads.tempfileRepo.getById(tempfileId);
            getFile.error = error;
            yield Uploads.tempfileRepo.patch(getFile);
          }
        } catch (e) {
          _this.uploadingFiles.splice(_this.uploadingFiles.indexOf(tempfileId), 1);
        }
        _this.removeFileAndUploadingFile(tempfileId);
        _this.decreaseCurrentUploads();
        setTimeout(function () {
          _this.startUpload();
        }, 2000);
      });
      return function (_x2, _x3) {
        return _ref3.apply(this, arguments);
      };
    }();
    this.getFiles = function () {
      if (!_this.isFileCheckInProgress) {
        var asyncNow = function () {
          var _ref4 = (0, _asyncToGenerator2.default)(function* () {
            _this.isFileCheckInProgress = true;
            yield _DateLocale.DateLocale.waitFor(function () {
              return _CurrentUser.CurrentUser.entity != null && _AuthClient.AuthClient.instance.serverConnection != null && Uploads.localDb != null;
            }, 33, 5000);
            if (Uploads.localDb == null) {
              yield _this.getFiles();
              _this.isFileCheckInProgress = false;
              return;
            }
            try {
              if (_this.hasFreeUploadSlot() && Uploads.localDb != null && Uploads.tempfileRepo != null) {
                var countedFiles = yield Uploads.tempfileRepo.count();
                if (countedFiles > 0) {
                  var files = yield Uploads.tempfileRepo.get({
                    filter: 'targetUrl ne null',
                    orderby: 'prio ASC'
                  });
                  if (files.length > 0) {
                    for (var f of files) {
                      if (f.error == null) {
                        _this.addFile(f);
                      }
                    }
                    if (_this.files.length > 0) {
                      var maximumUploadsShouldStart = Math.min(_this._maxUploading - _this.currentUploads, countedFiles);
                      for (var i = 0; i <= maximumUploadsShouldStart; i += 1) {
                        _this.startUpload();
                      }
                      _this.isFileCheckInProgress = false;
                      return;
                    }
                  }
                }
              }
            } catch (e) {}
            _this.isFileCheckInProgress = false;
          });
          return function asyncNow() {
            return _ref4.apply(this, arguments);
          };
        }();
        asyncNow().catch(function (err) {
          return void 0;
        });
      } else {
        if (_this.filesCheckingTimer != null) {
          clearTimeout(_this.filesCheckingTimer);
        }
        _this.filesCheckingTimer = setTimeout(_this.getFiles, 2000);
      }
    };
    this.onFileUploadResult = function (tempfileId, file) {
      return function (info) {
        _this.onProgress(info);
        var asyncNow = function () {
          var _ref5 = (0, _asyncToGenerator2.default)(function* () {
            if (info.tempFile.error != null || info.canceled != null) {
              if (info.canceled) {
                _this.removeFileAndUploadingFile(info.tempFile.id);
                _this.decreaseCurrentUploads();
                _this.removeUpload(info.tempFile.id).catch(function (err) {
                  return void 0;
                });
                try {
                  yield file.removeUploads();
                } catch (e) {}
                _this.startUpload();
              } else if (info.tempFile.error != null) {
                yield _this.fileUploadError(tempfileId, info.tempFile.error);
              }
            } else if (info.percent >= 100 && info.finished) {
              yield _this.fileUploadComplete(tempfileId);
              try {
                yield file.removeUploads();
              } catch (e) {}
            }
          });
          return function asyncNow() {
            return _ref5.apply(this, arguments);
          };
        }();
        asyncNow().catch(function (err) {
          return void 0;
        });
      };
    };
    this.onProgress = function (data) {
      _this.evtProgressProgress.post(data);
    };
    if (Uploads._instance != null) {
      throw new Error('Uploads must be addressed via instance');
    } else {
      Uploads._instance = this;
    }
  }
  (0, _createClass2.default)(Uploads, [{
    key: "removeUpload",
    value: function () {
      var _removeUpload = (0, _asyncToGenerator2.default)(function* (tempfileId) {
        var cancel = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        try {
          if (Uploads.tempfileRepo != null) {
            var tempFile = yield Uploads.tempfileRepo.getById(tempfileId);
            yield Uploads.tempfileRepo.delete(tempfileId);
            if (cancel) {
              this.onProgress({
                tempFile: tempFile,
                canceled: true,
                percent: 0
              });
            }
          }
        } catch (e) {}
        this.removeFileAndUploadingFile(tempfileId);
      });
      function removeUpload(_x4) {
        return _removeUpload.apply(this, arguments);
      }
      return removeUpload;
    }()
  }, {
    key: "startUploads",
    value: function () {
      var _startUploads = (0, _asyncToGenerator2.default)(function* (localDB) {
        var _this2 = this;
        Uploads.localDb = localDB;
        if (!this.uploadStarted) {
          var _AuthClient$instance$;
          this.uploadStarted = true;
          if (Uploads.localDb == null) {
            this.uploadStarted = false;
            return;
          }
          Uploads.tempfileRepo = Uploads.localDb.getRepos(new _TempFile.TempFile());
          Uploads.tempfileRepo.changeEvent.attach(this.onChanged);
          this._maxUploading = 2;
          yield this.clearWebTempCache();
          (_AuthClient$instance$ = _AuthClient.AuthClient.instance.serverConnection) == null || _AuthClient$instance$.onConnect.attach(this.onConnect);
        } else {
          var _Uploads$tempfileRepo;
          (_Uploads$tempfileRepo = Uploads.tempfileRepo) == null || _Uploads$tempfileRepo.get({
            filter: `error ne null`
          }).then(function (files) {
            files.forEach(function (f) {
              _this2.tryUploadAgain(f.id).catch(function (err) {
                return void 0;
              });
            });
          }).catch(function (err) {
            return void 0;
          });
        }
        setTimeout(function () {
          _this2.getFiles();
        }, 1000);
      });
      function startUploads(_x5) {
        return _startUploads.apply(this, arguments);
      }
      return startUploads;
    }()
  }, {
    key: "restartUploads",
    value: function restartUploads() {
      var _this3 = this;
      if (this.uploadStarted) {
        setTimeout(function () {
          _this3.getFiles();
        }, 1000);
      }
    }
  }, {
    key: "stopUploads",
    value: function () {
      var _stopUploads = (0, _asyncToGenerator2.default)(function* () {
        if (this.uploadStarted) {
          var _AuthClient$instance$2;
          yield this.clearWebTempCache();
          if (Uploads.tempfileRepo != null) {
            Uploads.tempfileRepo.changeEvent.detach(this.onChanged);
            Uploads.tempfileRepo = null;
          }
          Uploads.localDb = null;
          if (this.filesCheckingTimer != null) {
            clearTimeout(this.filesCheckingTimer);
          }
          this._maxUploading = 0;
          this.uploadStarted = false;
          (_AuthClient$instance$2 = _AuthClient.AuthClient.instance.serverConnection) == null || _AuthClient$instance$2.onConnect.detach(this.onConnect);
        }
      });
      function stopUploads() {
        return _stopUploads.apply(this, arguments);
      }
      return stopUploads;
    }()
  }, {
    key: "tryUploadAgain",
    value: function () {
      var _tryUploadAgain = (0, _asyncToGenerator2.default)(function* (tempfileId) {
        try {
          if (Uploads.tempfileRepo != null) {
            var file = yield Uploads.tempfileRepo.getById(tempfileId);
            file.error = undefined;
            yield Uploads.tempfileRepo.patch(file);
            yield this.getFiles();
          }
        } catch (e) {}
      });
      function tryUploadAgain(_x6) {
        return _tryUploadAgain.apply(this, arguments);
      }
      return tryUploadAgain;
    }()
  }, {
    key: "clearWebTempCache",
    value: function () {
      var _clearWebTempCache = (0, _asyncToGenerator2.default)(function* () {
        if (_reactNativeWeb.Platform.OS === 'web') {
          try {
            yield _WaitFor.WaitFor.instance.waitFor(function () {
              return Uploads.localDb != null;
            }, 100, 5000);
            if (Uploads.localDb != null && Uploads.tempfileRepo != null) {
              yield _WaitFor.WaitFor.instance.waitFor(function () {
                var _Uploads$localDb;
                return ((_Uploads$localDb = Uploads.localDb) == null ? void 0 : _Uploads$localDb.getRepos(new _TempFile.TempFile())) != null;
              });
              try {
                yield Uploads.tempfileRepo.deleteFilter(`createdAt lt ${new Date().toISOString()}`);
              } catch (e) {}
            }
          } catch (e) {}
        }
      });
      function clearWebTempCache() {
        return _clearWebTempCache.apply(this, arguments);
      }
      return clearWebTempCache;
    }()
  }, {
    key: "addFile",
    value: function addFile(file) {
      var alreadyIn = false;
      for (var i = 0; i < this.files.length; i += 1) {
        if (this.files[i].id === file.id) {
          alreadyIn = true;
          break;
        }
      }
      if (!alreadyIn) {
        this.files.push(file);
      }
    }
  }, {
    key: "decreaseCurrentUploads",
    value: function decreaseCurrentUploads() {
      if (this.currentUploads <= 1) {
        this.evtProgressEnd.post(true);
        if (_reactNativeWeb.Platform.OS === 'web') {
          window.onbeforeunload = null;
        }
        this.currentUploads = 0;
      } else {
        this.currentUploads -= 1;
      }
    }
  }, {
    key: "hasFreeUploadSlot",
    value: function hasFreeUploadSlot() {
      return this.currentUploads <= this._maxUploading;
    }
  }, {
    key: "increaseUploadingCounter",
    value: function increaseUploadingCounter() {
      if (this.currentUploads === 0) {
        this.evtProgressStart.post(true);
      }
      this.currentUploads += 1;
    }
  }, {
    key: "removeFile",
    value: function removeFile(id) {
      try {
        for (var i = 0; i < this.files.length; i += 1) {
          if (this.files[i].id === id) {
            this.files.splice(i, 1);
            return;
          }
        }
        this.fileUploads[id] = null;
      } catch (e) {}
    }
  }, {
    key: "removeFileAndUploadingFile",
    value: function removeFileAndUploadingFile(id) {
      this.removeFile(id);
      if (this.uploadingFiles.indexOf(id) >= 0) {
        this.uploadingFiles.splice(this.uploadingFiles.indexOf(id), 1);
      }
    }
  }, {
    key: "startUpload",
    value: function startUpload() {
      if (this.hasFreeUploadSlot() && _AuthClient.AuthClient.instance.serverConnection.isConnected) {
        this.increaseUploadingCounter();
        this.uploadNextFile().catch(function (err) {
          return void 0;
        });
      }
    }
  }, {
    key: "uploadNextFile",
    value: function () {
      var _uploadNextFile = (0, _asyncToGenerator2.default)(function* () {
        var _this4 = this;
        if (this.files.length > 0) {
          var index = 0;
          var nextFile = this.files[index];
          if (nextFile == null) {
            this.decreaseCurrentUploads();
            return;
          }
          var isUploading = this.uploadingFiles.indexOf(nextFile.id) >= 0;
          if (isUploading) {
            this.removeFile(nextFile.id);
            this.decreaseCurrentUploads();
            return;
          }
          this.uploadingFiles.push(nextFile.id);
          try {
            var fileUpload = new _UploadFile.UploadFile();
            this.fileUploads[nextFile.id] = fileUpload;
            fileUpload.progressEvent.attach(this.onFileUploadResult(nextFile.id, fileUpload));
            fileUpload.startUpload(nextFile).catch(function (err) {
              _this4.fileUploadError(nextFile.id, err).catch(function (err) {
                return void 0;
              });
            });
            this.removeFile(nextFile.id);
          } catch (e) {
            this.fileUploadError(nextFile.id, e).catch(function (err) {
              return void 0;
            });
          }
        } else {
          this.decreaseCurrentUploads();
          yield this.getFiles();
        }
      });
      function uploadNextFile() {
        return _uploadNextFile.apply(this, arguments);
      }
      return uploadNextFile;
    }()
  }], [{
    key: "instance",
    get: function get() {
      if (Uploads._instance == null) {
        Uploads._instance = new Uploads();
      }
      return Uploads._instance;
    }
  }, {
    key: "getUploads",
    value: function () {
      var _getUploads = (0, _asyncToGenerator2.default)(function* (type) {
        yield _WaitFor.WaitFor.instance.waitFor(function () {
          return Uploads.localDb != null;
        }, 100, 5000);
        if (Uploads.localDb != null && Uploads.tempfileRepo != null) {
          if (type == null) return Uploads.tempfileRepo.get();
          return Uploads.tempfileRepo.get({
            filter: `type eq '${type}'`
          });
        }
        return [];
      });
      function getUploads(_x7) {
        return _getUploads.apply(this, arguments);
      }
      return getUploads;
    }()
  }, {
    key: "addRemoteUpload",
    value: function () {
      var _addRemoteUpload = (0, _asyncToGenerator2.default)(function* (targetUrl) {
        var headers = {};
        headers.authorization = `Bearer ${_CurrentUser.CurrentUser.token}`;
        return fetch(targetUrl, {
          headers: headers,
          method: 'POST'
        });
      });
      function addRemoteUpload(_x8) {
        return _addRemoteUpload.apply(this, arguments);
      }
      return addRemoteUpload;
    }()
  }, {
    key: "addUpload",
    value: function () {
      var _addUpload = (0, _asyncToGenerator2.default)(function* (targetUrl, file, type, data) {
        var prio = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 5;
        var maxImageSize = arguments.length > 5 ? arguments[5] : undefined;
        var maxUploadFileSize = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : Uploads.maxUploadFileSize;
        if (maxImageSize == null) {
          maxImageSize = 3651;
          if (_CurrentUser.CurrentUser.settings != null && _CurrentUser.CurrentUser.settings.appSettings != null && _CurrentUser.CurrentUser.settings.appSettings['imageUploadQuality']) {
            if (_CurrentUser.CurrentUser.settings.appSettings['imageUploadQuality'] === 1) maxImageSize = 3651;else if (_CurrentUser.CurrentUser.settings.appSettings['imageUploadQuality'] === 2) maxImageSize = 2828;else if (_CurrentUser.CurrentUser.settings.appSettings['imageUploadQuality'] === 3) maxImageSize = 2000;
          }
        }
        if (file['size'] != null && file['size'] > maxUploadFileSize) {
          throw new Error(_I18n.I18n.m.getMessage('fileTooLarge', {
            maxSize: bytes(maxUploadFileSize)
          }));
        }
        if (!Uploads.instance.uploadStarted) throw new Error('upload service not started');
        if (Uploads.localDb == null || Uploads.tempfileRepo == null) throw new Error('no local db set');
        if (_reactNativeWeb.Platform.OS === 'web') {
          window.onbeforeunload = function () {
            return _I18n.I18n.m.getMessage('webBeforeUnloadUploadHint');
          };
        }
        var name = file['name'] != null ? file['name'] : file['filename'] != null ? file['filename'] : file['fileName'];
        var tempFile = new _TempFile.TempFile({
          name: name,
          targetUrl: targetUrl,
          type: type,
          prio: prio,
          data: data,
          file: file
        });
        var prepare = new _UploadFile.UploadFile();
        try {
          var prepared = yield prepare.prepareUpload(tempFile, file);
          prepared.maxImageSize = maxImageSize;
          yield Uploads.tempfileRepo.post(prepared);
          Uploads.instance.evtProgressProgress.post({
            tempFile: prepared,
            percent: 0
          });
          yield Uploads.instance.getFiles();
          return tempFile;
        } catch (e) {
          throw e;
        }
      });
      function addUpload(_x9, _x10, _x11, _x12) {
        return _addUpload.apply(this, arguments);
      }
      return addUpload;
    }()
  }]);
  return Uploads;
}();
Uploads.maxUploadFileSize = 104857600;
Uploads.cancelUpload = function (tempfileId) {
  if (Uploads.instance.fileUploads[tempfileId] != null) {
    try {
      Uploads.instance.fileUploads[tempfileId].cancelUpload();
      Uploads.instance.fileUploads[tempfileId] = null;
    } catch (e) {}
  } else {
    Uploads.instance.removeUpload(tempfileId, true).catch(function (err) {
      return void 0;
    });
  }
};