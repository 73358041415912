Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TableCell = TableCell;
var _react = _interopRequireWildcard(require("../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-support/node_modules/react-native-web");
var _I18n = require("../i18n/I18n");
var _TableTypes = require("./TableTypes");
var _MaterialText = require("./text/MaterialText");
var _Ripple = require("./utils/Ripple");
var _jsxRuntime = require("../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function TableCell(props) {
  var getDefaultCellTextAlignment = function getDefaultCellTextAlignment(dataType) {
    if (dataType === 'object') {
      return 'center';
    }
    return dataType === 'string' ? 'flex-start' : 'flex-end';
  };
  var defaultCellRenderer = function defaultCellRenderer(item, column, index, sLayout, lineHeight) {
    var keyInData = column.keyInData,
      dataType = column.dataType,
      style = column.style,
      cellStyle = column.cellStyle;
    var out = column.format != null ? column.format(item) : item[keyInData];
    if (dataType != null && out != null) {
      if (dataType === 'Date') {
        out = _I18n.I18n.m.dateCurrent.localeDateString(item[keyInData]);
      } else if (dataType === 'DateWithTime') {
        out = _I18n.I18n.m.dateCurrent.localeDateWithTimeString(item[keyInData]);
      } else if (dataType === 'Time') {
        out = _I18n.I18n.m.dateCurrent.getLocalTimeString(item[keyInData]);
      }
    }
    if (out == null) out = '';
    var cellAlignment = sLayout ? 'flex-end' : getDefaultCellTextAlignment(dataType);
    var onCellPress = props.column.onCellPress;
    if (onCellPress != null) {
      return (0, _jsxRuntime.jsx)(_Ripple.Ripple, {
        onPress: function onPress(e) {
          onCellPress(props.item, e);
        },
        children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: [_TableTypes.styles.cellContainer, style, {
            height: lineHeight,
            minHeight: sLayout ? undefined : 54,
            alignSelf: cellAlignment,
            width: '100%',
            maxWidth: '100%'
          }],
          children: keyInData ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: [_TableTypes.styles.cellContent, {
              justifyContent: cellAlignment
            }, cellStyle],
            children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              showToolTipOnCutText: true,
              textAlign: cellAlignment === 'flex-end' ? 'right' : undefined,
              ellipsizeMode: "tail",
              numberOfLines: sLayout ? undefined : 1,
              fixedWidth: "100%",
              centeredBox: false,
              children: out
            })
          }) : null
        }, `cellRipple-${item.id}-${index}`)
      }, `cellRipple-${item.id}-${index}`);
    }
    return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      style: [_TableTypes.styles.cellContainer, style, {
        height: lineHeight,
        minHeight: sLayout ? undefined : 54,
        alignSelf: cellAlignment,
        width: '100%',
        maxWidth: '100%'
      }],
      children: keyInData ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: [_TableTypes.styles.cellContent, {
          justifyContent: cellAlignment
        }, cellStyle],
        children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          showToolTipOnCutText: true,
          textAlign: cellAlignment === 'flex-end' ? 'right' : undefined,
          ellipsizeMode: "tail",
          numberOfLines: sLayout ? undefined : 1,
          fixedWidth: "100%",
          centeredBox: false,
          children: out
        })
      }) : null
    }, `cell-${item.id}-${index}`);
  };
  var rendered = (0, _react.useMemo)(function () {
    return defaultCellRenderer(props.item, props.column, props.index, props.sLayout, props.lineHeight);
  }, [props.item]);
  return rendered;
}