var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormInputLabel = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _ThemeManager = require("../../ThemeManager");
var _MaterialText = require("../text/MaterialText");
var _FormInputLabelAnimation = require("./FormInputLabelAnimation");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var FormInputLabel = exports.FormInputLabel = function (_PureComponent) {
  (0, _inherits2.default)(FormInputLabel, _PureComponent);
  var _super = _createSuper(FormInputLabel);
  function FormInputLabel(props) {
    var _this;
    (0, _classCallCheck2.default)(this, FormInputLabel);
    _this = _super.call(this, props);
    var top;
    var scale;
    if (props.up === true) {
      scale = 0.75;
      top = FormInputLabel.topUp;
    } else {
      top = FormInputLabel.topDown + props.topDownDelta;
      scale = 1;
    }
    top = _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(top);
    _this.state = {
      focused: props.focused,
      labelAnimation: new _FormInputLabelAnimation.FormInputLabelAnimation({
        scale: scale,
        top: top
      }, 195)
    };
    return _this;
  }
  (0, _createClass2.default)(FormInputLabel, [{
    key: "componentDidUpdate",
    value: function componentDidUpdate(_prevProps, _prevState, _snapshot) {
      var current = this.state.labelAnimation.getValues();
      if (this.props.up && (current.top !== FormInputLabel.topUp || current.scale !== 0.875)) {
        this.state.labelAnimation.toValue({
          top: FormInputLabel.topUp,
          scale: 0.875
        });
      } else if (!this.props.up && (current.top !== FormInputLabel.topDown + this.props.topDownDelta || current.scale !== 1)) {
        this.state.labelAnimation.toValue({
          scale: 1,
          top: FormInputLabel.topDown + this.props.topDownDelta
        });
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _this$props = this.props,
        labelText = _this$props.labelText,
        color = _this$props.color,
        style = _this$props.style,
        backgroundColor = _this$props.backgroundColor;
      var labelAnimation = this.state.labelAnimation;
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: [{
          height: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(56),
          minHeight: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(21)
        }, style],
        pointerEvents: "none",
        children: (0, _jsxRuntime.jsx)(_reactNativeWeb.Animated.View, {
          style: [{
            backgroundColor: 'transparent',
            position: 'absolute',
            width: '100%',
            overflow: 'visible'
          }, labelAnimation.labelAnimations],
          children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            type: _MaterialText.MaterialTextTypes.Body1,
            color: color,
            backgroundColor: backgroundColor,
            numberOfLines: 1,
            children: labelText
          })
        })
      });
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        color: _ThemeManager.ThemeManager.style.brandPrimary,
        labelText: '',
        up: false,
        topDownDelta: 0
      };
    }
  }, {
    key: "topDown",
    get: function get() {
      return _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(15);
    }
  }, {
    key: "topUp",
    get: function get() {
      return _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(0);
    }
  }, {
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.up) {
        prevState.labelAnimation.toValue({
          top: FormInputLabel.topUp,
          scale: 0.875
        });
      } else {
        prevState.labelAnimation.toValue({
          scale: 1,
          top: FormInputLabel.topDown + nextProps.topDownDelta
        });
      }
      return {
        focused: nextProps.focused
      };
    }
  }]);
  return FormInputLabel;
}(_react.PureComponent);