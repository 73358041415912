import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Form } from 'materialTheme/src/theme/components/forminput/Form';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { ChangeCompanyPayment, } from 'upmesh-support-core/src/commands/company/ChangeCompanyPayment';
import { CompanyPaymentEntity } from 'upmesh-support-core/src/entities/CompanyPaymentEntity';
import { ConnectionHandler } from '../../ConnectionHandler';
export class ChangeCompanyPaymentDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.init = async () => {
            const client = ConnectionHandler.getClient();
            const { data } = this.props;
            if (client != null && data != null && data.id != null) {
                let settings = new CompanyPaymentEntity({ id: data.id });
                try {
                    settings = await client.companyPayment.getById(data.id);
                }
                catch (e) {
                    console.debug('cant get Partner Settings', e);
                }
                this.setState({ settings });
            }
        };
        this.close = (_e) => {
            Dialog.instance?.close();
        };
        this.catchError = (e) => {
            Routing.instance.alert.post({
                text: `error: ${JSON.stringify(e)}`,
            });
            return true;
        };
        this.onSaved = () => {
            this.close();
        };
        this.state = {
            error: '',
        };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug('cant init Dialog', err));
    }
    render() {
        const { settings } = this.state;
        if (settings == null)
            return <Spinner />;
        const dataOptions = new Map();
        dataOptions.set('price', {
            formType: 'number',
            cols: 1,
            props: {
                labelText: 'Einkaufspreis Projektleiter',
            },
        });
        dataOptions.set('priceCollaborator', {
            formType: 'number',
            cols: 1,
            props: {
                labelText: 'Einkaufspreis Mitarbeiter',
            },
        });
        dataOptions.set('priceTimeTracking', {
            formType: 'number',
            cols: 1,
            props: {
                labelText: 'Einkaufspreis Zeiterfasser',
            },
        });
        return (<Form baseCols={1} catchError={this.catchError} dataOptions={dataOptions} formHeaderProps={{
                formTitle: 'Partner ändern',
            }} store={ConnectionHandler.getStore()} command={new ChangeCompanyPayment({ ...settings }, this.props.data.id, ConnectionHandler.serverConnection.token)} additionalButtons={[
                <ContainedButton key="abort" onPress={this.close} title="abbrechen" backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
            ]} onSaved={this.onSaved} saveButtonLabel="speichern" stickyHeader stickyActions resetButton serverConnection={ConnectionHandler.getConnection()}/>);
    }
}
ChangeCompanyPaymentDialog.openDialog = (partnerId) => (e) => {
    const openPosition = { x: e ? e.nativeEvent.pageX : 0, y: e ? e.nativeEvent.pageY : 0 };
    Dialog.instance?.open({
        openPosition,
        fullscreenResponsive: true,
        scrollable: false,
        contentPadding: false,
        content: <ChangeCompanyPaymentDialog data={{ id: partnerId }}/>,
    });
};
