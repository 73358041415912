var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ZoomTileHelper = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _crossFetch = _interopRequireDefault(require("cross-fetch"));
var _StdApplicationError = require("odatarepos/build/src/entities/StdApplicationError");
var _AuthClient = require("upmesh-auth-core/build/src/client/AuthClient");
var _a;
var ZoomTileHelper = exports.ZoomTileHelper = function () {
  function ZoomTileHelper() {
    (0, _classCallCheck2.default)(this, ZoomTileHelper);
  }
  (0, _createClass2.default)(ZoomTileHelper, null, [{
    key: "getTileInfo",
    value: function () {
      var _getTileInfo = (0, _asyncToGenerator2.default)(function* (tileUrl) {
        if (_a.tileInfosCache != null && _a.tileInfosCache.size > 0) {
          try {
            var info = _a.tileInfosCache.get(tileUrl);
            if (info != null) {
              return info;
            }
          } catch (err) {}
        }
        if (_AuthClient.AuthClient.instance.serverConnected()) {
          var data = yield (0, _crossFetch.default)(`${tileUrl}/info`);
          if (data != null && data.status === 200) {
            var json = yield data.json();
            if (json.final) yield _a.saveInfo(tileUrl, json);
            return json;
          }
        }
        throw _StdApplicationError.StdApplicationError.temporarilyUnavailable('TilesNotYetCreated', 'Die Tiles sind noch nicht erstellt');
      });
      function getTileInfo(_x) {
        return _getTileInfo.apply(this, arguments);
      }
      return getTileInfo;
    }()
  }, {
    key: "getCenterOfPoints",
    value: function getCenterOfPoints(points) {
      var num_coords = points.length;
      var X = 0.0;
      var Y = 0.0;
      var Z = 0.0;
      for (var i = 0; i < points.length; i += 1) {
        var lat = points[i][0] * Math.PI / 180;
        var lon = points[i][1] * Math.PI / 180;
        var a = Math.cos(lat) * Math.cos(lon);
        var b = Math.cos(lat) * Math.sin(lon);
        var c = Math.sin(lat);
        X += a;
        Y += b;
        Z += c;
      }
      X /= num_coords;
      Y /= num_coords;
      Z /= num_coords;
      var la = Math.atan2(Y, X);
      var hyp = Math.sqrt(X * X + Y * Y);
      var lo = Math.atan2(Z, hyp);
      var newX = la * 180 / Math.PI;
      var newY = lo * 180 / Math.PI;
      return [newX, newY];
    }
  }, {
    key: "pointToLatLng",
    value: function pointToLatLng(point, info) {
      if (info != null) {
        var biggerSide = Math.max(info.orgImageWidth, info.orgImageHeight) * 2;
        var scaleXOrg = info.width / info.orgImageWidth;
        var scaleYOrg = info.height / info.orgImageHeight;
        var scaleOrg = Math.min(scaleXOrg, scaleYOrg);
        var d = {
          x: info.dx / scaleOrg,
          y: info.dy / scaleOrg
        };
        var sqaurePosition = {
          x: point.x * 10 + d.x,
          y: point.y * 10 + d.y
        };
        var percentPosition = {
          x: Math.max(Math.min(sqaurePosition.x / biggerSide * 2 - 1, 1), -1),
          y: Math.max(Math.min(sqaurePosition.y / biggerSide * 2 - 1, 1), -1)
        };
        var worldPositon = {
          x: percentPosition.x * _a.R * Math.PI,
          y: percentPosition.y * _a.R * Math.PI
        };
        var de = 180 / Math.PI;
        return {
          lat: Math.max(Math.min((2 * Math.atan(Math.exp(worldPositon.y / _a.R)) - Math.PI / 2) * de, _a.MAX_LATITUDE), -_a.MAX_LATITUDE),
          lng: worldPositon.x * de / _a.R
        };
      }
      return {
        lat: 0,
        lng: 0
      };
    }
  }, {
    key: "latLngToPoint",
    value: function latLngToPoint(latlng, info) {
      var d = Math.PI / 180;
      var max = _a.MAX_LATITUDE;
      var lat = Math.max(Math.min(max, latlng.lat), -max);
      var sin = Math.sin(lat * d);
      var percentPosition = {
        x: Math.max(Math.min((_a.R * latlng.lng * d / _a.R / Math.PI + 1) / 2, 1), 0),
        y: Math.max(Math.min((_a.R * Math.log((1 + sin) / (1 - sin)) / 2 / _a.R / Math.PI + 1) / 2, 1), 0)
      };
      var scaleXOrg = info.width / info.orgImageWidth;
      var scaleYOrg = info.height / info.orgImageHeight;
      var scaleOrg = Math.min(scaleXOrg, scaleYOrg);
      var scaleD = {
        x: info.dx / scaleOrg,
        y: info.dy / scaleOrg
      };
      var biggerSide = Math.max(info.orgImageWidth, info.orgImageHeight) * 2;
      var sqaurePosition = {
        x: percentPosition.x * biggerSide,
        y: percentPosition.y * biggerSide
      };
      return {
        x: (sqaurePosition.x - scaleD.x) / 10,
        y: (sqaurePosition.y - scaleD.y) / 10
      };
    }
  }]);
  return ZoomTileHelper;
}();
_a = ZoomTileHelper;
ZoomTileHelper.tileInfosCache = new Map();
ZoomTileHelper.isSaving = false;
ZoomTileHelper.saveInfo = function () {
  var _ref = (0, _asyncToGenerator2.default)(function* (url, info) {
    if (!_a.isSaving) {
      _a.isSaving = true;
      try {
        _a.tileInfosCache.set(url, info);
      } catch (err) {}
      _a.isSaving = false;
    } else {
      while (_a.isSaving) {
        yield new Promise(function (resolve) {
          setTimeout(resolve, 200);
        });
      }
    }
  });
  return function (_x2, _x3) {
    return _ref.apply(this, arguments);
  };
}();
ZoomTileHelper.R = 6371000;
ZoomTileHelper.MAX_LATITUDE = 85.0511287798;