var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TabContent = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _ThemeManager = require("../../ThemeManager");
var _TabEventHandler = require("./TabEventHandler");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var TabContent = exports.TabContent = function (_PureComponent) {
  (0, _inherits2.default)(TabContent, _PureComponent);
  var _super = _createSuper(TabContent);
  function TabContent(props) {
    var _this;
    (0, _classCallCheck2.default)(this, TabContent);
    _this = _super.call(this, props);
    _this.startMove = 0;
    _this.firstTime = true;
    _this.onChangeTab = function (e) {
      if (_this.props.tabId === e.tabId) {
        if (e.tabIndex !== _this.state.selected) {
          _this.changeSelected(e.tabIndex);
        }
      }
    };
    _this.state = {
      selected: _this.props.startSelected != null ? _this.props.startSelected : 0,
      width: 200,
      height: 200,
      contentPosition: [],
      contentPositionAnimated: []
    };
    _this.createPanResponder();
    return _this;
  }
  (0, _createClass2.default)(TabContent, [{
    key: "changeSelected",
    value: function changeSelected(newSelected, callBack) {
      var _this2 = this;
      var duration = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 375;
      var _this$props = this.props,
        contents = _this$props.contents,
        tabId = _this$props.tabId;
      var width = this.state.width;
      this.setState({
        selected: newSelected
      }, function () {
        if (_this2.props.changeSelection != null) {
          _this2.props.changeSelection(newSelected);
        }
        var newPositions = TabContent.createContentPosition(newSelected, contents, width);
        var animations = [];
        if (_this2.firstTime) {
          var newPos = [];
          for (var i = 0; i < newPositions.length; i += 1) {
            newPos.push(new _reactNativeWeb.Animated.Value(newPositions[i]));
          }
          _this2.firstTime = false;
          _this2.setState({
            contentPositionAnimated: newPos
          });
        } else {
          for (var _i = 0; _i < newPositions.length; _i += 1) {
            animations.push(_reactNativeWeb.Animated.timing(_this2.state.contentPositionAnimated[_i], {
              duration: duration,
              toValue: newPositions[_i],
              useNativeDriver: _reactNativeWeb.Platform.OS !== 'web'
            }));
          }
          _reactNativeWeb.Animated.parallel(animations, {
            stopTogether: true
          }).start(function () {
            if (callBack != null) {
              callBack();
            }
            if (_this2.props.changedSelection != null) {
              _this2.props.changedSelection(newSelected);
            }
          });
        }
        if (tabId != null) {
          _TabEventHandler.TabEventHandler.changeTab.post({
            tabId: tabId,
            tabIndex: newSelected
          });
        }
      });
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      if (this.props.tabId != null) {
        _TabEventHandler.TabEventHandler.changeTab.attach(this.onChangeTab);
      }
      if (this.props.startSelected != null) {
        this.changeSelected(this.props.startSelected);
      } else {
        this.firstTime = false;
      }
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(_prevProps, _prevState, _snapshot) {
      if (this.props.selected != null && _prevProps.selected !== this.props.selected) {
        this.changeSelected(this.props.selected);
      }
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (this.props.tabId != null) {
        _TabEventHandler.TabEventHandler.changeTab.detach(this.onChangeTab);
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _this$props2 = this.props,
        width = _this$props2.width,
        height = _this$props2.height,
        disableSwipe = _this$props2.disableSwipe;
      var viewProps = disableSwipe !== true ? this._panResponder.panHandlers : undefined;
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          width: width,
          height: height,
          position: 'relative',
          overflow: 'hidden',
          backgroundColor: 'transparent'
        },
        children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, Object.assign({}, viewProps, {
          pointerEvents: "auto",
          style: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            flexDirection: 'row',
            zIndex: 500
          },
          children: this.renderContents()
        }))
      });
    }
  }, {
    key: "renderContents",
    value: function renderContents() {
      var contents = this.props.contents;
      var _this$state = this.state,
        height = _this$state.height,
        width = _this$state.width;
      var renderedContents = [];
      for (var i = 0; i < contents.length; i += 1) {
        renderedContents.push((0, _jsxRuntime.jsx)(_reactNativeWeb.Animated.View, {
          style: {
            width: width,
            height: height,
            overflow: 'hidden',
            position: 'absolute',
            top: 0,
            left: 0,
            transform: [{
              translateX: this.state.contentPositionAnimated[i]
            }]
          },
          children: contents[i]
        }, `contentElement${i}`));
      }
      return renderedContents;
    }
  }, {
    key: "createPanResponder",
    value: function createPanResponder() {
      var _this3 = this;
      this._panResponder = _reactNativeWeb.PanResponder.create({
        onStartShouldSetPanResponder: function onStartShouldSetPanResponder(_evt, _gestureState) {
          return false;
        },
        onStartShouldSetPanResponderCapture: function onStartShouldSetPanResponderCapture(_evt, _gestureState) {
          _this3.startMoveX = _gestureState.dx;
          return false;
        },
        onMoveShouldSetPanResponder: function onMoveShouldSetPanResponder(_evt, _gestureState) {
          var diff = Math.abs(_gestureState.dx - _this3.startMoveX);
          if (diff > _ThemeManager.ThemeManager.style.minSwipeValue) {
            return true;
          }
          return false;
        },
        onMoveShouldSetPanResponderCapture: function onMoveShouldSetPanResponderCapture(_evt, _gestureState) {
          return false;
        },
        onPanResponderGrant: function onPanResponderGrant(_evt, _gestureState) {
          _this3.startMove = Date.now();
          return false;
        },
        onPanResponderMove: function onPanResponderMove(_evt, _gestureState) {
          var _this3$props = _this3.props,
            width = _this3$props.width,
            contents = _this3$props.contents;
          var _this3$state = _this3.state,
            selected = _this3$state.selected,
            contentPositionAnimated = _this3$state.contentPositionAnimated;
          if (_this3.startMove != null) {
            var direction = _gestureState.dx < 0 ? 1 : -1;
            var newSelected = selected + direction;
            if (newSelected < contents.length && newSelected >= 0) {
              contentPositionAnimated[newSelected].setValue(width * direction + _gestureState.dx);
              contentPositionAnimated[selected].setValue(_gestureState.dx);
            }
          }
          return false;
        },
        onPanResponderTerminationRequest: function onPanResponderTerminationRequest(_evt, _gestureState) {
          return true;
        },
        onPanResponderRelease: function onPanResponderRelease(_evt, _gestureState) {
          var _this3$props2 = _this3.props,
            contents = _this3$props2.contents,
            width = _this3$props2.width;
          if (_this3.startMove != null) {
            var selected = _this3.state.selected;
            var direction = _gestureState.dx < 0 ? 1 : -1;
            var swipeDuration = Date.now() - _this3.startMove;
            var shortSwipe = swipeDuration < 100 && Math.abs(_gestureState.dx) > 20;
            var newSelected = shortSwipe || Math.abs(_gestureState.dx) > Math.min(150, width / 5) ? selected + direction : selected;
            if (contents.length > newSelected && newSelected >= 0) {
              _this3.changeSelected(newSelected);
            }
            _this3.startMove = 0;
            return false;
          }
          return true;
        },
        onPanResponderTerminate: function onPanResponderTerminate(_evt, _gestureState) {},
        onShouldBlockNativeResponder: function onShouldBlockNativeResponder(_evt, _gestureState) {
          return true;
        }
      });
    }
  }], [{
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, prevState) {
      var newState = Object.assign({}, prevState);
      var positionUpdate = false;
      var updateNeeded = false;
      if (nextProps.selected != null && nextProps.selected !== newState.selected) {
        newState.selected = nextProps.selected;
        updateNeeded = true;
      }
      if (nextProps.width != null && nextProps.width !== newState.width) {
        newState.width = nextProps.width;
        updateNeeded = true;
        positionUpdate = true;
      }
      if (nextProps.height != null && nextProps.height !== newState.height) {
        newState.height = nextProps.height;
        updateNeeded = true;
      }
      if (newState.contentPosition.length === 0 || positionUpdate) {
        newState.contentPosition = TabContent.createContentPosition(newState.selected, nextProps.contents, newState.width);
        for (var i = 0; i < newState.contentPosition.length; i += 1) {
          if (newState.contentPositionAnimated[i] == null) {
            newState.contentPositionAnimated[i] = new _reactNativeWeb.Animated.Value(newState.contentPosition[i]);
          } else {
            newState.contentPositionAnimated[i].setValue(newState.contentPosition[i]);
          }
        }
      }
      if (updateNeeded) {
        return newState;
      }
      return null;
    }
  }, {
    key: "createContentPosition",
    value: function createContentPosition(selected, contents, width) {
      var contentPosition = [];
      for (var i = 0; i < contents.length; i += 1) {
        if (selected === i) {
          contentPosition[i] = 0;
        } else if (selected > i) {
          contentPosition[i] = -width;
        } else {
          contentPosition[i] = width;
        }
      }
      return contentPosition;
    }
  }]);
  return TabContent;
}(_react.PureComponent);