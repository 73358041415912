"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FolderEntity = void 0;
var ReadModelEntity_1 = require("cqrs-core/build/src/core/query/ReadModelEntity");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var StdApplicationError_1 = require("odatarepos/build/src/entities/StdApplicationError");
var RightsManager_1 = require("../../../access/rights/RightsManager");
var FolderEntity = function (_ReadModelEntity_1$Re) {
  (0, _inherits2.default)(FolderEntity, _ReadModelEntity_1$Re);
  var _super = _createSuper(FolderEntity);
  function FolderEntity(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, FolderEntity);
    _this = _super.call(this, obj);
    _this.entityName = 'Folder';
    _this.fill(obj);
    return _this;
  }
  (0, _createClass2.default)(FolderEntity, [{
    key: "createOne",
    value: function createOne(obj) {
      return new FolderEntity(obj);
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('deleted', new OdataEntity_1.OdataIndex(true));
      baseMap.set('projectId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('subFolderFromId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('title', new OdataEntity_1.OdataIndex());
      baseMap.set('folderSizeInBytes', new OdataEntity_1.OdataIndex());
      baseMap.set('accessAll', new OdataEntity_1.OdataIndex());
      baseMap.set('access', new OdataEntity_1.OdataIndex(true));
      baseMap.set('_access2', new OdataEntity_1.OdataIndex(true));
      baseMap.set('description', new OdataEntity_1.OdataIndex());
      baseMap.set('tags', new OdataEntity_1.OdataIndex(true));
      return baseMap;
    }
  }], [{
    key: "hasFolderEditRights",
    value: function () {
      var _hasFolderEditRights = (0, _asyncToGenerator2.default)(function* (folderId, userId) {
        return FolderEntity.hasFolderRights(folderId, userId, 'edit');
      });
      function hasFolderEditRights(_x, _x2) {
        return _hasFolderEditRights.apply(this, arguments);
      }
      return hasFolderEditRights;
    }()
  }, {
    key: "hasFolderWriteRights",
    value: function () {
      var _hasFolderWriteRights = (0, _asyncToGenerator2.default)(function* (folderId, userId) {
        return FolderEntity.hasFolderRights(folderId, userId, 'write');
      });
      function hasFolderWriteRights(_x3, _x4) {
        return _hasFolderWriteRights.apply(this, arguments);
      }
      return hasFolderWriteRights;
    }()
  }, {
    key: "hasFolderDeleteRights",
    value: function () {
      var _hasFolderDeleteRights = (0, _asyncToGenerator2.default)(function* (folderId, userId) {
        return FolderEntity.hasFolderRights(folderId, userId, 'delete');
      });
      function hasFolderDeleteRights(_x5, _x6) {
        return _hasFolderDeleteRights.apply(this, arguments);
      }
      return hasFolderDeleteRights;
    }()
  }, {
    key: "getPathLength",
    value: function () {
      var _getPathLength = (0, _asyncToGenerator2.default)(function* (folderId) {
        var folder = yield ReadModels_1.ReadModels.get('Folder').getById(folderId);
        var parents = yield FolderEntity.getMainFolders(folder);
        var pathLength = folder.title.length;
        parents.forEach(function (p) {
          pathLength += p.title.length + 1;
        });
        return {
          pathLength: pathLength,
          deep: parents.length
        };
      });
      function getPathLength(_x7) {
        return _getPathLength.apply(this, arguments);
      }
      return getPathLength;
    }()
  }, {
    key: "getMainFolders",
    value: function () {
      var _getMainFolders = (0, _asyncToGenerator2.default)(function* (folder) {
        var folders = [];
        var f = yield FolderEntity.getMainFolder(folder);
        if (f != null) {
          folders.push(f);
          if (f.subFolderFromId != null && f.subFolderFromId.length > 0) {
            var fo = yield FolderEntity.getMainFolders(f);
            folders.push.apply(folders, (0, _toConsumableArray2.default)(fo));
          }
        }
        return folders;
      });
      function getMainFolders(_x8) {
        return _getMainFolders.apply(this, arguments);
      }
      return getMainFolders;
    }()
  }, {
    key: "getMainFolder",
    value: function () {
      var _getMainFolder = (0, _asyncToGenerator2.default)(function* (folder) {
        if (folder.subFolderFromId != null && folder.subFolderFromId.length > 0) {
          var mainFolder = yield ReadModels_1.ReadModels.get('Folder').getById(folder.subFolderFromId);
          return mainFolder;
        }
        return null;
      });
      function getMainFolder(_x9) {
        return _getMainFolder.apply(this, arguments);
      }
      return getMainFolder;
    }()
  }, {
    key: "getSubFolders",
    value: function () {
      var _getSubFolders = (0, _asyncToGenerator2.default)(function* (folder) {
        var folders = yield ReadModels_1.ReadModels.get('Folder');
        var subFolders = [];
        var subs = yield folders.get({
          filter: `subFolderFromId eq '${folder.id}'`
        });
        for (var s of subs) {
          var subs2 = yield FolderEntity.getSubFolders(s);
          if (subs2.length > 0) subFolders.push.apply(subFolders, (0, _toConsumableArray2.default)(subs2));
          subFolders.push(s);
        }
        return subFolders;
      });
      function getSubFolders(_x10) {
        return _getSubFolders.apply(this, arguments);
      }
      return getSubFolders;
    }()
  }, {
    key: "hasFolderRights",
    value: function () {
      var _hasFolderRights = (0, _asyncToGenerator2.default)(function* (folderId, userId, right) {
        if (folderId === 'private' || folderId === 'public' || folderId === '' || folderId.length === 0) {
          return true;
        }
        var getFolder = yield ReadModels_1.ReadModels.get('Folder').getById(folderId);
        var rights = getFolder.access.find(function (e) {
          return e.type === 'user' && e.userOrRoleId === userId;
        });
        if (rights != null && rights[right]) return true;
        var project = yield ReadModels_1.ReadModels.get('project').getById(getFolder.projectId);
        if (project.creator === userId) return true;
        var pu = yield ReadModels_1.ReadModels.get('projectUser').get({
          filter: `projectId eq '${project.id}' and userId eq '${userId}' and status eq 'accepted'`
        });
        if (pu.length > 0) {
          if (pu[0].roleId === '1') return true;
          var roleRight = getFolder.access.find(function (e) {
            return e.type === 'role' && e.userOrRoleId === pu[0].roleId;
          });
          if (roleRight != null && roleRight[right]) return true;
          var rr = yield RightsManager_1.RightsManager.hasWriteRight(project.id, userId, 'commandEditFolders');
          if (rr) return true;
        }
        if (right === 'write') throw StdApplicationError_1.StdApplicationError.badRequest('noRightToAddFile', `No write right for folder`);else if (right === 'edit') throw StdApplicationError_1.StdApplicationError.badRequest('noRightToEditFile', `No write right for folder`);else throw StdApplicationError_1.StdApplicationError.badRequest('noRightToRemoveFile', `No write right for folder`);
      });
      function hasFolderRights(_x11, _x12, _x13) {
        return _hasFolderRights.apply(this, arguments);
      }
      return hasFolderRights;
    }()
  }]);
  return FolderEntity;
}(ReadModelEntity_1.ReadModelEntity);
exports.FolderEntity = FolderEntity;
FolderEntity.maxPathLength = 200;