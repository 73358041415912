export class ConnectionHandler {
    static getConnection() {
        return ConnectionHandler.serverConnection;
    }
    static getStore() {
        return ConnectionHandler.store;
    }
    static getClient() {
        return ConnectionHandler.supportClient;
    }
}
ConnectionHandler.apiUrl = '';
