var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SlideAnimation = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _ResizeEvent = require("../../ResizeEvent");
var _Animation2 = _interopRequireDefault(require("./Animation"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var SlideAnimation = exports.SlideAnimation = function (_Animation) {
  (0, _inherits2.default)(SlideAnimation, _Animation);
  var _super = _createSuper(SlideAnimation);
  function SlideAnimation() {
    var _this;
    var toValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var slideFrom = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'bottom';
    var spring = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    var duration = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 195;
    (0, _classCallCheck2.default)(this, SlideAnimation);
    _this = _super.call(this, toValue);
    _this.duration = 195;
    _this.animations = _this.createAnimations(slideFrom);
    _this.spring = spring;
    _this.duration = duration;
    return _this;
  }
  (0, _createClass2.default)(SlideAnimation, [{
    key: "createAnimations",
    value: function createAnimations(slideFrom) {
      var transform = [];
      if (['top', 'bottom'].includes(slideFrom)) {
        if (slideFrom === 'bottom') {
          transform.push({
            translateY: this.animate.interpolate({
              inputRange: [0, 1],
              outputRange: [_ResizeEvent.ResizeEvent.current.windowHeight, 1]
            })
          });
        } else {
          transform.push({
            translateY: this.animate.interpolate({
              inputRange: [0, 1],
              outputRange: [-_ResizeEvent.ResizeEvent.current.windowHeight, 1]
            })
          });
        }
      } else if (['left', 'right'].includes(slideFrom)) {
        if (slideFrom === 'right') {
          transform.push({
            translateX: this.animate.interpolate({
              inputRange: [0, 1],
              outputRange: [_ResizeEvent.ResizeEvent.current.windowWidth, 1]
            })
          });
        } else {
          transform.push({
            translateX: this.animate.interpolate({
              inputRange: [0, 1],
              outputRange: [-_ResizeEvent.ResizeEvent.current.windowWidth, 1]
            })
          });
        }
      }
      return {
        transform: transform,
        opacity: 1
      };
    }
  }, {
    key: "toValue",
    value: function toValue(_toValue, onFinished) {
      if (this.spring) {
        _reactNativeWeb.Animated.timing(this.animate, {
          toValue: _toValue,
          useNativeDriver: false
        }).start(function () {
          if (onFinished != null) {
            onFinished();
          }
        });
      } else {
        _reactNativeWeb.Animated.timing(this.animate, {
          toValue: _toValue,
          duration: this.duration,
          useNativeDriver: _reactNativeWeb.Platform.OS !== 'web'
        }).start(function () {
          if (onFinished != null) {
            onFinished();
          }
        });
      }
    }
  }]);
  return SlideAnimation;
}(_Animation2.default);