"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UpmeshPartnerSettingsEntity = void 0;
var ReadModelEntity_1 = require("cqrs-core/build/src/core/query/ReadModelEntity");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var UpmeshPartnerSettingsEntity = function (_ReadModelEntity_1$Re) {
  (0, _inherits2.default)(UpmeshPartnerSettingsEntity, _ReadModelEntity_1$Re);
  var _super = _createSuper(UpmeshPartnerSettingsEntity);
  function UpmeshPartnerSettingsEntity(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, UpmeshPartnerSettingsEntity);
    _this = _super.call(this, obj);
    _this.entityName = 'UpmeshPartnerSettings';
    _this.sellingPrice = 89;
    _this.price = 29;
    _this.pricePercent = 50;
    _this.sellingPriceCollaborator = 19.9;
    _this.priceCollaborator = 10;
    _this.priceCollaboratorPercent = 50;
    _this.sellingPriceTimeTracking = 4.5;
    _this.priceTimeTracking = 3;
    _this.priceTimeTrackingPercent = 50;
    _this.fill(obj);
    return _this;
  }
  (0, _createClass2.default)(UpmeshPartnerSettingsEntity, [{
    key: "createOne",
    value: function createOne(obj) {
      return new UpmeshPartnerSettingsEntity(obj);
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('sellingPrice', new OdataEntity_1.OdataIndex());
      baseMap.set('price', new OdataEntity_1.OdataIndex());
      baseMap.set('pricePercent', new OdataEntity_1.OdataIndex());
      baseMap.set('sellingPriceCollaborator', new OdataEntity_1.OdataIndex());
      baseMap.set('priceCollaborator', new OdataEntity_1.OdataIndex());
      baseMap.set('priceCollaboratorPercent', new OdataEntity_1.OdataIndex());
      baseMap.set('sellingPriceTimeTracking', new OdataEntity_1.OdataIndex());
      baseMap.set('priceTimeTracking', new OdataEntity_1.OdataIndex());
      baseMap.set('itemNumber', new OdataEntity_1.OdataIndex());
      baseMap.set('itemNumberCollaborator', new OdataEntity_1.OdataIndex());
      baseMap.set('itemNumberTimeTracking', new OdataEntity_1.OdataIndex());
      baseMap.set('sellingItemNumber', new OdataEntity_1.OdataIndex());
      baseMap.set('sellingItemNumberCollaborator', new OdataEntity_1.OdataIndex());
      baseMap.set('sellingItemNumberTimeTracking', new OdataEntity_1.OdataIndex());
      baseMap.set('customerNumber', new OdataEntity_1.OdataIndex(true));
      baseMap.set('partnerCostumerNumberForUpmesh', new OdataEntity_1.OdataIndex());
      baseMap.set('billingText', new OdataEntity_1.OdataIndex());
      baseMap.set('billingTextCollaborator', new OdataEntity_1.OdataIndex());
      baseMap.set('billingTextTimeTracking', new OdataEntity_1.OdataIndex());
      return baseMap;
    }
  }]);
  return UpmeshPartnerSettingsEntity;
}(ReadModelEntity_1.ReadModelEntity);
exports.UpmeshPartnerSettingsEntity = UpmeshPartnerSettingsEntity;