"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddUserToUpmeshPartner = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var UpmeshPartnerUserAdded_1 = require("upmesh-core/build/src/server/events/partner/UpmeshPartnerUserAdded");
var AddUserToUpmeshPartner = function (_CommandRoot_1$Comman) {
  (0, _inherits2.default)(AddUserToUpmeshPartner, _CommandRoot_1$Comman);
  var _super = _createSuper(AddUserToUpmeshPartner);
  function AddUserToUpmeshPartner(privatedata, entityId, token) {
    var _this;
    (0, _classCallCheck2.default)(this, AddUserToUpmeshPartner);
    _this = _super.call(this, privatedata, entityId, token);
    _this.aggregate = 'partner';
    _this.commandName = 'AddUserToUpmeshPartner';
    return _this;
  }
  (0, _createClass2.default)(AddUserToUpmeshPartner, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') {
          return true;
        }
        var admin = ReadModels_1.ReadModels.get('SupportRoles');
        var admins = yield admin.get({
          filter: `userId eq '${userId}' and deleted ne true`
        });
        if (admins.length > 0) {
          for (var adm of admins) {
            if (adm.role === 'Admin') {
              return true;
            }
          }
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "clone",
    value: function clone(newData, entityId, token) {
      return new AddUserToUpmeshPartner(newData, entityId, token);
    }
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return new UpmeshPartnerUserAdded_1.UpmeshPartnerUserAdded(this.entityId, Object.assign({}, this.data));
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        if (key == null || key === 'userId') {
          if (this.data.userId.length < 0) {
            throw new Error('required');
          }
        }
        if (key == null || key === 'role') {
          if (this.data.role.length < 0) {
            throw new Error('required');
          }
        }
        var partner = ReadModels_1.ReadModels.get('upmeshpartner');
        var exists = yield partner.count(`users/userId eq '${this.data.userId}'`);
        if (exists > 0) {
          throw new Error('user already assigned to one partner');
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
  return AddUserToUpmeshPartner;
}(CommandRoot_1.CommandRoot);
exports.AddUserToUpmeshPartner = AddUserToUpmeshPartner;