var _a;
import { CommandRemoteStore, CommandRemoteStoreConfig } from 'cqrs-core/src/core/commands/CommandRemoteStore';
import { ServerConnection } from 'cqrs-core/src/core/ServerConnection';
import { Url } from 'cqrs-shared/src/uri/Url';
import Cookies from 'js-cookie';
import { SimpleReactNativeStorage } from 'materialTheme/src/odataDB/SimpleReactNativeStorage';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { Wrapper } from 'materialTheme/src/theme/components/wrappper/Wrapper';
import { LanguageAsyncCache } from 'materialTheme/src/theme/components/wrappper/WrapperUserContext';
import { I18n as ThemeI18n } from 'materialTheme/src/theme/i18n/I18n';
import { LocaleDetector } from 'materialTheme/src/theme/i18n/LocaleDetector';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Redirect } from 'materialTheme/src/theme/routing/Redirect';
import { RouterControl } from 'materialTheme/src/theme/routing/RouterControl';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import React, { PureComponent } from 'react';
import { Image, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { LoginWithToken } from 'upmesh-auth-core/src/client/commands/guest/LoginWithToken';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { UserSettingsEntity } from 'upmesh-auth-core/src/client/query/entities/UserSettingsEntity';
import { DefaultErrorHandler } from 'upmesh-client/src/components/DefaultErrorHandler';
import { NotFoundView } from 'upmesh-client/src/components/root/NotFoundView';
import { ConfigAll } from 'upmesh-client/src/config/ConfigAll';
import { I18n as ClientI18n } from 'upmesh-client/src/i18n/I18n';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { RightsManagerClient } from 'upmesh-core/src/access/rights/RightsManagerClient';
import { CommandReadModels } from 'upmesh-core/src/server/webserver/commands/CommandReadModels';
import { I18nClient, I18nOptions } from 'upmesh-i18n/src/I18nClient';
import { SupportClient } from 'upmesh-support-core/src/SupportClient';
import * as uuid from 'uuid';
import { ChangeCompanyDialog } from './components/company/ChangeCompanyDialog';
import { CompanyDialog } from './components/company/CompanyDialog';
import { CompanyView } from './components/CompanyView';
import { DashboardView } from './components/DashboardView';
import { LoginView } from './components/LoginView';
import { LogsView } from './components/LogsView';
import { OnlineView } from './components/OnlineView';
import { ChangePartnerDialog } from './components/partner/ChangePartnerDialog';
import { PartnerDialog } from './components/partner/PartnerDialog';
import { PartnerView } from './components/PartnerView';
import { PaymentView } from './components/PaymentView';
import { ProjectDialog } from './components/projects/ProjectDialog';
import { ProjectsView } from './components/ProjectsView';
import { SubscriptionView } from './components/SubscriptionView';
import { UserDialog } from './components/users/UserDialog';
import { UsersView } from './components/UsersView';
import { ConnectionHandler } from './ConnectionHandler';
import { I18n } from './i18n/I18n';
import { MessagesDe } from './i18n/MessagesDe';
import { SupportStyle } from './theme/SupportStyle';
const LOGO = require('./assets/upmesh_logo.png');
export class SupportApp extends PureComponent {
    constructor(props) {
        super(props);
        this.onConnectionChange = (e) => {
            this.setState({ connected: e });
        };
        this.onConnection = (_e) => {
            const token = Cookies.get('token');
            if (token != null && token.length > 0) {
                const l = new LoginWithToken({ token });
                try {
                    l.execute(ConnectionHandler.store)
                        .then(() => {
                        _a.onLoggedIn(l.result);
                    })
                        .catch((err) => console.debug(err));
                }
                catch (e) {
                    console.debug('cant login with token', e);
                }
            }
            LoadingEvents.instance.stopLoading();
            Routing.instance.goTo('/login');
        };
        this.onConnectionError = (_e) => {
            _a.removeClient().catch((err) => console.debug(err));
        };
        this.selectServer = (server) => {
            LoadingEvents.instance.startLoading('Verbinde mit Server...');
            ConnectionHandler.apiUrl = 'http://127.0.0.1:30077/support/api';
            if (server === 'dev') {
                ConnectionHandler.apiUrl = 'https://devsupportapi.upmesh.de';
            }
            else if (server === 'production') {
                ConnectionHandler.apiUrl = 'https://supportapi.upmesh.de';
            }
            if (ConnectionHandler.apiUrl.length === 0) {
                Routing.instance.alert.post({
                    text: 'Server ist noch nicht online!',
                });
                LoadingEvents.instance.stopLoading();
            }
            this.setState({ started: true }, () => {
                this.connectToServer()
                    .then(() => {
                    this.setState({ connected: ConnectionHandler.getConnection()?.isConnected });
                })
                    .catch((e) => {
                    console.debug('cant connect to server', e);
                });
            });
        };
        this.notFoundView = (props) => <NotFoundView {...props}/>;
        this.state = { started: false, connected: false };
        SimpleStorage.init(new SimpleReactNativeStorage());
        try {
            ThemeManager.style = new SupportStyle();
            const uri = Url.getURLfromString(window.location.href);
            ThemeManager.style.basename = uri.hostname === 'dev.upmesh.de' ? '/control/' : '/';
        }
        catch (e) {
            ErrorReporter.sendReport({ subject: 'Support App constructor style issue', data: e, type: 'warn' });
        }
        Routing.routes = _a.routes;
        RouterControl.notFoundRoute = this.notFoundView;
    }
    static get routes() {
        return [
            { path: '/', component: (props) => <Redirect {...props} path="/login" key={uuid.v1()}/> },
            { path: '/home', component: (props) => <Redirect {...props} path="/login"/> },
            {
                path: '/login',
                beforeRoute: this.redirectIfLoggedIn,
                component: (props) => <LoginView {...props} onLoggedIn={this.onLoggedIn}/>,
            },
            {
                path: '/dashboard',
                component: (props) => <DashboardView {...props}/>,
                beforeRoute: this.redirectIfNotLoggedIn,
            },
            { path: '/users', component: (props) => <UsersView {...props}/>, beforeRoute: this.redirectIfNotLoggedIn },
            {
                path: 'user',
                asDialog: 'fullscreen',
                component: (props) => <UserDialog {...props}/>,
                beforeRoute: this.redirectIfNotLoggedIn,
            },
            { path: '/projects', component: (props) => <ProjectsView {...props}/>, beforeRoute: this.redirectIfNotLoggedIn },
            {
                path: 'project',
                asDialog: 'fullscreen',
                component: (props) => <ProjectDialog {...props}/>,
                beforeRoute: this.redirectIfNotLoggedIn,
            },
            {
                path: '/partner',
                component: (props) => <PartnerView {...props}/>,
                beforeRoute: this.redirectIfNotLoggedIn,
            },
            {
                path: 'partner',
                asDialog: 'fullscreen',
                component: (props) => <PartnerDialog {...props}/>,
                beforeRoute: this.redirectIfNotLoggedIn,
            },
            {
                path: 'partnerEdit',
                asDialog: 'form',
                component: (props) => <ChangePartnerDialog {...props}/>,
                beforeRoute: this.redirectIfNotLoggedIn,
            },
            {
                path: '/companies',
                component: (props) => <CompanyView {...props}/>,
                beforeRoute: this.redirectIfNotLoggedIn,
            },
            {
                path: 'company',
                asDialog: 'fullscreen',
                component: (props) => <CompanyDialog {...props}/>,
                beforeRoute: this.redirectIfNotLoggedIn,
            },
            {
                path: 'companyEdit',
                asDialog: 'form',
                component: (props) => <ChangeCompanyDialog {...props}/>,
                beforeRoute: this.redirectIfNotLoggedIn,
            },
            {
                path: '/subscription',
                component: (props) => <SubscriptionView {...props}/>,
                beforeRoute: this.redirectIfNotLoggedIn,
            },
            {
                path: '/payments',
                component: (props) => <PaymentView {...props}/>,
                beforeRoute: this.redirectIfNotLoggedIn,
            },
            {
                path: '/online',
                component: (props) => <OnlineView {...props}/>,
                beforeRoute: this.redirectIfNotLoggedIn,
            },
            {
                path: '/logs',
                component: (props) => <LogsView {...props}/>,
                beforeRoute: this.redirectIfNotLoggedIn,
            },
            {
                path: '/logs/:file',
                component: (props) => <LogsView {...props}/>,
                beforeRoute: this.redirectIfNotLoggedIn,
            },
        ];
    }
    async connectToServer() {
        if (ConnectionHandler.serverConnection == null) {
            ConnectionHandler.serverConnection = new ServerConnection({
                remoteOptions: { apiUrl: ConnectionHandler.apiUrl },
                autoConnect: false,
                socketOptions: {
                    reconnection: false,
                    autoConnect: false,
                },
                query: { clientversion: '9999999999' },
            });
            ConnectionHandler.store = new CommandRemoteStore(new CommandRemoteStoreConfig(() => ConnectionHandler.serverConnection));
            ConnectionHandler.serverConnection.onConnectionChanged.attach(this.onConnectionChange);
            ConnectionHandler.serverConnection.onConnect.attach(this.onConnection);
            ConnectionHandler.serverConnection.onConnectionError.attach(this.onConnectionError);
        }
        await ConnectionHandler.serverConnection.connect();
        const token = Cookies.get('token');
        if (token != null && token.length > 0) {
            const l = new LoginWithToken({ token });
            try {
                await l.execute(ConnectionHandler.store);
                _a.onLoggedIn(l.result);
            }
            catch (e) {
                console.debug('cant login with token', e);
            }
        }
    }
    static async initClient() {
        if (ConnectionHandler.serverConnection != null && ConnectionHandler.supportClient == null) {
            ConnectionHandler.supportClient = new SupportClient();
            await ConnectionHandler.supportClient.init(ConnectionHandler.apiUrl, () => ConnectionHandler.serverConnection, ConnectionHandler.store);
            CommandReadModels.instance = SupportClient.instance;
            RightsManager.instance = new RightsManagerClient();
        }
    }
    static async setPartnerId() {
        try {
            if (ConnectionHandler.isPartner) {
                const p = await ConnectionHandler.getClient()?.upmeshPartner.get();
                if (p && p.length === 1) {
                    ConnectionHandler.partnerId = p[0].id;
                }
            }
        }
        catch (err) {
            console.error(err);
        }
    }
    static async removeClient() {
        if (ConnectionHandler.serverConnection != null) {
        }
        LoadingEvents.instance.stopLoading();
        Routing.instance.alert.post({
            text: 'Server offline...',
        });
        Routing.instance.goTo('/login');
    }
    componentDidMount() {
        if (window.location.pathname.length > 1 &&
            window.location.pathname.indexOf('/home') === -1 &&
            window.location.pathname.indexOf('/login') === -1) {
            _a.initLink = `${window.location.pathname}${window.location.search}${window.location.hash}`;
        }
        this.init().catch((err) => console.debug(err));
    }
    render() {
        const { started, connected } = this.state;
        if (!started) {
            return (<View style={{ ...ThemeManager.style.absoluteStyle, justifyContent: 'center' }}>
          <Image source={LOGO} resizeMode="contain" style={{
                    width: 256,
                    height: 256,
                    maxWidth: '100%',
                    maxHeight: '100%',
                    alignSelf: 'center',
                }}/>
        </View>);
        }
        if (!connected) {
            return (<View style={{ ...ThemeManager.style.absoluteStyle, justifyContent: 'center' }}>
          <MaterialText centeredBox centeredText>
            Verbinde mit Server...
          </MaterialText>
        </View>);
        }
        return <Wrapper key="wrapper" style={ThemeManager.style.absoluteStyle}/>;
    }
    async init() {
        await ConfigAll.init();
        I18n.m = new I18nClient(new MessagesDe(), new I18nOptions({
            cache: new LanguageAsyncCache('trans'),
            backendUrl: `${ConfigAll.b2cURL}/backend/translations`,
            component: 'client',
        }));
        const lastLocale = SimpleStorage.get('locale');
        if (lastLocale == null) {
            await I18n.m.init(LocaleDetector.detect());
        }
        else {
            await I18n.m.init(lastLocale);
        }
        ThemeI18n.m = I18n.m;
        ClientI18n.m = I18n.m;
        const url = Url.getURLfromString(window.location.href);
        if (url.hostname === '127.0.0.1')
            this.selectServer('127.0.0.1');
        else if (url.hostname === 'devcontrol.upmesh.de')
            this.selectServer('dev');
        else
            this.selectServer('production');
    }
}
_a = SupportApp;
SupportApp.initLink = '/dashboard';
SupportApp.getUserSettings = async (userId) => {
    try {
        return await AuthClient.instance.modals.userSettings.getById(userId);
    }
    catch (e) {
        return new UserSettingsEntity({ id: userId, userId });
    }
};
SupportApp.onLoggedIn = (loginData) => {
    LoadingEvents.instance.stopLoading();
    const asyncNow = async () => {
        if (ConnectionHandler.serverConnection != null) {
            await ConnectionHandler.serverConnection.changeAuthToken(loginData.token);
            await _a.initClient();
            const userId = CurrentUser.getUnVerifiedDataFromToken(loginData.token)._id;
            const user = await ConnectionHandler.getClient().user.getById(userId);
            const settings = await _a.getUserSettings(userId);
            CurrentUser.setUser(user, loginData.token, settings);
            try {
                const roles = await ConnectionHandler.getClient()?.roles.get();
                ConnectionHandler.isPartner = roles == null || roles.length === 0;
                await _a.setPartnerId();
                if (roles != null && roles.length > 0)
                    ConnectionHandler.myRole = roles[0];
            }
            catch (err) {
                ConnectionHandler.isPartner = true;
                await _a.setPartnerId();
            }
            Cookies.set('token', loginData.token);
            requestAnimationFrame(() => {
                Routing.instance.goTo(_a.initLink);
            });
        }
    };
    asyncNow().catch(DefaultErrorHandler.showDefaultErrorAlert);
};
SupportApp.redirectIfNotLoggedIn = () => {
    if (ConnectionHandler.serverConnection == null || !ConnectionHandler.serverConnection.isConnected)
        return undefined;
    if (CurrentUser.userId != null && CurrentUser.userId.length > 0) {
        return undefined;
    }
    return (_props) => (<Redirect path="/login" replace key={uuid.v1()}>
        <View />
      </Redirect>);
};
SupportApp.redirectIfLoggedIn = () => {
    if (CurrentUser.userId != null && CurrentUser.userId.length > 0) {
        return function (_props) {
            return (<Redirect path="/dashboard" replace key={uuid.v1()}>
            <View />
          </Redirect>);
        };
    }
    return undefined;
};
