"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UpdateProjectAccess = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../../access/rights/RightsManager");
var ProjectEntity_1 = require("../../../../client/query/entities/ProjectEntity");
var ProjectUserEntity_1 = require("../../../../client/query/entities/ProjectUserEntity");
var UpdateProjectAccess = function (_CommandRoot_1$Comman) {
  (0, _inherits2.default)(UpdateProjectAccess, _CommandRoot_1$Comman);
  var _super = _createSuper(UpdateProjectAccess);
  function UpdateProjectAccess(data, projectId, token) {
    var _this;
    (0, _classCallCheck2.default)(this, UpdateProjectAccess);
    _this = _super.call(this, data, projectId, token);
    _this.commandName = 'UpdateProjectAccess';
    _this.timeout = 1000000;
    _this.aggregate = 'project';
    _this.data = data;
    return _this;
  }
  (0, _createClass2.default)(UpdateProjectAccess, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var tokenData = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token);
        var userId = tokenData._id;
        yield ProjectEntity_1.ProjectEntity.checkProjectStatusAndGeneralAccess(this.entityId, userId);
        if (userId === 'all') {
          return true;
        }
        if (yield RightsManager_1.RightsManager.hasWriteRight(this.entityId, userId, 'commandChangeProject')) {
          return true;
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {});
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        try {
          var start = new Date();
          var r = ReadModels_1.ReadModels.get('ReSync');
          yield r.updateProjectAccess(new ProjectUserEntity_1.ProjectUserEntity({
            projectId: this.entityId,
            status: 'deleted',
            userId: 'all'
          }), false, false, true);
        } catch (e) {}
        return [];
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }]);
  return UpdateProjectAccess;
}(CommandRoot_1.CommandRoot);
exports.UpdateProjectAccess = UpdateProjectAccess;