var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SimpleReactNativeStorage = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var SimpleReactNativeStorage = exports.SimpleReactNativeStorage = function () {
  function SimpleReactNativeStorage() {
    (0, _classCallCheck2.default)(this, SimpleReactNativeStorage);
  }
  (0, _createClass2.default)(SimpleReactNativeStorage, [{
    key: "get",
    value: function get(key) {
      try {
        return localStorage.getItem(key);
      } catch (e) {
        return null;
      }
    }
  }, {
    key: "getAllKeys",
    value: function () {
      var _getAllKeys = (0, _asyncToGenerator2.default)(function* () {
        return Object.keys(localStorage);
      });
      function getAllKeys() {
        return _getAllKeys.apply(this, arguments);
      }
      return getAllKeys;
    }()
  }, {
    key: "remove",
    value: function remove(key) {
      return localStorage.removeItem(key);
    }
  }, {
    key: "set",
    value: function set(key, value) {
      try {
        return localStorage.setItem(key, value);
      } catch (err) {
        return undefined;
      }
    }
  }, {
    key: "clear",
    value: function clear() {
      localStorage.clear();
    }
  }]);
  return SimpleReactNativeStorage;
}();