"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StdApplicationError = void 0;
var ApplicationError_1 = require("./ApplicationError");
var StdApplicationError = function () {
  function StdApplicationError() {
    (0, _classCallCheck2.default)(this, StdApplicationError);
  }
  (0, _createClass2.default)(StdApplicationError, null, [{
    key: "notFound",
    value: function notFound() {
      var messageCode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'notFound';
      var message = arguments.length > 1 ? arguments[1] : undefined;
      var data = arguments.length > 2 ? arguments[2] : undefined;
      return new ApplicationError_1.ApplicationError(message != null ? message : messageCode, messageCode, ApplicationError_1.ApplicationErrorStatusCode.NotFoundError, data);
    }
  }, {
    key: "unauthorized",
    value: function unauthorized() {
      var messageCode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'unauthorized';
      var message = arguments.length > 1 ? arguments[1] : undefined;
      return new ApplicationError_1.ApplicationError(message != null ? message : messageCode, messageCode, ApplicationError_1.ApplicationErrorStatusCode.UnauthorizedError);
    }
  }, {
    key: "notImplemented",
    value: function notImplemented() {
      var messageCode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'notimplemented';
      var message = arguments.length > 1 ? arguments[1] : undefined;
      return new ApplicationError_1.ApplicationError(message != null ? message : messageCode, messageCode, ApplicationError_1.ApplicationErrorStatusCode.NotImplementedError);
    }
  }, {
    key: "forbidden",
    value: function forbidden() {
      var messageCode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'forbidden';
      var message = arguments.length > 1 ? arguments[1] : undefined;
      return new ApplicationError_1.ApplicationError(message != null ? message : messageCode, messageCode, ApplicationError_1.ApplicationErrorStatusCode.ForbidenError);
    }
  }, {
    key: "badRequest",
    value: function badRequest() {
      var messageCode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'badRequest';
      var message = arguments.length > 1 ? arguments[1] : undefined;
      return new ApplicationError_1.ApplicationError(message != null ? message : messageCode, messageCode, ApplicationError_1.ApplicationErrorStatusCode.BadRequestError);
    }
  }, {
    key: "temporarilyUnavailable",
    value: function temporarilyUnavailable() {
      var messageCode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'temporarilyUnavailable';
      var message = arguments.length > 1 ? arguments[1] : undefined;
      return new ApplicationError_1.ApplicationError(message != null ? message : messageCode, messageCode, ApplicationError_1.ApplicationErrorStatusCode.Unavailable);
    }
  }, {
    key: "waitForCreation",
    value: function waitForCreation() {
      var messageCode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'temporarilyUnavailable';
      var message = arguments.length > 1 ? arguments[1] : undefined;
      return new ApplicationError_1.ApplicationError(message != null ? message : messageCode, messageCode, 202);
    }
  }]);
  return StdApplicationError;
}();
exports.StdApplicationError = StdApplicationError;