var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OnboardingHandler = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _tsEvents = require("ts-events");
var OnboardingHandler = exports.OnboardingHandler = function () {
  function OnboardingHandler() {
    (0, _classCallCheck2.default)(this, OnboardingHandler);
  }
  (0, _createClass2.default)(OnboardingHandler, null, [{
    key: "registerOnboardingElement",
    value: function registerOnboardingElement(ref) {
      OnboardingHandler.registeredElements.set(ref.id, ref);
    }
  }, {
    key: "unRegisterOnboardingElement",
    value: function unRegisterOnboardingElement(id) {
      OnboardingHandler.registeredElements.delete(id);
    }
  }]);
  return OnboardingHandler;
}();
OnboardingHandler.registeredElements = new Map();
OnboardingHandler.eventHandler = new _tsEvents.AsyncEvent();