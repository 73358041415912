var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MaterialTextEditable = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _ThemeManager = require("../../ThemeManager");
var _Ripple = require("../utils/Ripple");
var _MaterialText = require("./MaterialText");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var MaterialTextEditable = exports.MaterialTextEditable = function (_PureComponent) {
  (0, _inherits2.default)(MaterialTextEditable, _PureComponent);
  var _super = _createSuper(MaterialTextEditable);
  function MaterialTextEditable(props) {
    var _this;
    (0, _classCallCheck2.default)(this, MaterialTextEditable);
    _this = _super.call(this, props);
    _this.handleEnter = function (e) {
      var onSubmitByEnter = _this.props.onSubmitByEnter;
      if (_reactNativeWeb.Platform.OS === 'web' && e.nativeEvent.key === 'Enter' && !e.nativeEvent.shiftKey && onSubmitByEnter != null) {
        onSubmitByEnter();
        e.preventDefault();
      }
    };
    _this.onActivateEdit = function () {
      _this.setState({
        editMode: true
      });
    };
    _this.onBlur = function () {
      var currentValue = _this.state.currentValue;
      _this.setState({
        editMode: false
      }, function () {
        if (currentValue !== _this.state.abortValue) {
          requestAnimationFrame(function () {
            _this.setState({
              currentValue: currentValue,
              editMode: false,
              abortValue: currentValue
            }, function () {
              if (_this.props.onChanged != null) {
                _this.props.onChanged(_this.state.currentValue);
              }
            });
          });
        }
        if (_this.props.onBlur != null) {
          _this.props.onBlur();
        }
      });
    };
    _this.onChangeText = function (currentValue) {
      _this.setState({
        currentValue: currentValue
      }, function () {
        if (_this.props.onChange != null) {
          _this.props.onChange(currentValue);
        }
      });
    };
    _this.onContentSizeChange = function (e) {
      if (_this.props.materailTextProps != null && _this.props.materailTextProps.numberOfLines !== 1) {
        if (e && e.nativeEvent) {
          var textFieldHeight = _this.state.textFieldHeight;
          var contentHeight = textFieldHeight;
          if (e.nativeEvent.contentSize && e.nativeEvent.contentSize.height) {
            contentHeight = e.nativeEvent.contentSize.height;
            _this.setState({
              textFieldHeight: contentHeight
            });
          } else if (e.nativeEvent.target && e.nativeEvent.target.scrollHeight) {
            var target = e.nativeEvent.target;
            _this.setState({
              textFieldHeight: 0
            }, function () {
              _this.setState({
                textFieldHeight: target.scrollHeight + 4
              });
            });
          }
        }
      }
    };
    _this.onFocus = function () {
      if (_this.props.onFocus != null) {
        _this.props.onFocus();
      }
    };
    _this.onLayout = function (e) {
      if (e && e.nativeEvent && e.nativeEvent.layout) {
        var layout = e.nativeEvent.layout;
        var textFieldHeight = layout.height;
        var clickPadding = _this.props.clickPadding;
        if (clickPadding) {
          textFieldHeight -= 2 * clickPadding;
        }
        _this.setState({
          textFieldHeight: textFieldHeight
        });
      }
    };
    var editMode = props.autoFocus === true || _this.state != null && _this.state.editMode;
    _this.state = {
      editMode: editMode,
      currentValue: props.children,
      abortValue: props.children
    };
    return _this;
  }
  (0, _createClass2.default)(MaterialTextEditable, [{
    key: "blur",
    value: function blur() {
      this.setState({
        editMode: false
      });
    }
  }, {
    key: "focus",
    value: function focus() {
      this.setState({
        editMode: true
      });
    }
  }, {
    key: "render",
    value: function render() {
      var _this$props = this.props,
        materailTextProps = _this$props.materailTextProps,
        editable = _this$props.editable,
        placeholder = _this$props.placeholder,
        style = _this$props.style,
        underlineColor = _this$props.underlineColor,
        hoverColor = _this$props.hoverColor,
        rippleColor = _this$props.rippleColor,
        accessibilityLabel = _this$props.accessibilityLabel,
        onSubmitEditing = _this$props.onSubmitEditing,
        clickPadding = _this$props.clickPadding;
      var _this$state = this.state,
        editMode = _this$state.editMode,
        currentValue = _this$state.currentValue,
        textFieldHeight = _this$state.textFieldHeight;
      if (editable !== true) {
        return (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, Object.assign({}, materailTextProps, {
          children: currentValue
        }));
      }
      if (editMode) {
        var multiline = materailTextProps.numberOfLines !== 1;
        var typesStyle = Object.assign({}, (0, _MaterialText.getStyleForType)(materailTextProps.type, materailTextProps.color, materailTextProps.backgroundColor, materailTextProps.centeredText, materailTextProps.centeredBox, materailTextProps.textPadding));
        return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: Object.assign({
            borderBottomWidth: 1,
            borderStyle: 'solid',
            borderColor: underlineColor,
            paddingVertical: clickPadding,
            alignItems: 'center',
            justifyContent: 'center'
          }, style),
          children: (0, _jsxRuntime.jsx)(_reactNativeWeb.TextInput, {
            accessibilityLabel: accessibilityLabel,
            autoFocus: true,
            underlineColorAndroid: "transparent",
            onContentSizeChange: this.onContentSizeChange,
            onChange: this.onContentSizeChange,
            onKeyPress: this.handleEnter,
            style: [{
              padding: 0,
              margin: 0
            }, typesStyle, {
              width: '100%',
              height: textFieldHeight || 20,
              fontSize: _ThemeManager.ThemeManager.style.getTextInputFontSize(typesStyle.fontSize),
              alignSelf: 'center'
            }],
            onChangeText: this.onChangeText,
            onSubmitEditing: onSubmitEditing,
            multiline: multiline,
            value: currentValue,
            onBlur: this.onBlur,
            onFocus: this.onFocus
          })
        });
      }
      if (currentValue == null || currentValue.length === 0) {}
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        onLayout: this.onLayout,
        style: Object.assign({
          marginBottom: 1
        }, style),
        children: (0, _jsxRuntime.jsx)(_Ripple.Ripple, {
          accessibilityLabel: accessibilityLabel,
          hoverColor: hoverColor,
          rippleColor: rippleColor,
          onPress: this.onActivateEdit,
          style: {
            paddingVertical: clickPadding,
            width: '100%',
            height: '100%',
            borderColor: 'transparent',
            justifyContent: materailTextProps != null && materailTextProps.numberOfLines === 1 ? 'center' : undefined
          },
          children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, Object.assign({}, materailTextProps, {
            color: materailTextProps.color !== undefined && materailTextProps.color.length > 0 ? materailTextProps.color : _ThemeManager.ThemeManager.style.black38,
            children: currentValue == null || currentValue.length === 0 ? placeholder : currentValue
          }))
        })
      });
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        underlineColor: _ThemeManager.ThemeManager.style.brandPrimary,
        hoverColor: _ThemeManager.ThemeManager.style.materialTextHoverColor,
        children: '',
        editable: true,
        autoFocus: false,
        placeholder: '...hinzufügen',
        materailTextProps: {
          type: _MaterialText.MaterialTextTypes.Body1,
          centeredText: false,
          numberOfLines: undefined,
          ellipsizeMode: 'tail'
        }
      };
    }
  }, {
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, prevState) {
      var editMode = nextProps.autoFocus === true || prevState != null && prevState.editMode;
      if (editMode !== prevState.editMode || nextProps.children !== prevState.abortValue) {
        return {
          editMode: editMode,
          currentValue: nextProps.children,
          abortValue: nextProps.children
        };
      }
      if (nextProps.children != null && nextProps.children.length === 0) {
        return {
          textFieldHeight: 0
        };
      }
      return null;
    }
  }]);
  return MaterialTextEditable;
}(_react.PureComponent);