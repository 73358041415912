import Cookies from 'js-cookie';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { Login } from 'upmesh-auth-core/src/client/commands/guest/Login';
import { ConnectionHandler } from '../ConnectionHandler';
import { I18n } from '../i18n/I18n';
export class LoginView extends PureComponent {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.onConnectionChange = (e) => {
            this.setState({ loading: !e });
        };
        this.onSetEmail = (eMail) => {
            this.setState({ eMail });
        };
        this.onSetPassword = (password) => {
            const { eMail } = this.state;
            this.login(eMail, password).catch((err) => console.debug(err));
        };
        this.onSubmitEmail = () => {
            const { eMail } = this.state;
            if (eMail.length > 0) {
                this.setState({ eMailSubmited: true });
            }
        };
        this.state = {
            loading: true,
            eMail: '',
            eMailSubmited: false,
        };
    }
    componentDidMount() {
        LoadingEvents.instance.startLoading();
        this.mounted = true;
        ConnectionHandler.serverConnection?.onConnectionChanged.attach(this.onConnectionChange);
        this.init().catch((err) => console.debug(err));
    }
    componentWillUnmount() {
        this.mounted = false;
        ConnectionHandler.serverConnection?.onConnectionChanged.detach(this.onConnectionChange);
    }
    render() {
        const { loading } = this.state;
        if (loading)
            return null;
        return (<View style={{
                width: '100%',
                height: '100%',
                alignItems: 'center',
                alignContent: 'center',
                paddingTop: 48,
                backgroundColor: '#FFFFFF',
            }}>
        <View style={{ width: 300 }}>
          {this.renderEmailField()}
          {this.renderPasswordField()}
        </View>
      </View>);
    }
    renderEmailField() {
        const { eMailSubmited, eMail } = this.state;
        if (eMailSubmited) {
            return (<View style={{ padding: 8 }}>
          <MaterialText type={MaterialTextTypes.Overline}>{I18n.m.getMessage('emailaddress')}</MaterialText>
          <MaterialText type={MaterialTextTypes.H6}>{eMail}</MaterialText>
        </View>);
        }
        return (<FormInputFilled labelText={I18n.m.getMessage('emailaddress')} autoFocus={eMail.length === 0} onChange={this.onSetEmail} onBlur={this.onSubmitEmail}/>);
    }
    async init() {
        this.setState({ loading: false }, () => {
            LoadingEvents.instance.stopLoading();
        });
    }
    async login(email, password) {
        LoadingEvents.instance.startLoading(I18n.m.getMessage('willBeLoggedIn'));
        const login = new Login({ password, email });
        try {
            const store = ConnectionHandler.getStore();
            await login.execute(store);
            Cookies.set('token', login.result.token);
            window.location.reload();
        }
        catch (e) {
            console.error(e);
            LoadingEvents.instance.stopLoading();
            Routing.instance.alert.post({ text: I18n.m.getMessage('wrongCredentials') });
        }
    }
    renderPasswordField() {
        const { eMailSubmited } = this.state;
        if (eMailSubmited) {
            return (<FormInputFilled labelText="Dein Passwort:" autoFocus secureTextEntry onSubmitEditing={this.onSetPassword}/>);
        }
        return null;
    }
}
