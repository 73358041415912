var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DownloadCache = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _AuthClient = require("upmesh-auth-core/build/src/client/AuthClient");
var _DateLocale = require("upmesh-i18n/build/src/i18n/DateLocale");
var DownloadCache = exports.DownloadCache = function () {
  function DownloadCache() {
    (0, _classCallCheck2.default)(this, DownloadCache);
    this.started = false;
    if (DownloadCache._instance != null) {
      throw new Error('DownloadCache must be addressed via instance');
    } else {
      DownloadCache._instance = this;
    }
  }
  (0, _createClass2.default)(DownloadCache, [{
    key: "addFileDownload",
    value: function addFileDownload(url) {}
  }, {
    key: "getByUrl",
    value: function () {
      var _getByUrl = (0, _asyncToGenerator2.default)(function* (targetUrl) {
        var _prio = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 5;
        var _cache = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
        yield _DateLocale.DateLocale.waitFor(function () {
          return _AuthClient.AuthClient.instance.ready;
        }, 33, 50000);
        return targetUrl;
      });
      function getByUrl(_x) {
        return _getByUrl.apply(this, arguments);
      }
      return getByUrl;
    }()
  }, {
    key: "start",
    value: function () {
      var _start = (0, _asyncToGenerator2.default)(function* () {
        if (!this.started) {
          this.started = true;
        }
      });
      function start() {
        return _start.apply(this, arguments);
      }
      return start;
    }()
  }], [{
    key: "instance",
    get: function get() {
      if (DownloadCache._instance == null) {
        DownloadCache._instance = new DownloadCache();
      }
      return DownloadCache._instance;
    }
  }, {
    key: "generateNameFromUrl",
    value: function generateNameFromUrl(url) {
      var uniqueName = url.replace(/[.*+?^${}\/()|[\]\\]/g, '_');
      uniqueName = `${uniqueName.substring(0, uniqueName.lastIndexOf('_'))}.${uniqueName.substring(uniqueName.lastIndexOf('_') + 1, uniqueName.length)}`;
      return uniqueName;
    }
  }, {
    key: "getFullPathFromUrl",
    value: function getFullPathFromUrl(url) {
      var linkToFilename = DownloadCache.generateNameFromUrl(url);
      return linkToFilename;
    }
  }]);
  return DownloadCache;
}();