"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _assertThisInitialized2 = _interopRequireDefault(require("@babel/runtime/helpers/assertThisInitialized"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RemoteODataDB = void 0;
var cross_fetch_1 = require("cross-fetch");
var ApplicationError_1 = require("../../entities/ApplicationError");
var OdataEntity_1 = require("../../entities/root/OdataEntity");
var StdApplicationError_1 = require("../../entities/StdApplicationError");
var ODataDB_1 = require("../../odata/root/ODataDB");
var ODataRemoteRepo_1 = require("./ODataRemoteRepo");
var RemoteFileHandler_1 = require("./RemoteFileHandler");
var RemoteODataDB = function (_ODataDB_1$ODataDB) {
  (0, _inherits2.default)(RemoteODataDB, _ODataDB_1$ODataDB);
  var _super = _createSuper(RemoteODataDB);
  function RemoteODataDB() {
    var _this;
    (0, _classCallCheck2.default)(this, RemoteODataDB);
    _this = _super.call(this);
    _this.odataRepoClass = ODataRemoteRepo_1.ODataRemoteRepo;
    if (RemoteODataDB._mainInstance == null) {
      RemoteODataDB._mainInstance = (0, _assertThisInitialized2.default)(_this);
    }
    return _this;
  }
  (0, _createClass2.default)(RemoteODataDB, [{
    key: "apiUrl",
    get: function get() {
      if (this.options != null && this.options.apiUrl != null) {
        return this.options.apiUrl.substr(this.options.apiUrl.length - 1, 1) === '/' ? this.options.apiUrl.substr(0, this.options.apiUrl.length - 1) : this.options.apiUrl;
      }
      throw StdApplicationError_1.StdApplicationError.notFound('Api Url is not defined');
    }
  }, {
    key: "authToken",
    get: function get() {
      if (this.options != null && this.options.authToken != null) {
        if (typeof this.options.authToken === 'string') {
          return this.options.authToken;
        }
        return this.options.authToken();
      }
      return '';
    }
  }, {
    key: "connected",
    get: function get() {
      return true;
    }
  }, {
    key: "closeDB",
    value: function () {
      var _closeDB = (0, _asyncToGenerator2.default)(function* () {
        return true;
      });
      function closeDB() {
        return _closeDB.apply(this, arguments);
      }
      return closeDB;
    }()
  }, {
    key: "fetchData",
    value: function () {
      var _fetchData = (0, _asyncToGenerator2.default)(function* (path) {
        var init = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
          method: 'GET'
        };
        var result;
        var fullUrl = this.apiUrl;
        try {
          fullUrl = `${this.apiUrl}${path.substr(0, 1) === '/' ? path : `/${path}`}`;
          result = yield (0, cross_fetch_1.default)(fullUrl, {
            headers: this.getHeader(),
            method: init.method,
            body: JSON.stringify(init.body)
          });
        } catch (e) {
          throw ApplicationError_1.ApplicationError.returnApplicationError(e);
        }
        if (result == null) {
          return undefined;
        }
        var jsonResult;
        if (typeof result.text === 'function') {
          var text = '';
          try {
            text = yield result.text();
            jsonResult = JSON.parse(text, OdataEntity_1.OdataEntity.dateReviver);
          } catch (err) {
            throw ApplicationError_1.ApplicationError.returnApplicationError({
              text: text,
              fullUrl: fullUrl,
              error: err
            });
          }
        } else if (typeof result.json === 'function') {
          try {
            jsonResult = yield result.json();
          } catch (err) {
            throw ApplicationError_1.ApplicationError.returnApplicationError({
              result: result,
              fullUrl: fullUrl,
              error: err
            });
          }
        }
        if (result.status >= 400) {
          var e = Object.assign({
            fullUrl: fullUrl
          }, jsonResult);
          throw ApplicationError_1.ApplicationError.returnApplicationError(e);
        }
        return jsonResult;
      });
      function fetchData(_x) {
        return _fetchData.apply(this, arguments);
      }
      return fetchData;
    }()
  }, {
    key: "initDB",
    value: function () {
      var _initDB = (0, _asyncToGenerator2.default)(function* (options) {
        if (options != null) {
          this.options = options;
          this.fileHandler = options.fileHandler != null ? options.fileHandler : new RemoteFileHandler_1.RemoteFileHandler(`${this.apiUrl}/files`);
        }
        return true;
      });
      function initDB(_x2) {
        return _initDB.apply(this, arguments);
      }
      return initDB;
    }()
  }, {
    key: "getHeader",
    value: function getHeader() {
      var headers = {};
      headers.Accept = 'application/json';
      headers['Content-Type'] = 'application/json';
      if (this.authToken != null && this.authToken.length > 0) {
        headers.authorization = `Bearer ${this.authToken}`;
      }
      if (this.options != null && this.options.additionalHeader != null) {
        for (var head of this.options.additionalHeader) {
          headers[head[0]] = head[1];
        }
      }
      return headers;
    }
  }, {
    key: "deleteDB",
    value: function () {
      var _deleteDB = (0, _asyncToGenerator2.default)(function* () {
        return true;
      });
      function deleteDB() {
        return _deleteDB.apply(this, arguments);
      }
      return deleteDB;
    }()
  }, {
    key: "saveDB",
    value: function () {
      var _saveDB = (0, _asyncToGenerator2.default)(function* () {
        return true;
      });
      function saveDB() {
        return _saveDB.apply(this, arguments);
      }
      return saveDB;
    }()
  }], [{
    key: "mainInstance",
    get: function get() {
      if (RemoteODataDB._mainInstance == null) {
        RemoteODataDB._mainInstance = new RemoteODataDB();
      }
      return RemoteODataDB._mainInstance;
    }
  }]);
  return RemoteODataDB;
}(ODataDB_1.ODataDB);
exports.RemoteODataDB = RemoteODataDB;