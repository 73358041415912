var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UploadFile = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var ExifReader = _interopRequireWildcard(require("exifreader"));
var mime = _interopRequireWildcard(require("mime"));
var _tsEvents = require("ts-events");
var _CurrentUser = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = _interopRequireWildcard(require("uuid"));
var _DeviceManager = require("../../../securestore/DeviceManager");
var _I18n = require("../../../theme/i18n/I18n");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var UploadFile = exports.UploadFile = function () {
  function UploadFile() {
    var _this = this;
    (0, _classCallCheck2.default)(this, UploadFile);
    this.progressEvent = new _tsEvents.AsyncEvent();
    this.onCompleted = function () {
      global['tempfiles'][_this.file.id] = undefined;
      _this.progressEvent.post({
        tempFile: _this.file,
        percent: 100,
        finished: true
      });
    };
    this.onStarted = function () {
      _this.progressEvent.post({
        tempFile: _this.file,
        percent: 0
      });
    };
    this.onError = function (error) {
      _this.file.error = error;
      _this.progressEvent.post({
        tempFile: _this.file,
        percent: 0
      });
    };
    this.onProgress = function (percent) {
      if (percent < 100) {
        _this.progressEvent.post({
          percent: percent,
          tempFile: _this.file
        });
      }
    };
  }
  (0, _createClass2.default)(UploadFile, [{
    key: "prepareUpload",
    value: function () {
      var _prepareUpload = (0, _asyncToGenerator2.default)(function* (tempfile, file) {
        if (global['tempfiles'] == null) {
          global['tempfiles'] = {};
        }
        global['tempfiles'][tempfile.id] = file;
        tempfile.preview = yield UploadFile.createWebPreview(file);
        return tempfile;
      });
      function prepareUpload(_x, _x2) {
        return _prepareUpload.apply(this, arguments);
      }
      return prepareUpload;
    }()
  }, {
    key: "cancelUpload",
    value: function () {
      var _cancelUpload = (0, _asyncToGenerator2.default)(function* () {
        try {
          this.xhr.abort();
          this.progressEvent.post({
            tempFile: this.file,
            percent: 0,
            canceled: true
          });
        } catch (e) {}
      });
      function cancelUpload() {
        return _cancelUpload.apply(this, arguments);
      }
      return cancelUpload;
    }()
  }, {
    key: "removeUploads",
    value: function () {
      var _removeUploads = (0, _asyncToGenerator2.default)(function* () {});
      function removeUploads() {
        return _removeUploads.apply(this, arguments);
      }
      return removeUploads;
    }()
  }, {
    key: "startUpload",
    value: function () {
      var _startUpload = (0, _asyncToGenerator2.default)(function* (file) {
        var _this2 = this;
        this.file = file;
        this.onStarted();
        if (global['tempfiles'] == null || global['tempfiles'][file.id] == null) {
          this.onError('notFound');
          return;
        }
        var f = global['tempfiles'][file.id];
        if (f == null || f.name == null) {
          this.onError('notFound');
          return;
        }
        var resizedFile;
        try {
          if (file.maxImageSize != null && file.maxImageSize > 0) {
            resizedFile = yield this.resizeImage(f, file.maxImageSize);
          }
        } catch (e) {
          this.onError(_I18n.I18n.m.getMessage('fileResizeError'));
        }
        try {
          var data = new FormData();
          data.append('file', resizedFile != null ? resizedFile : f, f.name);
          data.append('deviceId', _DeviceManager.DeviceManager.deviceId);
          data.append('id', file.id);
          data.append('orgFilename', file.name);
          try {
            var tags = yield ExifReader.load(f, {
              includeUnknown: false
            });
            var metaData = {
              gps: {
                GPSLongitudeRef: tags['GPSLongitudeRef'] ? tags['GPSLongitudeRef']['value'][0] : undefined,
                GPSLatitudeRef: tags['GPSLatitudeRef'] ? tags['GPSLatitudeRef']['value'][0] : undefined,
                GPSLatitude: tags['GPSLatitude'] && tags['GPSLatitude']['value'] && tags['GPSLatitude']['value'].length === 3 ? [tags['GPSLatitude']['value'][0][0] / tags['GPSLatitude']['value'][0][1], tags['GPSLatitude']['value'][1][0] / tags['GPSLatitude']['value'][1][1], tags['GPSLatitude']['value'][2][0] / tags['GPSLatitude']['value'][2][1]] : undefined,
                GPSLongitude: tags['GPSLongitude'] && tags['GPSLongitude']['value'] && tags['GPSLongitude']['value'].length === 3 ? [tags['GPSLongitude']['value'][0][0] / tags['GPSLongitude']['value'][0][1], tags['GPSLongitude']['value'][1][0] / tags['GPSLongitude']['value'][1][1], tags['GPSLongitude']['value'][2][0] / tags['GPSLongitude']['value'][2][1]] : undefined
              },
              exif: {
                DateTimeOriginal: tags['DateTimeOriginal'] && tags['DateTimeOriginal']['value'] && tags['DateTimeOriginal']['value'].length === 1 ? tags['DateTimeOriginal']['value'][0] : undefined,
                LensMake: tags['LensMake'] ? tags['LensMake']['value'][0] : undefined,
                LensModel: tags['LensModel'] ? tags['LensModel']['value'][0] : undefined,
                WhiteBalance: tags['WhiteBalance'] ? tags['WhiteBalance']['value'][0] : undefined,
                FNumber: tags['FNumber'] ? tags['FNumber']['value'][0] : undefined,
                ExposureTime: tags['ExposureTime'] ? tags['ExposureTime']['value'][0] : undefined,
                FocalLength: tags['FocalLength'] ? tags['FocalLength']['value'][0] : undefined,
                ISO: tags['ISO'] ? tags['ISO']['value'][0] : undefined
              },
              image: {
                DateTimeOriginal: tags['DateTimeOriginal'] && tags['DateTimeOriginal']['value'] && tags['DateTimeOriginal']['value'].length === 1 ? tags['DateTimeOriginal']['value'][0] : undefined,
                Make: tags['Make'] ? tags['Make']['value'][0] : undefined,
                Model: tags['Model'] ? tags['Model']['value'][0] : undefined,
                Orientation: tags['Orientation'] ? tags['Orientation']['value'][0] : undefined,
                ModifyDate: tags['ModifyDate'] ? tags['ModifyDate']['value'][0] : undefined,
                GPSInfo: tags['GPSInfo'] ? tags['GPSInfo']['value'][0] : undefined,
                Copyright: tags['Copyright'] ? tags['Copyright']['value'][0] : undefined,
                ImageDescription: tags['ImageDescription'] ? tags['ImageDescription']['value'][0] : undefined
              }
            };
            if (metaData) data.append('metaData', JSON.stringify(metaData));
          } catch (e) {}
          for (var d in file.data) {
            if (file.data[d] != null && file.data[d] !== 'undefined' && !data.has(d)) {
              if (typeof file.data[d] === 'object') {
                data.append(d, JSON.stringify(file.data[d]));
              } else {
                data.append(d, file.data[d]);
              }
            }
          }
          var url = file.targetUrl;
          this.xhr = new XMLHttpRequest();
          var xhr = this.xhr;
          xhr.open('POST', url, true);
          xhr.setRequestHeader('authorization', `Bearer ${_CurrentUser.CurrentUser.token}`);
          var onreadystatechange = function onreadystatechange(_e) {
            if (xhr.readyState === xhr.DONE && xhr.status >= 200 && xhr.status <= 299) {
              _this2.onCompleted();
            } else if (xhr.readyState === xhr.DONE && !(xhr.status >= 200 && xhr.status <= 299)) {
              if (xhr.responseText != null) {
                try {
                  if (typeof xhr.responseText === 'string') {
                    try {
                      _this2.onError(JSON.parse(xhr.responseText));
                    } catch (e) {
                      if (xhr.responseText.length > 0) {
                        _this2.onError(xhr.responseText);
                      } else {
                        _this2.onError(_I18n.I18n.m.getMessage('cantUpload', {
                          error: `status ${xhr.status}`
                        }));
                      }
                    }
                  } else {
                    _this2.onError(JSON.parse(xhr.responseText));
                  }
                } catch (err) {
                  _this2.onError(`${err.message}`);
                }
              } else {
                _this2.onError(_I18n.I18n.m.getMessage('cantUpload', {
                  error: `status ${xhr.status}`
                }));
              }
            }
          };
          xhr.onreadystatechange = onreadystatechange;
          xhr.upload.addEventListener('progress', function (e) {
            var percent = e.loaded * 100.0 / e.total;
            _this2.onProgress(percent);
          });
          setTimeout(function () {
            xhr.send(data);
          }, 200);
        } catch (e) {
          this.onError(`${_I18n.I18n.m.getMessage('fileUploadStartError')} ${e.toString()}`);
        }
      });
      function startUpload(_x3) {
        return _startUpload.apply(this, arguments);
      }
      return startUpload;
    }()
  }, {
    key: "resizeImage",
    value: function () {
      var _resizeImage = (0, _asyncToGenerator2.default)(function* (file, maxSize) {
        return new Promise(function (resolve) {
          var mimeType = mime.getType(file.name != null ? file.name : file['fileName']);
          if (!(mimeType === 'image/jpeg' || mimeType === 'image/png')) {
            resolve(null);
          }
          var reader = new FileReader();
          reader.onload = function (readerEvent) {
            if (readerEvent != null && readerEvent.target != null && readerEvent.target['result'] != null) {
              var image = new Image();
              image.id = uuid.v1();
              image.onload = function (_imageEvent) {
                var w = image.width;
                var h = image.height;
                if (w > h) {
                  if (w > maxSize) {
                    h *= maxSize / w;
                    w = maxSize;
                  }
                } else if (h > maxSize) {
                  w *= maxSize / h;
                  h = maxSize;
                }
                var canvas = document.createElement('canvas');
                canvas.width = w;
                canvas.height = h;
                var context = canvas.getContext('2d');
                if (context != null) {
                  context.drawImage(image, 0, 0, w, h);
                } else {
                  resolve(null);
                  return;
                }
                if (mimeType === 'image/jpeg') {
                  canvas.toBlob(function (blob) {
                    resolve(blob);
                  }, 'image/jpeg', 0.85);
                } else {
                  canvas.toBlob(function (blob) {
                    resolve(blob);
                  }, 'image/png');
                }
              };
              image.src = readerEvent.target['result'];
            }
          };
          reader.readAsDataURL(file);
        });
      });
      function resizeImage(_x4, _x5) {
        return _resizeImage.apply(this, arguments);
      }
      return resizeImage;
    }()
  }], [{
    key: "cleanUploadFolder",
    value: function () {
      var _cleanUploadFolder = (0, _asyncToGenerator2.default)(function* (_tempfileRepo) {});
      function cleanUploadFolder(_x6) {
        return _cleanUploadFolder.apply(this, arguments);
      }
      return cleanUploadFolder;
    }()
  }, {
    key: "getFilePreview",
    value: function () {
      var _getFilePreview = (0, _asyncToGenerator2.default)(function* (file) {
        var path = yield UploadFile.createWebPreview(file);
        return path != null ? path : '';
      });
      function getFilePreview(_x7) {
        return _getFilePreview.apply(this, arguments);
      }
      return getFilePreview;
    }()
  }, {
    key: "createWebPreview",
    value: function () {
      var _createWebPreview = (0, _asyncToGenerator2.default)(function* (file) {
        var mimeType = mime.getType(file.name != null ? file.name : file['fileName']);
        if (!(mimeType === 'image/jpeg' || mimeType === 'image/png' || mimeType === 'image/gif')) return undefined;
        var reader = new FileReader();
        return new Promise(function (resolve) {
          reader.onload = function (readerEvent) {
            if (readerEvent != null && readerEvent.target != null && readerEvent.target['result'] != null) {
              var image = new Image();
              image.onload = function (_imageEvent) {
                var maxSize = 256;
                var w = image.width;
                var h = image.height;
                if (w < h && w > maxSize) {
                  h *= maxSize / w;
                  w = maxSize;
                } else if (h > maxSize) {
                  w *= maxSize / h;
                  h = maxSize;
                }
                var canvas = document.createElement('canvas');
                canvas.width = w;
                canvas.height = h;
                var context = canvas.getContext('2d');
                if (context != null) {
                  context.drawImage(image, 0, 0, w, h);
                } else {
                  resolve(undefined);
                }
                if (mimeType === 'image/jpeg') return resolve(canvas.toDataURL('image/jpeg', 1.0));
                if (mimeType === 'image/png') return resolve(canvas.toDataURL('image/png'));
                if (mimeType === 'image/gif') return resolve(canvas.toDataURL('image/gif'));
                resolve(undefined);
              };
              image.src = readerEvent.target['result'];
            }
          };
          reader.readAsDataURL(file);
        });
      });
      function createWebPreview(_x8) {
        return _createWebPreview.apply(this, arguments);
      }
      return createWebPreview;
    }()
  }]);
  return UploadFile;
}();