import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Toast } from 'materialTheme/src/theme/components/Toast';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { ScrollView, View } from 'react-native';
import { UserEntity } from 'upmesh-auth-core/src/client/query/entities/UserEntity';
import { DefaultErrorHandler } from 'upmesh-client/src/components/DefaultErrorHandler';
import { DeleteProjectFromServer } from 'upmesh-support-core/src/commands/project/DeleteProjectFromServer';
import { ReactivateProject } from 'upmesh-support-core/src/commands/project/ReactivateProject';
import { UpdateProjectAccess } from 'upmesh-support-core/src/commands/project/UpdateProjectAccess';
import { ConnectionHandler } from '../../ConnectionHandler';
import { I18n } from '../../i18n/I18n';
const bytes = require('bytes');
export class ProjectDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.init = async () => {
            const client = ConnectionHandler.getClient();
            const { data } = this.props;
            if (client != null && data != null && data.id != null) {
                const project = await client.project.getById(data.id);
                if (project == null)
                    return;
                const projectUser = [];
                if (project.creator.startsWith('company')) {
                    const companyId = project.creator.slice(7);
                    const company = await client.company.getById(companyId);
                    projectUser.push({
                        user: new UserEntity({ firstname: company.company, id: project.creator }),
                        roleName: I18n.m.getMessage('projectOwner'),
                    });
                }
                else {
                    try {
                        const user = await client.user.getById(project.creator);
                        projectUser.push({ user, roleName: I18n.m.getMessage('projectOwner') });
                    }
                    catch (e) {
                        console.debug('cant get user', e);
                    }
                }
                const memberOnProjects = await client.projectUser.get({
                    select: ['projectId', 'roleId', 'userId'],
                    filter: `projectId eq '${project.id}' and status eq 'accepted'`,
                });
                for (const m of memberOnProjects) {
                    let user = new UserEntity();
                    try {
                        user = await client.user.getById(m.userId);
                    }
                    catch (e) {
                        console.debug('cant get user', e);
                    }
                    let roleName = m.roleId;
                    try {
                        const r = await client.projectRoles.getById(m.roleId);
                        roleName = I18n.m.getMessage(r.roleName);
                    }
                    catch (e) {
                        roleName = m.roleId;
                        console.debug('cant get project role', e);
                    }
                    projectUser.push({
                        user,
                        projectUser: m,
                        roleName,
                    });
                }
                const tickets = await client.ticket.count(`projectId eq '${project.id}'`);
                const plans = await client.plan.count(`projectId eq '${project.id}'`);
                const planVersions = await client.planVersion.count(`projectId eq '${project.id}'`);
                const files = await client.storedFile.count(`projectId eq '${project.id}'`);
                const folders = await client.folder.count(`projectId eq '${project.id}'`);
                const logBookEntries = await client.logBook.get({
                    filter: `projectId eq '${project.id}'`,
                    top: 10,
                    skip: 0,
                    orderby: 'createdAt DESC',
                });
                const logBookEntriesCount = await client.logBook.count(`projectId eq '${project.id}'`);
                this.setState({
                    project,
                    projectUser,
                    tickets,
                    plans,
                    planVersions,
                    files,
                    folders,
                    logBookEntriesCount,
                    logBookEntries,
                });
            }
        };
        this.updateProject = () => {
            Routing.instance.alert.post({
                text: 'Projektzugriff neu erstellen?',
                buttons: [
                    <ContainedButton key="okButton" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                    <ContainedButton key="reactivate" title="neu erstellen" onPress={this.updateProjectNow}/>,
                ],
            });
        };
        this.updateProjectNow = () => {
            LoadingEvents.instance.startLoading();
            Alert.instance?.close(() => {
                const { project } = this.state;
                const token = ConnectionHandler.getConnection()?.token;
                if (project == null || token == null)
                    return null;
                const r = new UpdateProjectAccess({}, project.id, token);
                LoadingEvents.instance.stopLoading();
                const start = Date.now();
                Toast.instance?.open({
                    title: 'Die Projektzugriffsrechte werden neu ertsellt',
                });
                r.execute(ConnectionHandler.getStore())
                    .then(() => {
                    Toast.instance?.open({
                        title: `Die Projektzugriffsrechte wurden in "${(Date.now() - start) / 1000}" Sekunden neu ertsellt`,
                    });
                })
                    .catch((e) => {
                    DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m);
                });
                return null;
            });
        };
        this.reactivateProject = () => {
            Routing.instance.alert.post({
                text: 'Projekt wiederherstellen?',
                buttons: [
                    <ContainedButton key="okButton" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                    <ContainedButton key="reactivate" title="wiederherstellen" onPress={this.reactivateNow}/>,
                ],
            });
        };
        this.reactivateNow = () => {
            LoadingEvents.instance.startLoading();
            Alert.instance?.close(() => {
                const { project } = this.state;
                const token = ConnectionHandler.getConnection()?.token;
                if (project == null || token == null)
                    return null;
                const r = new ReactivateProject({}, project.id, token);
                r.execute(ConnectionHandler.getStore())
                    .catch((e) => {
                    DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m);
                })
                    .finally(() => {
                    LoadingEvents.instance.stopLoading();
                });
                return null;
            });
        };
        this.deleteProject = () => {
            Routing.instance.alert.post({
                text: 'Projekt sofort löschen?',
                buttons: [
                    <ContainedButton key="okButton" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                    <ContainedButton key="reactivate" title="Jetzt löschen" onPress={this.deleteNow} backgroundColor={ThemeManager.style.brandDanger}/>,
                ],
            });
        };
        this.deleteNow = () => {
            LoadingEvents.instance.startLoading();
            Alert.instance?.close(() => {
                const { project } = this.state;
                const token = ConnectionHandler.getConnection()?.token;
                if (project == null || token == null)
                    return null;
                const r = new DeleteProjectFromServer({}, project.id, token);
                r.execute(ConnectionHandler.getStore())
                    .then(() => {
                    this.close();
                })
                    .catch((e) => {
                    DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m);
                })
                    .finally(() => {
                    LoadingEvents.instance.stopLoading();
                });
                return null;
            });
        };
        this.activitiesLoading = false;
        this.activitiesTO = null;
        this.onScroll = (e) => {
            try {
                const endReached = e.nativeEvent.contentSize.height - e.nativeEvent.contentOffset.y - e.nativeEvent.layoutMeasurement.height <=
                    100;
                if (endReached) {
                    this.getNextActivities().catch((err) => console.debug('cant get next activities', err));
                }
            }
            catch (err) {
                console.debug('cant get end Reached', err);
            }
        };
        this.openUser = (userId) => (e) => {
            if (userId != null && userId.length > 0) {
                if (userId.startsWith('company')) {
                    const companyId = userId.slice(7);
                    Routing.instance.openDialog('company', { id: companyId })(e);
                }
                else {
                    Routing.instance.openDialog('user', { id: userId })(e);
                }
            }
        };
        this.close = (e) => {
            Routing.instance.closeDialog(true)(e);
        };
        this.state = {
            projectUser: [],
            tickets: 0,
            plans: 0,
            planVersions: 0,
            files: 0,
            folders: 0,
            logBookEntries: [],
            logBookEntriesFrom: 0,
            logBookEntriesCount: 0,
        };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug('cant init Dialog', err));
        this.listenerId = ConnectionHandler.getClient()?.eventDispatcher.attach({
            callback: (en) => {
                const e = en.entities.get(this.props.data.id);
                if (e)
                    this.setState({ project: e.entity });
            },
            readModelName: 'Project',
            entityId: this.props.data.id,
        });
    }
    componentWillUnmount() {
        if (this.listenerId) {
            ConnectionHandler.getClient()?.eventDispatcher.detach('Project', this.listenerId);
        }
    }
    renderStatus() {
        const { project } = this.state;
        if (project != null && project.deleted) {
            const deleteDay = new Date(project.lastModifiedAt);
            deleteDay.setDate(deleteDay.getDate() + 90);
            const deletionInDays = Math.ceil((deleteDay.getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24);
            return [
                <View style={{ height: 8 }}/>,
                <MaterialText type={MaterialTextTypes.Caption}>Projekt Status</MaterialText>,
                <MaterialText color={ThemeManager.style.brandDanger}>{`gelöscht, wird endgültig entfernt in ${deletionInDays} Tagen`}</MaterialText>,
                <View style={{ flexDirection: 'row' }}>
          <ContainedButton title="Projekt wiederherstellen" onPress={this.reactivateProject} backgroundColor={ThemeManager.style.brandPrimary}/>
          <View style={{ width: 16 }}/>
          <ContainedButton title="Endgültig löschen" onPress={this.deleteProject} backgroundColor={ThemeManager.style.brandDanger}/>
        </View>,
            ];
        }
        if (project != null) {
            return [
                <View style={{ height: 8 }}/>,
                <MaterialText type={MaterialTextTypes.Caption}>Projekt Zugriff</MaterialText>,
                <View style={{ flexDirection: 'row' }}>
          <ContainedButton title="Projektzugriffe neu erstellen" onPress={this.updateProject} backgroundColor={ThemeManager.style.brandPrimary}/>
        </View>,
            ];
        }
        return null;
    }
    async getNextActivities() {
        if (this.activitiesTO != null)
            clearTimeout(this.activitiesTO);
        if (this.activitiesLoading) {
            setTimeout(() => {
                this.getNextActivities().catch((err) => console.debug('cant get next activities', err));
            }, 500);
        }
        else {
            this.activitiesLoading = true;
            try {
                const { project } = this.state;
                if (project == null)
                    return;
                const client = ConnectionHandler.getClient();
                if (client != null) {
                    const { logBookEntriesFrom, logBookEntries } = this.state;
                    const skip = logBookEntriesFrom + 10;
                    const logBookEntriesNext = await client.logBook.get({
                        filter: `projectId eq '${project.id}'`,
                        top: 10,
                        skip,
                        orderby: 'createdAt DESC',
                    });
                    this.setState({
                        logBookEntries: [...logBookEntries, ...logBookEntriesNext],
                        logBookEntriesFrom: skip,
                    }, () => {
                        this.activitiesLoading = false;
                    });
                }
            }
            catch (err) {
                this.activitiesLoading = false;
                console.debug('cant get next activities', err);
            }
        }
    }
    render() {
        const { project, tickets, plans, planVersions, files, folders } = this.state;
        if (project == null)
            return <Spinner />;
        return (<View style={{
                ...ThemeManager.style.absoluteStyle,
            }}>
        <ScrollView style={{ width: '100%', height: '100%' }} onScroll={this.onScroll}>
          <View style={{
                alignSelf: 'center',
                maxWidth: 768,
                width: '100%',
            }}>
            <View style={{
                width: '100%',
                padding: ThemeManager.style.getScreenRelativePixelSize(16),
            }}>
              <MaterialText type={MaterialTextTypes.Caption}>Id: {project.id}</MaterialText>
              <MaterialText type={MaterialTextTypes.H6}>{`${project.title}`}</MaterialText>
              {this.renderStatus()}
              <View style={{ height: 8 }}/>
              <MaterialText type={MaterialTextTypes.Caption}>Erstellt</MaterialText>
              <MaterialText>
                {project.createdAt != null ? I18n.m.dateCurrent.localeDateWithTimeString(project.createdAt) : '---'}
              </MaterialText>
              <View style={{ height: 8 }}/>
              <MaterialText type={MaterialTextTypes.Caption}>letzte Änderung</MaterialText>
              <MaterialText>
                {project.lastModifiedAt != null
                ? I18n.m.dateCurrent.localeDateWithTimeString(project.lastModifiedAt)
                : '---'}
              </MaterialText>
              <View style={{ height: 8 }}/>
              <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('projectSize')}</MaterialText>
              <MaterialText recognizeUrl>
                {project.storageUsageInBytes != null ? bytes(project.storageUsageInBytes) : '0'}
              </MaterialText>

              <View style={{ height: 8 }}/>
              <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('projectDescription')}</MaterialText>
              <MaterialText recognizeUrl>{project.description != null ? project.description : '--'}</MaterialText>
              <View style={{ height: 8 }}/>
              <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('projectSubscription')}</MaterialText>
              <MaterialText>{project.projectSubscription != null ? project.projectSubscription : 'basic'}</MaterialText>
              <View style={{ height: 8 }}/>
              <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('tickets')}</MaterialText>
              <MaterialText>{tickets}</MaterialText>
              <View style={{ height: 8 }}/>
              <MaterialText type={MaterialTextTypes.Caption}>
                {I18n.m.getMessage('plans')} ({I18n.m.getMessage('planVersions')})
              </MaterialText>
              <MaterialText>
                {plans} ({planVersions})
              </MaterialText>
              <View style={{ height: 8 }}/>
              <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('files')}</MaterialText>
              <MaterialText>{files}</MaterialText>
              <View style={{ height: 8 }}/>
              <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('folder')}</MaterialText>
              <MaterialText>{folders}</MaterialText>
            </View>
            {this.renderUsers()}
            {this.renderLogBooks()}
          </View>
        </ScrollView>
        <View style={{ position: 'absolute', top: 8, right: 8 }}>
          <Icon icon="close" onPress={this.close} toolTip=""/>
        </View>
      </View>);
    }
    renderUsers() {
        const { projectUser } = this.state;
        const lines = [];
        if (projectUser.length === 0) {
            lines.push(<View style={{ backgroundColor: '#EEEEEE', flexDirection: 'row' }} key="keine">
          <MaterialText centeredBox>Keine</MaterialText>
        </View>);
        }
        else {
            let i = 0;
            for (const s of projectUser) {
                i += 1;
                const backgroundColor = i % 2 === 0 ? '#FFFFFF' : '#EEEEEE';
                lines.push(<View style={{
                        backgroundColor,
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'space-between',
                    }} key={s.user.id}>
            <Ripple onPress={this.openUser(s.user.id)} style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
              <View style={{ justifyContent: 'flex-start' }}>
                <MaterialText strong>{s.user.getFullName()}</MaterialText>
                <MaterialText>{s.roleName}</MaterialText>
              </View>
              <View style={{ width: 72, justifyContent: 'center', alignItems: 'flex-end' }}>
                
                
                
                
                
              </View>
            </Ripple>
          </View>);
            }
        }
        return (<View style={{ marginTop: 24, padding: 16 }}>
        <MaterialText type={MaterialTextTypes.H6}>Team</MaterialText>
        {lines}
      </View>);
    }
    renderLogBooks() {
        const { logBookEntries, logBookEntriesCount } = this.state;
        const lines = [];
        const showing = logBookEntries.length;
        if (logBookEntries.length === 0) {
            lines.push(<View style={{ backgroundColor: '#EEEEEE', flexDirection: 'row' }} key="keine">
          <MaterialText centeredBox>Keine</MaterialText>
        </View>);
        }
        else {
            let i = 0;
            for (const s of logBookEntries) {
                i += 1;
                const backgroundColor = i % 2 === 0 ? '#FFFFFF' : '#EEEEEE';
                const logbookItem = s;
                lines.push(<View style={{ backgroundColor, width: '100%' }} key={logbookItem.id}>
            <MaterialText type={MaterialTextTypes.Caption}>
              {I18n.m.dateCurrent.localeDateWithTimeString(logbookItem.createdAt)}
            </MaterialText>
            <MaterialText>{this.createLogBookText(logbookItem)}</MaterialText>
            
            
            
            
            
            
            
            
            
            
          </View>);
            }
        }
        return (<View style={{ marginTop: 24 }}>
        <MaterialText type={MaterialTextTypes.H6}>{`Letzte Aktivitäten ${showing}/${logBookEntriesCount}`}</MaterialText>
        <View style={{ width: '100%' }}>{lines}</View>
      </View>);
    }
    createLogBookText(logbookItem) {
        let text = '';
        const values = { ...logbookItem, ...logbookItem.data };
        if (logbookItem.entityType === 'TicketComments' && logbookItem.eventName === 'TicketCommentAdded') {
            if (logbookItem.data.attachedFileId != null && logbookItem.data.attachedFileType === 'image') {
                text = I18n.m.getMessage('logBookTicketCommentAddedPicture', values);
            }
            else {
                text = I18n.m.getMessage('logBookTicketCommentAdded', values);
            }
        }
        else if (logbookItem.entityType === 'ProjectUser' && logbookItem.eventName === 'ProjectUserRemoved') {
            if (values.creatorName === values.userName) {
                text = I18n.m.getMessage('logBookProjectUserRemovedHimself', values);
            }
            else {
                text = I18n.m.getMessage('logBookProjectUserRemoved', values);
            }
        }
        else {
            text = I18n.m.getMessage(`logBook${logbookItem.eventName}`, values);
        }
        if (text == null || text.length === 0 || text === `logBook${logbookItem.eventName}`) {
            if (logbookItem.entityType === 'Ticket') {
                text = I18n.m.getMessage('logBookTicketDefault', values);
            }
            else {
                text = `missing text: ${logbookItem.eventName}`;
            }
        }
        text = text.replace(/&quot;/g, '"');
        return text;
    }
}
