"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StoredFileDeleted = void 0;
var ProjectEvents_1 = require("../_root/ProjectEvents");
var StoredFileDeleted = function (_ProjectEvents_1$Proj) {
  (0, _inherits2.default)(StoredFileDeleted, _ProjectEvents_1$Proj);
  var _super = _createSuper(StoredFileDeleted);
  function StoredFileDeleted(entityId, projectId) {
    (0, _classCallCheck2.default)(this, StoredFileDeleted);
    return _super.call(this, entityId, {
      deleted: true
    }, 'StoredFileDeleted', projectId);
  }
  return (0, _createClass2.default)(StoredFileDeleted);
}(ProjectEvents_1.ProjectEvents);
exports.StoredFileDeleted = StoredFileDeleted;