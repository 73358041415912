var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Wrapper = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _assertThisInitialized2 = _interopRequireDefault(require("@babel/runtime/helpers/assertThisInitialized"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _lodash = _interopRequireDefault(require("lodash"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _reactNativeDeviceInfo = _interopRequireDefault(require("react-native-device-info"));
var _reactNativeGestureHandler = require("react-native-gesture-handler");
var _reactNativeSafeAreaContext = require("react-native-safe-area-context");
var _reactRouter = require("react-router");
var _Config = require("../../../Config");
var _I18n = require("../../i18n/I18n");
var _ResizeEvent = require("../../ResizeEvent");
var _LoadingEvents = require("../../routing/LoadingEvents");
var _RouterControl = require("../../routing/RouterControl");
var _ThemeManager = require("../../ThemeManager");
var _Alert = require("../Alert");
var _BottomSheet = require("../BottomSheet");
var _Dialog = require("../Dialog");
var _DialogBetween = require("../DialogBetween");
var _DialogPicker = require("../DialogPicker");
var _DialogUp = require("../DialogUp");
var _DragZone = require("../dragndrop/DragZone");
var _FileDropZone = require("../file/FileDropZone");
var _ContentFooter = require("../footer/ContentFooter");
var _ContentFooterEventHandler = require("../footer/ContentFooterEventHandler");
var _ContentHeader = require("../header/ContentHeader");
var _LightboxWrapper = require("../lightbox/LightboxWrapper");
var _Menu = require("../Menu");
var _MenuRaw = require("../MenuRaw");
var _ModalBottomSheet = require("../ModalBottomSheet");
var _Toast = require("../Toast");
var _WrapperUploads = require("../upload/WrapperUploads");
var _Onboarding = require("./onboarding/Onboarding");
var _WrapperAutoCompletes = require("./WrapperAutoCompletes");
var _WrapperCamera = require("./WrapperCamera");
var _WrapperLoader = _interopRequireDefault(require("./WrapperLoader"));
var _WrapperNetworkContext = require("./WrapperNetworkContext");
var _WrapperToolTips = require("./WrapperToolTips");
var _WrapperUserContext = require("./WrapperUserContext");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var Wrapper = exports.Wrapper = function (_PureComponent) {
  (0, _inherits2.default)(Wrapper, _PureComponent);
  var _super = _createSuper(Wrapper);
  function Wrapper(props) {
    var _this;
    (0, _classCallCheck2.default)(this, Wrapper);
    _this = _super.call(this, props);
    _this.currentKeyboardHeight = 0;
    _this.onChangeLanguage = function (locale) {
      if (locale !== _this.state.locale) {
        _this.setState({
          locale: locale
        });
      }
    };
    _this.renderContent = function (props) {
      if (_this.props.outlineView == null) {
        return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: _ThemeManager.ThemeManager.style.absoluteStyle,
          collapsable: false,
          children: (0, _jsxRuntime.jsx)(_reactRouter.Route, {
            style: _ThemeManager.ThemeManager.style.absoluteStyle,
            render: _this.renderRouterControl
          })
        });
      }
      var OUTLINED = _this.props.outlineView;
      var size = _this.state.size;
      return (0, _jsxRuntime.jsx)(OUTLINED, Object.assign({
        style: _ThemeManager.ThemeManager.style.absoluteStyle
      }, props, {
        size: size,
        children: (0, _jsxRuntime.jsx)(_reactRouter.Route, {
          style: _ThemeManager.ThemeManager.style.absoluteStyle,
          render: _this.renderRouterControl
        })
      }));
    };
    _this.renderMain = function (props) {
      if (_this.props.staticView == null) {
        return null;
      }
      var STATICS = _this.props.staticView;
      return (0, _jsxRuntime.jsx)(STATICS, Object.assign({}, props));
    };
    _this.renderRouterControl = function (props) {
      return (0, _jsxRuntime.jsx)(_RouterControl.RouterControl, Object.assign({
        onSidebarWidthChange: _this.onSidebarWidthChange
      }, props, {
        size: _this.state.size
      }));
    };
    _this.outerSize = _reactNativeWeb.Dimensions.get('window');
    _this.sideBarwidth = 0;
    _this.onSidebarWidthChange = function (width) {
      _this.sideBarwidth = Number.isNaN(width) ? 0 : width;
      _this.resize(_this.lastSize);
    };
    _this.footerStatus = function (_e) {
      _this.resize(_this.lastSize);
    };
    _this.onLayoutSafeAreaContentView = function (e) {
      if (e != null && e.nativeEvent != null && e.nativeEvent.layout != null) {
        var size = e.nativeEvent.layout;
        _this.lastSize = size;
        _this.resize(size);
      }
    };
    _this.isLandscapeMode = function () {
      return _ResizeEvent.ResizeEvent.current.windowWidth > _ResizeEvent.ResizeEvent.current.windowHeight;
    };
    _this.onLayoutOuter = function (e) {
      if (e != null && e.nativeEvent != null && e.nativeEvent.layout != null) {
        _this.outerSize = e.nativeEvent.layout;
        _this.resize(_this.lastSize);
      }
    };
    _this.resize = _lodash.default.debounce(function (newSize) {
      var resizeEvent = _this.createSize(newSize);
      _ResizeEvent.ResizeEvent.post(resizeEvent);
      var _this$state = _this.state,
        layoutKnown = _this$state.layoutKnown,
        size = _this$state.size;
      if (JSON.stringify(size) !== JSON.stringify(resizeEvent) || !layoutKnown) {
        _this.setState({
          size: resizeEvent,
          layoutKnown: true
        }, function () {
          _ResizeEvent.ResizeEvent.post(resizeEvent);
        });
      }
    }, 16);
    _this.onKeyboardWillShow = function (e) {
      if (e != null && e.endCoordinates != null && e.endCoordinates.height != null) {
        _this.currentKeyboardHeight = e.endCoordinates.height;
        _this.resize(_this.lastSize);
      }
    };
    _this.onKeyboardWillHide = function () {
      _this.currentKeyboardHeight = 0;
      _this.resize(_this.lastSize);
    };
    _this.onKeyboardDidShow = function (e) {
      if (e != null && e.endCoordinates != null && e.endCoordinates.height != null) {
        _this.currentKeyboardHeight = e.endCoordinates.height;
        _this.resize(_this.lastSize);
      }
    };
    _this.onKeyboardDidHide = function () {
      _this.currentKeyboardHeight = 0;
      _this.resize(_this.lastSize);
    };
    _this.hideLoading = function (_text) {
      _this.setState({
        loading: {
          text: '',
          show: false
        }
      });
    };
    _this.onContentFooterHeightChange = function (_height) {
      _this.resize(_this.lastSize);
    };
    _this.showLoading = function (text) {
      _this.setState({
        loading: {
          text: text,
          show: true
        }
      });
    };
    _this.basename = _ThemeManager.ThemeManager.style.basename;
    if (_I18n.I18n.m == null || !_I18n.I18n.m.initialized) {
      throw new Error('no language set up!');
    }
    Wrapper._instance = (0, _assertThisInitialized2.default)(_this);
    var resizeEvent = _ResizeEvent.ResizeEvent.current;
    _this.lastSize = Object.assign({}, _reactNativeWeb.Dimensions.get('window'), {
      x: 0,
      y: 0
    });
    _this.state = {
      size: resizeEvent,
      layoutKnown: false,
      loading: _LoadingEvents.LoadingEvents.currentState,
      locale: _I18n.I18n.m.currentLanguage
    };
    return _this;
  }
  (0, _createClass2.default)(Wrapper, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.keyboardShowListener = _reactNativeWeb.Keyboard.addListener('keyboardDidShow', this.onKeyboardDidShow);
      this.keyboardHideListener = _reactNativeWeb.Keyboard.addListener('keyboardDidHide', this.onKeyboardDidHide);
      this.keyboardWillShowListener = _reactNativeWeb.Keyboard.addListener('keyboardWillShow', this.onKeyboardWillShow);
      this.keyboardWillHideListener = _reactNativeWeb.Keyboard.addListener('keyboardWillHide', this.onKeyboardWillHide);
      _ContentFooterEventHandler.ContentFooterEventHandler.heightChangeEvent.attach(this.onContentFooterHeightChange);
      _ContentFooterEventHandler.ContentFooterEventHandler.statusEvent.attach(this.footerStatus);
      _LoadingEvents.LoadingEvents.instance.loadingStopped.attach(this.hideLoading);
      _LoadingEvents.LoadingEvents.instance.loadingStarted.attach(this.showLoading);
      _I18n.I18n.m.changedLanguage.attach(this.onChangeLanguage);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      _I18n.I18n.m.changedLanguage.detach(this.onChangeLanguage);
      _ContentFooterEventHandler.ContentFooterEventHandler.heightChangeEvent.detach(this.onContentFooterHeightChange);
      _ContentFooterEventHandler.ContentFooterEventHandler.statusEvent.detach(this.footerStatus);
      _LoadingEvents.LoadingEvents.instance.loadingStopped.detach(this.hideLoading);
      _LoadingEvents.LoadingEvents.instance.loadingStarted.detach(this.showLoading);
      if (this.keyboardShowListener != null) {
        this.keyboardShowListener.remove();
        this.keyboardShowListener = null;
      }
      if (this.keyboardHideListener != null) {
        this.keyboardHideListener.remove();
        this.keyboardHideListener = null;
      }
      if (this.keyboardWillShowListener != null) {
        this.keyboardWillShowListener.remove();
        this.keyboardWillShowListener = null;
      }
      if (this.keyboardWillHideListener != null) {
        this.keyboardWillHideListener.remove();
        this.keyboardWillHideListener = null;
      }
    }
  }, {
    key: "render",
    value: function render() {
      var connectionContext = this.props.connectionContext;
      var _this$state2 = this.state,
        layoutKnown = _this$state2.layoutKnown,
        size = _this$state2.size,
        loading = _this$state2.loading;
      if (!layoutKnown) {
        return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          onLayout: this.onLayoutOuter,
          collapsable: false,
          style: Object.assign({}, _ThemeManager.ThemeManager.style.absoluteStyle, {
            backgroundColor: '#FFFFFF',
            overflow: 'visible'
          }),
          children: (0, _jsxRuntime.jsx)(_WrapperUserContext.WrapperUserContext, {
            children: (0, _jsxRuntime.jsx)(_reactNativeSafeAreaContext.SafeAreaProvider, {
              children: (0, _jsxRuntime.jsx)(_reactNativeSafeAreaContext.SafeAreaView, {
                style: {
                  flex: 1
                },
                children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
                  collapsable: false,
                  style: {
                    position: 'relative',
                    flex: 1
                  },
                  onLayout: this.onLayoutSafeAreaContentView,
                  children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
                    style: Object.assign({}, _ThemeManager.ThemeManager.style.absoluteStyle, {
                      backgroundColor: _ThemeManager.ThemeManager.style.appBgColor
                    })
                  })
                })
              })
            })
          })
        });
      }
      var iosHomeButtonBackground = _reactNativeWeb.Platform.OS === 'ios' && size.contentBottom > 0 ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          position: 'absolute',
          bottom: 0,
          right: 0,
          left: 0,
          height: size.contentBottom,
          backgroundColor: _ThemeManager.ThemeManager.style.appBgColor
        }
      }) : null;
      var view = (0, _jsxRuntime.jsxs)(_reactNativeGestureHandler.GestureHandlerRootView, {
        onLayout: this.onLayoutOuter,
        collapsable: false,
        style: Object.assign({}, _ThemeManager.ThemeManager.style.absoluteStyle, {
          backgroundColor: '#FFFFFF',
          overflow: 'visible'
        }),
        children: [iosHomeButtonBackground, (0, _jsxRuntime.jsxs)(_WrapperUserContext.WrapperUserContext, {
          children: [(0, _jsxRuntime.jsx)(_reactNativeSafeAreaContext.SafeAreaProvider, {
            children: (0, _jsxRuntime.jsx)(_reactNativeSafeAreaContext.SafeAreaView, {
              style: {
                flex: 1,
                overflow: 'visible'
              },
              children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
                collapsable: false,
                style: {
                  position: 'relative',
                  flex: 1,
                  overflow: 'visible',
                  backgroundColor: _ThemeManager.ThemeManager.style.appBgColor
                },
                onLayout: this.onLayoutSafeAreaContentView
              })
            })
          }), (0, _jsxRuntime.jsx)(_reactNativeWeb.TouchableWithoutFeedback, {
            disabled: this.currentKeyboardHeight === 0,
            onPressOut: _reactNativeWeb.Keyboard.dismiss,
            children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              collapsable: false,
              style: _reactNativeWeb.StyleSheet.absoluteFill,
              children: (0, _jsxRuntime.jsx)(_FileDropZone.FileDropZone, {
                id: "test",
                style: _ThemeManager.ThemeManager.style.absoluteStyle,
                children: (0, _jsxRuntime.jsxs)(_DragZone.DragZone, {
                  height: size.windowHeight,
                  width: size.windowWidth,
                  children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
                    collapsable: false,
                    style: _ThemeManager.ThemeManager.style.absoluteStyle,
                    children: (0, _jsxRuntime.jsx)(_Config.Router, {
                      style: _ThemeManager.ThemeManager.style.absoluteStyle,
                      basename: this.basename,
                      forceRefresh: false,
                      children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
                        style: _ThemeManager.ThemeManager.style.absoluteStyle,
                        children: [this.props.staticView == null ? null : (0, _jsxRuntime.jsx)(_reactRouter.Route, {
                          style: _ThemeManager.ThemeManager.style.absoluteStyle,
                          render: this.renderMain
                        }), this.renderContent(this.props), (0, _jsxRuntime.jsx)(_ContentHeader.ContentHeader, {
                          size: size
                        }), (0, _jsxRuntime.jsx)(_Dialog.Dialog, {
                          size: size
                        }, "dialog"), (0, _jsxRuntime.jsx)(_DialogBetween.DialogBetween, {
                          size: size
                        }), (0, _jsxRuntime.jsx)(_DialogUp.DialogUp, {
                          size: size
                        }), (0, _jsxRuntime.jsx)(_DialogPicker.DialogPicker, {})]
                      })
                    }, "router")
                  }), (0, _jsxRuntime.jsx)(_ContentFooter.ContentFooter, {
                    size: size
                  }), (0, _jsxRuntime.jsx)(_WrapperCamera.WrapperCamera, {
                    size: size
                  }), (0, _jsxRuntime.jsx)(_Menu.Menu, {
                    size: size
                  }), (0, _jsxRuntime.jsx)(_MenuRaw.MenuRaw, {
                    size: size
                  }), (0, _jsxRuntime.jsx)(_LightboxWrapper.LightboxWrapper, {
                    size: size
                  }), (0, _jsxRuntime.jsx)(_BottomSheet.BottomSheet, {
                    size: size
                  }), (0, _jsxRuntime.jsx)(_ModalBottomSheet.ModalBottomSheet, {
                    windowHeight: size.contentHeight,
                    windowWidth: size.contentWidth
                  }), (0, _jsxRuntime.jsx)(_WrapperUploads.WrapperUploads, {
                    size: size
                  }), (0, _jsxRuntime.jsx)(_Alert.Alert, {
                    size: size
                  }), (0, _jsxRuntime.jsx)(_Toast.Toast, {
                    size: size
                  }), (0, _jsxRuntime.jsx)(_Onboarding.Onboarding, {
                    size: size
                  }), (0, _jsxRuntime.jsx)(_WrapperToolTips.WrapperToolTips, {}), (0, _jsxRuntime.jsx)(_WrapperAutoCompletes.WrapperAutoCompletes, {
                    size: size
                  }), loading.show ? (0, _jsxRuntime.jsx)(_WrapperLoader.default, {
                    text: loading.text
                  }) : null, this.renderStatusBar()]
                })
              })
            })
          })]
        })]
      });
      if (!connectionContext) {
        return view;
      }
      return (0, _jsxRuntime.jsx)(_WrapperNetworkContext.WrapperNetworkContext, {
        children: view
      });
    }
  }, {
    key: "renderStatusBar",
    value: function renderStatusBar() {
      if (_reactNativeWeb.Platform.OS.toString() !== 'web') {
        return (0, _jsxRuntime.jsx)(_reactNativeWeb.StatusBar, {
          backgroundColor: "transparent",
          barStyle: _ThemeManager.ThemeManager.style.statusbarStyle,
          translucent: true
        }, "sb");
      }
      return null;
    }
  }, {
    key: "createSize",
    value: function createSize(windowSize) {
      var dimension = windowSize;
      _ThemeManager.ThemeManager.style.statusBarHeight = windowSize.y;
      var dimensions = this.outerSize;
      var contentHeight = dimension.height;
      if (_ContentFooter.ContentFooter.getCurrentHeight() != null) {
        contentHeight -= _ContentFooter.ContentFooter.getCurrentHeight();
      }
      var windowHeight = dimensions.height;
      var contentBottom = dimensions.height - windowSize.y - windowSize.height + _ContentFooter.ContentFooter.getCurrentHeight();
      var windowBottom = dimensions.height - windowSize.y - windowSize.height;
      var currentKeyboardHeight = _reactNativeWeb.Platform.OS === 'ios' && this.currentKeyboardHeight > 0 ? this.currentKeyboardHeight - contentBottom : this.currentKeyboardHeight;
      if (_reactNativeWeb.Platform.OS === 'android') currentKeyboardHeight = 0;
      var contentTop = _reactNativeDeviceInfo.default.isTablet() && _reactNativeWeb.Platform.OS === 'ios' && windowSize.y === 0 ? 20 : windowSize.y;
      var resizeEvent = {
        contentHeight: contentHeight,
        contentBottom: contentBottom,
        windowBottom: windowBottom,
        windowHeight: windowHeight,
        currentKeyboardHeight: currentKeyboardHeight,
        contentTop: contentTop,
        contentWidth: dimension.width - this.sideBarwidth,
        windowWidth: dimensions.width,
        contentLeft: windowSize.x,
        contentRight: dimensions.width - windowSize.x - windowSize.width,
        sidebarWidth: this.sideBarwidth
      };
      return resizeEvent;
    }
  }], [{
    key: "instance",
    get: function get() {
      return Wrapper._instance;
    }
  }]);
  return Wrapper;
}(_react.PureComponent);
Wrapper.closeAlert = function () {
  if (_Alert.Alert.instance != null) {
    var _Alert$instance;
    (_Alert$instance = _Alert.Alert.instance) == null || _Alert$instance.close();
  }
};