"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ReadModelRoot = exports.ReadModelRootConfig = void 0;
var ApplicationError_1 = require("odatarepos/build/src/entities/ApplicationError");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var StdApplicationError_1 = require("odatarepos/build/src/entities/StdApplicationError");
var ErrorReporter_1 = require("odatarepos/build/src/reporting/ErrorReporter");
var ReadModelBase_1 = require("./ReadModelBase");
var ReadModels_1 = require("./ReadModels");
var ReadModelRootConfig = (0, _createClass2.default)(function ReadModelRootConfig(c) {
  (0, _classCallCheck2.default)(this, ReadModelRootConfig);
  this.eventDispatcher = c.eventDispatcher;
  this.events = c.events;
  this.repo = c.repo;
});
exports.ReadModelRootConfig = ReadModelRootConfig;
var ReadModelRoot = function (_ReadModelBase_1$Read) {
  (0, _inherits2.default)(ReadModelRoot, _ReadModelBase_1$Read);
  var _super = _createSuper(ReadModelRoot);
  function ReadModelRoot() {
    var _this;
    (0, _classCallCheck2.default)(this, ReadModelRoot);
    _this = _super.apply(this, arguments);
    _this.eventsToHandle = [];
    _this.handlerForIdRunnung = new Set();
    _this.dispatchChanges = true;
    _this.eventListener = [];
    _this.lastTimeUpdate = 0;
    _this.handleEvents = (0, _asyncToGenerator2.default)(function* () {
      try {
        yield _this.handleEventsNow();
      } catch (e) {
        ErrorReporter_1.ErrorReporter.sendReport({
          data: e,
          subject: `error on ${_this.readModelName}.handleEvents`,
          type: 'error'
        });
        if (_this.eventsToHandle.length > 0) {
          yield _this.handleEvents();
        }
      }
    });
    _this.canHandleParallelEntity = true;
    _this.checkLock = false;
    return _this;
  }
  (0, _createClass2.default)(ReadModelRoot, [{
    key: "readModelName",
    get: function get() {
      return this.exampleEntity.entityName;
    }
  }, {
    key: "count",
    value: function () {
      var _count = (0, _asyncToGenerator2.default)(function* (filter, _userId) {
        return this.config == null ? 0 : this.config.repo.count(filter);
      });
      function count(_x, _x2) {
        return _count.apply(this, arguments);
      }
      return count;
    }()
  }, {
    key: "distinct",
    value: function () {
      var _distinct = (0, _asyncToGenerator2.default)(function* (select, filter, _userId) {
        return this.config == null ? [] : this.config.repo.distinct(select, filter);
      });
      function distinct(_x3, _x4, _x5) {
        return _distinct.apply(this, arguments);
      }
      return distinct;
    }()
  }, {
    key: "get",
    value: function () {
      var _get = (0, _asyncToGenerator2.default)(function* (options, _userId) {
        return this.config == null ? [] : this.config.repo.get(options);
      });
      function get(_x6, _x7) {
        return _get.apply(this, arguments);
      }
      return get;
    }()
  }, {
    key: "getById",
    value: function () {
      var _getById = (0, _asyncToGenerator2.default)(function* (id, select, _userId) {
        if (this.config == null) {
          throw new ApplicationError_1.ApplicationError('not found', `${this.exampleEntity.entityName}notFound`, ApplicationError_1.ApplicationErrorStatusCode.NotFoundError, {
            id: id
          });
        }
        return this.config.repo.getById(id, select);
      });
      function getById(_x8, _x9, _x10) {
        return _getById.apply(this, arguments);
      }
      return getById;
    }()
  }, {
    key: "getEventsById",
    value: function () {
      var _getEventsById = (0, _asyncToGenerator2.default)(function* (id) {
        var _withHistory = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        var _userId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'all';
        if (this.config == null) {
          throw new ApplicationError_1.ApplicationError('not found', `${this.exampleEntity.entityName}notFound`, ApplicationError_1.ApplicationErrorStatusCode.NotFoundError, {
            id: id
          });
        }
        throw StdApplicationError_1.StdApplicationError.notImplemented();
      });
      function getEventsById(_x11) {
        return _getEventsById.apply(this, arguments);
      }
      return getEventsById;
    }()
  }, {
    key: "init",
    value: function () {
      var _init = (0, _asyncToGenerator2.default)(function* (config) {
        var _this2 = this;
        ReadModels_1.ReadModels.registerReadModel(this);
        this.config = config;
        if (this.eventListener.length > 0) {
          for (var e of this.eventListener) {
            e();
          }
          this.eventListener = [];
        }
        if (config.events) for (var i = 0; i < this.onEvents.length; i += 1) {
          this.eventListener.push(config.events.on(this.onEvents[i].eventName, function (e) {
            return _this2.eventHandler(e).catch(function (err) {
              return void 0;
            });
          }));
        }
      });
      function init(_x12) {
        return _init.apply(this, arguments);
      }
      return init;
    }()
  }, {
    key: "repo",
    get: function get() {
      var _this$config;
      return (_this$config = this.config) == null ? void 0 : _this$config.repo;
    }
  }, {
    key: "saveEntity",
    value: function () {
      var _saveEntity = (0, _asyncToGenerator2.default)(function* (entity, preferPost) {
        var secondTime = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
        if (this.config == null) return entity;
        var r = this.repo;
        if (r == null) return entity;
        if (Array.isArray(entity)) {
          for (var _e of entity) {
            yield this.saveEntity(_e, preferPost, secondTime);
          }
          return entity;
        }
        var e = entity.createOne(entity);
        try {
          if (preferPost) return yield r.post(e);
          return yield r.patch(e);
        } catch (err) {
          if (preferPost && err.statusCode != null && err.statusCode === 409) {
            return this.saveEntity(e, false, secondTime);
          }
          if (err.statusCode != null && err.statusCode === 404) {
            try {
              return yield r.post(e);
            } catch (errPost) {
              if (err['statusCode'] === 409 && !secondTime) {
                return this.saveEntity(entity, false, true);
              }
              throw errPost;
            }
          } else {
            throw err;
          }
        }
      });
      function saveEntity(_x13, _x14) {
        return _saveEntity.apply(this, arguments);
      }
      return saveEntity;
    }()
  }, {
    key: "afterSave",
    value: function () {
      var _afterSave = (0, _asyncToGenerator2.default)(function* (_entity, _oldEntity) {});
      function afterSave(_x15, _x16) {
        return _afterSave.apply(this, arguments);
      }
      return afterSave;
    }()
  }, {
    key: "afterDispatch",
    value: function () {
      var _afterDispatch = (0, _asyncToGenerator2.default)(function* (_entity) {});
      function afterDispatch(_x17) {
        return _afterDispatch.apply(this, arguments);
      }
      return afterDispatch;
    }()
  }, {
    key: "dispatchChangedEvent",
    value: function () {
      var _dispatchChangedEvent = (0, _asyncToGenerator2.default)(function* (processedEvents, entity) {
        if (this.dispatchChanges && this.config != null && this.config.eventDispatcher != null && entity != null) {
          var entities = new Map();
          entities.set(entity.id, {
            entity: entity,
            events: processedEvents
          });
          this.config.eventDispatcher.post({
            readModelName: this.readModelName,
            entities: entities
          });
        }
      });
      function dispatchChangedEvent(_x18, _x19) {
        return _dispatchChangedEvent.apply(this, arguments);
      }
      return dispatchChangedEvent;
    }()
  }, {
    key: "eventHandler",
    value: function () {
      var _eventHandler = (0, _asyncToGenerator2.default)(function* (e) {
        if (this.onEvents.findIndex(function (ev) {
          return ev.eventName === e.eventName;
        }) >= 0) {
          this.eventsToHandle.push([e]);
          yield this.handleEvents();
        }
      });
      function eventHandler(_x20) {
        return _eventHandler.apply(this, arguments);
      }
      return eventHandler;
    }()
  }, {
    key: "handleEventsForEntity",
    value: function () {
      var _handleEventsForEntity = (0, _asyncToGenerator2.default)(function* (entityId, events) {
        var _this3 = this;
        var ent;
        if (this.config == null) {
          throw new Error('no config');
        }
        var start = Date.now();
        var beforeSave = 0;
        var afterSave = 0;
        var newValue = false;
        try {
          if (Array.isArray(entityId)) ent = yield this.get({
            filter: `id in ${JSON.stringify(entityId)}`
          });else ent = yield this.getById(entityId);
        } catch (err) {
          ent = undefined;
          newValue = true;
        }
        if (Array.isArray(ent) && ent.length === 1) {
          ent = ent[0];
        } else if (Array.isArray(ent) && ent.length === 0) {
          newValue = true;
          ent = undefined;
        }
        var oldEntity = Array.isArray(ent) ? (0, _toConsumableArray2.default)(ent) : Object.assign({}, ent);
        ent = yield this.handleEventsOnEntity(ent, events);
        try {
          if (ent != null) {
            beforeSave = Date.now() - start;
            ent = yield this.saveEntity(ent, newValue);
            afterSave = Date.now() - start;
            if (Array.isArray(ent)) {
              var _loop = function* _loop(i) {
                if (oldEntity != null && Array.isArray(oldEntity)) {
                  var old = oldEntity.find(function (a) {
                    return ent != null && a.id === ent[i].id;
                  });
                  yield _this3.afterSave(ent[i], old);
                } else {
                  yield _this3.afterSave(ent[i], undefined);
                }
                yield _this3.dispatchChangedEvent(events, ent[i]);
              };
              for (var i = 0; i < ent.length; i += 1) {
                yield* _loop(i);
              }
            } else {
              yield this.afterSave(ent, oldEntity != null ? oldEntity : undefined);
              yield this.dispatchChangedEvent(events, ent);
            }
          }
          if (Array.isArray(ent)) {
            for (var entSingle of ent) {
              yield this.afterDispatch(entSingle);
            }
          } else yield this.afterDispatch(ent);
        } catch (err2) {}
        this.lastTimeUpdate = Date.now() - start;
        if (this.lastTimeUpdate > 1000) {}
      });
      function handleEventsForEntity(_x21, _x22) {
        return _handleEventsForEntity.apply(this, arguments);
      }
      return handleEventsForEntity;
    }()
  }, {
    key: "handleEventsOnEntity",
    value: function () {
      var _handleEventsOnEntity = (0, _asyncToGenerator2.default)(function* (entity, events) {
        var ent = entity;
        for (var e of events) {
          if (typeof this[`on${e.eventName}`] === 'function') {
            try {
              ent = yield this[`on${e.eventName}`](e, ent);
            } catch (err) {
              err['event'] = e;
              ErrorReporter_1.ErrorReporter.sendReport({
                data: err,
                subject: 'Can not handle Event',
                type: 'error'
              });
              ent = undefined;
            }
          } else {
            ent = yield this.defaultEventHandler(e, ent);
          }
        }
        return ent;
      });
      function handleEventsOnEntity(_x23, _x24) {
        return _handleEventsOnEntity.apply(this, arguments);
      }
      return handleEventsOnEntity;
    }()
  }, {
    key: "defaultEventHandler",
    value: function () {
      var _defaultEventHandler = (0, _asyncToGenerator2.default)(function* (e, ent) {
        if (ent != null && Array.isArray(ent)) {
          var entities = [];
          for (var en of ent) {
            entities.push(yield this.defaultEventHandlerOnEntity(e, en));
          }
          return entities;
        }
        return this.defaultEventHandlerOnEntity(e, ent);
      });
      function defaultEventHandler(_x25, _x26) {
        return _defaultEventHandler.apply(this, arguments);
      }
      return defaultEventHandler;
    }()
  }, {
    key: "defaultEventHandlerOnEntity",
    value: function () {
      var _defaultEventHandlerOnEntity = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = ent != null ? ent : this.exampleEntity.createOne({
          id: e.entityId,
          createdAt: e.createdAt
        });
        entity.fill(Object.assign({}, ent, e.data));
        if (e.createdBy != null && e.createdBy.length > 6) {
          if (entity.createdBy == null || entity.createdBy.length <= 6) entity.createdBy = e.createdBy;
          entity.lastModifiedBy = e.createdBy;
        }
        var createdAt = OdataEntity_1.OdataEntity.dateReviver('', e.createdAt);
        var lastModifiedAt = entity.lastModifiedAt ? OdataEntity_1.OdataEntity.dateReviver('', entity.lastModifiedAt) : undefined;
        if (lastModifiedAt == null || createdAt.getTime() > lastModifiedAt.getTime()) {
          entity.lastModifiedAt = createdAt;
        } else {
          entity.lastModifiedAt.setMilliseconds(lastModifiedAt.getMilliseconds() + 1);
        }
        return entity;
      });
      function defaultEventHandlerOnEntity(_x27, _x28) {
        return _defaultEventHandlerOnEntity.apply(this, arguments);
      }
      return defaultEventHandlerOnEntity;
    }()
  }, {
    key: "getIdentifierValue",
    value: function getIdentifierValue(keys, object) {
      var value;
      var firstKey = keys.shift();
      if (firstKey != null) {
        value = object[firstKey];
        if (keys.length > 0) value = this.getIdentifierValue((0, _toConsumableArray2.default)(keys), value);
      }
      return value;
    }
  }, {
    key: "getEntityLocksForEvent",
    value: function () {
      var _getEntityLocksForEvent = (0, _asyncToGenerator2.default)(function* (event) {
        var locksForEntityIds = new Set();
        locksForEntityIds.add(event.entityId);
        var onEvent = this.onEvents.find(function (e) {
          return e.eventName === event.eventName;
        });
        if (onEvent != null) {
          var identifiers = onEvent.identifier.split('/');
          var value = this.getIdentifierValue((0, _toConsumableArray2.default)(identifiers), event);
          if (onEvent.affect === 'one' && value) {
            if (!locksForEntityIds.has(value)) locksForEntityIds.add(value);
          } else if (onEvent.affect === 'many' && onEvent.manyIdentifier != null) {
            var get = yield this.config.repo.get({
              select: 'id',
              filter: `${onEvent.manyIdentifier} eq '${value}'`
            });
            get.forEach(function (ent) {
              if (!locksForEntityIds.has(ent.id)) locksForEntityIds.add(ent.id);
            });
          }
        }
        return locksForEntityIds;
      });
      function getEntityLocksForEvent(_x29) {
        return _getEntityLocksForEvent.apply(this, arguments);
      }
      return getEntityLocksForEvent;
    }()
  }, {
    key: "handleEventsNow",
    value: function () {
      var _handleEventsNow = (0, _asyncToGenerator2.default)(function* () {
        var _this4 = this;
        if (!this.checkLock && this.eventsToHandle.length > 0) {
          this.checkLock = true;
          var eFirst = this.eventsToHandle[0];
          var getEntityLocks = Array.from(yield this.getEntityLocksForEvent(eFirst[0]));
          var locked = this.handlerForIdRunnung.size > 0;
          if (locked && this.canHandleParallelEntity) {
            locked = false;
            var i = 0;
            while (!locked && i < getEntityLocks.length) {
              locked = this.handlerForIdRunnung.has(getEntityLocks[i]);
              i += 1;
            }
          }
          if (!locked) {
            var e = this.eventsToHandle.shift();
            if (e != null) {
              getEntityLocks.forEach(function (id) {
                _this4.handlerForIdRunnung.add(id);
              });
              this.checkLock = false;
              try {
                yield this.handleEventsForEntity(getEntityLocks.length === 1 ? getEntityLocks[0] : getEntityLocks, e);
              } catch (e) {}
              getEntityLocks.forEach(function (id) {
                _this4.handlerForIdRunnung.delete(id);
              });
            }
            if (this.eventsToHandle.length > 0) {
              this.handleEvents().catch(function (err) {
                return void 0;
              });
            }
          } else {
            this.checkLock = false;
            if (this.eventHandlerTimer) {
              clearTimeout(this.eventHandlerTimer);
            }
            this.eventHandlerTimer = setTimeout(function () {
              _this4.handleEvents().catch(function (err) {
                return void 0;
              });
            }, 16);
          }
        }
      });
      function handleEventsNow() {
        return _handleEventsNow.apply(this, arguments);
      }
      return handleEventsNow;
    }()
  }]);
  return ReadModelRoot;
}(ReadModelBase_1.ReadModelBase);
exports.ReadModelRoot = ReadModelRoot;