"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WaitFor = void 0;
var WaitFor = function () {
  function WaitFor() {
    (0, _classCallCheck2.default)(this, WaitFor);
  }
  (0, _createClass2.default)(WaitFor, [{
    key: "waitFor",
    value: function () {
      var _waitFor = (0, _asyncToGenerator2.default)(function* (causa) {
        var intervall = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 33;
        var timeOut = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 60000;
        var starTime = Date.now();
        var returner = true;
        while (!causa()) {
          yield new Promise(function (resolve) {
            setTimeout(function () {
              resolve();
            }, intervall);
          });
          if (Date.now() - starTime > timeOut) {
            returner = false;
            break;
          }
        }
        return returner;
      });
      function waitFor(_x) {
        return _waitFor.apply(this, arguments);
      }
      return waitFor;
    }()
  }], [{
    key: "instance",
    get: function get() {
      if (WaitFor._instance == null) {
        WaitFor._instance = new WaitFor();
      }
      return WaitFor._instance;
    }
  }]);
  return WaitFor;
}();
exports.WaitFor = WaitFor;