"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LoginWithToken = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var LoggedIn_1 = require("../../events/guest/LoggedIn");
var LoginWithToken = function (_CommandRoot_1$Comman) {
  (0, _inherits2.default)(LoginWithToken, _CommandRoot_1$Comman);
  var _super = _createSuper(LoginWithToken);
  function LoginWithToken(data) {
    var _this;
    (0, _classCallCheck2.default)(this, LoginWithToken);
    _this = _super.call(this, data, '', '');
    _this.aggregate = 'guests';
    _this.commandName = 'LoginWithToken';
    return _this;
  }
  (0, _createClass2.default)(LoginWithToken, [{
    key: "result",
    get: function get() {
      return this._result;
    },
    set: function set(data) {
      this._result = data;
    }
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        if (key == null || key === 'token') {
          if (this.data.token.length <= 0) {
            throw {
              key: 'token',
              messageCode: 'loginErrorPasswordRequired',
              message: 'token wird benötigt'
            };
          }
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return new LoggedIn_1.LoggedIn(this.entityId, {
          token: '',
          userId: ''
        });
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }]);
  return LoginWithToken;
}(CommandRoot_1.CommandRoot);
exports.LoginWithToken = LoginWithToken;