import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Form } from 'materialTheme/src/theme/components/forminput/Form';
import React, { PureComponent } from 'react';
import { AddUpmeshPartner } from 'upmesh-support-core/src/commands/partner/AddUpmeshPartner';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { ConnectionHandler } from '../../ConnectionHandler';
export class AddPartnerDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.close = () => {
            Dialog.instance?.close();
        };
        this.catchError = (e) => {
            Routing.instance.alert.post({
                text: `error: ${JSON.stringify(e)}`,
            });
            return true;
        };
        this.onSaved = () => {
            this.close();
        };
        this.state = {
            error: '',
        };
    }
    render() {
        const dataOptions = new Map();
        dataOptions.set('company', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Firma',
                autoFocus: true,
            },
        });
        dataOptions.set('contactName', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Kontaktname',
            },
        });
        dataOptions.set('email', {
            formType: 'email',
            cols: 1,
            props: {
                labelText: 'E-Mail Adresse',
            },
        });
        dataOptions.set('description', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Beschreibung',
            },
        });
        dataOptions.set('street', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Straße',
            },
        });
        dataOptions.set('streetNr', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Hausnummer',
            },
        });
        dataOptions.set('zip', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'PLZ',
            },
        });
        dataOptions.set('city', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Stadt',
            },
        });
        dataOptions.set('country', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Land',
            },
        });
        dataOptions.set('phone', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Telefon',
            },
        });
        return (<Form baseCols={1} catchError={this.catchError} dataOptions={dataOptions} formHeaderProps={{
                formTitle: 'Partner hinzufügen',
            }} store={ConnectionHandler.getStore()} command={new AddUpmeshPartner({}, ConnectionHandler.serverConnection.token)} additionalButtons={[
                <ContainedButton key="abort" onPress={this.close} title="abbrechen" backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
            ]} onSaved={this.onSaved} saveButtonLabel="erstellen" stickyHeader stickyActions serverConnection={ConnectionHandler.getConnection()}/>);
    }
}
