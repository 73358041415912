var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DialogActions = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _ThemeManager = require("../../ThemeManager");
var _ButtonGroup = require("../button/ButtonGroup");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var DialogActions = exports.DialogActions = function (_PureComponent) {
  (0, _inherits2.default)(DialogActions, _PureComponent);
  var _super = _createSuper(DialogActions);
  function DialogActions() {
    (0, _classCallCheck2.default)(this, DialogActions);
    return _super.apply(this, arguments);
  }
  (0, _createClass2.default)(DialogActions, [{
    key: "render",
    value: function render() {
      var _this$props = this.props,
        onBottom = _this$props.onBottom,
        marginRight = _this$props.marginRight;
      if (onBottom) {
        return [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            opacity: 0
          },
          children: (0, _jsxRuntime.jsx)(_ButtonGroup.ButtonGroup, {
            align: "flex-end",
            alignSelf: "flex-end",
            marginRight: marginRight,
            children: this.props.children
          })
        }, "1"), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            position: 'absolute',
            opacity: 1,
            bottom: 0,
            right: 0,
            width: '100%'
          },
          children: (0, _jsxRuntime.jsx)(_ButtonGroup.ButtonGroup, {
            align: "flex-end",
            alignSelf: "flex-end",
            marginRight: marginRight,
            children: this.props.children
          })
        }, "2")];
      }
      return (0, _jsxRuntime.jsx)(_ButtonGroup.ButtonGroup, {
        align: "flex-end",
        alignSelf: "flex-end",
        marginRight: marginRight,
        children: this.props.children
      });
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        onBottom: false,
        children: [],
        marginRight: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(8)
      };
    }
  }]);
  return DialogActions;
}(_react.PureComponent);