var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _constants = require("./constants");
var SizeAndPositionManager = exports.default = function () {
  function SizeAndPositionManager(_ref) {
    var itemCount = _ref.itemCount,
      itemSize = _ref.itemSize,
      numColumns = _ref.numColumns,
      direction = _ref.direction;
    (0, _classCallCheck2.default)(this, SizeAndPositionManager);
    this.direction = direction;
    this.itemSize = itemSize;
    this.itemCount = itemCount;
    this.numColumns = numColumns;
    this.itemSizeAndPositionData = {};
    this.lastMeasuredIndex = -1;
  }
  (0, _createClass2.default)(SizeAndPositionManager, [{
    key: "findNearestItem",
    value: function findNearestItem(offsetProp) {
      if (Number.isNaN(offsetProp)) {
        throw Error(`Invalid offset ${offsetProp} specified`);
      }
      var offset = offsetProp;
      offset = Math.max(0, offset);
      var lastMeasuredSizeAndPosition = this.getSizeAndPositionOfLastMeasuredItem();
      var lastMeasuredIndex = Math.max(0, this.lastMeasuredIndex);
      if (lastMeasuredSizeAndPosition.offset >= offset) {
        return this.binarySearch({
          offset: offset,
          high: lastMeasuredIndex,
          low: 0
        });
      }
      return this.exponentialSearch({
        offset: offset,
        index: lastMeasuredIndex
      });
    }
  }, {
    key: "getSizeAndPositionForIndex",
    value: function getSizeAndPositionForIndex(index) {
      if (index < 0 || index >= this.itemCount) {
        throw Error(`Requested index ${index} is outside of range 0..${this.itemCount}`);
      }
      var offset = Math.floor(index / this.numColumns) * this.itemSize[_constants.sizeProp[this.direction]];
      var offset2 = index % this.numColumns * this.itemSize[_constants.sizePropInVert[this.direction]];
      return {
        offset: offset,
        offset2: offset2,
        size: this.itemSize[_constants.sizeProp[this.direction]]
      };
    }
  }, {
    key: "getSizeAndPositionOfLastMeasuredItem",
    value: function getSizeAndPositionOfLastMeasuredItem() {
      return this.lastMeasuredIndex >= 0 ? this.itemSizeAndPositionData[this.lastMeasuredIndex] : {
        offset: 0,
        size: 0
      };
    }
  }, {
    key: "getTotalSize",
    value: function getTotalSize() {
      return Math.ceil(this.itemCount / this.numColumns) * this.itemSize[_constants.sizeProp[this.direction]];
    }
  }, {
    key: "getUpdatedOffsetForIndex",
    value: function getUpdatedOffsetForIndex(_ref2) {
      var _ref2$align = _ref2.align,
        align = _ref2$align === void 0 ? _constants.ALIGN_START : _ref2$align,
        containerSize = _ref2.containerSize,
        currentOffset = _ref2.currentOffset,
        targetIndex = _ref2.targetIndex;
      if (containerSize <= 0) {
        return 0;
      }
      var datum = this.getSizeAndPositionForIndex(targetIndex);
      var maxOffset = datum.offset;
      var minOffset = maxOffset - containerSize + datum.size;
      var idealOffset;
      switch (align) {
        case _constants.ALIGN_END:
          idealOffset = minOffset;
          break;
        case _constants.ALIGN_CENTER:
          idealOffset = maxOffset - (containerSize - datum.size) / 2;
          break;
        case _constants.ALIGN_START:
          idealOffset = maxOffset;
          break;
        default:
          idealOffset = Math.max(minOffset, Math.min(maxOffset, currentOffset));
      }
      var totalSize = this.getTotalSize();
      return Math.max(0, Math.min(totalSize - containerSize, idealOffset));
    }
  }, {
    key: "getVisibleRange",
    value: function getVisibleRange(_ref3) {
      var containerSize = _ref3.containerSize,
        offset = _ref3.offset,
        overscanCount = _ref3.overscanCount;
      var totalSize = this.getTotalSize();
      var newOffset = offset;
      if (totalSize === 0) {
        return {};
      }
      var start = this.findNearestItem(newOffset);
      if (typeof start === 'undefined') {
        throw Error(`Invalid offset ${newOffset} specified`);
      }
      var datum = this.getSizeAndPositionForIndex(start);
      newOffset = datum.offset + datum.size;
      var stop = start + Math.ceil(containerSize / this.itemSize[_constants.sizeProp[this.direction]]) * this.numColumns;
      if (overscanCount != null) {
        start = Math.max(0, start - overscanCount);
        stop = Math.min(stop + overscanCount, this.itemCount - 1);
      }
      return {
        start: start,
        stop: stop
      };
    }
  }, {
    key: "resetItem",
    value: function resetItem(index) {
      this.lastMeasuredIndex = Math.min(this.lastMeasuredIndex, index - 1);
    }
  }, {
    key: "updateConfig",
    value: function updateConfig(_ref4) {
      var itemCount = _ref4.itemCount,
        itemSize = _ref4.itemSize;
      this.itemCount = itemCount;
      this.itemSize = itemSize;
    }
  }, {
    key: "binarySearch",
    value: function binarySearch(_ref5) {
      var low = _ref5.low,
        high = _ref5.high,
        offset = _ref5.offset;
      var middle = 0;
      var currentOffset = 0;
      var newLow = low;
      var newHigh = high;
      while (newLow <= newHigh) {
        middle = newLow + Math.floor((newHigh - newLow) / 2);
        currentOffset = this.getSizeAndPositionForIndex(middle).offset;
        if (currentOffset === offset) {
          return middle;
        }
        if (currentOffset < offset) {
          newLow = middle + 1;
        } else if (currentOffset > offset) {
          newHigh = middle - 1;
        }
      }
      if (newLow > 0) {
        return newLow - 1;
      }
      return 0;
    }
  }, {
    key: "exponentialSearch",
    value: function exponentialSearch(_ref6) {
      var index = _ref6.index,
        offset = _ref6.offset;
      var interval = 1;
      var newIndex = index;
      while (newIndex < this.itemCount && this.getSizeAndPositionForIndex(newIndex).offset < offset) {
        newIndex += interval;
        interval *= 2;
      }
      return this.binarySearch({
        offset: offset,
        high: Math.min(newIndex, this.itemCount - 1),
        low: Math.floor(newIndex / 2)
      });
    }
  }]);
  return SizeAndPositionManager;
}();