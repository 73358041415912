import * as _ from 'lodash';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { I18n } from '../i18n/I18n';
import { Page } from '../Page';
import { openpaymentDialog } from './payments/PaymentAddOrChangeDialog';
import { PaymentTable } from './payments/PaymentTable';
export class PaymentView extends PureComponent {
    constructor(props, context) {
        super(props, context);
        this.mounted = false;
        this.openRow = (s) => {
            const { id } = s.to;
            if (s.to.type === 'company') {
                Routing.instance.openDialog('company', { id })(null);
            }
            else {
                Routing.instance.openDialog('partner', { id })(null);
            }
        };
        this.setFilter = _.debounce((text) => {
            Routing.instance.changeQueryParameter({ search: text }, true);
        }, 300);
        this.state = {};
    }
    componentDidMount() {
        this.mounted = true;
    }
    componentWillUnmount() {
        this.mounted = false;
    }
    render() {
        return (<Page pageTitle={`${I18n.m.getMessage('payments')}`}>
        <View style={{
                width: '100%',
                marginTop: 4,
                position: 'relative',
            }}>
          <Card style={{ width: '100%', height: 'auto' }}>
            <PaymentTable showSum tableName="allPayments" height={ResizeEvent.current.contentHeight - 128} onRowPress={this.openRow}/>
          </Card>
          <View style={{ position: 'absolute', right: 16, bottom: 24 }}>
            <Icon onPress={openpaymentDialog()} icon="plus" color="#FFFFFF" backgroundColor={ThemeManager.style.brandPrimary} radius={24} outerSize={48} toolTip="add"/>
          </View>
        </View>
      </Page>);
    }
}
