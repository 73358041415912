"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddCompany = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var EmailValidator = require("email-validator");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var CompanyCreated_1 = require("upmesh-core/build/src/server/events/companies/CompanyCreated");
var uuid = require("uuid");
var AddCompany = function (_CommandRoot_1$Comman) {
  (0, _inherits2.default)(AddCompany, _CommandRoot_1$Comman);
  var _super = _createSuper(AddCompany);
  function AddCompany(privatedata, token) {
    var _this;
    var entityId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : uuid.v1();
    (0, _classCallCheck2.default)(this, AddCompany);
    _this = _super.call(this, privatedata, entityId, token);
    _this.aggregate = 'company';
    _this.commandName = 'AddCompany';
    return _this;
  }
  (0, _createClass2.default)(AddCompany, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') {
          return true;
        }
        var partner = ReadModels_1.ReadModels.get('UpmeshPartner');
        var partners = yield partner.get({
          filter: `users/userId eq '${userId}'`
        });
        if (partners.length > 0) {
          this.data.partnerId = partners[0].id;
          if (partners[0].hasRight('canManageCompanies', userId)) {
            return true;
          }
        }
        var admin = ReadModels_1.ReadModels.get('SupportRoles');
        var admins = yield admin.get({
          filter: `userId eq '${userId}' and deleted ne true`
        });
        if (admins.length > 0) {
          for (var adm of admins) {
            if (adm.role === 'Admin') {
              return true;
            }
          }
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "clone",
    value: function clone(newData, entityId, token) {
      return new AddCompany(newData, token, entityId);
    }
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var createCompanyEvent = new CompanyCreated_1.CompanyCreated(this.entityId, Object.assign({}, this.data));
        return [createCompanyEvent];
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        var errors = [];
        if (key == null || key === 'company') {
          if (this.data.company == null || this.data.company.length === 0) {
            errors.push({
              name: 'company',
              key: 'company',
              message: 'Firma wird benötigt',
              messageCode: 'companyIsRequired'
            });
          }
        }
        if (key == null || key === 'city') {
          if (this.data.city == null || this.data.city.length <= 0) {
            errors.push({
              name: 'city',
              key: 'city',
              message: 'Stadt wird benötigt',
              messageCode: 'cityIsRequired'
            });
          }
        }
        if (key == null || key === 'country') {
          if (this.data.country == null || this.data.country.length <= 0) {
            errors.push({
              name: 'country',
              key: 'country',
              message: 'Land wird benötigt',
              messageCode: 'countryIsRequired'
            });
          }
        }
        if (key == null || key === 'email') {
          if (this.data.email == null || this.data.email.length <= 0) {
            errors.push({
              name: 'email',
              key: 'email',
              message: 'E-Mail Adresse wird benötigt',
              messageCode: 'emailIsRequired'
            });
          } else if (!EmailValidator.validate(this.data.email)) {
            errors.push({
              name: 'email',
              key: 'email',
              message: 'keine gültige E-Mail Adresse',
              messageCode: 'emailValidationError'
            });
          }
        }
        if (key == null || key === 'phone') {
          if (this.data.phone == null || this.data.phone.length <= 0) {
            errors.push({
              name: 'phone',
              key: 'phone',
              message: 'Telefon wird benötigt',
              messageCode: 'phoneIsRequired'
            });
          }
        }
        if (key == null || key === 'street') {
          if (this.data.street == null || this.data.street.length <= 0) {
            errors.push({
              name: 'street',
              key: 'street',
              message: 'Straße wird benötigt',
              messageCode: 'streetIsRequired'
            });
          }
        }
        if (key == null || key === 'streetNr') {
          if (this.data.streetNr == null || this.data.streetNr.length <= 0) {
            errors.push({
              name: 'streetNr',
              key: 'streetNr',
              message: 'Hausnummer wird benötigt',
              messageCode: 'streetNrIsRequired'
            });
          }
        }
        if (key == null || key === 'zip') {
          if (this.data.zip == null || this.data.zip.length <= 0) {
            errors.push({
              name: 'zip',
              key: 'zip',
              message: 'PLZ wird benötigt',
              messageCode: 'zipIsRequired'
            });
          }
        }
        if (errors.length > 0) throw errors;
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
  return AddCompany;
}(CommandRoot_1.CommandRoot);
exports.AddCompany = AddCompany;