import { SegmentedButton } from 'materialTheme/src/theme/components/button/SegmentedButton';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { DefaultErrorHandler } from 'upmesh-client/src/components/DefaultErrorHandler';
import { ConnectionHandler } from '../../ConnectionHandler';
const bytes = require('bytes');
export function CompanyProjects(props) {
    const [activeSegment, setActiveSegment] = useState(0);
    const [projects, setProjects] = useState([]);
    const updateData = () => {
        const client = ConnectionHandler.getClient();
        if (client == null)
            return;
        const f = activeSegment === 0
            ? `and deleted ne true and archived ne true and locked ne true`
            : `and (deleted eq true or archived eq true or locked eq true)`;
        client.project
            .get({
            select: ['id', 'title', 'storageUsageInBytes', 'creator', 'createdAt', 'archived', 'locked', 'deleted'],
            filter: `creator eq 'company${props.company.id}' ${f}`,
        })
            .then((p) => {
            const projects = [];
            p.forEach((project) => {
                const p2 = project;
                p2.roleName = project.creator === `company${props.company.id}` ? 'Eigentümer' : '?';
                projects.push(p2);
            });
            setProjects(projects);
        })
            .catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
    };
    useEffect(() => {
        updateData();
    }, [activeSegment]);
    const openProject = (item) => {
        const id = item.title !== 'Willkommens-Projekt 👋' ? item.id : '';
        if (id.length > 0)
            Routing.instance.openDialog('project', { id })(null);
    };
    return (<View style={{ width: '100%' }}>
      <SegmentedButton multiselect={false} buttons={[{ title: 'aktive' }, { title: 'inaktive' }]} singleSelectSelected={activeSegment} onPress={(selected) => {
            setActiveSegment(selected);
        }}/>
      {projects != null && projects.length === 0 ? (<View style={{ padding: 16, width: '100%' }}>
          <MaterialText fixedWidth="100%" textAlign="center">
            Noch keine Projekte vorhanden
          </MaterialText>
        </View>) : (<Table data={projects} tableName="companyProjects" actionItemsLength={0} onRowPress={openProject} columns={[
                { title: 'Titel', dataType: 'string', keyInData: 'title', style: { minWidth: 200 } },
                { title: 'Rolle', dataType: 'string', keyInData: 'roleName', style: { minWidth: 200 } },
                {
                    title: 'Größe',
                    dataType: 'number',
                    keyInData: 'storageUsageInBytes',
                    style: { minWidth: 200 },
                    cellRenderer: (item, _column, index) => {
                        const storageUsageInBytes = item.storageUsageInBytes != null && item.storageUsageInBytes > 0
                            ? bytes(item.storageUsageInBytes)
                            : bytes(0);
                        return (<View key={`cell-fileSize-${item.id}-${index}`} style={{
                                flex: 1,
                                justifyContent: 'flex-end',
                                flexDirection: 'row',
                                height: '100%',
                                paddingHorizontal: 16,
                                minWidth: 200,
                            }}>
                    <MaterialText centeredBox>{storageUsageInBytes}</MaterialText>
                  </View>);
                    },
                },
                { title: 'Erstellt', dataType: 'Date', keyInData: 'createdAt', style: { minWidth: 200 } },
                {
                    title: 'Status',
                    dataType: 'string',
                    keyInData: 'deleted',
                    sortable: false,
                    style: { minWidth: 200 },
                    cellRenderer: (item, _column, index) => {
                        const status = item.deleted ? 'gelöscht' : item.archived ? 'archiviert' : item.locked ? 'gesperrt' : 'aktiv';
                        return (<View key={`cell-fileSize-${item.id}-${index}`} style={{
                                flex: 1,
                                justifyContent: 'flex-end',
                                flexDirection: 'row',
                                height: '100%',
                                paddingHorizontal: 16,
                                minWidth: 200,
                            }}>
                    <MaterialText centeredBox>{status}</MaterialText>
                  </View>);
                    },
                },
            ]} maxHeight={props.height - 38}/>)}
    </View>);
}
