import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Picker } from 'materialTheme/src/theme/components/Picker';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { TabBar } from 'materialTheme/src/theme/components/tabs/TabBar';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { ScrollView, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { DefaultErrorHandler } from 'upmesh-client/src/components/DefaultErrorHandler';
import { AddSupportRole } from 'upmesh-support-core/src/commands/AddSupportRole';
import { RemoveSupportRole } from 'upmesh-support-core/src/commands/RemoveSupportRole';
import { DeleteAccount } from 'upmesh-support-core/src/commands/user/DeleteAccount';
import { DeleteAccountFromServer } from 'upmesh-support-core/src/commands/user/DeleteAccountFromServer';
import { ReactivateAccount } from 'upmesh-support-core/src/commands/user/ReactivateAccount';
import { ConnectionHandler } from '../../ConnectionHandler';
import { I18n } from '../../i18n/I18n';
import { UserNotes } from './UserNotes';
export class UserDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.openCompany = (id) => (e) => {
            Routing.instance.openDialog('company', { id })(e);
        };
        this.openProject = (id) => (e) => {
            if (id.length > 0)
                Routing.instance.openDialog('project', { id })(e);
        };
        this.prepareDeleteAccount = () => {
            Routing.instance.alert.post({
                text: 'Account wirklich zum Löschen vormerken?',
                buttons: [
                    <ContainedButton key="okButton" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                    <ContainedButton key="reactivate" title="Account löschen" onPress={() => this.prepareDeleteNow(false)} backgroundColor={ThemeManager.style.brandDanger}/>,
                ],
            });
        };
        this.prepareDeleteNow = (force = false) => {
            LoadingEvents.instance.startLoading();
            Alert.instance?.close(() => {
                const { user } = this.state;
                const token = ConnectionHandler.getConnection()?.token;
                if (user == null || token == null)
                    return null;
                const r = new DeleteAccount({ force }, user.id, token);
                const asyncNow = async () => {
                    try {
                        await r.execute(ConnectionHandler.getStore());
                        await this.init();
                    }
                    catch (e) {
                        if (!force) {
                            const text = DefaultErrorHandler.getDefaultErrorMessages(e, I18n.m, false);
                            const buttons = [
                                <ContainedButton key="cancelButton" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                                <ContainedButton key="forceNow" title="löschen erzwingen" onPress={() => {
                                        this.prepareDeleteNow(true);
                                    }} backgroundColor={ThemeManager.style.brandDanger}/>,
                            ];
                            Routing.instance.alert.post({ text, buttons });
                        }
                        else {
                            DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m);
                        }
                    }
                    LoadingEvents.instance.stopLoading();
                    return null;
                };
                asyncNow().catch((err) => console.error(err));
                return null;
            });
        };
        this.deleteAccount = () => {
            Routing.instance.alert.post({
                text: 'Account sofort löschen?',
                buttons: [
                    <ContainedButton key="okButton" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                    <ContainedButton key="reactivate" title="Jetzt löschen" onPress={this.deleteNow} backgroundColor={ThemeManager.style.brandDanger}/>,
                ],
            });
        };
        this.deleteNow = () => {
            LoadingEvents.instance.startLoading();
            Alert.instance?.close(() => {
                const { user } = this.state;
                const token = ConnectionHandler.getConnection()?.token;
                if (user == null || token == null)
                    return null;
                const r = new DeleteAccountFromServer({}, user.id, token);
                const asyncNow = async () => {
                    try {
                        await r.execute(ConnectionHandler.getStore());
                        await this.init();
                    }
                    catch (e) {
                        DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m);
                    }
                    LoadingEvents.instance.stopLoading();
                    return null;
                };
                asyncNow().catch((err) => console.error(err));
                return null;
            });
        };
        this.reactivateAccount = () => {
            Routing.instance.alert.post({
                text: 'Account reaktivieren?',
                buttons: [
                    <ContainedButton key="okButton" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                    <ContainedButton key="reactivate" title="wiederherstellen" onPress={this.reactivateNow}/>,
                ],
            });
        };
        this.reactivateNow = () => {
            LoadingEvents.instance.startLoading();
            Alert.instance?.close(() => {
                const { user } = this.state;
                const token = ConnectionHandler.getConnection()?.token;
                if (user == null || token == null)
                    return null;
                const asyncNow = async () => {
                    const r = new ReactivateAccount({}, user.id, token);
                    try {
                        await r.execute(ConnectionHandler.getStore());
                        await this.init();
                    }
                    catch (e) {
                        DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m);
                    }
                    LoadingEvents.instance.stopLoading();
                    return null;
                };
                asyncNow().catch((err) => console.error(err));
                return null;
            });
        };
        this.genToken = () => {
            if (this.props.data != null && this.props.data.id != null) {
                const { id } = this.props.data;
                this.getToken(id).catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
            }
        };
        this.activitiesLoading = false;
        this.activitiesTO = null;
        this.onScroll = (e) => {
            if (this.state.currentTab !== 2)
                return;
            try {
                const endReached = e.nativeEvent.contentSize.height - e.nativeEvent.contentOffset.y - e.nativeEvent.layoutMeasurement.height <=
                    100;
                if (endReached) {
                    this.getNextActivities().catch((err) => console.debug('cant get next activities', err));
                }
            }
            catch (err) {
                console.debug('cant get end Reached', err);
            }
        };
        this.onChangeTab = (e) => {
            Routing.instance.changeQueryParameter({ data: { id: this.props.data.id, tab: e.toString() } }, true);
            this.setState({ currentTab: e });
        };
        this.addRole = async () => {
            const { currentRoleSelected } = this.state;
            const { user } = this.state;
            if (user == null)
                return;
            if (currentRoleSelected === 1 || currentRoleSelected === 2) {
                LoadingEvents.instance.startLoading();
                const role = currentRoleSelected === 2 ? 'Support' : 'Admin';
                const c = new AddSupportRole({ role, userId: user.id }, ConnectionHandler.getConnection().token);
                try {
                    await c.execute(ConnectionHandler.getStore());
                }
                catch (e) {
                    LoadingEvents.instance.stopLoading();
                    Routing.instance.alert.post({ text: e['message'] != null ? e['message'] : e.toString() });
                    return;
                }
                await new Promise((res) => {
                    setTimeout(() => {
                        const asyncNow = async () => {
                            const r = await ConnectionHandler.getClient()?.roles.getById(c.entityId);
                            if (r != null) {
                                await this.init();
                                LoadingEvents.instance.stopLoading();
                                res();
                            }
                        };
                        asyncNow().catch((err) => console.error(err));
                    }, 300);
                });
            }
            else {
                Routing.instance.alert.post({ text: 'Bitte erst eine Rolle wählen!' });
            }
        };
        this.changeRole = (index) => {
            this.setState({ currentRoleSelected: index });
        };
        this.close = (e) => {
            Routing.instance.closeDialog()(e);
        };
        this.removeRole = async (role) => {
            LoadingEvents.instance.startLoading();
            const removeRole = new RemoveSupportRole(role.id, ConnectionHandler.getConnection().token);
            try {
                await removeRole.execute(ConnectionHandler.getStore());
            }
            catch (e) {
                LoadingEvents.instance.stopLoading();
                Routing.instance.alert.post({ text: e['message'] != null ? e['message'] : e.toString() });
                return;
            }
            await new Promise((res) => {
                setTimeout(() => {
                    const asyncNow = async () => {
                        const r = await ConnectionHandler.getClient()?.roles.getById(role.id);
                        if (r == null || r.deleted) {
                            await this.init();
                            LoadingEvents.instance.stopLoading();
                            res();
                        }
                    };
                    asyncNow().catch((err) => console.error(err));
                }, 300);
            });
        };
        this.openBillwerk = (contractId) => () => {
            if (contractId != null) {
                if (window.location.host.startsWith('control')) {
                    window.open(`https://app.billwerk.com/#/contracts/${contractId}`);
                }
                else {
                    window.open(`https://sandbox.billwerk.com/#/contracts/${contractId}`);
                }
            }
        };
        this.state = {
            currentTab: props.data.tab != null ? Number.parseInt(props.data.tab, 10) : 0,
            user: props.user,
            subscriptions: [],
            mails: [],
            projects: [],
            roles: [],
            logBookEntries: [],
            logBookEntriesFrom: 0,
            logBookEntriesCount: 0,
            currentRoleSelected: 0,
        };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug('cant init UserDialog', err));
    }
    renderCompany() {
        const { company } = this.state;
        if (company == null)
            return null;
        return [
            <MaterialText key="companyLabel" type={MaterialTextTypes.Caption}>
        {I18n.m.getMessage('accountCompany')}
      </MaterialText>,
            <MaterialText onPress={this.openCompany(company.id)}>
        {company.company != null ? company.company : '--'}
      </MaterialText>,
        ];
    }
    async getToken(id) {
        const f = await fetch(`${ConnectionHandler.apiUrl}/supportroles/usert/${id}`, {
            headers: { authorization: `Bearer ${ConnectionHandler.serverConnection.token}` },
        });
        const token = await f.json();
        const url = location.href;
        let appLink = 'https://app.upmesh.de';
        if (url.indexOf('devcontrol.upmesh.de') >= 0) {
            appLink = 'https://devapp.upmesh.de';
        }
        else if (url.indexOf('127.0.0.1') >= 0) {
            appLink = 'http://localhost:8085';
        }
        const loginLink = `${appLink}/login#at=${token['token']}`;
        this.setState({ loginLink });
    }
    renderLoginLink() {
        const { user, loginLink } = this.state;
        if (user == null)
            return null;
        if (ConnectionHandler.myRole?.role === 'Admin') {
            if (!CurrentUser.entity?.emails?.includes('stein@upmesh.de'))
                return null;
            const st = user.status != null && user.status !== 'active' ? user.status : 'active';
            if (st === 'active') {
                let content = (<a target="_blank" href={loginLink} rel="noreferrer">
            {loginLink}
          </a>);
                if (loginLink == null) {
                    content = (<ContainedButton key="tok" title="Login Link generieren" onPress={this.genToken} backgroundColor="#FFFFFF" textColor={ThemeManager.style.brandPrimary}/>);
                }
                return <View style={{ paddingTop: 8, width: '100%' }}>{content}</View>;
            }
        }
        return null;
    }
    renderStatus() {
        const { user } = this.state;
        if (user == null)
            return null;
        const st = user.status != null && user.status !== 'active' ? user.status : 'active';
        if (st === 'active') {
            return [
                <MaterialText key="statusLabel" type={MaterialTextTypes.Caption}>
          Account status
        </MaterialText>,
                <MaterialText key="text">aktiv</MaterialText>,
                <ContainedButton key="loeschen" title="Account löschen" onPress={this.prepareDeleteAccount} backgroundColor={ThemeManager.style.brandPrimary}/>,
            ];
        }
        if (st === 'deleted') {
            return [
                <MaterialText key="statusLabel" type={MaterialTextTypes.Caption}>
          Account status
        </MaterialText>,
                <MaterialText>gelöscht</MaterialText>,
            ];
        }
        const deleteDay = new Date(user.lastModifiedAt);
        deleteDay.setDate(deleteDay.getDate() + 5);
        const deletionInDays = Math.ceil((deleteDay.getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24);
        return [
            <View style={{ height: 8 }}/>,
            <MaterialText key="statusLabel" type={MaterialTextTypes.Caption}>
        Account status
      </MaterialText>,
            <MaterialText color={ThemeManager.style.brandDanger}>
        {st === 'locked' ? 'gesperrt' : `Löschung beantragt, wird gelöscht in ${deletionInDays} Tagen`}
      </MaterialText>,
            <View style={{ flexDirection: 'row' }}>
        <ContainedButton title="Account reaktivieren" onPress={this.reactivateAccount} backgroundColor={ThemeManager.style.brandPrimary}/>
        <View style={{ width: 16 }}/>
        <ContainedButton title="sofort löschen" onPress={this.deleteAccount} backgroundColor={ThemeManager.style.brandDanger}/>
      </View>,
        ];
    }
    async getNextActivities() {
        if (this.activitiesTO != null)
            clearTimeout(this.activitiesTO);
        if (this.activitiesLoading) {
            setTimeout(() => {
                this.getNextActivities().catch((err) => console.debug('cant get next activities', err));
            }, 500);
        }
        else {
            this.activitiesLoading = true;
            try {
                const { user } = this.state;
                if (user == null)
                    return;
                const client = ConnectionHandler.getClient();
                if (client != null) {
                    const { logBookEntriesFrom, logBookEntries } = this.state;
                    const skip = logBookEntriesFrom + 10;
                    const logBookEntriesNext = await client.logBook.get({
                        filter: `userIds/userId eq '${user.id}'`,
                        top: 10,
                        skip,
                        orderby: 'createdAt DESC',
                    });
                    this.setState({
                        logBookEntries: [...logBookEntries, ...logBookEntriesNext],
                        logBookEntriesFrom: skip,
                    }, () => {
                        this.activitiesLoading = false;
                    });
                }
            }
            catch (err) {
                this.activitiesLoading = false;
                console.debug('cant get next activities', err);
            }
        }
    }
    render() {
        const { user, currentTab } = this.state;
        if (user == null)
            return <Spinner />;
        return (<View style={{
                ...ThemeManager.style.absoluteStyle,
            }}>
        <TabBar contentHeight={0} tabs={[
                { title: user.getFullName() },
                { title: I18n.m.getMessage('projects') },
                { title: I18n.m.getMessage('activities') },
                { title: I18n.m.getMessage('notes') },
            ]} width={ResizeEvent.current.windowWidth - 96} onChangedSelected={this.onChangeTab} startSelected={currentTab}/>
        <ScrollView style={{ width: '100%', height: '100%' }} onScroll={this.onScroll}>
          <View style={{
                width: '100%',
                padding: ThemeManager.style.getScreenRelativePixelSize(16),
            }}>
            {currentTab === 0 && (<View style={{
                    width: '100%',
                }}>
                <View style={{
                    width: '100%',
                    flexDirection: 'row',
                }}>
                  <View style={{
                    flex: 1,
                    paddingLeft: ThemeManager.style.getScreenRelativePixelSize(16),
                    paddingTop: 0,
                }}>
                    <MaterialText type={MaterialTextTypes.Caption}>Id: {user.id}</MaterialText>
                    <MaterialText type={MaterialTextTypes.H6}>{`${user.firstname} ${user.lastname}`}</MaterialText>
                    <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('accountCompany')}</MaterialText>
                    <MaterialText>{user.company != null ? user.company : '--'}</MaterialText>
                    <MaterialText type={MaterialTextTypes.Caption}>
                      {I18n.m.getMessage('accountFunctionInCompany')}
                    </MaterialText>
                    <MaterialText>{user.functionInCompany != null ? user.functionInCompany : '--'}</MaterialText>
                    <MaterialText type={MaterialTextTypes.Caption}>Admin Rollen</MaterialText>
                    {this.renderRoles()}
                    {this.renderCompany()}
                    {this.renderStatus()}
                    {this.renderLoginLink()}
                  </View>
                  <View style={{
                    borderRadius: ThemeManager.style.getScreenRelativePixelSize(64),
                    alignItems: 'flex-end',
                    width: 128,
                }}>
                    <UserImage size={128} user={user} backendUrl={ConnectionHandler.apiUrl}/>
                  </View>
                </View>
                {this.renderMails()}
                {this.renderSubcriptions()}
              </View>)}
            {currentTab === 1 && this.renderProjects()}
            {currentTab === 2 && this.renderLogBooks()}
          </View>
        </ScrollView>
        {currentTab === 3 && <UserNotes user={user} height={ResizeEvent.current.windowHeight - 68}/>}
        <View style={{ position: 'absolute', top: 8, right: 8 }}>
          <Icon icon="close" onPress={this.close} toolTip=""/>
        </View>
      </View>);
    }
    async init() {
        const client = ConnectionHandler.getClient();
        const { data } = this.props;
        if (client != null && data != null && data.id != null) {
            const user = await client.user.getById(data.id);
            if (user == null)
                return;
            const currentUser = await client.user.getById(user.id);
            const roles = await client.roles.get({ filter: `userId eq '${user.id}' and deleted ne true` });
            const subscriptions = await client.subscription.get({
                filter: `userId eq '${user.id}' and subscription eq 'pro'`,
            });
            const logBookEntries = await client.logBook.get({
                filter: `userIds/userId eq '${user.id}'`,
                top: 50,
                skip: 0,
                orderby: 'createdAt DESC',
            });
            const logBookEntriesCount = await client.logBook.count(`userIds/userId eq '${user.id}'`);
            const mails = await client.userMails.get({
                filter: `userId eq '${user.id}' and deleted ne true`,
            });
            const memberOnProjects = await client.projectUser.get({
                select: ['projectId', 'roleId'],
                filter: `userId eq '${user.id}' and status eq 'accepted'`,
            });
            let projectFilter = `creator eq '${user.id}'`;
            if (memberOnProjects.length > 0) {
                const projectIds = [];
                memberOnProjects.forEach((pu) => projectIds.push(pu.projectId));
                projectFilter = `creator eq '${user.id}' or (id in ${JSON.stringify(projectIds)})`;
            }
            const projects = await client.project.get({ filter: projectFilter, select: ['title', 'creator', 'locked'] });
            const projectsWithRole = [];
            for (const project of projects) {
                let roleName = project.creator === user.id ? 'Eigentümer' : '?';
                let roleId = '0';
                if (roleName === '?') {
                    const role = memberOnProjects.find((m) => m.projectId === project.id);
                    if (role != null) {
                        try {
                            const r = await client.projectRoles.getById(role.roleId);
                            roleName = I18n.m.getMessage(r.roleName);
                            roleId = r.basedOnRoleId != null ? r.basedOnRoleId : r.id;
                        }
                        catch (e) {
                            roleName = role.roleId;
                            console.debug('cant get project role', e);
                        }
                    }
                }
                let baseRole = I18n.m.getMessage('projectCreator');
                if (roleId === '1') {
                    baseRole = I18n.m.getMessage('projectManager');
                }
                else if (roleId === '2') {
                    baseRole = I18n.m.getMessage('subcontractor');
                }
                else if (roleId === '3') {
                    baseRole = I18n.m.getMessage('companyRoleUser');
                }
                else if (roleId === '4') {
                    baseRole = I18n.m.getMessage('watcher');
                }
                projectsWithRole.push({
                    project,
                    roleName,
                    baseRole,
                });
            }
            const companies = await client.company.get({ filter: `users/userId eq '${user.id}'` });
            const company = companies.length > 0 ? companies[0] : undefined;
            this.setState({
                user: currentUser,
                subscriptions,
                mails,
                projects: projectsWithRole,
                roles,
                logBookEntries,
                company,
                logBookEntriesCount,
            });
        }
    }
    renderMails() {
        const { mails } = this.state;
        const lines = [];
        if (mails.length === 0) {
            return null;
        }
        let i = 0;
        for (const s of mails) {
            i += 1;
            const backgroundColor = i % 2 === 0 ? '#FFFFFF' : '#EEEEEE';
            const sub = s;
            if (sub.deleted !== true) {
                const validated = sub.validated != null && sub.validated;
                const notifications = sub.notifications != null && sub.notifications;
                lines.push(<View style={{
                        backgroundColor,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                    }} key={sub.id}>
            <MaterialText centeredBox>{sub.email}</MaterialText>
            <View style={{ flexDirection: 'row' }}>
              <Icon toolTip={validated ? 'validiert' : 'noch nicht validiert'} icon={validated ? 'email-check' : 'email-alert'}/>
              <Icon toolTip={notifications ? 'Benachrichtigungen' : 'Benachrichtigungen aus'} icon={notifications ? 'bell' : 'bell-off'}/>
            </View>
          </View>);
            }
        }
        return (<View style={{ marginTop: 24 }}>
        <MaterialText type={MaterialTextTypes.H6}>E-Mail Adressen</MaterialText>
        {lines}
      </View>);
    }
    renderProjects() {
        const { projects } = this.state;
        const lines = [];
        if (projects.length === 0) {
            lines.push(<View style={{ backgroundColor: '#EEEEEE', flexDirection: 'row' }} key="keine">
          <MaterialText centeredBox>Keine</MaterialText>
        </View>);
        }
        else {
            let i = 0;
            for (const s of projects) {
                i += 1;
                const backgroundColor = i % 2 === 0 ? '#FFFFFF' : '#EEEEEE';
                const sub = s.project;
                const locked = !!(sub.locked != null && sub.locked);
                lines.push(<View style={{
                        backgroundColor,
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'space-between',
                    }} key={sub.id}>
            <Ripple onPress={this.openProject(sub.id)} style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
              <View style={{ justifyContent: 'flex-start' }}>
                <MaterialText strong>{sub.title}</MaterialText>
                <MaterialText>
                  {s.roleName} | Basis-Rolle: {s.baseRole}
                </MaterialText>
              </View>
              <View style={{ width: 72, justifyContent: 'center', alignItems: 'flex-end' }}>
                <Icon toolTip={locked ? 'entsperren?' : 'sperren?'} icon={locked ? 'lock' : 'lock-open'}/>
              </View>
            </Ripple>
          </View>);
            }
        }
        return (<View style={{ marginTop: 24 }}>
        <MaterialText type={MaterialTextTypes.H6}>Projekte</MaterialText>
        {lines}
      </View>);
    }
    renderRoles() {
        const { roles, currentRoleSelected } = this.state;
        const lines = [];
        if (roles.length === 0) {
            lines.push(<MaterialText key="keineRollen">keine</MaterialText>);
        }
        else {
            let i = 0;
            for (const r of roles) {
                i += 1;
                const backgroundColor = i % 2 === 0 ? '#FFFFFF' : '#EEEEEE';
                const role = r;
                if (role.deleted == null || !role.deleted) {
                    lines.push(<View key={role.id} style={{ backgroundColor, flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
              <MaterialText centeredBox>{role.role.toString()}</MaterialText>
              <Icon toolTip="Rolle entfernen" icon="minus" onPress={() => {
                            this.removeRole(role).catch((err) => console.error(err));
                        }}/>
            </View>);
                }
            }
        }
        lines.push(<View key="newRole" style={{ flexDirection: 'row', marginLeft: -14, alignItems: 'center' }}>
        <Picker list={['Rolle hinzufügen', 'Admin', 'Support']} selectedIndex={currentRoleSelected} onChange={this.changeRole}/>
        <Icon toolTip="Rolle hinzufügen" icon="plus" onPress={() => {
                this.addRole().catch((err) => console.error(err));
            }}/>
      </View>);
        return lines;
    }
    renderSubcriptions() {
        const { subscriptions } = this.state;
        const lines = [];
        if (subscriptions.length === 0)
            return null;
        let i = 0;
        for (const s of subscriptions) {
            i += 1;
            const backgroundColor = i % 2 === 0 ? '#FFFFFF' : '#EEEEEE';
            const sub = s;
            lines.push(<View style={{ backgroundColor, flexDirection: 'row' }} key={sub.id}>
          <Ripple onPress={this.openBillwerk(sub.contractId)} style={{ width: '100%', height: '100%', flexDirection: 'row' }}>
            <MaterialText centeredBox>
              {sub.subscription} - von {I18n.m.dateCurrent.localeDateWithTimeString(sub.startDate)} bis{' '}
              {I18n.m.dateCurrent.localeDateWithTimeString(sub.expireDate)}
            </MaterialText>
          </Ripple>
        </View>);
        }
        return (<View style={{ marginTop: 24 }}>
        <MaterialText type={MaterialTextTypes.H6}>bisherige Abos</MaterialText>
        {lines}
      </View>);
    }
    renderLogBooks() {
        const { logBookEntries, logBookEntriesCount } = this.state;
        const lines = [];
        const showing = logBookEntries.length;
        if (logBookEntries.length === 0) {
            lines.push(<View style={{ backgroundColor: '#EEEEEE', flexDirection: 'row' }} key="keine">
          <MaterialText centeredBox>Keine</MaterialText>
        </View>);
        }
        else {
            let i = 0;
            for (const s of logBookEntries) {
                i += 1;
                const backgroundColor = i % 2 === 0 ? '#FFFFFF' : '#EEEEEE';
                const logbookItem = s;
                lines.push(<View style={{ backgroundColor, width: '100%' }} key={logbookItem.id}>
            <MaterialText type={MaterialTextTypes.Caption}>
              {I18n.m.dateCurrent.localeDateWithTimeString(logbookItem.createdAt)}
            </MaterialText>
            <MaterialText>{this.createLogBookText(logbookItem)}</MaterialText>
            
            
            
            
            
            
            
            
            
            
          </View>);
            }
        }
        return (<View style={{ marginTop: 24 }}>
        <MaterialText type={MaterialTextTypes.H6}>{`Letzte Aktivitäten ${showing}/${logBookEntriesCount}`}</MaterialText>
        <View style={{ width: '100%' }}>{lines}</View>
      </View>);
    }
    createLogBookText(logbookItem) {
        let text = '';
        const values = { ...logbookItem, ...logbookItem.data };
        if (logbookItem.entityType === 'TicketComments' && logbookItem.eventName === 'TicketCommentAdded') {
            if (logbookItem.data.attachedFileId != null && logbookItem.data.attachedFileType === 'image') {
                text = I18n.m.getMessage('logBookTicketCommentAddedPicture', values);
            }
            else {
                text = I18n.m.getMessage('logBookTicketCommentAdded', values);
            }
        }
        else if (logbookItem.entityType === 'ProjectUser' && logbookItem.eventName === 'ProjectUserRemoved') {
            if (values.creatorName === values.userName) {
                text = I18n.m.getMessage('logBookProjectUserRemovedHimself', values);
            }
            else {
                text = I18n.m.getMessage('logBookProjectUserRemoved', values);
            }
        }
        else {
            text = I18n.m.getMessage(`logBook${logbookItem.eventName}`, values);
        }
        if (text == null || text.length === 0 || text === `logBook${logbookItem.eventName}`) {
            if (logbookItem.entityType === 'Ticket') {
                text = I18n.m.getMessage('logBookTicketDefault', values);
            }
            else {
                text = `missing text: ${logbookItem.eventName}`;
            }
        }
        text = text.replace(/&quot;/g, '"');
        return text;
    }
}
