var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Datepicker = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _CurrentUser = require("upmesh-auth-core/build/src/client/CurrentUser");
var _I18n = require("../../i18n/I18n");
var _ResizeEvent = require("../../ResizeEvent");
var _ThemeManager = require("../../ThemeManager");
var _ContainedButton = require("../button/ContainedButton");
var _Chip = require("../chips/Chip");
var _DialogPicker = require("../DialogPicker");
var _DateHeader = require("./DateHeader");
var _DateInput = _interopRequireDefault(require("./DateInput"));
var _DaySelection = require("./DaySelection");
var _MonthSelection = require("./MonthSelection");
var _TimeInput = _interopRequireDefault(require("./TimeInput"));
var _TimeSection = require("./TimeSection");
var _YearSelection = _interopRequireDefault(require("./YearSelection"));
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var Datepicker = exports.Datepicker = function (_PureComponent) {
  (0, _inherits2.default)(Datepicker, _PureComponent);
  var _super = _createSuper(Datepicker);
  function Datepicker(props) {
    var _this;
    (0, _classCallCheck2.default)(this, Datepicker);
    _this = _super.call(this, props);
    _this.onResize = function () {
      _this.forceUpdate();
    };
    _this.updateSelectedDate = function (day) {
      var range = _this.props.range;
      var _this$state = _this.state,
        viewDate = _this$state.viewDate,
        selectedDate = _this$state.selectedDate,
        currentSelection = _this$state.currentSelection;
      if (!range) {
        var currenSelected = selectedDate instanceof Date ? selectedDate : new Date();
        var newSelected = new Date(viewDate.getFullYear(), viewDate.getMonth(), day, currenSelected.getHours(), currenSelected.getMinutes(), currenSelected.getSeconds(), currenSelected.getMilliseconds());
        _this.setState({
          selectedDate: newSelected
        });
      } else {
        var t = new Date();
        var _currenSelected = selectedDate != null ? selectedDate : {
          from: new Date(t.getFullYear(), t.getMonth(), t.getDate(), 0, 0, 0, 0),
          to: undefined
        };
        _currenSelected[currentSelection] = new Date(viewDate.getFullYear(), viewDate.getMonth(), day, _currenSelected[currentSelection].getHours(), _currenSelected[currentSelection].getMinutes(), _currenSelected[currentSelection].getSeconds(), _currenSelected[currentSelection].getMilliseconds());
        if (_currenSelected.from == null) _currenSelected.from = _currenSelected.to ? new Date(_currenSelected.to.getFullYear(), _currenSelected.to.getMonth(), _currenSelected.to.getDate(), 0, 0, 0, 0) : new Date(t.getFullYear(), t.getMonth(), t.getDate(), 0, 0, 0, 0);
        if (_currenSelected.to == null) _currenSelected.to = _currenSelected.from ? new Date(_currenSelected.from.getFullYear(), _currenSelected.from.getMonth(), _currenSelected.from.getDate() + 1, 0, 0, 0, -1) : new Date(t.getFullYear(), t.getMonth(), t.getDate() + 1, 0, 0, 0, -1);
        if (_currenSelected.from.getTime() > _currenSelected.to.getTime()) {
          _currenSelected.to = new Date(_currenSelected.from);
        }
        _this.setState({
          selectedDate: _currenSelected,
          currentSelection: currentSelection === 'from' ? 'to' : 'from'
        });
      }
    };
    _this.updateViewDate = function (params) {
      var viewDate = _this.state.viewDate;
      var _this$props = _this.props,
        minDate = _this$props.minDate,
        maxDate = _this$props.maxDate;
      var newDate = new Date(viewDate.getTime());
      if (params.newDay != null) {
        newDate.setDate(params.newDay);
      }
      if (params.newMonth != null) {
        newDate.setMonth(params.newMonth);
        if (!_this.checkMinDate(newDate)) {
          newDate = minDate || newDate;
        } else if (!_this.checkMaxDate(newDate)) {
          newDate = maxDate || newDate;
        }
      }
      if (params.newYear != null) {
        newDate.setFullYear(params.newYear);
        if (!_this.checkMinDate(newDate)) {
          newDate.setMonth((minDate == null ? void 0 : minDate.getMonth()) || newDate.getMonth());
        } else if (!_this.checkMaxDate(newDate)) {
          newDate.setMonth((maxDate == null ? void 0 : maxDate.getMonth()) || newDate.getMonth());
        }
        if (!_this.checkMinDate(newDate)) {
          newDate = minDate || newDate;
        } else if (!_this.checkMaxDate(newDate)) {
          newDate = maxDate || newDate;
        }
      }
      _this.setState(function () {
        return {
          viewDate: newDate
        };
      }, function () {
        if (viewDate.getMonth() !== newDate.getMonth()) {
          _this.loadColoreds().catch(function (err) {
            return void 0;
          });
        }
      });
    };
    _this.setNewDate = function (newDate) {
      var selectedDate = _this.state.selectedDate;
      var range = _this.props.range;
      if (range) {} else {
        _this.setState(function () {
          return {
            selectedDate: newDate
          };
        }, function () {
          if (selectedDate == null || selectedDate.getMonth() !== newDate.getMonth()) {
            _this.loadColoreds().catch(function (err) {
              return void 0;
            });
          }
        });
      }
    };
    _this.getDateInRange = function (params) {
      var viewDate = _this.state.viewDate;
      var date = params.newDate || new Date(viewDate.getTime());
      if (params.day != null) {
        date.setDate(params.day);
      }
      if (params.month != null) {
        date.setMonth(params.month);
      }
      if (params.year != null) {
        date.setFullYear(params.year);
      }
      return _this.checkMinDate(date) && _this.checkMaxDate(date);
    };
    _this.checkMinDate = function (date) {
      var minDate = _this.props.minDate;
      if (!minDate) {
        return true;
      }
      if (minDate.getFullYear() > date.getFullYear()) {
        return false;
      }
      if (minDate.getFullYear() < date.getFullYear()) {
        return true;
      }
      if (minDate.getMonth() > date.getMonth()) {
        return false;
      }
      if (minDate.getMonth() < date.getMonth()) {
        return true;
      }
      if (minDate.getDate() > date.getDate()) {
        return false;
      }
      return true;
    };
    _this.checkMaxDate = function (date) {
      var maxDate = _this.props.maxDate;
      if (!maxDate) {
        return true;
      }
      if (maxDate.getFullYear() < date.getFullYear()) {
        return false;
      }
      if (maxDate.getFullYear() > date.getFullYear()) {
        return true;
      }
      if (maxDate.getMonth() < date.getMonth()) {
        return false;
      }
      if (maxDate.getMonth() > date.getMonth()) {
        return true;
      }
      if (maxDate.getDate() < date.getDate()) {
        return false;
      }
      return true;
    };
    _this.cancel = function () {
      _this.props.getDate(undefined);
    };
    _this.confirm = function () {
      var _this$props2 = _this.props,
        range = _this$props2.range,
        getDate = _this$props2.getDate,
        mode = _this$props2.mode;
      var _this$state2 = _this.state,
        currentSelection = _this$state2.currentSelection,
        activePicker = _this$state2.activePicker,
        selectedDate = _this$state2.selectedDate;
      if (range) {
        if (mode === 'both' && activePicker !== 'time') {
          _this.setState({
            activePicker: 'time',
            currentSelection: 'from'
          });
        } else if (mode === 'both' && activePicker === 'time' && currentSelection === 'from') {
          _this.setState({
            activePicker: 'time',
            currentSelection: 'to'
          });
        } else {
          getDate(selectedDate);
        }
      } else {
        mode === 'both' && activePicker !== 'time' ? _this.setState({
          activePicker: 'time'
        }) : getDate(selectedDate);
      }
    };
    _this.setActivePicker = function (picker) {
      _this.setState({
        activePicker: picker
      });
    };
    _this.setSelection = function (selection) {
      _this.setState({
        activeSelection: selection
      });
    };
    _this.togglePage = function () {
      var range = _this.props.range;
      if (!range) {
        var activePage = _this.state.activePage;
        if (activePage === 'picker') {
          _this.setState({
            activePage: 'keyboard'
          });
        } else {
          _this.setState({
            activePage: 'picker'
          });
        }
      }
    };
    _this.setInput = function (input) {
      _this.setState({
        dateInput: input
      });
    };
    _this.setHour = function (hour) {
      var range = _this.props.range;
      var _this$state3 = _this.state,
        currentSelection = _this$state3.currentSelection,
        selectedDate = _this$state3.selectedDate;
      if (range) {
        if (selectedDate && selectedDate[currentSelection]) {
          selectedDate[currentSelection].setHours(hour.data);
          _this.setState({
            selectedDate: selectedDate
          });
        }
      } else {
        _this.setState(function (prevState) {
          try {
            var date = prevState.selectedDate ? prevState.selectedDate : new Date();
            date.setHours(hour.data);
            return {
              selectedDate: date
            };
          } catch (e) {
            return null;
          }
        });
      }
    };
    _this.setMin = function (minute) {
      var range = _this.props.range;
      var _this$state4 = _this.state,
        currentSelection = _this$state4.currentSelection,
        selectedDate = _this$state4.selectedDate;
      if (range) {
        if (selectedDate && selectedDate[currentSelection]) {
          selectedDate[currentSelection].setMinutes(minute);
          _this.setState({
            selectedDate: selectedDate
          });
        }
      } else {
        _this.setState(function (prevState) {
          try {
            var date = prevState.selectedDate ? prevState.selectedDate : new Date();
            date.setMinutes(minute);
            return {
              selectedDate: date
            };
          } catch (e) {
            return null;
          }
        });
      }
    };
    _this.getQuickSelection = function () {
      var _this$props3 = _this.props,
        quickSelections = _this$props3.quickSelections,
        range = _this$props3.range;
      if (range) return null;
      if (quickSelections === undefined) {
        return null;
      }
      var buttons = [];
      buttons = quickSelections.map(function (el, index) {
        return (0, _jsxRuntime.jsx)(_Chip.Chip, {
          title: el.name,
          onPressChip: function onPressChip() {
            return _this.quickSelect(el.days, el.inFromNow);
          }
        }, `${el.name}_${index.toString()}`);
      });
      return buttons;
    };
    _this.getTimeQuickSelection = function () {
      var _this$props4 = _this.props,
        timeQuickSelections = _this$props4.timeQuickSelections,
        range = _this$props4.range;
      if (range) return null;
      if (timeQuickSelections === undefined) return null;
      return timeQuickSelections.map(function (el, index) {
        return (0, _jsxRuntime.jsx)(_Chip.Chip, {
          title: el.name,
          onPressChip: function onPressChip() {
            return _this.timeQuickSelect(el.inMinutes, el.time);
          }
        }, `${el.name}_${index.toString()}`);
      });
    };
    _this.timeQuickSelect = function (inMinutes, time) {
      var selectedDate = _this.state.selectedDate;
      var range = _this.props.range;
      if (range) {} else {
        var newDate = selectedDate ? new Date(selectedDate) : new Date();
        if (inMinutes) {
          newDate.setMinutes(new Date(Date.now()).getMinutes() + inMinutes);
        } else if (time) {
          newDate.setHours(time.hour);
          newDate.setMinutes(time.minute);
        }
        if (_this.getDateInRange({
          newDate: newDate
        })) _this.setState({
          selectedDate: newDate
        });
      }
    };
    _this.quickSelect = function (days) {
      var inFromNow = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var newDate = inFromNow ? new Date() : new Date(days);
      if (inFromNow) newDate.setDate(newDate.getDate() + days);
      _this.setState({
        selectedDate: newDate,
        viewDate: newDate
      });
    };
    _this.loadColoreds = (0, _asyncToGenerator2.default)(function* () {
      var coloredDates = _this.props.coloredDates;
      var viewDate = _this.state.viewDate;
      if (!coloredDates) {
        return;
      }
      _this.setState({
        coloredDatesInMonth: yield coloredDates(new Date(viewDate.getFullYear(), viewDate.getMonth(), 1), new Date(viewDate.getFullYear(), viewDate.getMonth() + 1, 0))
      });
    });
    _this.setCurrentSelection = function (currentSelection) {
      _this.setState({
        currentSelection: currentSelection
      });
    };
    _this.state = {
      currentDate: new Date(),
      viewDate: props.selectedDate ? props.selectedDate instanceof Date ? props.selectedDate : props.selectedDate.from : new Date(),
      monthNamesShort: _I18n.I18n.m.dateCurrent.monthsNamesShort(),
      monthNames: _I18n.I18n.m.dateCurrent.monthsNames(),
      weekdaysShort: _I18n.I18n.m.dateCurrent.weekdayNamesShort(),
      selectedDate: props.selectedDate !== undefined ? props.selectedDate : undefined,
      activeSelection: 'daySelection',
      currentSelection: 'from',
      activePage: 'picker',
      dateInput: '',
      activePicker: props.mode === 'time' ? 'time' : 'date',
      coloredDatesInMonth: undefined
    };
    return _this;
  }
  (0, _createClass2.default)(Datepicker, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      _ResizeEvent.ResizeEvent.onResize.attach(this.onResize);
      this.loadColoreds().catch(function (err) {
        return void 0;
      });
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      _ResizeEvent.ResizeEvent.onResize.detach(this.onResize);
    }
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;
      var _this$props5 = this.props,
        quickSelections = _this$props5.quickSelections,
        labelText = _this$props5.labelText,
        timeQuickSelections = _this$props5.timeQuickSelections,
        selectTimeMinutesIntervall = _this$props5.selectTimeMinutesIntervall,
        minDate = _this$props5.minDate,
        maxDate = _this$props5.maxDate,
        timeFormat = _this$props5.timeFormat,
        mode = _this$props5.mode,
        range = _this$props5.range;
      var _this$state5 = this.state,
        coloredDatesInMonth = _this$state5.coloredDatesInMonth,
        weekdaysShort = _this$state5.weekdaysShort,
        activeSelection = _this$state5.activeSelection,
        monthNamesShort = _this$state5.monthNamesShort,
        monthNames = _this$state5.monthNames,
        currentDate = _this$state5.currentDate,
        dateInput = _this$state5.dateInput,
        selectedDate = _this$state5.selectedDate,
        viewDate = _this$state5.viewDate,
        currentSelection = _this$state5.currentSelection,
        activePage = _this$state5.activePage,
        activePicker = _this$state5.activePicker;
      var showKW = _CurrentUser.CurrentUser.settings.showKW;
      var landscapeView = _ResizeEvent.ResizeEvent.current.windowHeight < 500 && _ResizeEvent.ResizeEvent.current.windowHeight < _ResizeEvent.ResizeEvent.current.windowWidth;
      if (activePage === 'keyboard' && activePicker !== 'time') {
        landscapeView = false;
      }
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: [{
          width: landscapeView ? undefined : 372 - (!showKW ? 44 : 0),
          maxHeight: Math.min(activePage === 'picker' || activePicker === 'time' ? 560 - (landscapeView ? 120 : 0) : 264 - (landscapeView ? 200 : 0), _ResizeEvent.ResizeEvent.current.contentHeight * 0.9),
          backgroundColor: 'white',
          alignSelf: 'center',
          borderRadius: _ThemeManager.ThemeManager.style.borderRadius,
          flexDirection: landscapeView ? 'row' : 'column',
          overflow: 'hidden'
        }],
        children: [(0, _jsxRuntime.jsxs)(_DateHeader.DateHeader, {
          hideInputModeIcon: range,
          mode: mode,
          currentSelection: currentSelection,
          setCurrentSelection: this.setCurrentSelection,
          date: selectedDate,
          weekdaysShort: weekdaysShort,
          monthNamesShort: monthNamesShort,
          togglePage: this.togglePage,
          activePage: activePage,
          activePicker: activePicker,
          landscapeView: landscapeView,
          labelText: labelText,
          setPicker: this.setActivePicker,
          updateDate: this.updateViewDate,
          children: [landscapeView && quickSelections !== undefined && activeSelection === 'daySelection' && activePage === 'picker' && activePicker === 'date' ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            children: this.getQuickSelection()
          }) : null, landscapeView && timeQuickSelections !== undefined && activePage === 'picker' && activePicker === 'time' ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            children: this.getTimeQuickSelection()
          }) : null, landscapeView && _ResizeEvent.ResizeEvent.current.contentHeight <= 377 ? (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
            style: {
              justifyContent: 'flex-end',
              marginTop: 8,
              marginBottom: 8
            },
            children: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
              title: _I18n.I18n.m.getMessage('cancel'),
              onPress: this.cancel,
              backgroundColor: "rgba(0, 0, 0, 0)",
              textColor: "#ffffff"
            }), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
              title: "OK",
              onPress: this.confirm,
              backgroundColor: "rgba(0, 0, 0, 0)",
              textColor: "#ffffff"
            })]
          }) : null]
        }, `DateHeader${landscapeView && _ResizeEvent.ResizeEvent.current.contentHeight <= 377}`), (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            minHeight: activePage === 'picker' ? quickSelections != null && !landscapeView ? 390 : 338 : undefined
          },
          children: [activePicker === 'date' ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            children: activePage === 'picker' ? (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
              children: [(0, _jsxRuntime.jsx)(_MonthSelection.MonthSelection, {
                updateViewDate: this.updateViewDate,
                setSelection: function setSelection(sel) {
                  return _this2.setSelection(sel);
                },
                activeSelection: activeSelection,
                viewDate: viewDate,
                monthNames: monthNames,
                getDateInRange: this.getDateInRange
              }), activeSelection === 'daySelection' ? (0, _jsxRuntime.jsx)(_DaySelection.DaySelection, {
                updateSelectedDate: this.updateSelectedDate,
                weekdaysSchort: weekdaysShort,
                viewDate: viewDate,
                selectedDate: selectedDate,
                currentDate: currentDate,
                minDate: minDate,
                maxDate: maxDate,
                coloredDates: coloredDatesInMonth,
                getDateInRange: this.getDateInRange
              }, `${JSON.stringify(viewDate)}${JSON.stringify(selectedDate)}`) : activeSelection === 'yearSelection' ? (0, _jsxRuntime.jsx)(_YearSelection.default, {
                setSelection: this.setSelection,
                viewDate: viewDate,
                updateDate: this.updateViewDate
              }) : null]
            }) : (0, _jsxRuntime.jsx)(_DateInput.default, {
              updateDate: this.setNewDate,
              setInput: this.setInput,
              input: dateInput,
              getDateInRange: this.getDateInRange,
              maxDate: maxDate,
              minDate: minDate
            })
          }) : (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            children: activePage === 'picker' ? (0, _jsxRuntime.jsx)(_TimeSection.TimeSection, {
              timeFormat: timeFormat,
              onChangeHour: this.setHour,
              onChangeMinutes: this.setMin,
              selectedDate: selectedDate instanceof Date ? selectedDate : selectedDate ? selectedDate[currentSelection] : new Date(),
              color: _ThemeManager.ThemeManager.style.brandPrimary,
              selectTimeMinutesIntervall: selectTimeMinutesIntervall,
              contentSize: {
                width: 300,
                height: 500
              }
            }, `timeSection_${currentSelection}`) : (0, _jsxRuntime.jsx)(_TimeInput.default, {
              timeFormat: timeFormat,
              selectedDate: selectedDate instanceof Date ? selectedDate : new Date(),
              updateDate: this.setNewDate
            })
          }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              flex: 1
            }
          }), !landscapeView && quickSelections !== undefined && activeSelection === 'daySelection' && activePage === 'picker' && activePicker === 'date' ? (0, _jsxRuntime.jsx)(_reactNativeWeb.ScrollView, {
            horizontal: true,
            style: {
              width: '100%',
              marginTop: 8,
              marginBottom: 8
            },
            contentContainerStyle: {
              flexDirection: 'row',
              justifyContent: 'flex-start',
              paddingLeft: 16,
              paddingRight: 16
            },
            children: this.getQuickSelection()
          }) : null, !landscapeView && timeQuickSelections !== undefined && activePage === 'picker' && activePicker === 'time' ? (0, _jsxRuntime.jsx)(_reactNativeWeb.ScrollView, {
            horizontal: true,
            style: {
              width: '100%',
              marginTop: 10,
              marginBottom: 10
            },
            contentContainerStyle: {
              flexDirection: 'row',
              justifyContent: 'flex-start',
              paddingLeft: 16,
              paddingRight: 16
            },
            children: this.getTimeQuickSelection()
          }) : null, !landscapeView || _ResizeEvent.ResizeEvent.current.contentHeight > 377 ? (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
            style: {
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 8,
              marginBottom: 8,
              marginLeft: 16,
              marginRight: 16
            },
            children: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
              title: _I18n.I18n.m.getMessage('cancel'),
              onPress: this.cancel,
              backgroundColor: "rgba(0, 0, 0, 0)",
              textColor: _ThemeManager.ThemeManager.style.brandPrimary
            }), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
              title: "OK",
              onPress: this.confirm,
              backgroundColor: "rgba(0, 0, 0, 0)",
              textColor: _ThemeManager.ThemeManager.style.brandPrimary
            })]
          }) : null]
        })]
      });
    }
  }], [{
    key: "open",
    value: function open(props, e) {
      var _DialogPicker$instanc;
      var openPosition;
      if (e != null && e.nativeEvent != null && e.nativeEvent.pageX != null && e.nativeEvent.pageY != null) {
        openPosition = {
          x: e.nativeEvent.pageX,
          y: e.nativeEvent.pageY
        };
      }
      _reactNativeWeb.Keyboard.dismiss();
      (_DialogPicker$instanc = _DialogPicker.DialogPicker.instance) == null || _DialogPicker$instanc.open({
        content: (0, _jsxRuntime.jsx)(Datepicker, Object.assign({}, props, {
          getDate: function getDate(d) {
            var _DialogPicker$instanc2;
            (_DialogPicker$instanc2 = _DialogPicker.DialogPicker.instance) == null || _DialogPicker$instanc2.close(function () {
              if (d != null) {
                props.onChange(d);
              }
            });
          }
        })),
        contentPadding: false,
        openPosition: openPosition
      });
    }
  }]);
  return Datepicker;
}(_react.PureComponent);