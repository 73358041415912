"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CompanyMemberEntity = void 0;
var ReadModelEntity_1 = require("cqrs-core/build/src/core/query/ReadModelEntity");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var CompanyMemberEntity = function (_ReadModelEntity_1$Re) {
  (0, _inherits2.default)(CompanyMemberEntity, _ReadModelEntity_1$Re);
  var _super = _createSuper(CompanyMemberEntity);
  function CompanyMemberEntity(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, CompanyMemberEntity);
    _this = _super.call(this, obj);
    _this.entityName = 'CompanyMember';
    _this.fill(obj);
    return _this;
  }
  (0, _createClass2.default)(CompanyMemberEntity, [{
    key: "createOne",
    value: function createOne(obj) {
      return new CompanyMemberEntity(obj);
    }
  }, {
    key: "canTimeTrackFor",
    get: function get() {
      if (this.payroll) return 'all';
      if (this.timeTrackingFor) return this.timeTrackingFor;
      return 'all';
    }
  }, {
    key: "canSeeTimeTrackFrom",
    get: function get() {
      if (this.payroll) return 'all';
      if (this.timeTrackingFrom) return this.timeTrackingFrom;
      return 'self';
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('companyId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('email', new OdataEntity_1.OdataIndex(true));
      baseMap.set('userId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('role', new OdataEntity_1.OdataIndex(true));
      baseMap.set('groupId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('workingTimeModelId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('staffNumber', new OdataEntity_1.OdataIndex(true));
      baseMap.set('firstName', new OdataEntity_1.OdataIndex());
      baseMap.set('lastName', new OdataEntity_1.OdataIndex());
      baseMap.set('disableJournalEdit', new OdataEntity_1.OdataIndex());
      baseMap.set('canInviteEmployees', new OdataEntity_1.OdataIndex());
      baseMap.set('payroll', new OdataEntity_1.OdataIndex());
      baseMap.set('extraPayTracking', new OdataEntity_1.OdataIndex());
      baseMap.set('regionId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('deleted', new OdataEntity_1.OdataIndex(true));
      baseMap.set('licenseFree', new OdataEntity_1.OdataIndex(true));
      baseMap.set('phone', new OdataEntity_1.OdataIndex());
      baseMap.set('timeTrackingFor', new OdataEntity_1.OdataIndex());
      baseMap.set('timeTrackingFrom', new OdataEntity_1.OdataIndex());
      baseMap.set('absenceFor', new OdataEntity_1.OdataIndex());
      baseMap.set('absenceFrom', new OdataEntity_1.OdataIndex());
      baseMap.set('canPermitAbsenceFor', new OdataEntity_1.OdataIndex());
      baseMap.set('canLockTimeTrackingFrom', new OdataEntity_1.OdataIndex());
      return baseMap;
    }
  }]);
  return CompanyMemberEntity;
}(ReadModelEntity_1.ReadModelEntity);
exports.CompanyMemberEntity = CompanyMemberEntity;