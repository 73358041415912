"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CreateTimeTracking = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var Config_1 = require("../../../../Config");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var TimeTrackingChanged_1 = require("../../../events/company/timetracking/TimeTrackingChanged");
var TimeTrackingCreated_1 = require("../../../events/company/timetracking/TimeTrackingCreated");
var TimeTrackingEntity_1 = require("../../../query/entities/TimeTrackingEntity");
var CreateTimeTracking = function (_CommandRoot_1$Comman) {
  (0, _inherits2.default)(CreateTimeTracking, _CommandRoot_1$Comman);
  var _super = _createSuper(CreateTimeTracking);
  function CreateTimeTracking(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v1();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, CreateTimeTracking);
    _this = _super.call(this, data, entityId, token);
    _this.aggregate = 'company';
    _this.commandName = 'CreateTimeTracking';
    _this.datesToMinutes();
    return _this;
  }
  (0, _createClass2.default)(CreateTimeTracking, [{
    key: "datesToMinutes",
    value: function datesToMinutes() {
      this.data.starts = CreateTimeTracking.roundDateToMinute(this.data.starts);
      if (this.data.ends != null) this.data.ends = CreateTimeTracking.roundDateToMinute(this.data.ends);
    }
  }, {
    key: "getCompany",
    value: function () {
      var _getCompany = (0, _asyncToGenerator2.default)(function* () {
        if (this._company == null) this._company = yield CommandReadModels_1.CommandReadModels.company.getById(this.data.companyId);
        return this._company;
      });
      function getCompany() {
        return _getCompany.apply(this, arguments);
      }
      return getCompany;
    }()
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        var company = yield this.getCompany();
        var companyMember = yield CommandReadModels_1.CommandReadModels.companyMember.get({
          filter: `companyId eq '${company.id}' and userId eq '${userId}' and deleted ne true`,
          top: 1
        });
        if (companyMember.length === 1 && companyMember[0].role !== 'inactive' && companyMember[0].payroll) return true;
        if (this.data.userId != null && (this.data.memberId == null || this.data.memberId.length === 0)) {
          yield this.checkForRecordingPeriod(this.data.userId, 'userId');
        } else {
          yield this.checkForRecordingPeriod(this.data.memberId);
        }
        if (this.data.memberId != null && this.data.memberId !== companyMember[0].id && companyMember[0].canTimeTrackFor === 'group') {
          var forMember = yield CommandReadModels_1.CommandReadModels.companyMember.getById(this.data.memberId);
          if (forMember.groupId !== companyMember[0].groupId) {
            throw {
              message: 'Keine Berechtigung',
              messageCode: 'forbiddenCommand'
            };
          }
        }
        if (companyMember.length === 1 && companyMember[0].role !== 'inactive') return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var company = yield this.getCompany();
        var events = [];
        var users = ReadModels_1.ReadModels.get('user');
        var promises = [];
        var promisesOpenCheck = [];
        if (this.data.userId != null) {
          var u = this.data.userId;
          var companyMember = yield CommandReadModels_1.CommandReadModels.companyMember.get({
            filter: `companyId eq '${company.id}' and userId eq '${u}' and deleted ne true`,
            top: 1
          });
          if (companyMember.length === 1) {
            if (this.data.ends == null) promisesOpenCheck.push(this.checkForOpenTrackings(companyMember[0].id));
            events.push(new TimeTrackingCreated_1.TimeTrackingCreated({
              costCenterId: this.data.costCenterId,
              userId: u,
              memberId: companyMember[0].id,
              companyId: this.data.companyId,
              starts: new Date(this.data.starts),
              ends: this.data.ends != null ? new Date(this.data.ends) : undefined,
              note: this.data.note,
              taskId: this.data.taskId,
              pause: this.data.pause,
              projectId: this.data.projectId
            }, this.entityId));
            promises.push(users.getById(u));
          }
        } else {
          var _u = this.data.memberId;
          var _companyMember = yield CommandReadModels_1.CommandReadModels.companyMember.getById(_u);
          if (this.data.ends == null) promisesOpenCheck.push(this.checkForOpenTrackings(_u));
          events.push(new TimeTrackingCreated_1.TimeTrackingCreated({
            costCenterId: this.data.costCenterId,
            memberId: _u,
            userId: _companyMember.userId,
            companyId: this.data.companyId,
            starts: new Date(this.data.starts),
            ends: this.data.ends != null ? new Date(this.data.ends) : undefined,
            note: this.data.note,
            taskId: this.data.taskId,
            pause: this.data.pause,
            projectId: this.data.projectId
          }, this.entityId));
          if (_companyMember.userId != null && _companyMember.userId.length > 0) promises.push(users.getById(_companyMember.userId));
        }
        yield Promise.all(promises);
        var changed = yield Promise.all(promisesOpenCheck);
        changed.forEach(function (f) {
          if (f != null) events.unshift(f);
        });
        return events;
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "checkForRecordingPeriod",
    value: function () {
      var _checkForRecordingPeriod = (0, _asyncToGenerator2.default)(function* (memberOrUserId) {
        var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'memberId';
        var company = yield this.getCompany();
        var member;
        if (type === 'userId') {
          var companyMember = yield CommandReadModels_1.CommandReadModels.companyMember.get({
            filter: `companyId eq '${company.id}' and userId eq '${memberOrUserId}' and deleted ne true`,
            top: 1
          });
          if (companyMember.length === 1) {
            member = companyMember[0];
          }
        } else {
          member = yield CommandReadModels_1.CommandReadModels.companyMember.getById(memberOrUserId);
        }
        if (member != null) {
          var period = yield TimeTrackingEntity_1.TimeTrackingEntity.getRecordingPeriod(member);
          var starts = new Date(this.data.starts);
          if (starts.getTime() < period.start.getTime() || starts.getTime() > period.end.getTime()) {
            throw {
              message: 'Das Startdatum liegt nicht im möglichen Erfassungszeitraum',
              messageCode: 'timeTrackingNotInRecordingPeriod'
            };
          }
        }
      });
      function checkForRecordingPeriod(_x) {
        return _checkForRecordingPeriod.apply(this, arguments);
      }
      return checkForRecordingPeriod;
    }()
  }, {
    key: "checkForOpenTrackings",
    value: function () {
      var _checkForOpenTrackings = (0, _asyncToGenerator2.default)(function* (memberId) {
        var getEntries = yield CommandReadModels_1.CommandReadModels.timeTracking.get({
          filter: `memberId eq '${memberId}' and companyId eq '${this.data.companyId}' and deleted ne true`,
          orderby: 'ends asc',
          top: 1
        });
        if (getEntries.length > 0 && getEntries[0].ends == null) {
          var eStarts = new Date(getEntries[0].starts);
          var newEnds = this.data.starts.getTime() >= eStarts.getTime() && this.data.starts.getTime() - eStarts.getTime() < 60 * 60 * 10000 * 6 ? this.data.starts : new Date(eStarts.getFullYear(), eStarts.getMonth(), eStarts.getDate(), eStarts.getHours(), eStarts.getMinutes() + 1, 0, 0);
          if (this.data.starts.getTime() > eStarts.getTime()) return new TimeTrackingChanged_1.TimeTrackingChanged(getEntries[0].id, {
            ends: newEnds
          }, this.data.companyId);
        }
        return null;
      });
      function checkForOpenTrackings(_x2) {
        return _checkForOpenTrackings.apply(this, arguments);
      }
      return checkForOpenTrackings;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        if (key == null || key === 'note') {
          if (this.data.note != null && this.data.note.length > Config_1.ConfigHandler.config.string_Large) {
            throw {
              key: 'note',
              messageCode: 'stringToLong',
              message: 'Eingabe ist zu lang'
            };
          }
        }
        yield CreateTimeTracking.validateTimeTrackingData(this.data, key);
      });
      function validate(_x3) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }], [{
    key: "roundDateToMinute",
    value: function roundDateToMinute(date) {
      var d = new Date(date);
      d.setMinutes(d.getMinutes(), 0, 0);
      return d;
    }
  }, {
    key: "validateTimeTrackingData",
    value: function () {
      var _validateTimeTrackingData = (0, _asyncToGenerator2.default)(function* (data, key) {
        var errors = [];
        if (key == null || key === 'companyId') {
          if (data.companyId == null || data.companyId.length === 0) {
            errors.push({
              message: 'Die Firma wird benötigt',
              messageCode: 'required',
              key: 'companyId'
            });
          }
        }
        if (key == null || key === 'costCenterId' || key === 'projectId') {
          if (data.costCenterId == null || data.costCenterId.length === 0) {
            errors.push({
              message: 'Die Kostenstelle wird benötigt',
              messageCode: 'required',
              key: 'costCenterId'
            });
          } else {
            try {
              var cc = yield CommandReadModels_1.CommandReadModels.costCenter.getById(data.costCenterId);
              if (cc.costType === 'costUnit') {
                if (data.projectId == null) {
                  errors.push({
                    message: 'Wenn ein Kostenträger gewählt ist, wird die Projekt id benötigt',
                    messageCode: 'costUnitNeedsProject',
                    key: 'projectId'
                  });
                }
                var isIn = cc.projectIds == null ? -1 : cc.projectIds.findIndex(function (p) {
                  return p === data.projectId;
                });
                if (isIn === -1) {
                  errors.push({
                    message: 'Das Projekt ist dem Kostenträger nicht zugeordnet',
                    messageCode: 'projectNotInCostUnit',
                    key: 'projectId'
                  });
                }
              }
            } catch (e) {
              errors.push({
                message: 'Diese Kostenstelle existiert nicht',
                messageCode: 'costCenterIdNotFound',
                key: 'costCenterId'
              });
            }
          }
        }
        if (key == null || key === 'memberId') {
          if (data.memberId == null || data.memberId.length === 0) {
            errors.push({
              message: 'Min. 1 Benutzer wird benötigt',
              messageCode: 'required',
              key: 'memberIds'
            });
          }
        }
        if (key == null || key === 'starts') {
          if (data.starts == null) {
            errors.push({
              message: 'Die Startzeit wird benötigt',
              messageCode: 'required',
              key: 'starts'
            });
          }
        }
        if (key == null || key === 'ends') {
          if (data.ends != null && data.starts != null) {
            var diff = data.ends.getTime() - data.starts.getTime();
            if (data.starts.getTime() > data.ends.getTime()) {
              errors.push({
                message: 'Die Endzeit vor Startzeit',
                messageCode: 'timeTrackingErrorEndsBeforeStart',
                key: 'ends'
              });
            } else if (diff > 86400000) {
              errors.push({
                message: 'Die Gesamtzeit darf nicht größer als 24 Stunden sein',
                messageCode: 'timeTrackingErrorEndsStartDiff',
                key: 'ends'
              });
            }
          }
        }
        if (errors.length > 0) throw errors;
      });
      function validateTimeTrackingData(_x4, _x5) {
        return _validateTimeTrackingData.apply(this, arguments);
      }
      return validateTimeTrackingData;
    }()
  }]);
  return CreateTimeTracking;
}(CommandRoot_1.CommandRoot);
exports.CreateTimeTracking = CreateTimeTracking;