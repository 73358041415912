var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WrapperUploads = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _I18n = require("../../i18n/I18n");
var _ThemeManager = require("../../ThemeManager");
var _Icon = require("../Icon");
var _Spinner = require("../Spinner");
var _MaterialText = require("../text/MaterialText");
var _UploadingList = require("./UploadingList");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var WrapperUploads = exports.WrapperUploads = function (_PureComponent) {
  (0, _inherits2.default)(WrapperUploads, _PureComponent);
  var _super = _createSuper(WrapperUploads);
  function WrapperUploads(props) {
    var _this;
    (0, _classCallCheck2.default)(this, WrapperUploads);
    _this = _super.call(this, props);
    _this.onUploading = function (e) {
      _this.setState({
        uploads: e.length
      });
    };
    _this.toggle = function () {
      _this.setState(function (prevState) {
        return {
          visible: !prevState.visible
        };
      });
    };
    _this.toggleClose = function () {
      _this.setState(function (prevState) {
        return {
          small: !prevState.small
        };
      });
    };
    _this.state = {
      uploads: 0,
      visible: true,
      small: true
    };
    return _this;
  }
  (0, _createClass2.default)(WrapperUploads, [{
    key: "render",
    value: function render() {
      var size = this.props.size;
      var _this$state = this.state,
        uploads = _this$state.uploads,
        visible = _this$state.visible,
        small = _this$state.small;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          position: 'absolute',
          bottom: size.contentBottom,
          left: 0,
          maxWidth: size.windowWidth - 96,
          width: small ? _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(24) : 400,
          height: uploads === 0 ? 0 : visible && !small ? uploads * 56 + 36 : small ? _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(24) : 36,
          maxHeight: 200,
          backgroundColor: '#FFFFFF',
          borderRadius: _ThemeManager.ThemeManager.style.borderRadius,
          borderStyle: 'solid',
          borderWidth: uploads === 0 ? 0 : _ThemeManager.ThemeManager.style.borderWidth,
          overflow: 'hidden'
        },
        children: [uploads === 0 ? null : small ? (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            position: 'relative'
          },
          children: [(0, _jsxRuntime.jsx)(_Spinner.Spinner, {
            small: true
          }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              position: 'absolute',
              width: '100%',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center'
            },
            children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
                type: _MaterialText.MaterialTextTypes.Caption,
                onPress: this.toggleClose,
                children: uploads
              })
            })
          })]
        }) : (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            height: 36,
            overflow: 'hidden',
            width: '100%',
            backgroundColor: _ThemeManager.ThemeManager.style.appBgColor,
            flexDirection: 'row',
            justifyContent: 'space-between',
            borderStyle: 'solid',
            borderWidth: 0,
            borderBottomWidth: _ThemeManager.ThemeManager.style.borderWidth,
            paddingHorizontal: 4
          },
          children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              height: 36,
              justifyContent: 'center'
            },
            children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              centeredBox: true,
              children: _I18n.I18n.m.getMessage('uploadsRunning', {
                count: uploads
              })
            })
          }), (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
            style: {
              height: 36,
              width: 80,
              flexDirection: 'row',
              justifyContent: 'space-between'
            },
            children: [(0, _jsxRuntime.jsx)(_Icon.Icon, {
              icon: visible ? 'chevron-up' : 'chevron-down',
              toolTip: "",
              onPress: this.toggle
            }), (0, _jsxRuntime.jsx)(_Icon.Icon, {
              icon: "close",
              toolTip: "",
              onPress: this.toggleClose
            })]
          })]
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.ScrollView, {
          style: {
            width: '100%',
            height: '100%'
          },
          children: (0, _jsxRuntime.jsx)(_UploadingList.UploadingList, {
            disablePreview: true,
            onUploading: this.onUploading
          }, "uploadingList")
        })]
      });
    }
  }]);
  return WrapperUploads;
}(_react.PureComponent);