"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CompanyChanged = void 0;
var EventRootOdata_1 = require("cqrs-core/build/src/core/events/EventRootOdata");
var CompanyChanged = function (_EventRootOdata_1$Eve) {
  (0, _inherits2.default)(CompanyChanged, _EventRootOdata_1$Eve);
  var _super = _createSuper(CompanyChanged);
  function CompanyChanged(entityId, data) {
    (0, _classCallCheck2.default)(this, CompanyChanged);
    return _super.call(this, {
      entityId: entityId,
      data: data,
      eventName: 'CompanyChanged',
      aggregate: 'company',
      aggregateId: entityId
    });
  }
  return (0, _createClass2.default)(CompanyChanged);
}(EventRootOdata_1.EventRootOdata);
exports.CompanyChanged = CompanyChanged;