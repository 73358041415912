"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Logout = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var EventRootOdata_1 = require("cqrs-core/build/src/core/events/EventRootOdata");
var CurrentUser_1 = require("../../CurrentUser");
var Logout = function (_CommandRoot_1$Comman) {
  (0, _inherits2.default)(Logout, _CommandRoot_1$Comman);
  var _super = _createSuper(Logout);
  function Logout(data, token) {
    var _this;
    (0, _classCallCheck2.default)(this, Logout);
    _this = _super.call(this, data, '', token);
    _this.aggregate = 'guests';
    _this.commandName = 'Logout';
    return _this;
  }
  (0, _createClass2.default)(Logout, [{
    key: "result",
    get: function get() {
      return this._result;
    },
    set: function set(data) {
      this._result = data;
    }
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var userId = this.token != null ? CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id : '';
        return new EventRootOdata_1.EventRootOdata({
          entityId: this.entityId,
          eventName: 'Logout',
          aggregate: 'user',
          aggregateId: userId
        });
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "execute",
    value: function () {
      var _execute = (0, _asyncToGenerator2.default)(function* (onStore) {
        try {
          yield (0, _get2.default)((0, _getPrototypeOf2.default)(Logout.prototype), "execute", this).call(this, onStore);
        } catch (e) {}
      });
      function execute(_x) {
        return _execute.apply(this, arguments);
      }
      return execute;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {});
      function validate(_x2) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
  return Logout;
}(CommandRoot_1.CommandRoot);
exports.Logout = Logout;