var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TabBar = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _color = _interopRequireDefault(require("color"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _I18n = require("../../i18n/I18n");
var _ThemeManager = require("../../ThemeManager");
var _BottomSheet = require("../BottomSheet");
var _ContainedButton = require("../button/ContainedButton");
var _Icon = require("../Icon");
var _ListItem = require("../ListItem");
var _MaterialText = require("../text/MaterialText");
var _Ripple = require("../utils/Ripple");
var _TabBarContent = require("./TabBarContent");
var _TabEventHandler = require("./TabEventHandler");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var TabBar = exports.TabBar = function (_PureComponent) {
  (0, _inherits2.default)(TabBar, _PureComponent);
  var _super = _createSuper(TabBar);
  function TabBar(props) {
    var _this;
    (0, _classCallCheck2.default)(this, TabBar);
    _this = _super.call(this, props);
    _this.scrollView = undefined;
    _this.showChevron = false;
    _this.tabHeight = _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(48);
    _this.onPressScrollButton = function (direction) {
      return function (_e) {
        var _this$state = _this.state,
          tabWidth = _this$state.tabWidth,
          scrollPosition = _this$state.scrollPosition,
          width = _this$state.width;
        var tabs = _this.props.tabs;
        var maxVisible = Math.floor(width / tabWidth);
        var scrollToX = 0;
        if (direction === 'right') {
          var targetValue = scrollPosition + maxVisible * tabWidth;
          var value = Math.floor(targetValue / tabWidth) * tabWidth;
          scrollToX = Math.min(value, tabs.length * tabWidth - maxVisible * tabWidth);
        } else {
          var _targetValue = scrollPosition - maxVisible * tabWidth;
          var _value = Math.floor(_targetValue / tabWidth) * tabWidth;
          scrollToX = Math.max(_value, 0);
        }
        _this.scrollView.scrollTo({
          x: scrollToX
        });
      };
    };
    _this.setScrollView = function (scrollView) {
      _this.scrollView = scrollView;
    };
    _this.openMoreMenu = function (_e) {
      var _BottomSheet$instance;
      var _this$props = _this.props,
        tabs = _this$props.tabs,
        upperCase = _this$props.upperCase;
      (_BottomSheet$instance = _BottomSheet.BottomSheet.instance) == null || _BottomSheet$instance.open({
        showOverlay: true,
        content: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          children: tabs.map(function (tab, i) {
            return (0, _jsxRuntime.jsx)(_ListItem.ListItem, {
              onPress: function onPress(e) {
                var _BottomSheet$instance2;
                (_BottomSheet$instance2 = _BottomSheet.BottomSheet.instance) == null || _BottomSheet$instance2.close();
                _this.contentChangeSelection(i)(e);
              },
              title: tab.title == null ? '' : upperCase !== true ? tab.title : tab.title.toUpperCase(),
              thumbnail: tab.icon == null ? undefined : {
                thumbnail: (0, _jsxRuntime.jsx)(_Icon.Icon, {
                  radius: 0,
                  badge: tab.badge,
                  backgroundColor: tab.badgeColor,
                  toolTip: "",
                  iconIconMoon: tab.iconMoon,
                  icon: tab.icon,
                  iconSize: 24,
                  outerSize: 24
                }),
                width: 24
              }
            }, `bottomsheetElement${i}${tab.title ? tab.title : ''}`);
          })
        }),
        scrollable: true,
        closeOnTouchOutside: true
      });
    };
    _this.changeSelected = function (index) {
      var fromProps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var animated = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      var _this$props2 = _this.props,
        onChangeSelected = _this$props2.onChangeSelected,
        onChangedSelected = _this$props2.onChangedSelected,
        tabId = _this$props2.tabId;
      var _this$state2 = _this.state,
        tabWidth = _this$state2.tabWidth,
        width = _this$state2.width;
      var i = index;
      if (typeof index !== 'number') {
        return;
      }
      _this.setState({
        selected: i,
        toSelected: i
      }, function () {
        var _this$props3 = _this.props,
          tabs = _this$props3.tabs,
          maxTabs = _this$props3.maxTabs;
        if (tabId != null) _TabEventHandler.TabEventHandler.changeTab.post({
          tabId: tabId,
          tabIndex: i
        });
        _this.scrollView.scrollTo({
          x: i * tabWidth - width / 2 + tabWidth / 2
        });
        var maxT = TabBar.getMaxVisibleTabs(tabs, maxTabs);
        var max = Math.min(maxT, i);
        if (!animated) {
          _this.setState({
            borderPosition: new _reactNativeWeb.Animated.Value(max * tabWidth)
          });
        } else {
          _this.startAnimation(max * tabWidth, function () {
            if (onChangedSelected != null) {
              onChangedSelected(i);
            }
          });
        }
        if (!fromProps && onChangeSelected != null) {
          onChangeSelected(i);
        }
      });
    };
    _this.contentChangeSelection = function (index) {
      var _fromProps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      return function (_e) {
        var onChangeSelected = _this.props.onChangeSelected;
        if (onChangeSelected == null) {
          _this.changeSelected(index, false);
        } else {
          onChangeSelected(index);
        }
      };
    };
    _this.onChangeTab = function (e) {
      var tabId = _this.props.tabId;
      var selected = _this.state.selected;
      if (tabId === e.tabId && selected !== e.tabIndex) {
        _this.changeSelected(e.tabIndex);
      }
    };
    _this.onScroll = function (e) {
      if (e != null && e.nativeEvent != null && e.nativeEvent.contentOffset != null && e.nativeEvent.contentOffset.x >= 0) {
        _this.setState({
          scrollPosition: e.nativeEvent.contentOffset.x
        });
      }
    };
    var tabWidth = props.minItemWidth != null ? props.minItemWidth : 264;
    var selected = props.selected != null ? props.selected : props.startSelected != null ? props.startSelected : 0;
    _this.state = {
      tabWidth: tabWidth,
      selected: selected,
      toSelected: selected,
      borderPosition: new _reactNativeWeb.Animated.Value(selected * tabWidth),
      scrollPosition: 0,
      contents: [],
      width: 200,
      height: 200
    };
    return _this;
  }
  (0, _createClass2.default)(TabBar, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this$state3 = this.state,
        tabWidth = _this$state3.tabWidth,
        selected = _this$state3.selected;
      var _this$props4 = this.props,
        tabId = _this$props4.tabId,
        startSelected = _this$props4.startSelected;
      var position = tabWidth * selected;
      if (tabId != null) {
        _TabEventHandler.TabEventHandler.changeTab.attach(this.onChangeTab);
      }
      this.scrollView.scrollTo({
        x: position,
        animated: false
      });
      if (startSelected != null) {
        this.changeSelected(startSelected, true, false);
      }
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(_prevProps, _prevState, _snapshot) {
      var tabId = this.props.tabId;
      var _this$state4 = this.state,
        selected = _this$state4.selected,
        toSelected = _this$state4.toSelected;
      if (tabId == null && toSelected !== selected) {
        this.changeSelected(toSelected, true);
      }
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      var tabId = this.props.tabId;
      if (tabId != null) {
        _TabEventHandler.TabEventHandler.changeTab.detach(this.onChangeTab);
      }
    }
  }, {
    key: "getBorderColor",
    value: function getBorderColor() {
      var _this$props5 = this.props,
        underlineColor = _this$props5.underlineColor,
        textColor = _this$props5.textColor;
      if (underlineColor != null) {
        return underlineColor;
      }
      return textColor;
    }
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;
      var _this$props6 = this.props,
        textColor = _this$props6.textColor,
        backgroundColor = _this$props6.backgroundColor,
        borderHeight = _this$props6.borderHeight,
        indicatorOnTop = _this$props6.indicatorOnTop,
        tabs = _this$props6.tabs;
      var _this$state5 = this.state,
        selected = _this$state5.selected,
        contents = _this$state5.contents,
        width = _this$state5.width,
        height = _this$state5.height,
        tabWidth = _this$state5.tabWidth,
        scrollPosition = _this$state5.scrollPosition,
        borderPosition = _this$state5.borderPosition;
      var borderColor = this.getBorderColor();
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          width: width,
          position: 'relative'
        },
        children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            height: this.tabHeight,
            overflow: 'hidden'
          },
          children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.ScrollView, {
            collapsable: false,
            ref: this.setScrollView,
            showsHorizontalScrollIndicator: false,
            horizontal: true,
            scrollEnabled: true,
            scrollEventThrottle: 16,
            onScroll: this.onScroll,
            contentContainerStyle: {
              overflow: 'hidden',
              height: this.tabHeight,
              padding: 0,
              paddingTop: indicatorOnTop ? borderHeight != null ? borderHeight : 2 : 0
            },
            style: {
              width: width,
              backgroundColor: backgroundColor,
              height: this.tabHeight + 20,
              minHeight: this.tabHeight + 20,
              overflow: 'hidden'
            },
            children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              style: {
                overflow: 'hidden',
                flexDirection: 'row'
              },
              children: this.renderTabButtons()
            }), (0, _jsxRuntime.jsx)(_reactNativeWeb.Animated.View, {
              style: {
                height: borderHeight != null ? borderHeight : 2,
                width: tabWidth,
                backgroundColor: borderColor,
                position: 'absolute',
                top: indicatorOnTop ? 0 : this.tabHeight - (borderHeight != null ? borderHeight : 2),
                left: 0,
                transform: [{
                  translateX: borderPosition
                }]
              }
            })]
          })
        }), this.showChevron && scrollPosition > 0 ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            position: 'absolute',
            left: 0,
            top: (this.tabHeight - 36) / 2
          },
          children: (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            icon: {
              icon: 'chevron-left',
              color: textColor
            },
            backgroundColor: backgroundColor,
            onPress: this.onPressScrollButton('left')
          })
        }) : null, this.showChevron && scrollPosition < tabs.length * tabWidth - width ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            position: 'absolute',
            right: 0,
            top: (this.tabHeight - 36) / 2
          },
          children: (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            icon: {
              icon: 'chevron-right',
              color: textColor
            },
            backgroundColor: backgroundColor,
            onPress: this.onPressScrollButton('right')
          })
        }) : null, contents != null && contents.length > 0 ? (0, _jsxRuntime.jsx)(_TabBarContent.TabContent, {
          changeSelection: function changeSelection(i) {
            return _this2.contentChangeSelection(i)();
          },
          contents: contents,
          width: width,
          height: height,
          selected: selected
        }) : null]
      });
    }
  }, {
    key: "startAnimation",
    value: function startAnimation(toValue, callBack) {
      var borderPosition = this.state.borderPosition;
      _reactNativeWeb.Animated.timing(borderPosition, {
        toValue: toValue,
        duration: 375,
        useNativeDriver: false
      }).start(function () {
        if (callBack != null) {
          callBack();
        }
      });
    }
  }, {
    key: "renderTabButtons",
    value: function renderTabButtons() {
      var _this$props7 = this.props,
        tabs = _this$props7.tabs,
        maxTabs = _this$props7.maxTabs,
        textColor = _this$props7.textColor,
        width = _this$props7.width,
        unfocusedTextColor = _this$props7.unfocusedTextColor,
        backgroundColor = _this$props7.backgroundColor,
        minItemWidth = _this$props7.minItemWidth,
        textProps = _this$props7.textProps,
        upperCase = _this$props7.upperCase;
      var max = TabBar.getMaxVisibleTabs(tabs, maxTabs);
      var _this$state6 = this.state,
        selected = _this$state6.selected,
        tabWidth = _this$state6.tabWidth;
      var renderedTabs = [];
      var unfocusedColor = unfocusedTextColor != null ? unfocusedTextColor : (0, _color.default)(textColor).alpha(0.7).toString();
      var maxVisible = Math.floor(width / tabWidth);
      this.showChevron = _reactNativeWeb.Platform.OS === 'web' && maxVisible < tabs.length;
      for (var i = 0; i < max; i += 1) {
        var tab = tabs[i];
        renderedTabs.push((0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            alignSelf: 'center',
            width: tabWidth,
            minWidth: minItemWidth,
            height: this.tabHeight
          },
          children: (0, _jsxRuntime.jsxs)(_Ripple.Ripple, {
            accessibilityLabel: "tabbarElement",
            style: {
              width: '100%',
              justifyContent: 'center',
              height: this.tabHeight
            },
            backgroundColor: backgroundColor,
            rippleColor: (0, _color.default)(textColor).alpha(0.4).toString(),
            onPress: this.contentChangeSelection(i),
            toolTip: tab.hideTitle ? tab.title : '',
            children: [tab.icon != null && tab.icon.length > 0 ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              style: {
                alignSelf: 'center',
                overflow: 'visible'
              },
              children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
                radius: 0,
                badge: tab.badge,
                backgroundColor: tab.badgeColor,
                toolTip: "",
                iconIconMoon: tab.iconMoon,
                icon: tab.icon,
                iconSize: 24,
                outerSize: 24,
                color: selected === i ? textColor : unfocusedTextColor
              })
            }) : null, !tab.hideTitle && tab.title != null && tab.title.length > 0 ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              collapsable: false,
              style: {
                width: tabWidth,
                height: 'auto',
                alignSelf: 'center',
                paddingRight: 6,
                paddingLeft: 6
              },
              children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, Object.assign({
                type: _MaterialText.MaterialTextTypes.Button,
                color: selected === i ? textColor : unfocusedColor,
                centeredText: true,
                centeredBox: true
              }, textProps, {
                children: upperCase !== true ? tab.title : tab.title.toUpperCase()
              }))
            }) : null]
          })
        }, `tabbarElement${i}${tab.title ? tab.title : ''}`));
      }
      if (maxTabs != null && maxTabs < tabs.length) {
        renderedTabs.push((0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            alignSelf: 'center',
            width: tabWidth,
            minWidth: minItemWidth,
            height: this.tabHeight
          },
          children: (0, _jsxRuntime.jsxs)(_Ripple.Ripple, {
            accessibilityLabel: "tabbarElement",
            style: {
              width: '100%',
              justifyContent: 'center',
              height: this.tabHeight
            },
            backgroundColor: backgroundColor,
            rippleColor: (0, _color.default)(textColor).alpha(0.4).toString(),
            onPress: this.openMoreMenu,
            toolTip: _I18n.I18n.m.getMessage('more'),
            children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              style: {
                alignSelf: 'center'
              },
              children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
                radius: 0,
                toolTip: "",
                icon: "dots-vertical",
                iconSize: 24,
                outerSize: 24,
                color: unfocusedTextColor
              })
            }), !tabs[0].hideTitle ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              collapsable: false,
              style: {
                width: tabWidth,
                height: 'auto',
                alignSelf: 'center',
                paddingRight: 6,
                paddingLeft: 6
              },
              children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, Object.assign({
                type: _MaterialText.MaterialTextTypes.Button,
                color: unfocusedColor,
                centeredText: true,
                centeredBox: true
              }, textProps, {
                children: upperCase !== true ? _I18n.I18n.m.getMessage('more') : _I18n.I18n.m.getMessage('more').toUpperCase()
              }))
            }) : null]
          })
        }, "tabbarElementMore"));
      }
      return renderedTabs;
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        tabs: [{
          title: 'Header'
        }],
        backgroundColor: _ThemeManager.ThemeManager.style.headerBG,
        textColor: _ThemeManager.ThemeManager.style.brandPrimary,
        width: 200,
        contentHeight: 200,
        minItemWidth: 72,
        upperCase: true
      };
    }
  }, {
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, prevState) {
      var newState = Object.assign({}, prevState);
      var updateNeeded = false;
      var max = TabBar.getMaxVisibleTabs(nextProps.tabs, nextProps.maxTabs);
      if (max < nextProps.tabs.length) max += 1;
      var tabWidth = nextProps.width / max;
      if (nextProps.minItemWidth != null && tabWidth <= nextProps.minItemWidth) {
        tabWidth = nextProps.minItemWidth;
      } else if (tabWidth > 264) {
        tabWidth = 264;
      }
      if (newState.tabWidth !== tabWidth) {
        newState.tabWidth = tabWidth;
        newState.borderPosition = new _reactNativeWeb.Animated.Value(prevState.selected * tabWidth);
        updateNeeded = true;
      }
      if (nextProps.selected != null && nextProps.selected !== newState.selected) {
        newState.toSelected = nextProps.selected;
        updateNeeded = true;
      }
      if (nextProps.width != null && nextProps.width !== newState.width) {
        newState.width = nextProps.width;
        updateNeeded = true;
      }
      if (nextProps.contentHeight != null && nextProps.contentHeight !== newState.height) {
        newState.height = nextProps.contentHeight;
        updateNeeded = true;
      }
      var contents = [];
      for (var i = 0; i < nextProps.tabs.length; i += 1) {
        if (nextProps.tabs[i].content != null) {
          contents.push(nextProps.tabs[i].content);
        }
        newState.contents = contents;
      }
      if (updateNeeded) {
        return newState;
      }
      return null;
    }
  }, {
    key: "getMaxVisibleTabs",
    value: function getMaxVisibleTabs(tabs, maxTabs) {
      return maxTabs != null ? Math.min(maxTabs, tabs.length) : tabs.length;
    }
  }]);
  return TabBar;
}(_react.PureComponent);