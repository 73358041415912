"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EditTicketComment = void 0;
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var Config_1 = require("../../../Config");
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var StoredFileDeleted_1 = require("../../events/storedfiles/StoredFileDeleted");
var TicketCommentChanged_1 = require("../../events/ticketcomments/TicketCommentChanged");
var ClientProjectCommands_1 = require("../_root/ClientProjectCommands");
var EditTicketComment = function (_ClientProjectCommand) {
  (0, _inherits2.default)(EditTicketComment, _ClientProjectCommand);
  var _super = _createSuper(EditTicketComment);
  function EditTicketComment(data, entityId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, EditTicketComment);
    _this = _super.call(this, data, entityId, token);
    _this.aggregate = 'project';
    _this.commandName = 'EditTicketComment';
    return _this;
  }
  (0, _createClass2.default)(EditTicketComment, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') {
          return true;
        }
        var entity = yield CommandReadModels_1.CommandReadModels.ticketComments.getById(this.entityId);
        yield (0, _get2.default)((0, _getPrototypeOf2.default)(EditTicketComment.prototype), "checkProjectStatusAndGeneralAccess", this).call(this, entity.projectId, userId);
        if (this.data.deleteFile && (entity.attachedFileId == null || entity.attachedFileId.length < 0)) {
          throw {
            message: 'Keine Berechtigung',
            messageCode: 'forbiddenCommand'
          };
        }
        var oneDay = 1000 * 60 * 60 * 24;
        if (entity.creator === userId && entity.createdAt.getTime() + oneDay > new Date().getTime()) {
          return true;
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var rm = yield CommandReadModels_1.CommandReadModels.ticketComments.getById(this.entityId);
        if (this.data.deleteFile) {
          return [new TicketCommentChanged_1.TicketCommentChanged(this.entityId, {
            comment: this.data.comment,
            creator: userId,
            attachedFileId: '',
            attachedFileType: ''
          }, rm.projectId), new StoredFileDeleted_1.StoredFileDeleted(rm.attachedFileId, rm.projectId)];
        }
        return new TicketCommentChanged_1.TicketCommentChanged(this.entityId, Object.assign({}, this.data, {
          creator: userId
        }), rm.projectId);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        if (key == null || key === 'comment') {
          if (this.data.comment.length > Config_1.ConfigHandler.config.string_Huge) {
            throw {
              key: 'comment',
              messageCode: 'stringToLong',
              message: 'Eingabe ist zu lang'
            };
          }
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
  return EditTicketComment;
}(ClientProjectCommands_1.ClientProjectCommands);
exports.EditTicketComment = EditTicketComment;