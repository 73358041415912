"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DeleteProjectFromServer = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var DeleteProjectFromServerNow_1 = require("upmesh-core/build/src/client/commands/project/DeleteProjectFromServerNow");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var ClientStore_1 = require("upmesh-core/build/src/client/ClientStore");
var UpmeshClient_1 = require("upmesh-core/build/src/client/UpmeshClient");
var DeleteProjectFromServer = function (_CommandRoot_1$Comman) {
  (0, _inherits2.default)(DeleteProjectFromServer, _CommandRoot_1$Comman);
  var _super = _createSuper(DeleteProjectFromServer);
  function DeleteProjectFromServer(data, userId, token) {
    var _this;
    (0, _classCallCheck2.default)(this, DeleteProjectFromServer);
    _this = _super.call(this, data, userId, token);
    _this.waitForResult = true;
    _this.commandName = 'DeleteProjectFromServer';
    _this.aggregate = 'supportuser';
    return _this;
  }
  (0, _createClass2.default)(DeleteProjectFromServer, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        var admin = ReadModels_1.ReadModels.get('SupportRoles');
        var admins = yield admin.get({
          filter: `userId eq '${userId}' and deleted ne true`
        });
        if (admins.length > 0) {
          for (var adm of admins) {
            if (adm.role === 'Admin' || adm.role === 'Support') {
              return true;
            }
          }
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {});
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var token = UpmeshClient_1.UpmeshClient.instance.serverConnection.token;
        if (token != null) {
          var c = new DeleteProjectFromServerNow_1.DeleteProjectFromServerNow({}, this.entityId, token);
          yield c.execute(ClientStore_1.ClientStore.commandStore);
        } else throw new Error('cant get server connection');
        return [];
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }]);
  return DeleteProjectFromServer;
}(CommandRoot_1.CommandRoot);
exports.DeleteProjectFromServer = DeleteProjectFromServer;