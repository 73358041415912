"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ReadModelChangedDefaultClientDispatcher = void 0;
var ts_events_1 = require("ts-events");
var uuid = require("uuid");
var ReadModels_1 = require("../../core/query/ReadModels");
var ReadModelChangedDefaultClientDispatcher = function () {
  function ReadModelChangedDefaultClientDispatcher() {
    var _this = this;
    (0, _classCallCheck2.default)(this, ReadModelChangedDefaultClientDispatcher);
    this.timeOutForCollectEntities = 0;
    this.events = new Map();
    this.toSend = new Map();
    this.timeOuts = new Map();
    this.postReadModel = function (r) {
      var asyncEvent = _this.events.get(r);
      var toSend = _this.toSend.get(r);
      if (toSend != null && toSend.size > 0) {
        _this.toSend.delete(r);
        if (asyncEvent != null) {
          asyncEvent.event.post({
            readModelName: r,
            entities: toSend
          });
        }
      }
    };
    this.callbacks = function (e) {
      var event = _this.events.get(e.readModelName);
      if (event != null) {
        event.attached.forEach(function (value, key) {
          if (value.entityId == null || e.entities.has(value.entityId.toString())) {
            try {
              value.callback(e);
            } catch (e2) {}
            if (value.once === true) {
              _this.detach(e.readModelName, key);
            }
          }
        });
      }
    };
  }
  (0, _createClass2.default)(ReadModelChangedDefaultClientDispatcher, [{
    key: "attach",
    value: function attach(eventAttach) {
      var event = this.events.get(eventAttach.readModelName);
      if (event == null) {
        event = {
          event: new ts_events_1.AsyncEvent(),
          attached: new Map()
        };
        event.event.attach(this.callbacks);
        this.events.set(eventAttach.readModelName, event);
      }
      var key = eventAttach.attachKey != null ? eventAttach.attachKey : uuid.v1();
      event.attached.set(key, eventAttach);
      this.events.set(eventAttach.readModelName, event);
      return key;
    }
  }, {
    key: "detach",
    value: function detach(readModelName, key) {
      var d = this.events.get(readModelName);
      if (d != null) {
        var d2 = d.attached.get(key);
        if (d2 != null) {
          d.attached.delete(key);
        }
        this.events.set(readModelName, d);
      }
    }
  }, {
    key: "post",
    value: function post(e) {
      var _this2 = this;
      try {
        var ent = ReadModels_1.ReadModels.get(e.readModelName);
        if (ent != null) {
          var to = this.timeOuts.get(e.readModelName);
          if (to != null) clearTimeout(to);
          var toSend = this.toSend.get(e.readModelName);
          if (toSend == null) toSend = new Map();
          e.entities.forEach(function (e, k) {
            var entity = ent.exampleEntity.createOne(e.entity);
            toSend.set(k, {
              entity: entity
            });
          });
          this.toSend.set(e.readModelName, toSend);
          if (this.timeOutForCollectEntities > 0) {
            var t = setTimeout(function () {
              _this2.postReadModel(e.readModelName);
            }, 150);
            this.timeOuts.set(e.readModelName, t);
          } else {
            this.postReadModel(e.readModelName);
          }
          var asyncEventAny = this.events.get('any');
          if (asyncEventAny != null) {
            e.entities.forEach(function (en) {
              en.entity = ent.exampleEntity.createOne(en.entity);
              asyncEventAny.attached.forEach(function (value, key) {
                if (value.entityId == null || value.entityId.toString() === en.entity.id.toString()) {
                  try {
                    value.callback(e);
                  } catch (e2) {}
                  if (value.once === true) {
                    _this2.detach('any', key);
                  }
                }
              });
            });
          }
        }
      } catch (err) {}
    }
  }], [{
    key: "instance",
    get: function get() {
      return ReadModelChangedDefaultClientDispatcher._instance;
    }
  }]);
  return ReadModelChangedDefaultClientDispatcher;
}();
exports.ReadModelChangedDefaultClientDispatcher = ReadModelChangedDefaultClientDispatcher;
ReadModelChangedDefaultClientDispatcher._instance = new ReadModelChangedDefaultClientDispatcher();