"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CommandRemoteStore = exports.CommandRemoteStoreConfig = void 0;
var ApplicationError_1 = require("odatarepos/build/src/entities/ApplicationError");
var ts_events_1 = require("ts-events");
var uuid = require("uuid");
var CommandRemoteStoreConfig = (0, _createClass2.default)(function CommandRemoteStoreConfig(serverConnection) {
  var dispatcher = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var waitForResult = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  (0, _classCallCheck2.default)(this, CommandRemoteStoreConfig);
  this.serverConnection = serverConnection;
  this.dispatcher = dispatcher;
  this.waitForResult = waitForResult;
});
exports.CommandRemoteStoreConfig = CommandRemoteStoreConfig;
var CommandRemoteStore = function () {
  function CommandRemoteStore(config) {
    (0, _classCallCheck2.default)(this, CommandRemoteStore);
    this.onError = new ts_events_1.AsyncEvent();
    this.config = config;
  }
  (0, _createClass2.default)(CommandRemoteStore, [{
    key: "isConnected",
    get: function get() {
      if (this.config.serverConnection == null) return false;
      return this.config.serverConnection().isConnected;
    }
  }, {
    key: "serverConnection",
    get: function get() {
      return this.config.serverConnection ? this.config.serverConnection() : undefined;
    }
  }, {
    key: "save",
    value: function () {
      var _save = (0, _asyncToGenerator2.default)(function* (command) {
        var _this = this;
        var sock = this.serverConnection ? this.serverConnection.socket : undefined;
        if (sock == null || !sock.connected) {
          throw {
            message: 'Server not connected',
            messageCode: 'noConnection'
          };
        }
        var id = uuid.v1();
        var socketData = {
          id: id,
          authToken: command.token,
          aggregate: command.aggregate,
          commandName: command.commandName,
          entityId: command.entityId,
          data: command.data
        };
        if (!this.config.waitForResult || !command.waitForResult || this.config.dispatcher == null) return this.sendCommandOverSocket(sock, socketData, command.timeout ? command.timeout : 20000);
        var timerForResult;
        return new Promise(function (resolve, reject) {
          var _this$config$dispatch;
          var attachKey = (_this$config$dispatch = _this.config.dispatcher) == null ? void 0 : _this$config$dispatch.attach({
            entityId: command.entityId,
            once: true,
            readModelName: 'any',
            callback: function callback(e) {
              var _e$entities$get;
              if (timerForResult != null) clearTimeout(timerForResult);
              resolve((_e$entities$get = e.entities.get(command.entityId)) == null ? void 0 : _e$entities$get.entity);
            }
          });
          _this.sendCommandOverSocket(sock, socketData, command.timeout ? command.timeout : 20000).then(function (data) {
            timerForResult = setTimeout(function () {
              var _this$config$dispatch2;
              (_this$config$dispatch2 = _this.config.dispatcher) == null || _this$config$dispatch2.detach('any', attachKey);
              resolve(data);
            }, command.timeout ? command.timeout : 20000);
          }).catch(function (e) {
            var _this$config$dispatch3;
            (_this$config$dispatch3 = _this.config.dispatcher) == null || _this$config$dispatch3.detach('any', attachKey);
            reject(e);
          });
        });
      });
      function save(_x) {
        return _save.apply(this, arguments);
      }
      return save;
    }()
  }, {
    key: "sendCommandOverSocket",
    value: function () {
      var _sendCommandOverSocket = (0, _asyncToGenerator2.default)(function* (sock, e, timeout) {
        var response;
        try {
          response = yield sock.timeout(timeout).emitWithAck('command', e);
        } catch (err) {
          var err2 = new ApplicationError_1.ApplicationError('Es sieht so aus, als würde der Server zu lange brauchen, um zu antworten. Bitte versuchen Sie es später noch einmal', 'commandExecuteTimeout', 408);
          this.onError.post(Object.assign({}, err2, {
            commandName: e.commandName,
            data: e.data
          }));
          throw err2;
        }
        if (response != null && response['error']) {
          this.onError.post(Object.assign({}, response['error'], {
            commandName: e.commandName,
            data: e.data
          }));
          throw response['error'];
        }
        return response;
      });
      function sendCommandOverSocket(_x2, _x3, _x4) {
        return _sendCommandOverSocket.apply(this, arguments);
      }
      return sendCommandOverSocket;
    }()
  }]);
  return CommandRemoteStore;
}();
exports.CommandRemoteStore = CommandRemoteStore;