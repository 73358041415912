"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.JournalEntity = exports.JournalAttachment = exports.JournalOwner = exports.AttendanceJournal = exports.EmployeeJournal = exports.EmployeeGroup = exports.Employee = exports.WeatherJournal = void 0;
var ReadModelEntity_1 = require("cqrs-core/build/src/core/query/ReadModelEntity");
var moment = require("moment");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var WeatherJournal = (0, _createClass2.default)(function WeatherJournal() {
  (0, _classCallCheck2.default)(this, WeatherJournal);
});
exports.WeatherJournal = WeatherJournal;
var Employee = (0, _createClass2.default)(function Employee() {
  (0, _classCallCheck2.default)(this, Employee);
});
exports.Employee = Employee;
var EmployeeGroup = (0, _createClass2.default)(function EmployeeGroup() {
  (0, _classCallCheck2.default)(this, EmployeeGroup);
});
exports.EmployeeGroup = EmployeeGroup;
var EmployeeJournal = function () {
  function EmployeeJournal() {
    (0, _classCallCheck2.default)(this, EmployeeJournal);
  }
  (0, _createClass2.default)(EmployeeJournal, null, [{
    key: "isGroup",
    value: function isGroup(e) {
      return e.employee['workerCount'] != null;
    }
  }]);
  return EmployeeJournal;
}();
exports.EmployeeJournal = EmployeeJournal;
var AttendanceJournal = (0, _createClass2.default)(function AttendanceJournal() {
  (0, _classCallCheck2.default)(this, AttendanceJournal);
});
exports.AttendanceJournal = AttendanceJournal;
var JournalOwner = (0, _createClass2.default)(function JournalOwner() {
  (0, _classCallCheck2.default)(this, JournalOwner);
});
exports.JournalOwner = JournalOwner;
var JournalAttachment = (0, _createClass2.default)(function JournalAttachment() {
  (0, _classCallCheck2.default)(this, JournalAttachment);
});
exports.JournalAttachment = JournalAttachment;
var JournalEntity = function (_ReadModelEntity_1$Re) {
  (0, _inherits2.default)(JournalEntity, _ReadModelEntity_1$Re);
  var _super = _createSuper(JournalEntity);
  function JournalEntity(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, JournalEntity);
    _this = _super.call(this, obj);
    _this.entityName = 'Journal';
    _this.attendance = [];
    _this.employee = [];
    _this.ticketReportTemplateId = '';
    _this.weather = [];
    _this.imagesPerLine = 3;
    _this.photoQuality = 'normal';
    _this.fill(obj);
    return _this;
  }
  (0, _createClass2.default)(JournalEntity, [{
    key: "createOne",
    value: function createOne(obj) {
      return new JournalEntity(obj);
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('projectId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('owner', new OdataEntity_1.OdataIndex(true));
      baseMap.set('forDay', new OdataEntity_1.OdataIndex(true));
      baseMap.set('employee', new OdataEntity_1.OdataIndex());
      baseMap.set('attendance', new OdataEntity_1.OdataIndex());
      baseMap.set('specialOccurrences', new OdataEntity_1.OdataIndex());
      baseMap.set('attachments', new OdataEntity_1.OdataIndex());
      baseMap.set('workDone', new OdataEntity_1.OdataIndex());
      baseMap.set('ticketReportAttachment', new OdataEntity_1.OdataIndex());
      baseMap.set('weather', new OdataEntity_1.OdataIndex());
      baseMap.set('journal', new OdataEntity_1.OdataIndex());
      baseMap.set('includeTicketReport', new OdataEntity_1.OdataIndex());
      baseMap.set('ticketReportFilter', new OdataEntity_1.OdataIndex());
      baseMap.set('ticketReportSettings', new OdataEntity_1.OdataIndex());
      baseMap.set('send', new OdataEntity_1.OdataIndex(true));
      baseMap.set('imagesPerLine', new OdataEntity_1.OdataIndex());
      baseMap.set('photoQuality', new OdataEntity_1.OdataIndex());
      baseMap.set('additionalFields', new OdataEntity_1.OdataIndex());
      baseMap.set('ticketReportTemplateId', new OdataEntity_1.OdataIndex());
      return baseMap;
    }
  }], [{
    key: "getNormalizedDateShort",
    value: function getNormalizedDateShort() {
      var date = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : new Date();
      return moment(date).format('YYYYMMDD');
    }
  }, {
    key: "getNormalizedDateShortToDate",
    value: function getNormalizedDateShortToDate(date) {
      try {
        if (date.length === 8) return new Date(Number.parseInt(date.substr(0, 4), 10), Number.parseInt(date.substr(4, 2), 10) - 1, Number.parseInt(date.substr(6, 2), 10), 12, 0, 0, 0);
      } catch (e) {}
      var now = new Date();
      return new Date(now.getFullYear(), now.getMonth(), now.getDate(), 12, 0, 0, 0);
    }
  }, {
    key: "getFilterForDate",
    value: function getFilterForDate(userId, date, projectId, companyId) {
      var queryDate = JournalEntity.getNormalizedDateShort(date);
      var userOrCompanyId = companyId != null ? companyId : userId;
      return `owner/id eq '${userOrCompanyId}' and projectId eq '${projectId}' and forDay eq '${queryDate}'`;
    }
  }, {
    key: "minutesToTimeString",
    value: function minutesToTimeString(num) {
      var hours = Math.floor(num / 60);
      var minutes = num % 60;
      var rminutes = minutes > 9 ? minutes.toString(10) : `0${minutes.toString(10)}`;
      return `${hours}:${rminutes}`;
    }
  }]);
  return JournalEntity;
}(ReadModelEntity_1.ReadModelEntity);
exports.JournalEntity = JournalEntity;