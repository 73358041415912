import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Form } from 'materialTheme/src/theme/components/forminput/Form';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { ChangeCompany } from 'upmesh-support-core/src/commands/company/ChangeCompany';
import { ConnectionHandler } from '../../ConnectionHandler';
export class ChangeCompanyDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.init = async () => {
            const client = ConnectionHandler.getClient();
            const { data } = this.props;
            if (client != null && data != null && data.id != null) {
                const company = await client.company.getById(data.id);
                if (company == null)
                    return;
                this.setState({ company });
            }
        };
        this.close = (e) => {
            Routing.instance.closeDialog(true)(e);
        };
        this.catchError = (e) => {
            Routing.instance.alert.post({
                text: `error: ${JSON.stringify(e)}`,
            });
            return true;
        };
        this.onSaved = () => {
            this.close();
        };
        this.state = {
            error: '',
        };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug('cant init Dialog', err));
    }
    render() {
        const { company } = this.state;
        if (company == null)
            return <Spinner />;
        const dataOptions = new Map();
        dataOptions.set('company', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Firma',
                autoFocus: true,
            },
        });
        if (!ConnectionHandler.isPartner) {
            dataOptions.set('partnerCustomerNumber', {
                formType: 'string',
                cols: 1,
                optional: true,
                props: {
                    labelText: 'Kundennummer',
                },
            });
        }
        else {
            dataOptions.set('customerNumber', {
                formType: 'string',
                cols: 1,
                optional: true,
                props: {
                    labelText: 'Kundennummer',
                },
            });
            dataOptions.set('billwerkCustomerNumber', {
                formType: 'string',
                cols: 1,
                optional: true,
                props: {
                    labelText: 'Billwerk Kundennummer',
                },
            });
        }
        dataOptions.set('contactName', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Kontaktname',
            },
        });
        dataOptions.set('email', {
            formType: 'email',
            cols: 1,
            props: {
                labelText: 'E-Mail Adresse',
            },
        });
        dataOptions.set('description', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Beschreibung',
            },
        });
        dataOptions.set('street', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Straße',
            },
        });
        dataOptions.set('streetNr', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Hausnummer',
            },
        });
        dataOptions.set('zip', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'PLZ',
            },
        });
        dataOptions.set('city', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Stadt',
            },
        });
        dataOptions.set('country', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Land',
            },
        });
        dataOptions.set('phone', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Telefon',
            },
        });
        return (<Form baseCols={1} catchError={this.catchError} dataOptions={dataOptions} formHeaderProps={{
                formTitle: 'Firma ändern',
            }} store={ConnectionHandler.getStore()} command={new ChangeCompany({ ...company }, this.props.data.id, ConnectionHandler.serverConnection.token)} additionalButtons={[
                <ContainedButton key="abort" onPress={this.close} title="abbrechen" backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
            ]} onSaved={this.onSaved} saveButtonLabel="speichern" stickyHeader stickyActions resetButton serverConnection={ConnectionHandler.getConnection()}/>);
    }
}
