import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
export function PaymentTableSum(props) {
    const [summe, setSumme] = useState(0);
    useEffect(() => {
        let s = 0;
        props.p.forEach((p) => {
            s += p.totalPrice;
        });
        setSumme(Math.round(s * 100) / 100);
    }, [props.p]);
    return (<View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <Icon toolTip="" icon="sigma"/>
      <View>
        <MaterialText>{summe.toString()} €</MaterialText>
      </View>
    </View>);
}
