import { ControlNotesEntity } from 'upmesh-support-core/src/entities/ControlNotesEntity';
import { ConnectionHandler } from '../../ConnectionHandler';
export class ControlNoteExtended extends ControlNotesEntity {
    static async getToName(item) {
        if (item.for.type === 'partner') {
            if (ControlNoteExtended.partners[item.for.id] != null)
                return ControlNoteExtended.partners[item.for.id];
            const partner = await ConnectionHandler.getClient()?.upmeshPartner.getById(item.for.id);
            if (partner != null) {
                ControlNoteExtended.partners[item.for.id] = partner.company;
            }
            return partner != null ? partner.company : '?';
        }
        if (item.for.type === 'user') {
            if (ControlNoteExtended.users[item.for.id] != null)
                return ControlNoteExtended.users[item.for.id];
            const user = await ConnectionHandler.getClient()?.user.getById(item.for.id);
            if (user != null) {
                ControlNoteExtended.users[item.for.id] = user.getFullName();
            }
            return user != null ? user.getFullName() : 'Support User';
        }
        if (ControlNoteExtended.companies[item.for.id] != null)
            return ControlNoteExtended.companies[item.for.id];
        const company = await ConnectionHandler.getClient()?.company.getById(item.for.id);
        if (company != null) {
            ControlNoteExtended.companies[item.for.id] = company.company;
        }
        return company != null ? company.company : '?';
    }
    static async getFromName(item) {
        if (item.createdBy == null)
            return 'upmesh';
        if (ControlNoteExtended.users[item.createdBy] != null)
            return ControlNoteExtended.users[item.createdBy];
        const user = await ConnectionHandler.getClient()?.user.getById(item.createdBy);
        if (user != null) {
            ControlNoteExtended.users[item.createdBy] = user.getFullName();
        }
        return user != null ? user.getFullName() : 'Support User';
    }
    static async ControlNoteToExtended(p) {
        const p2 = p;
        p2.toName = await ControlNoteExtended.getToName(p);
        p2.fromName = await ControlNoteExtended.getFromName(p);
        return p2;
    }
}
ControlNoteExtended.partners = {};
ControlNoteExtended.companies = {};
ControlNoteExtended.users = {};
