"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CostCenterEntity = void 0;
var ReadModelEntity_1 = require("cqrs-core/build/src/core/query/ReadModelEntity");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var CostCenterEntity = function (_ReadModelEntity_1$Re) {
  (0, _inherits2.default)(CostCenterEntity, _ReadModelEntity_1$Re);
  var _super = _createSuper(CostCenterEntity);
  function CostCenterEntity(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, CostCenterEntity);
    _this = _super.call(this, obj);
    _this.entityName = 'CostCenter';
    _this.deleted = false;
    _this.active = true;
    _this.fill(obj);
    return _this;
  }
  (0, _createClass2.default)(CostCenterEntity, [{
    key: "createOne",
    value: function createOne(obj) {
      return new CostCenterEntity(obj);
    }
  }, {
    key: "getName",
    value: function getName() {
      if (this.description != null && this.description.length > 0) {
        return `${this.mark} - ${this.description}`;
      }
      return `${this.mark}`;
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('foreignId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('companyId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('mark', new OdataEntity_1.OdataIndex(true));
      baseMap.set('description', new OdataEntity_1.OdataIndex());
      baseMap.set('costType', new OdataEntity_1.OdataIndex(true));
      baseMap.set('projectIds', new OdataEntity_1.OdataIndex(true));
      baseMap.set('deleted', new OdataEntity_1.OdataIndex(true));
      baseMap.set('active', new OdataEntity_1.OdataIndex(true));
      return baseMap;
    }
  }]);
  return CostCenterEntity;
}(ReadModelEntity_1.ReadModelEntity);
exports.CostCenterEntity = CostCenterEntity;