import * as Sentry from '@sentry/browser';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ConfigAll } from 'upmesh-client/src/config/ConfigAll';
import './assets/materialTheme.web.css';
import './assets/tui-editor.web.css';
import './assets/upmesh.web.css';
import { unregister } from './registerServiceWorker';
import { SupportApp } from './SupportApp';
ErrorReporter.ENVIRONMENT = ConfigAll.getEnvironment();
ConfigAll.init()
    .then()
    .catch((err) => console.debug('cant init', err))
    .finally(() => {
    if (ConfigAll.getEnvironment() !== 'development') {
        Sentry.init({
            dsn: 'https://b1b7dd1e52d848728968e55b476ec864@s.upmesh.de/2',
            tracesSampleRate: ConfigAll.getEnvironment() === 'development' ? 0 : 0.1,
            environment: `control_${ErrorReporter.ENVIRONMENT}`,
            release: ConfigAll.getVersion(),
            integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });
        ErrorReporter.Reporter = Sentry;
    }
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(<SupportApp />);
    unregister();
});
