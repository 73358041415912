var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = require("../../../../../upmesh-support/node_modules/react");
var useScroll = function useScroll() {
  var _useState = (0, _react.useState)({
      x: 0,
      y: 0
    }),
    _useState2 = (0, _slicedToArray2.default)(_useState, 2),
    size = _useState2[0],
    setSize = _useState2[1];
  var onScroll = (0, _react.useCallback)(function (event) {
    var _event$nativeEvent$co = event.nativeEvent.contentOffset,
      x = _event$nativeEvent$co.x,
      y = _event$nativeEvent$co.y;
    setSize({
      x: x,
      y: y
    });
  }, []);
  return [size, onScroll];
};
var _default = exports.default = useScroll;