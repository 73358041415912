"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OdataEntity = exports.OdataIndex = void 0;
var uuid = require("uuid");
var OdataIndex = (0, _createClass2.default)(function OdataIndex() {
  var index = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var unique = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var sparse = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var fullText = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  (0, _classCallCheck2.default)(this, OdataIndex);
  this.index = false;
  this.sparse = false;
  this.unique = false;
  this.fullText = false;
  this.index = index;
  this.unique = unique;
  this.sparse = sparse;
  this.fullText = fullText;
});
exports.OdataIndex = OdataIndex;
var OdataEntity = function () {
  function OdataEntity(obj) {
    (0, _classCallCheck2.default)(this, OdataEntity);
    this.createdAt = new Date();
    this.lastModifiedAt = new Date();
    if (obj != null) {
      this.fill(obj);
    }
    if (this.id == null || this.id.length === 0) {
      this.createdAt = new Date();
      this.id = uuid.v1();
    }
  }
  (0, _createClass2.default)(OdataEntity, [{
    key: "dBOptions",
    get: function get() {
      var map = OdataEntity.entityDBOptions.get(this.entityName);
      if (map == null) {
        map = this.createMap();
        OdataEntity.entityDBOptions.set(this.entityName, map);
      }
      return map;
    }
  }, {
    key: "createArray",
    value: function createArray(obj) {
      if (Array.isArray(obj)) {
        return this.convertArray(obj);
      }
      if (obj['0'] != null) {
        return this.convertObjectArray(obj);
      }
      var o = Object.create(this);
      o.fill(obj);
      return [o];
    }
  }, {
    key: "fill",
    value: function fill(object) {
      var obj = object != null ? JSON.parse(JSON.stringify(object)) : {};
      var options = this.dBOptions;
      for (var k in obj) {
        try {
          if (k != null && !k.startsWith('$')) {
            obj[k] = OdataEntity.dateReviver(k, obj[k]);
            if (options.has(k) || k.startsWith('$')) {
              if (this[k] instanceof Date && !(obj[k] instanceof Date)) {
                this[k] = new Date(obj[k]);
              } else if (obj[k] != null && Array.isArray(obj[k])) {
                this[k] = Array.from(obj[k]);
              } else {
                this[k] = obj[k];
              }
            }
          }
        } catch (e) {}
      }
    }
  }, {
    key: "getActions",
    value: function () {
      var _getActions = (0, _asyncToGenerator2.default)(function* () {
        return [];
      });
      function getActions() {
        return _getActions.apply(this, arguments);
      }
      return getActions;
    }()
  }, {
    key: "convertArray",
    value: function convertArray(obj) {
      var resultArray = [];
      for (var i = 0; i < obj.length; i += 1) {
        var o = this.createOne(obj[i]);
        resultArray.push(o);
      }
      return resultArray;
    }
  }, {
    key: "convertObjectArray",
    value: function convertObjectArray(obj) {
      var resultArray = [];
      for (var key in obj) {
        var o = this.createOne(obj[key]);
        resultArray.push(o);
      }
      return resultArray;
    }
  }], [{
    key: "createBaseMap",
    value: function createBaseMap() {
      var map = new Map();
      map.set('id', new OdataIndex(true, true));
      map.set('createdBy', new OdataIndex(true));
      map.set('createdAt', new OdataIndex(true));
      map.set('lastModifiedAt', new OdataIndex(true));
      map.set('lastModifiedBy', new OdataIndex(true));
      return map;
    }
  }]);
  return OdataEntity;
}();
exports.OdataEntity = OdataEntity;
OdataEntity.entityDBOptions = new Map();
OdataEntity.dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
OdataEntity.dateReviver = function (_key, value) {
  if (typeof value === 'string' && OdataEntity.dateFormat.test(value)) {
    return new Date(value);
  }
  if (typeof value === 'string') {
    return value.trim();
  }
  return value;
};