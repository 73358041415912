import Cookies from 'js-cookie';
import { Header } from 'materialTheme/src/theme/components/header/Header';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { ScrollView, UIManager, View } from 'react-native';
import { ConnectionHandler } from './ConnectionHandler';
import { I18n } from './i18n/I18n';
export class Page extends PureComponent {
    constructor() {
        super(...arguments);
        this.logOut = () => {
            LoadingEvents.instance.startLoading();
            Cookies.set('token', '');
            Cookies.set('lastServer', '');
            ConnectionHandler.supportClient = undefined;
            location.reload();
        };
        this.openMenu = (e) => {
            UIManager.measureInWindow(e.nativeEvent.target, (x, y, _width, height) => {
                const client = {
                    x,
                    y,
                    height,
                    width: 256,
                };
                const items = [
                    {
                        text: I18n.m.getMessage('dashboard'),
                        onPress: Routing.instance.goToButton('dashboard'),
                        thumbnail: { thumbnail: <Icon icon="view-dashboard" toolTip=""/>, width: 24 },
                    },
                ];
                if (ConnectionHandler.isPartner === false) {
                    items.push({
                        text: I18n.m.getMessage('user'),
                        onPress: Routing.instance.goToButton('users'),
                        thumbnail: { thumbnail: <Icon icon="account" toolTip=""/>, width: 24 },
                    }, {
                        text: `${I18n.m.getMessage('user')} online`,
                        onPress: Routing.instance.goToButton('online'),
                        thumbnail: { thumbnail: <Icon icon="transit-connection-horizontal" toolTip=""/>, width: 24 },
                    }, {
                        text: I18n.m.getMessage('projects'),
                        onPress: Routing.instance.goToButton('projects'),
                        thumbnail: { thumbnail: <Icon icon="domain" toolTip=""/>, width: 24 },
                    }, {
                        text: I18n.m.getMessage('partner'),
                        onPress: Routing.instance.goToButton('partner'),
                        thumbnail: { thumbnail: <Icon icon="domain-plus" toolTip=""/>, width: 24 },
                    });
                }
                else {
                    items.push({
                        text: I18n.m.getMessage('partner'),
                        onPress: Routing.instance.goToButton('partner'),
                        thumbnail: { thumbnail: <Icon icon="domain-plus" toolTip=""/>, width: 24 },
                    });
                }
                items.push({
                    text: I18n.m.getMessage('companyCustomers'),
                    onPress: Routing.instance.goToButton('companies'),
                    thumbnail: { thumbnail: <Icon icon="domain" toolTip=""/>, width: 24 },
                });
                items.push({
                    text: I18n.m.getMessage('subscriptions'),
                    onPress: Routing.instance.goToButton('subscription'),
                    thumbnail: { thumbnail: <Icon icon="account-cash-outline" toolTip=""/>, width: 24 },
                });
                items.push({
                    text: I18n.m.getMessage('payments'),
                    onPress: Routing.instance.goToButton('payments'),
                    thumbnail: { thumbnail: <Icon icon="cash-multiple" toolTip=""/>, width: 24 },
                });
                if (ConnectionHandler.myRole?.role === 'Admin') {
                    items.push({
                        text: 'Server-Logs',
                        onPress: Routing.instance.goToButton('logs'),
                        thumbnail: { thumbnail: <Icon icon="archive-outline" toolTip=""/>, width: 24 },
                    });
                }
                Menu.instance?.open({
                    client,
                    items,
                });
            });
        };
    }
    render() {
        const { props } = this;
        const rightButtons = props.rightButtons != null ? props.rightButtons : [];
        rightButtons.push(<Icon toolTip={I18n.m.getMessage('logout')} icon="logout" key="logout" onPress={this.logOut}/>);
        const header = ConnectionHandler.supportClient == null ? null : (<Header title={`${I18n.m.getMessage('upmesh')} ${I18n.m.getMessage('admin')} - ${props.pageTitle}`} leftButtons={[<Icon toolTip="" icon="menu" key="menu" onPress={this.openMenu}/>]} rightButtons={rightButtons}/>);
        return (<View {...props} style={{ width: '100%', height: '100%', backgroundColor: ThemeManager.style.appBgColorDarken }}>
        {header}
        <ScrollView style={{ width: '100%', height: ResizeEvent.current.contentHeight - 48 }} contentContainerStyle={{ height: 'auto', minHeight: ResizeEvent.current.contentHeight - 48 }}>
          <View style={{ padding: 16, height: 'auto', minHeight: ResizeEvent.current.contentHeight - 48 }}>
            {props.children}
          </View>
        </ScrollView>
      </View>);
    }
}
