"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EventHandler = void 0;
var ts_events_1 = require("ts-events");
var EventHandler = function () {
  function EventHandler() {
    (0, _classCallCheck2.default)(this, EventHandler);
    this.events = new Map();
  }
  (0, _createClass2.default)(EventHandler, [{
    key: "on",
    value: function on(eventName, callback) {
      var tsEvent = this.events.get(eventName);
      if (tsEvent == null) {
        tsEvent = new ts_events_1.AsyncEvent();
        this.events.set(eventName, tsEvent);
      } else {
        tsEvent.detach(callback);
      }
      return tsEvent.attach(callback);
    }
  }, {
    key: "post",
    value: function post(e) {
      var tsEvent = this.events.get(e.eventName);
      if (tsEvent != null) {
        tsEvent.post(e);
        return true;
      }
      return false;
    }
  }]);
  return EventHandler;
}();
exports.EventHandler = EventHandler;
EventHandler.defaultHandler = new EventHandler();