var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChipDialogForm = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var uuid = _interopRequireWildcard(require("uuid"));
var _I18n = require("../../i18n/I18n");
var _ResizeEvent = require("../../ResizeEvent");
var _ThemeManager = require("../../ThemeManager");
var _ContainedButton = require("../button/ContainedButton");
var _SegmentedButton = require("../button/SegmentedButton");
var _Icon = require("../Icon");
var _ListItem = require("../ListItem");
var _SearchBar = require("../SearchBar");
var _MaterialText = require("../text/MaterialText");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var ChipDialogForm = exports.ChipDialogForm = function (_Component) {
  (0, _inherits2.default)(ChipDialogForm, _Component);
  var _super = _createSuper(ChipDialogForm);
  function ChipDialogForm(props) {
    var _this;
    (0, _classCallCheck2.default)(this, ChipDialogForm);
    _this = _super.call(this, props);
    _this.sortByTitle = function (a, b) {
      return `${a.title}`.localeCompare(`${b.title}`);
    };
    _this.sortByGroup = function (a, b) {
      var x = a.groupId ? a.groupId.toLowerCase() : '';
      var y = b.groupId ? b.groupId.toLowerCase() : '';
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      if (_this.props.disableAutoSort != null || _this.props.disableAutoSort === false) {
        return _this.props.sortFunction ? _this.props.sortFunction(a, b) : _this.sortByTitle(a, b);
      }
      return 0;
    };
    _this.getItemKey = function (item, _index) {
      return `item_${item.id != null ? item.id : uuid.v1()}`;
    };
    _this.renderRow = function (_ref) {
      var item = _ref.item,
        index = _ref.index;
      var _this$props = _this.props,
        multiselect = _this$props.multiselect,
        singleSelectSelected = _this$props.singleSelectSelected,
        sortByGroup = _this$props.sortByGroup;
      var searchedItems = _this.state.searchedItems;
      var isSelected = multiselect ? item.selected && item.selected === true : singleSelectSelected === index;
      if (sortByGroup !== true) {
        return (0, _jsxRuntime.jsx)(_ListItem.ListItem, Object.assign({
          iconRight: _this.getCheckboxIcon(isSelected),
          onPress: multiselect ? _this.checkMultiselect(index) : item.onPress
        }, item, {
          selected: false
        }), `item_${item.id != null ? item.id : uuid.v1()}`);
      }
      var checkbox = _this.getGroupCheckboxIcon(item.groupId);
      var groupChanged = index > 0 && searchedItems != null && item.groupId != null && item.groupId !== searchedItems[index - 1].groupId;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          width: '100%',
          borderTopWidth: groupChanged ? _ThemeManager.ThemeManager.style.borderWidth : 0,
          borderTopColor: _ThemeManager.ThemeManager.style.borderColor
        },
        children: [index === 0 && item.groupId !== 'AddNewListItem' || groupChanged ? (0, _jsxRuntime.jsx)(_ListItem.ListItem, {
          iconRight: checkbox,
          onPress: multiselect ? _this.checkGroupMultiselect(item.groupId, (checkbox == null ? void 0 : checkbox.color) === _ThemeManager.ThemeManager.style.brandPrimary) : undefined,
          title: item.secondTextLine && item.secondTextLine.length > 0 ? item.secondTextLine : _I18n.I18n.m.getMessage('chipGroupNoGroup'),
          secondTextLine: ""
        }, `group${item.groupId != null ? item.id : uuid.v1()}`) : undefined, (0, _jsxRuntime.jsx)(_ListItem.ListItem, Object.assign({
          iconRight: _this.getCheckboxIcon(isSelected),
          onPress: multiselect ? _this.checkMultiselect(index) : item.onPress,
          thumbnail: {
            thumbnail: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {}),
            width: 24
          }
        }, item, {
          selected: false,
          secondTextLine: ""
        }), `item_${item.id != null ? item.id : uuid.v1()}`)]
      });
    };
    _this.getCheckboxIcon = function (checked) {
      var _this$props2 = _this.props,
        multiselect = _this$props2.multiselect,
        showSelectedIcons = _this$props2.showSelectedIcons;
      if (showSelectedIcons === false) return undefined;
      if (multiselect && multiselect === true) {
        if (checked) {
          return {
            icon: 'checkbox-marked-outline',
            color: _ThemeManager.ThemeManager.style.brandPrimary
          };
        }
        return {
          icon: 'checkbox-blank-outline',
          color: _ThemeManager.ThemeManager.style.defaultIconColor
        };
      }
      if (checked) {
        return {
          icon: 'radiobox-marked',
          color: _ThemeManager.ThemeManager.style.brandPrimary
        };
      }
      return {
        icon: 'radiobox-blank',
        color: _ThemeManager.ThemeManager.style.defaultIconColor
      };
    };
    _this.getGroupCheckboxIcon = function (groupId) {
      var _this$props3 = _this.props,
        multiselect = _this$props3.multiselect,
        showSelectedIcons = _this$props3.showSelectedIcons;
      var searchedItems = _this.state.searchedItems;
      if (showSelectedIcons === false) return undefined;
      if (searchedItems != null) {
        var checked = true;
        searchedItems.forEach(function (item) {
          if (item.groupId === groupId && item.selected === false) {
            checked = false;
          }
        });
        if (multiselect && multiselect === true) {
          if (checked) {
            return {
              icon: 'checkbox-multiple-marked-outline',
              color: _ThemeManager.ThemeManager.style.brandPrimary
            };
          }
          return {
            icon: 'checkbox-multiple-blank-outline',
            color: _ThemeManager.ThemeManager.style.defaultIconColor
          };
        }
      }
      return undefined;
    };
    _this.checkMultiselect = function (index) {
      return function (_e) {
        _this.setState(function (prevState) {
          var searchedItems = prevState.searchedItems,
            items = prevState.items;
          if (searchedItems && items) {
            var selectedItem = searchedItems[index];
            var itemsIndex = items.findIndex(function (a) {
              return a === selectedItem;
            });
            var isSelected = searchedItems[index].selected === true;
            searchedItems[index].selected = !isSelected;
            if (itemsIndex >= 0) items[itemsIndex].selected = !isSelected;
            var searchedList = searchedItems.slice();
            if (_this.props.sortByGroup) searchedList = searchedItems.sort(_this.sortByGroup);
            return {
              items: items.slice(),
              searchedItems: searchedList
            };
          }
          return null;
        });
      };
    };
    _this.checkGroupMultiselect = function (groupId, checked) {
      return function (_e) {
        _this.setState(function (prevState) {
          var searchedItems = prevState.searchedItems,
            items = prevState.items;
          if (searchedItems && items) {
            var searchedList = searchedItems.slice();
            searchedList.forEach(function (item) {
              if (item.groupId === groupId) {
                item.selected = !checked;
              }
            });
            var wholeList = items.slice();
            wholeList.forEach(function (item) {
              if (item.groupId === groupId) {
                item.selected = !checked;
              }
            });
            return {
              items: wholeList,
              searchedItems: searchedList
            };
          }
          return null;
        });
      };
    };
    _this.onSearchSubmit = function () {
      var canAddNewChipsViaSearchbar = _this.props.canAddNewChipsViaSearchbar;
      var _this$state = _this.state,
        searchValue = _this$state.searchValue,
        addNewButtonDisabled = _this$state.addNewButtonDisabled;
      if (canAddNewChipsViaSearchbar === true && !addNewButtonDisabled) _this.addNewChip(searchValue)();
    };
    _this.searchOnChange = function (searchText) {
      var _this$props4 = _this.props,
        canAddNewChips = _this$props4.canAddNewChips,
        addNewText = _this$props4.addNewText,
        checkBeforeEnterNewText = _this$props4.checkBeforeEnterNewText,
        sortByGroup = _this$props4.sortByGroup,
        addNewButtons = _this$props4.addNewButtons;
      var items = _this.state.items;
      var searchedItems = [];
      if (items != null) {
        if (!searchText || searchText.length === 0) {
          searchedItems.push.apply(searchedItems, (0, _toConsumableArray2.default)(items));
        } else {
          var text = searchText.toLocaleLowerCase();
          for (var i = 0; i < items.length; i += 1) {
            var _items$i = items[i],
              title = _items$i.title,
              secondTextLine = _items$i.secondTextLine,
              thirdTextLine = _items$i.thirdTextLine;
            if (title.toLocaleLowerCase().indexOf(text) !== -1) {
              searchedItems.push(items[i]);
            } else if (secondTextLine != null && secondTextLine.toLocaleLowerCase().indexOf(text) !== -1) {
              searchedItems.push(items[i]);
            } else if (thirdTextLine != null && thirdTextLine.toLocaleLowerCase().indexOf(text) !== -1) {
              searchedItems.push(items[i]);
            }
          }
          var hasExactSearchText = items.find(function (item) {
            return item.title.toLocaleLowerCase() === searchText.toLocaleLowerCase();
          }) != null;
          if (!hasExactSearchText && canAddNewChips === true) {
            var disabled = checkBeforeEnterNewText != null ? checkBeforeEnterNewText(searchText) : false;
            if (addNewButtons != null) {
              var addNewLIs = addNewButtons(searchText);
              addNewLIs.forEach(function (b) {
                searchedItems.push(Object.assign({}, b, {
                  externalAddButton: true,
                  onPress: disabled === false ? _this.addNewChip(searchText, b.groupId) : undefined
                }));
              });
            } else {
              searchedItems.push({
                thumbnail: {
                  thumbnail: (0, _jsxRuntime.jsx)(_Icon.Icon, {
                    toolTip: "",
                    icon: "plus"
                  }),
                  width: 24,
                  rounded: true
                },
                title: addNewText != null ? addNewText(searchText) : '',
                id: searchText,
                iconRight: undefined,
                onPress: disabled === false ? _this.addNewChip(searchText) : undefined,
                textColor: disabled === false ? _ThemeManager.ThemeManager.style.black87 : _ThemeManager.ThemeManager.style.black42,
                groupId: 'AddNewListItem'
              });
            }
          }
        }
      }
      if (sortByGroup) searchedItems.sort(_this.sortByGroup);
      _this.setState({
        searchedItems: searchedItems,
        searchValue: searchText
      });
    };
    _this.addNewChip = function (text, groupId) {
      return function () {
        var _this$props5 = _this.props,
          onAddedNewItem = _this$props5.onAddedNewItem,
          checkBeforeEnterNewText = _this$props5.checkBeforeEnterNewText,
          sortByGroup = _this$props5.sortByGroup,
          chipGroups = _this$props5.chipGroups;
        var _this$state2 = _this.state,
          items = _this$state2.items,
          searchedItems = _this$state2.searchedItems;
        var newItems = items != null && items.length > 0 ? (0, _toConsumableArray2.default)(items) : [];
        var newSeachedItems = searchedItems != null && searchedItems.length > 0 ? (0, _toConsumableArray2.default)(searchedItems) : [];
        if (checkBeforeEnterNewText != null && checkBeforeEnterNewText(text)) return;
        var backgroundColor = null;
        if (groupId && chipGroups != null) {
          var group = chipGroups.find(function (group) {
            return group.id === groupId;
          });
          if (group != null) backgroundColor = group.backgroundColor;
        }
        var newChip = {
          title: text,
          id: text,
          selected: true,
          groupId: groupId,
          thumbnail: {
            thumbnail: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              style: {
                backgroundColor: backgroundColor || 'transparent',
                width: 24,
                height: 24,
                borderRadius: 12
              }
            }),
            width: 24
          }
        };
        newItems.push(newChip);
        newSeachedItems.splice(newSeachedItems.length - 1, 1);
        newSeachedItems.push(newChip);
        if (onAddedNewItem != null) {
          onAddedNewItem({
            title: text
          }, groupId);
        }
        if (sortByGroup) newSeachedItems.sort(_this.sortByGroup);
        _this.setState({
          items: newItems,
          searchedItems: newSeachedItems,
          searchValue: ''
        }, function () {
          return _this.searchOnChange('');
        });
      };
    };
    _this.onPressSave = function () {
      var onSave = _this.props.onSave;
      var items = _this.state.items;
      if (onSave && items) {
        onSave(items);
      }
    };
    _this.getAllSelected = function () {
      var searchedItems = _this.state.searchedItems;
      if (!searchedItems) return false;
      return searchedItems.findIndex(function (el) {
        return el.selected === false;
      }) === -1;
    };
    _this.toggleSelectAll = function () {
      var allSelected = _this.getAllSelected();
      _this.setState(function (oldState) {
        var _oldState$items, _oldState$searchedIte2;
        return {
          items: (_oldState$items = oldState.items) == null ? void 0 : _oldState$items.map(function (el) {
            var _oldState$searchedIte;
            var index = (_oldState$searchedIte = oldState.searchedItems) == null ? void 0 : _oldState$searchedIte.findIndex(function (x) {
              return x.id === el.id;
            });
            if (index !== -1) return Object.assign({}, el, {
              selected: !allSelected
            });
            return el;
          }),
          searchedItems: (_oldState$searchedIte2 = oldState.searchedItems) == null ? void 0 : _oldState$searchedIte2.map(function (el) {
            return Object.assign({}, el, {
              selected: !allSelected
            });
          })
        };
      });
    };
    var segmentedLists = props.segmentedLists;
    var activeSegment = props.activeSegment ? props.activeSegment : 0;
    var searchedItems = props.items;
    if (segmentedLists != null && segmentedLists.length > 0) searchedItems = segmentedLists[activeSegment].items;
    if (searchedItems) {
      if (_this.props.sortByGroup) searchedItems.sort(_this.sortByGroup);else if (_this.props.disableAutoSort == null || _this.props.disableAutoSort === false) {
        searchedItems.sort(_this.props.sortFunction != null ? _this.props.sortFunction : _this.sortByTitle);
      }
    }
    _this.state = {
      items: searchedItems,
      searchedItems: searchedItems,
      addNewButtonDisabled: false,
      searchValue: '',
      activeSegment: activeSegment
    };
    return _this;
  }
  (0, _createClass2.default)(ChipDialogForm, [{
    key: "render",
    value: function render() {
      var _this2 = this;
      var _this$props6 = this.props,
        title = _this$props6.title,
        sortFunction = _this$props6.sortFunction,
        icon = _this$props6.icon,
        multiselect = _this$props6.multiselect,
        onCancel = _this$props6.onCancel,
        searchBarPlaceholder = _this$props6.searchBarPlaceholder,
        size = _this$props6.size,
        saveButtonText = _this$props6.saveButtonText,
        segmentedLists = _this$props6.segmentedLists,
        hideSelectAll = _this$props6.hideSelectAll;
      var buttonText = saveButtonText != null ? saveButtonText : _I18n.I18n.m.getMessage('save');
      var _this$state3 = this.state,
        searchedItems = _this$state3.searchedItems,
        searchValue = _this$state3.searchValue,
        activeSegment = _this$state3.activeSegment;
      var s = size != null ? size : _ResizeEvent.ResizeEvent.current;
      var fullscreen = s.windowWidth <= _ThemeManager.ThemeManager.style.breakpointM;
      var windowHeight = fullscreen ? s.contentHeight : s.windowHeight * 0.9;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          width: '100%',
          height: windowHeight,
          maxHeight: '100%',
          position: 'relative',
          overflow: 'hidden'
        },
        children: [(0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: 16,
            alignItems: 'center',
            alignContent: 'flex-start'
          },
          children: [(0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            centeredBox: true,
            type: _MaterialText.MaterialTextTypes.H6,
            children: title
          }), icon ? (0, _jsxRuntime.jsx)(_Icon.Icon, Object.assign({}, icon)) : null]
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            height: 56,
            paddingHorizontal: 16,
            paddingBottom: 8,
            width: '100%'
          },
          children: (0, _jsxRuntime.jsx)(_SearchBar.SearchBar, {
            maxWidth: 0,
            searchBarValue: searchValue,
            searchBarPlaceholder: searchBarPlaceholder,
            searchOnChange: this.searchOnChange,
            onSubmitEditing: this.onSearchSubmit
          })
        }), segmentedLists != null && segmentedLists.length > 0 ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            height: 56,
            paddingHorizontal: 16,
            paddingBottom: 8,
            width: '100%'
          },
          children: (0, _jsxRuntime.jsx)(_SegmentedButton.SegmentedButton, {
            multiselect: false,
            buttons: segmentedLists,
            singleSelectSelected: activeSegment,
            onPress: function onPress(selected) {
              var segmentedLists = _this2.props.segmentedLists;
              if (segmentedLists != null && segmentedLists.length > 0) {
                var onChangedSegment = _this2.props.onChangedSegment;
                if (onChangedSegment != null) onChangedSegment(selected);
                var _searchedItems = (0, _toConsumableArray2.default)(segmentedLists[selected].items);
                _searchedItems.sort(sortFunction || _this2.sortByTitle);
                _this2.setState({
                  activeSegment: selected,
                  searchedItems: _searchedItems,
                  items: (0, _toConsumableArray2.default)(_searchedItems),
                  searchValue: ''
                });
              }
            }
          })
        }) : null, !hideSelectAll && multiselect ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            flexDirection: 'row',
            justifyContent: 'flex-end',
            paddingHorizontal: 16,
            paddingBottom: 8
          },
          children: (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            title: this.getAllSelected() ? _I18n.I18n.m.getMessage('chipDialogDeselectAll') : _I18n.I18n.m.getMessage('chipDialogSelectAll'),
            backgroundColor: "transparent",
            textColor: _ThemeManager.ThemeManager.style.brandPrimary,
            onPress: this.toggleSelectAll
          })
        }) : null, searchedItems == null || searchedItems.length === 0 ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            paddingHorizontal: 16,
            flex: 1
          },
          children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            children: _I18n.I18n.m.getMessage('searchNoResult')
          })
        }) : (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            padding: 0,
            flex: 1
          },
          children: (0, _jsxRuntime.jsx)(_reactNativeWeb.FlatList, {
            style: {
              height: '100%',
              paddingHorizontal: 16,
              flexGrow: 0,
              flexShrink: 0
            },
            renderItem: this.renderRow,
            data: searchedItems,
            keyExtractor: this.getItemKey
          }, "items_Flatlist")
        }), (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            paddingTop: 8,
            paddingBottom: 8,
            paddingRight: 16,
            alignSelf: 'flex-end',
            alignContent: 'flex-end',
            flexDirection: 'row'
          },
          children: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            title: _I18n.I18n.m.getMessage('cancel'),
            backgroundColor: "transparent",
            textColor: _ThemeManager.ThemeManager.style.brandPrimary,
            onPress: onCancel
          }), multiselect ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              paddingLeft: 8
            },
            children: (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
              title: buttonText,
              onPress: this.onPressSave
            })
          }) : null]
        })]
      });
    }
  }]);
  return ChipDialogForm;
}(_react.Component);