export default function register() {
    if (process?.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            const swUrl = `${process?.env.PUBLIC_URL}/service-worker.js`;
            navigator.serviceWorker
                .register(swUrl)
                .then((registration) => {
                if (registration == null) {
                    return;
                }
                registration.onupdatefound = () => {
                    const installingWorker = registration.installing;
                    if (installingWorker == null) {
                        return;
                    }
                    installingWorker.onstatechange = () => {
                        if (installingWorker.state === 'installed') {
                            if (navigator.serviceWorker.controller == null) {
                                console.debug('time to refresh');
                            }
                            else {
                                console.debug('precached');
                            }
                        }
                    };
                };
            })
                .catch((error) => {
                console.error('Error during service worker registration:', error);
            });
        });
    }
}
export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
            .then((registration) => {
            registration.unregister().catch((err) => console.debug(err));
        })
            .catch((err) => console.debug(err));
    }
}
