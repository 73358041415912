"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeTicketSubTicketFrom = void 0;
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../access/rights/RightsManager");
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var TicketSubTicketFromChanged_1 = require("../../events/tickets/TicketSubTicketFromChanged");
var TicketSubTicketFromRemoved_1 = require("../../events/tickets/TicketSubTicketFromRemoved");
var ClientProjectCommands_1 = require("../_root/ClientProjectCommands");
var ChangeTicketSubTicketFrom = function (_ClientProjectCommand) {
  (0, _inherits2.default)(ChangeTicketSubTicketFrom, _ClientProjectCommand);
  var _super = _createSuper(ChangeTicketSubTicketFrom);
  function ChangeTicketSubTicketFrom(data, entityId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ChangeTicketSubTicketFrom);
    _this = _super.call(this, data, entityId, token);
    _this.aggregate = 'project';
    _this.commandName = 'ChangeTicketSubTicketFrom';
    return _this;
  }
  (0, _createClass2.default)(ChangeTicketSubTicketFrom, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var ticket = yield CommandReadModels_1.CommandReadModels.ticket.getById(this.entityId);
        yield (0, _get2.default)((0, _getPrototypeOf2.default)(ChangeTicketSubTicketFrom.prototype), "checkProjectStatusAndGeneralAccess", this).call(this, ticket.projectId, userId);
        if (ticket.creator === userId) {
          return true;
        }
        if (yield RightsManager_1.RightsManager.hasWriteRight(ticket.projectId, userId, 'canChangeAllTickets')) {
          return true;
        }
        if (yield RightsManager_1.RightsManager.hasWriteRight(ticket.projectId, userId, 'commandChangeTicketSubTicketFrom')) {
          return true;
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var rm = yield CommandReadModels_1.CommandReadModels.ticket.getById(this.entityId);
        if (rm.subTicketFrom === this.data.ticketId) return [];
        if (this.data.ticketId == null || this.data.ticketId === '0' || this.data.ticketId === '') {
          if (rm.subTicketFrom != null && rm.subTicketFrom.length > 1) {
            return new TicketSubTicketFromRemoved_1.TicketSubTicketFromRemoved(this.entityId, {
              ticketId: rm.subTicketFrom
            }, rm.projectId);
          }
          return [];
        }
        yield CommandReadModels_1.CommandReadModels.ticket.getById(this.data.ticketId);
        var changed = new TicketSubTicketFromChanged_1.TicketSubTicketFromChanged(this.entityId, {
          subTicketFrom: this.data.ticketId
        }, rm.projectId);
        if (rm.subTicketFrom != null && rm.subTicketFrom.length > 1) {
          return [new TicketSubTicketFromRemoved_1.TicketSubTicketFromRemoved(this.entityId, {
            ticketId: rm.subTicketFrom
          }, rm.projectId), changed];
        }
        return changed;
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {});
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
  return ChangeTicketSubTicketFrom;
}(ClientProjectCommands_1.ClientProjectCommands);
exports.ChangeTicketSubTicketFrom = ChangeTicketSubTicketFrom;