var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ExcelFileDialog = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var XLSX = _interopRequireWildcard(require("xlsx"));
var _FilePicker = require("../../../file/FilePicker");
var _FilePicketComp = require("../../../file/FilePicketComp");
var _I18n = require("../../i18n/I18n");
var _ThemeManager = require("../../ThemeManager");
var _ContainedButton = require("../button/ContainedButton");
var _Dialog = require("../Dialog");
var _DialogActions = require("../dialog/DialogActions");
var _DialogContent = require("../dialog/DialogContent");
var _DialogTitle = require("../dialog/DialogTitle");
var _Table = require("../Table");
var _MaterialText = require("../text/MaterialText");
var _Spinner = require("../Spinner");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var ExcelFileDialog = exports.ExcelFileDialog = function (_PureComponent) {
  (0, _inherits2.default)(ExcelFileDialog, _PureComponent);
  var _super = _createSuper(ExcelFileDialog);
  function ExcelFileDialog(props) {
    var _this;
    (0, _classCallCheck2.default)(this, ExcelFileDialog);
    _this = _super.call(this, props);
    _this.filePickerId = 'excelUploadPickerId';
    _this.close = function (e) {
      var _Dialog$instance;
      (_Dialog$instance = _Dialog.Dialog.instance) == null || _Dialog$instance.close(e);
    };
    _this.save = function () {
      var rawJson = _this.state.rawJson;
      var _this$props = _this.props,
        saveFunction = _this$props.saveFunction,
        singleSaveFunction = _this$props.singleSaveFunction;
      if (singleSaveFunction != null) {
        _this.setState({
          saving: true
        });
        for (var i = 0; i < rawJson.length; i += 1) {
          var current = rawJson[i];
          singleSaveFunction(current).catch(function (err) {}).finally(function () {
            _this.setState(function (prevState) {
              return {
                finished: prevState.finished + 1
              };
            }, function () {
              if (_this.state.finished === rawJson.length) {
                var _Dialog$instance2;
                (_Dialog$instance2 = _Dialog.Dialog.instance) == null || _Dialog$instance2.close();
              }
            });
          });
        }
      } else {
        var _Dialog$instance3;
        (_Dialog$instance3 = _Dialog.Dialog.instance) == null || _Dialog$instance3.close(function () {
          saveFunction(rawJson).catch(function (err) {
            return void 0;
          });
        });
      }
    };
    _this.onGotFile = function (f) {
      var columns = _this.props.columns;
      var files = Object.values(f);
      var asynNow = function () {
        var _ref = (0, _asyncToGenerator2.default)(function* () {
          var data = yield _this.readToArrayBuffer(files[0]);
          var sheet = XLSX.read(data, {
            type: 'base64',
            cellDates: true
          });
          var consoleJson = XLSX.utils.sheet_to_json(sheet.Sheets[sheet.SheetNames[0]], {
            raw: true
          });
          var formattedObjects = [];
          consoleJson.forEach(function (element) {
            var current = {};
            var index = 0;
            for (var k in element) {
              var tmp = element;
              current[columns[index].keyInData] = tmp[k];
              index += 1;
            }
            formattedObjects.push(current);
          });
          _this.setState({
            empty: false,
            rawJson: formattedObjects
          });
        });
        return function asynNow() {
          return _ref.apply(this, arguments);
        };
      }();
      asynNow().catch(function (err) {
        return void 0;
      });
    };
    _this.openFilePickerDialog = function () {
      _FilePicker.FilePicker.show(_this.filePickerId, false, ['allFiles'], function () {});
    };
    _this.generateTableFromExampleData = function () {
      var _this$props2 = _this.props,
        generateExampleRecords = _this$props2.generateExampleRecords,
        columns = _this$props2.columns;
      if (generateExampleRecords == null) return;
      var asyncNow = function () {
        var _ref2 = (0, _asyncToGenerator2.default)(function* () {
          var data = yield generateExampleRecords();
          var workBook = XLSX.utils.book_new();
          workBook.Props = {
            Title: _I18n.I18n.m.getMessage('excelDialogGeneratedName'),
            CreatedDate: new Date()
          };
          workBook.SheetNames.push(_I18n.I18n.m.getMessage('excelDialogGeneratedName'));
          var sheetData = [];
          var allColums = [];
          columns.forEach(function (column) {
            allColums.push(column.title);
          });
          sheetData.push(allColums);
          data.forEach(function (element) {
            var row = [];
            for (var key in element) {
              row.push(element[key]);
            }
            sheetData.push(row);
          });
          workBook.Sheets[_I18n.I18n.m.getMessage('excelDialogGeneratedName')] = XLSX.utils.aoa_to_sheet(sheetData);
          if (_reactNativeWeb.Platform.OS === 'web') {
            XLSX.writeFile(workBook, 'Example.xlsx');
          } else {
            var exportSheet = XLSX.write(workBook, {
              bookType: 'xlsx',
              type: 'buffer'
            });
          }
        });
        return function asyncNow() {
          return _ref2.apply(this, arguments);
        };
      }();
      asyncNow().catch(function (err) {
        return void 0;
      });
    };
    _this.state = {
      rawJson: [],
      empty: true,
      finished: 0,
      saving: false
    };
    return _this;
  }
  (0, _createClass2.default)(ExcelFileDialog, [{
    key: "componentDidMount",
    value: function componentDidMount() {}
  }, {
    key: "readToArrayBuffer",
    value: function readToArrayBuffer(file) {
      return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.onload = function () {
          resolve(reader.result);
        };
        reader.onerror = function (err) {
          reject(err);
        };
        reader.readAsArrayBuffer(file);
      });
    }
  }, {
    key: "renderEmptyContent",
    value: function renderEmptyContent() {
      return (0, _jsxRuntime.jsx)(_DialogContent.DialogContent, {
        children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          children: "Keine Datei ausgew\xE4hlt"
        })
      }, "empty_content_dialog");
    }
  }, {
    key: "renderExcelContent",
    value: function renderExcelContent() {
      var rawJson = this.state.rawJson;
      var columns = this.props.columns;
      var tableData = [];
      rawJson.forEach(function (element, index) {
        tableData.push(Object.assign({
          id: index
        }, element));
      });
      return (0, _jsxRuntime.jsx)(_DialogContent.DialogContent, {
        children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          children: [(0, _jsxRuntime.jsx)(_Table.Table, {
            tableName: "import data",
            actionItemsLength: 0,
            maxHeight: 10,
            columns: columns,
            data: tableData
          }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            children: _I18n.I18n.m.getMessage('excelDialogEntriesFound', {
              entries: tableData.length
            })
          })]
        })
      }, "excel_dialog_content");
    }
  }, {
    key: "render",
    value: function render() {
      var _this$state = this.state,
        empty = _this$state.empty,
        saving = _this$state.saving;
      var generateExampleRecords = this.props.generateExampleRecords;
      var showExample = generateExampleRecords != null;
      if (!saving) {
        var _dialog = [(0, _jsxRuntime.jsx)(_DialogTitle.DialogTitle, {
          children: _I18n.I18n.m.getMessage('excelDialogTitle')
        }, "title"), (0, _jsxRuntime.jsx)(_FilePicketComp.FilePicketComp, {
          id: this.filePickerId,
          multiple: false,
          gotFile: this.onGotFile,
          filter: ['allFiles']
        }, "filePickerComp"), (0, _jsxRuntime.jsx)(_DialogContent.DialogContent, {
          children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
            style: {
              flexDirection: 'row',
              justifyContent: 'space-between'
            },
            children: [showExample ? (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
              title: _I18n.I18n.m.getMessage('excelDialogDownloadExample'),
              onPress: this.generateTableFromExampleData
            }) : null, (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
              title: _I18n.I18n.m.getMessage('excelDialogSelectFile'),
              onPress: this.openFilePickerDialog
            })]
          })
        }, "dialogContent")];
        if (empty) {
          _dialog.push(this.renderEmptyContent());
        } else {
          _dialog.push(this.renderExcelContent());
        }
        _dialog.push((0, _jsxRuntime.jsxs)(_DialogActions.DialogActions, {
          children: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            title: _I18n.I18n.m.getMessage('cancel'),
            onPress: this.close,
            backgroundColor: "transparent",
            textColor: _ThemeManager.ThemeManager.style.brandPrimary
          }), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            title: _I18n.I18n.m.getMessage('save'),
            disabled: empty,
            onPress: this.save
          })]
        }, "dialogActions"));
        return _dialog;
      }
      var _this$state2 = this.state,
        rawJson = _this$state2.rawJson,
        finished = _this$state2.finished;
      var dialog = [(0, _jsxRuntime.jsx)(_DialogTitle.DialogTitle, {
        children: _I18n.I18n.m.getMessage('excelDialogTitle')
      }, "title"), (0, _jsxRuntime.jsx)(_DialogContent.DialogContent, {
        children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            justifyContent: 'center'
          },
          children: [(0, _jsxRuntime.jsx)(_Spinner.Spinner, {}), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: Object.assign({}, _ThemeManager.ThemeManager.style.contentPadding, {
              flexDirection: 'row',
              justifyContent: 'center'
            }),
            children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              children: `${finished}/${rawJson.length}`
            })
          })]
        })
      }, "dialogContent")];
      return dialog;
    }
  }]);
  return ExcelFileDialog;
}(_react.PureComponent);