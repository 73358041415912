var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ScaleAnimated = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _BaseAnimated2 = require("./BaseAnimated");
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var ScaleAnimated = exports.ScaleAnimated = function (_BaseAnimated) {
  (0, _inherits2.default)(ScaleAnimated, _BaseAnimated);
  var _super = _createSuper(ScaleAnimated);
  function ScaleAnimated(props) {
    var _this;
    (0, _classCallCheck2.default)(this, ScaleAnimated);
    _this = _super.call(this, props);
    _this.state = Object.assign({}, _this.state, {
      fromScale: props.from,
      toScale: props.to,
      size: props.size
    });
    return _this;
  }
  (0, _createClass2.default)(ScaleAnimated, [{
    key: "getAnimated",
    value: function getAnimated() {
      var _this$state = this.state,
        fromScale = _this$state.fromScale,
        size = _this$state.size,
        toScale = _this$state.toScale;
      var scale = this.animationState.interpolate({
        inputRange: [0, 1],
        outputRange: this.props.inverted === true ? [toScale, fromScale] : [fromScale, toScale]
      });
      if (size != null) {
        var scaleDiff = (toScale - fromScale) / 10;
        var translateInput = [0];
        var xOutput = [(size.width * fromScale - size.width) / 2 / fromScale];
        var yOutput = [(size.height * fromScale - size.height) / 2 / fromScale];
        for (var i = 1; i <= 10; i += 1) {
          translateInput.push(i / 10);
          var newScale = fromScale + scaleDiff * i;
          xOutput.push((size.width * newScale - size.width) / 2 / newScale);
          yOutput.push((size.height * newScale - size.height) / 2 / newScale);
        }
        var translateX = this.animationState.interpolate({
          inputRange: translateInput,
          outputRange: this.props.inverted === true ? [].concat(xOutput).reverse() : xOutput
        });
        var translateY = this.animationState.interpolate({
          inputRange: translateInput,
          outputRange: this.props.inverted === true ? [].concat(yOutput).reverse() : yOutput
        });
        return {
          transform: [{
            scale: scale
          }, {
            translateX: translateX
          }, {
            translateY: translateY
          }],
          position: 'relative',
          overflow: 'visible',
          alignSelf: 'flex-start',
          width: 'auto',
          height: 'auto'
        };
      }
      return {
        transform: [{
          scale: scale
        }],
        position: 'relative',
        overflow: 'visible',
        width: 'auto',
        height: 'auto'
      };
    }
  }], [{
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.to !== prevState.toScale || nextProps.from !== prevState.fromScale || nextProps.animationState !== prevState.animationState || nextProps.size !== prevState.size) {
        return {
          toScale: nextProps.to,
          fromScale: nextProps.from,
          size: nextProps.size,
          animationState: nextProps.animationState
        };
      }
      return null;
    }
  }]);
  return ScaleAnimated;
}(_BaseAnimated2.BaseAnimated);