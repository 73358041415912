var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SecureStoreC = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _SimpleStorage = require("odatarepos/build/src/db/SimpleStorage");
var SecureStoreC = exports.SecureStoreC = function () {
  function SecureStoreC() {
    (0, _classCallCheck2.default)(this, SecureStoreC);
  }
  (0, _createClass2.default)(SecureStoreC, [{
    key: "setItem",
    value: function () {
      var _setItem = (0, _asyncToGenerator2.default)(function* (key, value, expireDate) {
        try {
          if (expireDate != null) {
            SecureStoreC.cookie.set(key, value, {
              expires: expireDate
            });
            _SimpleStorage.SimpleStorage.set(key, value);
          } else {
            SecureStoreC.cookie.set(key, value);
          }
        } catch (error) {}
        if (expireDate != null) {
          try {
            var g = yield SecureStoreC.instance.getItem(key);
            if (g == null || g !== value) {
              _SimpleStorage.SimpleStorage.set(key, value);
            }
          } catch (e) {}
        }
      });
      function setItem(_x, _x2, _x3) {
        return _setItem.apply(this, arguments);
      }
      return setItem;
    }()
  }, {
    key: "getItem",
    value: function () {
      var _getItem = (0, _asyncToGenerator2.default)(function* (key) {
        try {
          var value = SecureStoreC.cookie.get(key);
          if (value == null) value = _SimpleStorage.SimpleStorage.get(key);
          return value;
        } catch (error) {
          return undefined;
        }
      });
      function getItem(_x4) {
        return _getItem.apply(this, arguments);
      }
      return getItem;
    }()
  }, {
    key: "removeItem",
    value: function () {
      var _removeItem = (0, _asyncToGenerator2.default)(function* (key) {
        try {
          var domain = window.location.host.split(':')[0];
          SecureStoreC.cookie.remove(key);
          SecureStoreC.cookie.remove(key, {
            path: ''
          });
          SecureStoreC.cookie.remove(key, {
            path: '/'
          });
          SecureStoreC.cookie.remove(key, {
            path: '',
            sameSite: 'strict'
          });
          SecureStoreC.cookie.remove(key, {
            path: '/',
            sameSite: 'strict'
          });
          SecureStoreC.cookie.remove(key, {
            path: '',
            sameSite: 'Lax'
          });
          SecureStoreC.cookie.remove(key, {
            path: '/',
            sameSite: 'Lax'
          });
          SecureStoreC.cookie.remove(key, {
            path: '',
            domain: domain,
            sameSite: 'strict'
          });
          SecureStoreC.cookie.remove(key, {
            path: '/',
            domain: domain,
            sameSite: 'strict'
          });
          SecureStoreC.cookie.remove(key, {
            path: '',
            domain: domain,
            sameSite: 'Lax'
          });
          SecureStoreC.cookie.remove(key, {
            path: '/',
            domain: domain,
            sameSite: 'Lax'
          });
          _SimpleStorage.SimpleStorage.remove(key);
        } catch (error) {}
      });
      function removeItem(_x5) {
        return _removeItem.apply(this, arguments);
      }
      return removeItem;
    }()
  }], [{
    key: "cookie",
    get: function get() {
      if (SecureStoreC._cookie == null) {
        SecureStoreC._cookie = _jsCookie.default.withAttributes({
          path: '/',
          sameSite: 'Lax'
        });
      }
      return SecureStoreC._cookie;
    }
  }, {
    key: "instance",
    get: function get() {
      if (SecureStoreC._instance == null) {
        SecureStoreC._instance = new SecureStoreC();
      }
      return SecureStoreC._instance;
    }
  }]);
  return SecureStoreC;
}();