"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConfigHandler = void 0;
var Config_1 = require("odatarepos/build/src/Config");
var ConfigHandler = function (_Config_1$ConfigHandl) {
  (0, _inherits2.default)(ConfigHandler, _Config_1$ConfigHandl);
  var _super = _createSuper(ConfigHandler);
  function ConfigHandler() {
    (0, _classCallCheck2.default)(this, ConfigHandler);
    return _super.apply(this, arguments);
  }
  (0, _createClass2.default)(ConfigHandler, null, [{
    key: "setConfig",
    value: function setConfig(config) {
      ConfigHandler._instance2 = config;
      Config_1.ConfigHandler.setConfig(config);
    }
  }, {
    key: "config",
    get: function get() {
      if (ConfigHandler._instance2 == null) {
        ConfigHandler._instance2 = ConfigHandler.createDefaultConfig();
        Config_1.ConfigHandler.setConfig(ConfigHandler._instance2);
      }
      return ConfigHandler._instance2;
    }
  }, {
    key: "createDefaultConfig",
    value: function createDefaultConfig() {
      var defaultConfig = {
        db: 'nedb',
        JWT_TOKEN_SECRET: 'cdKFAUfbwqeui239038ubnvcDSKbfg239ß1238',
        JWT_EXP_DAYS: 60,
        BCRYPT_SALT_ROUNDS: 12,
        cacheSize: '200MB',
        REDIS_PORT: 30634,
        REDIS_HOST: '127.0.0.1',
        REDIS_PASS: 'GoizrRSqRfdSQNirh9Oo',
        cors: true,
        inMemoryDB: true,
        dbName: 'cqrs-core',
        onlyWebsocketTransport: true,
        webServerUrl: 'http://127.0.0.1:32224/api',
        startSwagger: false,
        eventServerUrl: 'http://127.0.0.1:32223/api',
        startsWithTestData: false,
        fileHandler: 'node',
        fileHandlerPath: './files',
        maxUploadSizeInBytes: 104857600,
        version: '0.0.0',
        environment: 'development',
        serverName: 'server',
        S3_ACCESS_KEY_ID: '',
        S3_SECRET_ACCESS_KEY: '',
        S3_ENDPOINT: '',
        cronCacheClean: '',
        leaderElection: true,
        tracing: true
      };
      return ConfigHandler.getEnvirementConfig(defaultConfig);
    }
  }]);
  return ConfigHandler;
}(Config_1.ConfigHandler);
exports.ConfigHandler = ConfigHandler;