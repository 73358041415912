"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Note = void 0;
var ReadModelClientOfflineFirst_1 = require("cqrs-core/build/src/client/query/ReadModelClientOfflineFirst");
var NoteEntity_1 = require("./entities/NoteEntity");
var Note = function (_ReadModelClientOffli) {
  (0, _inherits2.default)(Note, _ReadModelClientOffli);
  var _super = _createSuper(Note);
  function Note() {
    var _this;
    (0, _classCallCheck2.default)(this, Note);
    _this = _super.apply(this, arguments);
    _this.exampleEntity = new NoteEntity_1.NoteEntity();
    _this.onEvents = [{
      eventName: 'NoteCreated',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'NoteChanged',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'NoteDeleted',
      affect: 'one',
      identifier: 'entityId'
    }];
    return _this;
  }
  (0, _createClass2.default)(Note, [{
    key: "onNoteDeleted",
    value: function () {
      var _onNoteDeleted = (0, _asyncToGenerator2.default)(function* (e, ent) {
        ent.deleted = true;
        ent.lastModifiedAt = e.createdAt;
        return ent;
      });
      function onNoteDeleted(_x, _x2) {
        return _onNoteDeleted.apply(this, arguments);
      }
      return onNoteDeleted;
    }()
  }]);
  return Note;
}(ReadModelClientOfflineFirst_1.ReadModelClientOfflineFirst);
exports.Note = Note;