"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeTicketType = void 0;
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../access/rights/RightsManager");
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var TicketApproverChanged_1 = require("../../events/tickets/TicketApproverChanged");
var TicketAssignedChanged_1 = require("../../events/tickets/TicketAssignedChanged");
var TicketCompletionOnRemoved_1 = require("../../events/tickets/TicketCompletionOnRemoved");
var TicketCraftChanged_1 = require("../../events/tickets/TicketCraftChanged");
var TicketCustomFieldChanged_1 = require("../../events/tickets/TicketCustomFieldChanged");
var TicketDescriptionChanged_1 = require("../../events/tickets/TicketDescriptionChanged");
var TicketPlanAffiliationRemoved_1 = require("../../events/tickets/TicketPlanAffiliationRemoved");
var TicketStatusChanged_1 = require("../../events/tickets/TicketStatusChanged");
var TicketTagRemoved_1 = require("../../events/tickets/TicketTagRemoved");
var TicketTypeChanged_1 = require("../../events/tickets/TicketTypeChanged");
var CustomField_1 = require("../../query/entities/simple/CustomField");
var TicketEntity_1 = require("../../query/entities/TicketEntity");
var ClientProjectCommands_1 = require("../_root/ClientProjectCommands");
var ChangeTicketCustomField_1 = require("./ChangeTicketCustomField");
var ChangeTicketType = function (_ClientProjectCommand) {
  (0, _inherits2.default)(ChangeTicketType, _ClientProjectCommand);
  var _super = _createSuper(ChangeTicketType);
  function ChangeTicketType(data, entityId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ChangeTicketType);
    _this = _super.call(this, data, entityId, token);
    _this.aggregate = 'project';
    _this.commandName = 'ChangeTicketType';
    return _this;
  }
  (0, _createClass2.default)(ChangeTicketType, [{
    key: "getTicket",
    value: function () {
      var _getTicket = (0, _asyncToGenerator2.default)(function* () {
        if (this._ticket == null) {
          this._ticket = yield CommandReadModels_1.CommandReadModels.ticket.getById(this.entityId);
        }
        return this._ticket;
      });
      function getTicket() {
        return _getTicket.apply(this, arguments);
      }
      return getTicket;
    }()
  }, {
    key: "getProject",
    value: function () {
      var _getProject = (0, _asyncToGenerator2.default)(function* () {
        if (this._project == null) {
          var ticket = yield this.getTicket();
          this._project = yield CommandReadModels_1.CommandReadModels.project.getById(ticket.projectId);
        }
        return this._project;
      });
      function getProject() {
        return _getProject.apply(this, arguments);
      }
      return getProject;
    }()
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var ticket = yield this.getTicket();
        yield (0, _get2.default)((0, _getPrototypeOf2.default)(ChangeTicketType.prototype), "checkProjectStatusAndGeneralAccess", this).call(this, ticket.projectId, userId);
        if (ticket.creator === userId) {
          return true;
        }
        if (yield RightsManager_1.RightsManager.hasWriteRight(ticket.projectId, userId, 'canChangeAllTickets')) {
          return true;
        }
        if (yield RightsManager_1.RightsManager.hasWriteRight(ticket.projectId, userId, 'commandChangeTicketType')) {
          return true;
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "getRemovedFields",
    value: function () {
      var _getRemovedFields = (0, _asyncToGenerator2.default)(function* () {
        var ticket = yield this.getTicket();
        var project = yield this.getProject();
        var ticketLayout = ChangeTicketCustomField_1.ChangeTicketCustomField.getTicketLayout(ticket, project);
        var ticketLayoutNew = ChangeTicketCustomField_1.ChangeTicketCustomField.getTicketLayout(new TicketEntity_1.TicketEntity(Object.assign({}, ticket, {
          type: this.data.ticketType
        })), project);
        var removedFields = [];
        ticketLayout.fields.forEach(function (a) {
          var hasField = ticketLayoutNew.fields.find(function (b) {
            if (a.systemField && b.systemField) return a.systemField === b.systemField;
            return a.id === b.id;
          });
          if (!hasField) {
            removedFields.push(a);
          }
        });
        return {
          removedFields: removedFields,
          ticketLayoutNew: ticketLayoutNew
        };
      });
      function getRemovedFields() {
        return _getRemovedFields.apply(this, arguments);
      }
      return getRemovedFields;
    }()
  }, {
    key: "getRemovedFieldsWithContent",
    value: function () {
      var _getRemovedFieldsWithContent = (0, _asyncToGenerator2.default)(function* () {
        var _yield$this$getRemove = yield this.getRemovedFields(),
          removedFields = _yield$this$getRemove.removedFields,
          ticketLayoutNew = _yield$this$getRemove.ticketLayoutNew;
        var filteredRemovedFields = [];
        var ticket = yield this.getTicket();
        removedFields.forEach(function (f) {
          if (f.customField) {
            var _ticket$fields;
            var found = (_ticket$fields = ticket.fields) == null ? void 0 : _ticket$fields.find(function (a) {
              return a.id === f.id;
            });
            if (found) filteredRemovedFields.push(f);
          } else if (f.systemField) {
            var k = CustomField_1.keyBySystemField[f.systemField];
            if (k && ticket[k] != null) {
              filteredRemovedFields.push(f);
            }
          }
        });
        return {
          removedFields: filteredRemovedFields,
          ticketLayoutNew: ticketLayoutNew
        };
      });
      function getRemovedFieldsWithContent() {
        return _getRemovedFieldsWithContent.apply(this, arguments);
      }
      return getRemovedFieldsWithContent;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var _this2 = this;
        var rm = yield this.getTicket();
        if (rm.type === this.data.ticketType) return [];
        var _yield$this$getRemove2 = yield this.getRemovedFieldsWithContent(),
          removedFields = _yield$this$getRemove2.removedFields,
          ticketLayoutNew = _yield$this$getRemove2.ticketLayoutNew;
        var e = new TicketTypeChanged_1.TicketTypeChanged(this.entityId, Object.assign({}, this.data), rm.projectId);
        var tokenData = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token);
        var userId = tokenData._id;
        var events = [e];
        var statusFound = false;
        for (var i = 0; i < ticketLayoutNew.fields.length; i += 1) {
          if (ticketLayoutNew.fields[i].systemField === 'status') {
            statusFound = true;
            if (rm.ticketStatus === TicketEntity_1.TicketStatus.noStatus) {
              events.push(new TicketStatusChanged_1.TicketStatusChanged(this.entityId, {
                ticketStatus: TicketEntity_1.TicketStatus.open,
                changedBy: userId
              }, rm.projectId));
            }
          }
        }
        if (!statusFound && rm.ticketStatus !== TicketEntity_1.TicketStatus.noStatus) {
          events.push(new TicketStatusChanged_1.TicketStatusChanged(this.entityId, {
            ticketStatus: TicketEntity_1.TicketStatus.noStatus,
            changedBy: userId
          }, rm.projectId));
        }
        if (removedFields.length > 0) {
          removedFields.forEach(function (a) {
            if (a.customField) {
              events.push(new TicketCustomFieldChanged_1.TicketCustomFieldChanged(_this2.entityId, {
                fieldId: a.id,
                type: a.customField.type,
                name: a.label,
                formattedValue: '',
                value: null
              }, rm.projectId));
            } else if (a.systemField) {
              if (a.systemField === 'assignee') {
                events.push(new TicketAssignedChanged_1.TicketAssignedChanged(_this2.entityId, {
                  assignedToUserId: ''
                }, rm.projectId));
              } else if (a.systemField === 'approver') {
                events.push(new TicketApproverChanged_1.TicketApproverChanged(_this2.entityId, {
                  approverUserId: ''
                }, rm.projectId));
              } else if (a.systemField === 'completionOn') {
                events.push(new TicketCompletionOnRemoved_1.TicketCompletionOnRemoved(_this2.entityId, {}, rm.projectId));
              } else if (a.systemField === 'craft') {
                events.push(new TicketCraftChanged_1.TicketCraftChanged(_this2.entityId, {
                  craft: null
                }, rm.projectId));
              } else if (a.systemField === 'status' && statusFound) {
                events.push(new TicketStatusChanged_1.TicketStatusChanged(_this2.entityId, {
                  ticketStatus: TicketEntity_1.TicketStatus.open,
                  changedBy: userId
                }, rm.projectId));
              } else if (a.systemField === 'plan') {
                events.push(new TicketPlanAffiliationRemoved_1.TicketPlanAffiliationRemoved(_this2.entityId, {
                  changedBy: userId
                }, rm.projectId));
              } else if (a.systemField === 'description') {
                events.push(new TicketDescriptionChanged_1.TicketDescriptionChanged(_this2.entityId, {
                  description: null
                }, rm.projectId));
              } else if (a.systemField === 'tags') {
                rm.tags.forEach(function (tag) {
                  events.push(new TicketTagRemoved_1.TicketTagRemoved(_this2.entityId, {
                    tag: tag
                  }, rm.projectId));
                });
              }
            }
          });
          return events;
        }
        return events;
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {
        var _project$ticketTypes;
        var project = yield this.getProject();
        if (this.data.ticketType != null && !((_project$ticketTypes = project.ticketTypes) != null && _project$ticketTypes.includes(this.data.ticketType))) {
          throw {
            message: 'Der Tickettyp existiert nicht im Projekt',
            messageCode: 'projectTicketTypeNotExists'
          };
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
  return ChangeTicketType;
}(ClientProjectCommands_1.ClientProjectCommands);
exports.ChangeTicketType = ChangeTicketType;