var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DateInputFormFilled = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _CurrentUser = require("upmesh-auth-core/build/src/client/CurrentUser");
var _DateLocale = require("upmesh-i18n/build/src/i18n/DateLocale");
var _I18n = require("../../i18n/I18n");
var _ThemeManager = require("../../ThemeManager");
var _Datepicker = require("../datepickerv2/Datepicker");
var _KeyboardEvents = require("../utils/KeyboardEvents");
var _FormInputFilled = require("./FormInputFilled");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var DateInputFormFilled = exports.DateInputFormFilled = function (_PureComponent) {
  (0, _inherits2.default)(DateInputFormFilled, _PureComponent);
  var _super = _createSuper(DateInputFormFilled);
  function DateInputFormFilled(props) {
    var _this;
    (0, _classCallCheck2.default)(this, DateInputFormFilled);
    _this = _super.call(this, props);
    _this.clearText = function (errorCode) {
      var error = errorCode != null ? errorCode : '';
      _this.setState({
        error: error,
        textValue: '',
        selectedDate: undefined
      }, function () {
        _this.setBaseValue('');
        if (_this.props.onChange != null) {
          _this.props.onChange(undefined);
        }
      });
    };
    _this.focusNextField = function () {
      if (_this.props.nextField != null) {
        if (typeof _this.props.nextField === 'function') {
          _this.props.nextField();
        } else if (typeof _this.props.nextField['focus'] === 'function') {
          _this.props.nextField.focus();
        }
      }
    };
    _this.changeDate = function (date) {
      var textValue = _this.createTextValue(new Date(date));
      if (textValue === 'invalidDate') {
        _this.clearText('invalidDate');
      } else {
        _this.setState({
          textValue: textValue,
          selectedDate: new Date(date),
          error: ''
        }, function () {
          _this.setBaseValue(textValue);
          if (_this.props.onChange != null) {
            _this.props.onChange(date);
          }
        });
      }
    };
    _this.onBlur = function () {
      _this.stopKeyboardEvents();
      _this.onChangedTextValue();
    };
    _this.onChangeTextValue = function (text) {
      var textValue = text.replace(/[^\d./: -]/g, '');
      _this.setState({
        textValue: textValue,
        error: ''
      }, function () {
        _this.setBaseValue(textValue);
        if (text == null || text.length === 0) {
          _this.onChangedTextValue();
        }
      });
    };
    _this.onChangedTextValue = function () {
      var text = _this.state.textValue;
      if ((text == null || text.length === 0) && _this.state.formInput == null) {
        _this.clearText();
        return;
      }
      var dateString = text;
      if (text == null && _this.state.formInput != null) {
        dateString = _this.state.formInput.getValue();
      }
      if (dateString == null || dateString.length === 0) {
        _this.clearText();
        return;
      }
      if (_this.props.selectTime === true) {
        if (_this.props.selectDate === false) {
          dateString = `01011970${dateString}`;
        }
        try {
          var _selectedDate = _this.locale.localeDateFromStringWithTime(dateString);
          if (_this.state.selectedDate == null || _selectedDate.toISOString() !== _this.state.selectedDate.toISOString()) {
            _this.changeDate(_selectedDate);
          }
        } catch (_e) {
          if (dateString.length > 0) {
            _this.clearText('invalidDate');
          } else {
            _this.clearText();
          }
        }
      } else {
        try {
          var _selectedDate2 = _this.locale.localeDateFromString(dateString);
          if (_this.state.selectedDate == null || _selectedDate2.toISOString() !== _this.state.selectedDate.toISOString()) {
            _this.changeDate(_selectedDate2);
          }
        } catch (_e) {
          if (dateString.length > 0) {
            _this.clearText('invalidDate');
          } else {
            _this.clearText();
          }
        }
      }
    };
    _this.onFocus = function () {
      _this.startKeyboardEvents();
    };
    _this.openDatePicker = function (e) {
      var _this$state$formInput, _this$state$formInput2;
      var _this$props = _this.props,
        selectTime = _this$props.selectTime,
        timeFormat = _this$props.timeFormat,
        selectDate = _this$props.selectDate,
        startDate = _this$props.startDate,
        labelText = _this$props.labelText,
        coloredDates = _this$props.coloredDates,
        quickSelections = _this$props.quickSelections,
        timeQuickSelections = _this$props.timeQuickSelections;
      var selectedDate = _this.state.selectedDate;
      _this.stopKeyboardEvents();
      var date = selectedDate != null ? new Date(selectedDate) : startDate != null ? new Date(startDate) : undefined;
      _Datepicker.Datepicker.open({
        onChange: _this.changeDate,
        mode: selectTime && selectDate ? 'both' : selectTime ? 'time' : 'date',
        labelText: labelText,
        timeFormat: timeFormat,
        selectedDate: ((_this$state$formInput = _this.state.formInput) == null ? void 0 : _this$state$formInput.getValue()) === null || ((_this$state$formInput2 = _this.state.formInput) == null ? void 0 : _this$state$formInput2.getValue()) === '' ? new Date() : date,
        selectTimeMinutesIntervall: _this.props.selectTimeMinutesIntervall,
        coloredDates: coloredDates,
        quickSelections: quickSelections,
        timeQuickSelections: timeQuickSelections
      }, e);
    };
    _this.setFocus = function (_e) {
      _this.openDatePicker(_e);
    };
    _this.setRef = function (formInput) {
      _this.setState({
        formInput: formInput
      });
    };
    _this.startKeyboardEvents = function () {
      _KeyboardEvents.KeyboardEvents.onKeyDown(function (e) {
        if (e.keyCode === 40) {
          e.preventDefault();
          _this.openDatePicker();
        }
      }, 'DatePickerFormInput');
    };
    _this.stopKeyboardEvents = function () {
      _KeyboardEvents.KeyboardEvents.remove('DatePickerFormInput');
    };
    var selectedDate = props.initValue != null ? props.initValue : props.value;
    var timeFormat = _this.props.timeFormat != null ? _this.props.timeFormat : _CurrentUser.CurrentUser.settings.timeFormat;
    var locale = _CurrentUser.CurrentUser.entity != null && _CurrentUser.CurrentUser.entity.locale != null ? _CurrentUser.CurrentUser.entity.locale : 'de';
    _this.locale = new _DateLocale.DateLocale(locale, timeFormat);
    _this.state = {
      selectedDate: selectedDate,
      formInput: null,
      textValue: _this.createTextValue(selectedDate)
    };
    return _this;
  }
  (0, _createClass2.default)(DateInputFormFilled, [{
    key: "blur",
    value: function blur() {}
  }, {
    key: "clear",
    value: function clear() {
      this.clearText();
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      this.stopKeyboardEvents();
    }
  }, {
    key: "focus",
    value: function focus() {
      var formInput = this.state.formInput;
      if (formInput != null) {
        formInput.focus();
      } else {
        this.openDatePicker();
      }
    }
  }, {
    key: "getId",
    value: function getId() {
      if (this.state.formInput != null) {
        return this.state.formInput.getId();
      }
      return '';
    }
  }, {
    key: "getValue",
    value: function getValue() {
      return this.state.selectedDate;
    }
  }, {
    key: "render",
    value: function render() {
      var keyboardType = _reactNativeWeb.Platform.OS !== 'web' ? 'numeric' : undefined;
      var _this$state = this.state,
        error = _this$state.error,
        textValue = _this$state.textValue;
      var _this$props2 = this.props,
        selectTime = _this$props2.selectTime,
        selectDate = _this$props2.selectDate;
      var disabled = this.props.disabled;
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          overflow: 'visible',
          width: '100%',
          flexDirection: 'row',
          height: 'auto'
        },
        children: (0, _jsxRuntime.jsx)(_FormInputFilled.FormInputFilled, {
          ref: this.setRef,
          keyboardType: keyboardType,
          overrideOnPressFocus: _reactNativeWeb.Platform.OS === 'web' ? undefined : this.openDatePicker,
          onFocus: this.onFocus,
          onBlur: this.onBlur,
          iconRight: disabled ? undefined : {
            icon: 'menu-down',
            toolTip: '',
            onPress: this.setFocus
          },
          disabled: disabled,
          labelText: this.props.labelText,
          backgroundColor: this.props.backgroundColor,
          color: this.props.color,
          initValue: textValue,
          helperText: error != null && error.length > 0 ? _I18n.I18n.m.getMessage(selectTime && !selectDate ? 'invalidTime' : 'invalidDate') : this.props.helperText,
          error: error != null && error.length > 0,
          clearButtonAlwaysVisible: this.props.clearButton,
          clearButton: this.props.clearButton,
          onChange: this.onChangeTextValue,
          focusAfterClear: false
        }, "Date")
      });
    }
  }, {
    key: "setValue",
    value: function setValue(value) {
      var _this2 = this;
      var textValue = this.createTextValue(value);
      if (textValue === 'invalidDate') {
        this.clearText('invalidDate');
      } else {
        this.setState({
          textValue: textValue,
          selectedDate: value
        }, function () {
          _this2.setBaseValue(textValue);
        });
      }
    }
  }, {
    key: "createTextValue",
    value: function createTextValue(date) {
      var _this$props3 = this.props,
        selectTime = _this$props3.selectTime,
        selectDate = _this$props3.selectDate;
      if (date == null) {
        return '';
      }
      var text = this.locale.localeDateString(date);
      if (text === 'Invalid date') {
        return 'invalidDate';
      }
      var hours = new Date(date).getHours();
      var minutes = new Date(date).getMinutes();
      var time = this.locale.getLocalTimeString(date);
      if (selectTime === true && (hours !== 0 || minutes !== 0)) {
        text = `${text} ${time}`;
      }
      if (selectDate === false && selectTime === true) {
        text = time;
      }
      return text;
    }
  }, {
    key: "setBaseValue",
    value: function setBaseValue(v) {
      var formInput = this.state.formInput;
      if (formInput) {
        formInput.setValue(v);
      }
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        labelText: 'Datum',
        disabled: false,
        value: undefined,
        datePickerColor: _ThemeManager.ThemeManager.style.datePickerColor,
        datePickerTextColor: _ThemeManager.ThemeManager.style.datePickerTextColor,
        selectTimeMinutesIntervall: 5,
        clearButton: true,
        showKW: _CurrentUser.CurrentUser.settings.showKW
      };
    }
  }]);
  return DateInputFormFilled;
}(_react.PureComponent);