var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Checkbox = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-support/node_modules/react"));
var _ThemeManager = require("../ThemeManager");
var _Icon = require("./Icon");
var _jsxRuntime = require("../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var Checkbox = exports.Checkbox = function (_PureComponent) {
  (0, _inherits2.default)(Checkbox, _PureComponent);
  var _super = _createSuper(Checkbox);
  function Checkbox(props) {
    var _this;
    (0, _classCallCheck2.default)(this, Checkbox);
    _this = _super.call(this, props);
    _this.onPressCheckbox = function (_e) {
      var onChange = _this.props.onChange;
      var checked = _this.state.checked;
      var checkedNew = checked != null ? !checked : false;
      _this.setState({
        checked: checkedNew
      }, function () {
        if (onChange != null) {
          onChange(checkedNew);
        }
      });
    };
    _this.state = {
      checked: props.initValue === true || props.value === true
    };
    return _this;
  }
  (0, _createClass2.default)(Checkbox, [{
    key: "blur",
    value: function blur() {}
  }, {
    key: "clear",
    value: function clear() {}
  }, {
    key: "focus",
    value: function focus() {}
  }, {
    key: "getId",
    value: function getId() {
      return '';
    }
  }, {
    key: "getValue",
    value: function getValue() {
      return this.state.checked;
    }
  }, {
    key: "render",
    value: function render() {
      var _this$props = this.props,
        colorChecked = _this$props.colorChecked,
        color = _this$props.color,
        disabled = _this$props.disabled,
        roundedCheckbox = _this$props.roundedCheckbox,
        accessibilityLabel = _this$props.accessibilityLabel;
      var checked = this.state.checked;
      var checkboxIcon = roundedCheckbox === true ? 'checkbox-blank-circle-outline' : 'checkbox-blank-outline';
      var checkboxIconChecked = roundedCheckbox === true ? 'check-circle' : 'checkbox-marked';
      var col = !(disabled === true) ? checked ? colorChecked : color : _ThemeManager.ThemeManager.style.black42;
      return (0, _jsxRuntime.jsx)(_Icon.Icon, {
        accessibilityLabel: accessibilityLabel != null ? accessibilityLabel : `checkbox_${checked}`,
        icon: checked ? checkboxIconChecked : checkboxIcon,
        toolTip: "",
        color: col,
        disabled: disabled,
        onPress: this.onPressCheckbox
      });
    }
  }, {
    key: "setValue",
    value: function setValue(value) {
      var _this$props2 = this.props,
        onChange = _this$props2.onChange,
        disabled = _this$props2.disabled;
      if (disabled) return;
      this.setState({
        checked: value === true
      }, function () {
        if (onChange != null) {
          onChange(value === true);
        }
      });
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        colorChecked: _ThemeManager.ThemeManager.style.brandPrimary,
        color: _ThemeManager.ThemeManager.style.black54,
        disabled: false,
        value: undefined
      };
    }
  }]);
  return Checkbox;
}(_react.PureComponent);