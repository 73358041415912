"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CommandReadModels = void 0;
var CommandReadModels = function () {
  function CommandReadModels() {
    (0, _classCallCheck2.default)(this, CommandReadModels);
  }
  (0, _createClass2.default)(CommandReadModels, null, [{
    key: "project",
    get: function get() {
      return CommandReadModels.instance.project;
    }
  }, {
    key: "projectUser",
    get: function get() {
      return CommandReadModels.instance.projectUser;
    }
  }, {
    key: "projectRoles",
    get: function get() {
      return CommandReadModels.instance.projectRoles;
    }
  }, {
    key: "planFiles",
    get: function get() {
      return CommandReadModels.instance.planFiles;
    }
  }, {
    key: "plan",
    get: function get() {
      return CommandReadModels.instance.plan;
    }
  }, {
    key: "planVersion",
    get: function get() {
      return CommandReadModels.instance.planVersion;
    }
  }, {
    key: "ticket",
    get: function get() {
      return CommandReadModels.instance.ticket;
    }
  }, {
    key: "ticketComments",
    get: function get() {
      return CommandReadModels.instance.ticketComments;
    }
  }, {
    key: "storedFile",
    get: function get() {
      return CommandReadModels.instance.storedFile;
    }
  }, {
    key: "folder",
    get: function get() {
      return CommandReadModels.instance.folder;
    }
  }, {
    key: "logBook",
    get: function get() {
      return CommandReadModels.instance.logBook;
    }
  }, {
    key: "projectAdress",
    get: function get() {
      return CommandReadModels.instance.projectAddress;
    }
  }, {
    key: "subscription",
    get: function get() {
      return CommandReadModels.instance.subscription;
    }
  }, {
    key: "reportSettings",
    get: function get() {
      return CommandReadModels.instance.reportSettings;
    }
  }, {
    key: "journal",
    get: function get() {
      return CommandReadModels.instance.journal;
    }
  }, {
    key: "journalSettings",
    get: function get() {
      return CommandReadModels.instance.journalSettings;
    }
  }, {
    key: "task",
    get: function get() {
      return CommandReadModels.instance.task;
    }
  }, {
    key: "workingTimeModel",
    get: function get() {
      return CommandReadModels.instance.workingTimeModel;
    }
  }, {
    key: "journalProtocol",
    get: function get() {
      return CommandReadModels.instance.journalProtocol;
    }
  }, {
    key: "notificationsSettings",
    get: function get() {
      return CommandReadModels.instance.notificationsSettings;
    }
  }, {
    key: "company",
    get: function get() {
      return CommandReadModels.instance.company;
    }
  }, {
    key: "timeTracking",
    get: function get() {
      return CommandReadModels.instance.timeTracking;
    }
  }, {
    key: "companyMember",
    get: function get() {
      return CommandReadModels.instance.companyMember;
    }
  }, {
    key: "companySettings",
    get: function get() {
      return CommandReadModels.instance.companySettings;
    }
  }, {
    key: "companyProject",
    get: function get() {
      return CommandReadModels.instance.companyProject;
    }
  }, {
    key: "upmeshPartner",
    get: function get() {
      return CommandReadModels.instance.upmeshPartner;
    }
  }, {
    key: "group",
    get: function get() {
      return CommandReadModels.instance.group;
    }
  }, {
    key: "costCenter",
    get: function get() {
      return CommandReadModels.instance.costCenter;
    }
  }, {
    key: "extraPay",
    get: function get() {
      return CommandReadModels.instance.extraPay;
    }
  }, {
    key: "extraPayTracking",
    get: function get() {
      return CommandReadModels.instance.extraPayTracking;
    }
  }, {
    key: "region",
    get: function get() {
      return CommandReadModels.instance.region;
    }
  }, {
    key: "holiday",
    get: function get() {
      return CommandReadModels.instance.holiday;
    }
  }, {
    key: "bookmark",
    get: function get() {
      return CommandReadModels.instance.bookmark;
    }
  }, {
    key: "note",
    get: function get() {
      return CommandReadModels.instance.note;
    }
  }, {
    key: "absence",
    get: function get() {
      return CommandReadModels.instance.absence;
    }
  }, {
    key: "share",
    get: function get() {
      return CommandReadModels.instance.share;
    }
  }, {
    key: "companyProjectInfo",
    get: function get() {
      return CommandReadModels.instance.companyProjectInfo;
    }
  }, {
    key: "userProjectSettings",
    get: function get() {
      return CommandReadModels.instance.userProjectSettings;
    }
  }]);
  return CommandReadModels;
}();
exports.CommandReadModels = CommandReadModels;