var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DashBoardItem = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _Icon = require("materialTheme/build/src/theme/components/Icon");
var _MaterialText = require("materialTheme/build/src/theme/components/text/MaterialText");
var _ThemeManager = require("materialTheme/build/src/theme/ThemeManager");
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var DashBoardItem = exports.DashBoardItem = function (_PureComponent) {
  (0, _inherits2.default)(DashBoardItem, _PureComponent);
  var _super = _createSuper(DashBoardItem);
  function DashBoardItem(props) {
    var _this;
    (0, _classCallCheck2.default)(this, DashBoardItem);
    _this = _super.call(this, props);
    _this.state = {};
    return _this;
  }
  (0, _createClass2.default)(DashBoardItem, [{
    key: "render",
    value: function render() {
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          margin: _ThemeManager.ThemeManager.style.contentPaddingValue,
          marginBottom: 0,
          flexDirection: 'row',
          alignItems: 'center',
          alignSelf: 'stretch',
          justifyContent: 'space-between'
        },
        children: [(0, _jsxRuntime.jsx)(_Icon.Icon, {
          toolTip: "",
          iconIconMoon: this.props.iconIconMoon === true,
          iconSize: 64,
          color: this.props.iconColor,
          icon: this.props.icon
        }), (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            alignContent: 'flex-end',
            backgroundColor: 'transparent',
            flex: 1
          },
          children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              width: '100%'
            },
            children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              type: _MaterialText.MaterialTextTypes.H5,
              fixedWidth: "100%",
              numberOfLines: 1,
              textAlign: "right",
              children: this.props.title
            })
          }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            children: (0, _jsxRuntime.jsxs)(_MaterialText.MaterialText, {
              color: _ThemeManager.ThemeManager.style.primaryTextColor,
              type: _MaterialText.MaterialTextTypes.H3,
              children: [this.props.counts, this.props.counts2 != null ? ` / ${this.props.counts2}` : '']
            })
          })]
        })]
      });
    }
  }]);
  return DashBoardItem;
}(_react.PureComponent);