"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CreateProjectRole = void 0;
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../access/rights/RightsManager");
var Config_1 = require("../../../Config");
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var ProjectRoleCreated_1 = require("../../events/roles/ProjectRoleCreated");
var ClientProjectCommands_1 = require("../_root/ClientProjectCommands");
var CreateProjectRole = function (_ClientProjectCommand) {
  (0, _inherits2.default)(CreateProjectRole, _ClientProjectCommand);
  var _super = _createSuper(CreateProjectRole);
  function CreateProjectRole(data, entityId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, CreateProjectRole);
    _this = _super.call(this, data, entityId, token);
    _this.aggregate = 'project';
    _this.commandName = 'CreateProjectRole';
    return _this;
  }
  (0, _createClass2.default)(CreateProjectRole, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        yield (0, _get2.default)((0, _getPrototypeOf2.default)(CreateProjectRole.prototype), "checkProjectStatusAndGeneralAccess", this).call(this, this.data.projectId, userId);
        var project = yield CommandReadModels_1.CommandReadModels.project.getById(this.data.projectId);
        if (project.projectSubscription !== 'enterprise') {
          throw {
            message: 'Rollen können nur in Firmen-Projekten erstellt werden',
            messageCode: 'rolesOnlyInEnterprise'
          };
        }
        if (yield RightsManager_1.RightsManager.hasWriteRight(this.data.projectId, userId, 'commandCreateProjectRole')) {
          return true;
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var getBase = yield CommandReadModels_1.CommandReadModels.projectRoles.getById(this.data.basedOnRoleId);
        var data = Object.assign({}, this.data, {
          readRights: getBase.readRights,
          writeRights: getBase.writeRights,
          addedByUserId: userId
        });
        return new ProjectRoleCreated_1.ProjectRoleCreated(this.entityId, data);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        if (key == null || key === 'roleName') {
          if (this.data.roleName == null || this.data.roleName.length <= 0) {
            throw {
              key: 'roleName',
              messageCode: 'required',
              message: 'wird benötigt'
            };
          }
          if (this.data.roleName.length > Config_1.ConfigHandler.config.string_Short) {
            throw {
              key: 'roleName',
              messageCode: 'stringToLong',
              message: 'zu lang (max 64)'
            };
          }
          var roles = yield CommandReadModels_1.CommandReadModels.projectRoles.get({
            filter: `roleName eq '${this.data.roleName}' and projectId eq '${this.data.projectId}' and deleted ne true`
          });
          if (roles.length > 0) {
            throw {
              key: 'roleName',
              message: 'Es existiert bereits eine Rolle mit diesem Namen',
              messageCode: 'roleWithNameExists'
            };
          }
        }
        if (key == null || key === 'projectId') {
          if (this.data.projectId == null || this.data.projectId.length <= 0) {
            throw {
              key: 'projectId',
              messageCode: 'required',
              message: 'wird benötigt'
            };
          }
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
  return CreateProjectRole;
}(ClientProjectCommands_1.ClientProjectCommands);
exports.CreateProjectRole = CreateProjectRole;