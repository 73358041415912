var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.B2cStyles = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _ResizeEvent = require("materialTheme/build/src/theme/ResizeEvent");
var _Styles2 = require("materialTheme/build/src/theme/Styles");
var _ThemeManager = require("materialTheme/build/src/theme/ThemeManager");
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var B2cStyles = exports.B2cStyles = function (_Styles) {
  (0, _inherits2.default)(B2cStyles, _Styles);
  var _super = _createSuper(B2cStyles);
  function B2cStyles() {
    var _this;
    (0, _classCallCheck2.default)(this, B2cStyles);
    _this = _super.call(this);
    _this.activeBgColor = 'rgba(33,150,243,1)';
    _this.appBgColor = '#F6F6F8';
    _this.appBgColorDarken = '#EBEBEB';
    _this.borderColor = '#D8D8D8';
    _this.borderRadius = 6;
    _this.brandDanger = '#F44336';
    _this.brandDark = '#252932';
    _this.brandInfo = '#03A9F4';
    _this.brandLight = '#EEEEEE';
    _this.brandPrimary = '#0073ff';
    _this.brandSidebar = '#252932';
    _this.brandSuccess = '#00cd9e';
    _this.brandWarning = '#ff9800';
    _this.cardBackgroundColor = '#FFFFFF';
    _this.datePickerColor = '#2196F3';
    _this.datePickerTextColor = '#FFFFFF';
    _this.defaultButtonTextColor = '#FFF';
    _this.headerBG = '#FFFFFF';
    _this.headerBGDarken = '#FAFAFA';
    _this.headerButtonColor = _this.defaultIconColor;
    _this.hintTextColor = 'rgba(0,0,0,0.38)';
    _this.homeButtonTarget = _ResizeEvent.ResizeEvent.current.windowWidth <= _ThemeManager.ThemeManager.style.breakpointM ? '/projects' : '/dashboard';
    _this.lightBoxOverlayOpacity = 1;
    _this.lisItemHoverColor = 'rgba(246, 246, 248, 0.5)';
    _this.lisItemSelectedColor = 'rgba(33,33,33,0.5)';
    _this.menuBgColor = '#FFFFFF';
    _this.menuBorderColor = _this.borderColor;
    _this.menuButtonColorActive = _this.brandPrimary;
    _this.menuRightBorderColor = _this.borderColor;
    _this.planThumbOverlayColor = _this.white87;
    _this.planThumbOverlayColorActive = _this.brandPrimary;
    _this.planThumbOverlayTextColorActive = '#FFFFFF';
    _this.primaryTextColor = '#212121';
    _this.headerTitleColor = _this.primaryTextColor;
    _this.planThumbOverlayTextColor = _this.primaryTextColor;
    _this.secondaryTextColor = '#616161';
    _this.defaultIconColor = _this.secondaryTextColor;
    _this.menuButtonColor = _this.secondaryTextColor;
    _this.statusbarStyle = 'dark-content';
    _this._basename = '/';
    return _this;
  }
  (0, _createClass2.default)(B2cStyles, [{
    key: "contentPaddingValue",
    get: function get() {
      return 16;
    }
  }, {
    key: "desktopMultiplicatorSize",
    get: function get() {
      return 1;
    }
  }, {
    key: "statusBarColor",
    get: function get() {
      return 'transparent';
    }
  }]);
  return B2cStyles;
}(_Styles2.Styles);