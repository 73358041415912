"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserOrCompany = void 0;
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var AuthClient_1 = require("upmesh-auth-core/build/src/client/AuthClient");
var UserEntity_1 = require("upmesh-auth-core/build/src/client/query/entities/UserEntity");
var UserOrCompany = function () {
  function UserOrCompany() {
    (0, _classCallCheck2.default)(this, UserOrCompany);
  }
  (0, _createClass2.default)(UserOrCompany, null, [{
    key: "getById",
    value: function () {
      var _getById = (0, _asyncToGenerator2.default)(function* (id) {
        if (id === 'system' || id === 'all') {
          var user = new UserEntity_1.UserEntity({
            id: id
          });
          user.firstname = 'System';
          user.lastname = '';
          user.company = undefined;
          user.emails = [];
          user.locale = 'de';
          return user;
        }
        if (id.startsWith('company')) {
          var companyId = id.slice(7);
          var companies = ReadModels_1.ReadModels.get('Company');
          var company = yield companies.getById(companyId);
          var _user = new UserEntity_1.UserEntity(Object.assign({}, company, {
            id: id
          }));
          _user.firstname = company.company;
          _user.lastname = '';
          _user.company = undefined;
          _user.emails = company.email ? [company.email] : [];
          _user.locale = 'de';
          return _user;
        }
        return AuthClient_1.AuthClient.instance.modals.user.getById(id);
      });
      function getById(_x) {
        return _getById.apply(this, arguments);
      }
      return getById;
    }()
  }]);
  return UserOrCompany;
}();
exports.UserOrCompany = UserOrCompany;