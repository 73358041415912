var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IDialogAnimationProp = exports.DialogAnimation = void 0;
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _ResizeEvent = require("../../ResizeEvent");
var _Animation2 = _interopRequireDefault(require("./Animation"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var IDialogAnimationProp = exports.IDialogAnimationProp = (0, _createClass2.default)(function IDialogAnimationProp() {
  (0, _classCallCheck2.default)(this, IDialogAnimationProp);
});
var DialogAnimation = exports.DialogAnimation = function (_Animation) {
  (0, _inherits2.default)(DialogAnimation, _Animation);
  var _super = _createSuper(DialogAnimation);
  function DialogAnimation() {
    var _this;
    var toValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var openPosition = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
      x: 0,
      y: 0
    };
    var duration = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 195;
    var elevation = arguments.length > 3 ? arguments[3] : undefined;
    (0, _classCallCheck2.default)(this, DialogAnimation);
    _this = _super.call(this, toValue);
    _this.left = 0;
    _this.duration = 195;
    _this.elevation = elevation;
    _this.openPosition = openPosition;
    _this.animations = _this.createAnimations();
    _this.duration = duration;
    return _this;
  }
  (0, _createClass2.default)(DialogAnimation, [{
    key: "createAnimations",
    value: function createAnimations() {
      var left = _ResizeEvent.ResizeEvent.current.windowWidth / 2;
      var top = _ResizeEvent.ResizeEvent.current.windowHeight / 2;
      var x = this.openPosition != null && this.openPosition.x != null ? -(this.openPosition.x - left) : 0;
      var y = this.openPosition != null && this.openPosition.y != null ? -(this.openPosition.y - top) : 0;
      var transform = [];
      transform.push({
        translateX: this.animate.interpolate({
          inputRange: [0, 1],
          outputRange: [-x, 0]
        })
      }, {
        translateY: this.animate.interpolate({
          inputRange: [0, 1],
          outputRange: [-y, 0]
        })
      }, {
        scale: this.animate.interpolate({
          inputRange: [0, 1],
          outputRange: [0.01, 1]
        })
      });
      if (_reactNativeWeb.Platform.OS.toString() === 'android' && this.elevation != null) {
        return {
          transform: transform,
          elevation: this.elevation
        };
      }
      return {
        transform: transform
      };
    }
  }, {
    key: "toValue",
    value: function toValue(_toValue, onFinished) {
      _reactNativeWeb.Animated.timing(this.animate, {
        toValue: _toValue,
        useNativeDriver: _reactNativeWeb.Platform.OS !== 'web',
        duration: this.duration
      }).start(function () {
        if (onFinished != null) {
          onFinished();
        }
      });
    }
  }]);
  return DialogAnimation;
}(_Animation2.default);