"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ReportSettingsEntity = exports.ReportTicketDetailData = exports.ReportLayoutData = exports.ReportFrontPageData = void 0;
var ReadModelEntity_1 = require("cqrs-core/build/src/core/query/ReadModelEntity");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var ReportFrontPageData = (0, _createClass2.default)(function ReportFrontPageData() {
  (0, _classCallCheck2.default)(this, ReportFrontPageData);
  this.hide = false;
  this.titleName = '';
  this.description = '';
  this.selectedMode = 'description';
  this.showTableOfContents = true;
});
exports.ReportFrontPageData = ReportFrontPageData;
var ReportLayoutData = (0, _createClass2.default)(function ReportLayoutData() {
  (0, _classCallCheck2.default)(this, ReportLayoutData);
  this.sort = 'ticketNumber';
  this.lineBreakBeforeTicket = false;
  this.includeLinks = true;
  this.qrCodes = true;
  this.mergeTicketPdfsToReport = false;
});
exports.ReportLayoutData = ReportLayoutData;
var ReportTicketDetailData = (0, _createClass2.default)(function ReportTicketDetailData() {
  (0, _classCallCheck2.default)(this, ReportTicketDetailData);
  this.type = true;
  this.status = true;
  this.dueDate = true;
  this.assignee = true;
  this.tags = true;
  this.approver = true;
  this.craft = true;
  this.comments = false;
  this.logbook = true;
  this.localizationPlan = false;
  this.localizationSnippet = true;
  this.localizationMap = true;
  this.planQuality = 'normal';
  this.photos = true;
  this.photosCategories = [];
  this.photoQuality = 'normal';
  this.imagesPerLine = 3;
  this.created = true;
  this.lastChanged = true;
  this.description = true;
  this.customFields = [];
});
exports.ReportTicketDetailData = ReportTicketDetailData;
var ReportSettingsEntity = function (_ReadModelEntity_1$Re) {
  (0, _inherits2.default)(ReportSettingsEntity, _ReadModelEntity_1$Re);
  var _super = _createSuper(ReportSettingsEntity);
  function ReportSettingsEntity(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, ReportSettingsEntity);
    _this = _super.call(this, obj);
    _this.entityName = 'ReportSettings';
    _this.frontPage = new ReportFrontPageData();
    _this.layout = new ReportLayoutData();
    _this.ticketDetails = new ReportTicketDetailData();
    _this.fill(obj);
    return _this;
  }
  (0, _createClass2.default)(ReportSettingsEntity, [{
    key: "createOne",
    value: function createOne(obj) {
      return new ReportSettingsEntity(obj);
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('projectId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('userId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('frontPage', new OdataEntity_1.OdataIndex());
      baseMap.set('layout', new OdataEntity_1.OdataIndex());
      baseMap.set('ticketDetails', new OdataEntity_1.OdataIndex());
      baseMap.set('customizedLogoDataFileId', new OdataEntity_1.OdataIndex());
      baseMap.set('customTitlePageFileId', new OdataEntity_1.OdataIndex());
      baseMap.set('customAttachmentPageFileId', new OdataEntity_1.OdataIndex());
      baseMap.set('customizedLogoDataFilename', new OdataEntity_1.OdataIndex());
      baseMap.set('customTitlePageFilename', new OdataEntity_1.OdataIndex());
      baseMap.set('customAttachmentPageFilename', new OdataEntity_1.OdataIndex());
      return baseMap;
    }
  }]);
  return ReportSettingsEntity;
}(ReadModelEntity_1.ReadModelEntity);
exports.ReportSettingsEntity = ReportSettingsEntity;