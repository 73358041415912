"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ReadModelClientRemote = exports.ReadModelClientRemoteConfig = void 0;
var StdApplicationError_1 = require("odatarepos/build/src/entities/StdApplicationError");
var ReadModelBase_1 = require("../../core/query/ReadModelBase");
var ReadModels_1 = require("../../core/query/ReadModels");
var ReadModelClientRemoteConfig = (0, _createClass2.default)(function ReadModelClientRemoteConfig() {
  (0, _classCallCheck2.default)(this, ReadModelClientRemoteConfig);
});
exports.ReadModelClientRemoteConfig = ReadModelClientRemoteConfig;
var ReadModelClientRemote = function (_ReadModelBase_1$Read) {
  (0, _inherits2.default)(ReadModelClientRemote, _ReadModelBase_1$Read);
  var _super = _createSuper(ReadModelClientRemote);
  function ReadModelClientRemote() {
    var _this;
    (0, _classCallCheck2.default)(this, ReadModelClientRemote);
    _this = _super.apply(this, arguments);
    _this.onConnected = function (e) {
      if (e != null && !e.hasListeners(`${_this.readModelName}Changed`)) e.on(`${_this.readModelName}Changed`, _this.onChanged);
    };
    _this.onDisconnected = function (e) {
      if (e != null) {}
    };
    _this.onChanged = function (e) {
      var entities = new Map();
      entities.set(e['id'], {
        entity: e
      });
      var ce = {
        readModelName: _this.readModelName,
        entities: entities
      };
      _this.config.eventDispatcher.post(ce);
    };
    return _this;
  }
  (0, _createClass2.default)(ReadModelClientRemote, [{
    key: "count",
    value: function () {
      var _count = (0, _asyncToGenerator2.default)(function* (filter, _userId) {
        if (!this.config.serverConnection().isConnected) {
          throw {
            message: 'Server not connected',
            messageCode: 'noConnection'
          };
        }
        return this.repo.count(filter);
      });
      function count(_x, _x2) {
        return _count.apply(this, arguments);
      }
      return count;
    }()
  }, {
    key: "distinct",
    value: function () {
      var _distinct = (0, _asyncToGenerator2.default)(function* (select, filter, _userId) {
        if (!this.config.serverConnection().isConnected) {
          throw {
            message: 'Server not connected',
            messageCode: 'noConnection'
          };
        }
        return this.repo.distinct(select, filter);
      });
      function distinct(_x3, _x4, _x5) {
        return _distinct.apply(this, arguments);
      }
      return distinct;
    }()
  }, {
    key: "get",
    value: function () {
      var _get = (0, _asyncToGenerator2.default)(function* (options, _userId) {
        if (!this.config.serverConnection().isConnected) {
          throw {
            message: 'Server not connected',
            messageCode: 'noConnection'
          };
        }
        return this.repo.get(options);
      });
      function get(_x6, _x7) {
        return _get.apply(this, arguments);
      }
      return get;
    }()
  }, {
    key: "getById",
    value: function () {
      var _getById = (0, _asyncToGenerator2.default)(function* (id, select, _userId) {
        if (!this.config.serverConnection().isConnected) {
          throw {
            message: 'Server not connected',
            messageCode: 'noConnection'
          };
        }
        return this.repo.getById(id, select);
      });
      function getById(_x8, _x9, _x10) {
        return _getById.apply(this, arguments);
      }
      return getById;
    }()
  }, {
    key: "getEventsById",
    value: function () {
      var _getEventsById = (0, _asyncToGenerator2.default)(function* (id) {
        var withHistory = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        var _userId = arguments.length > 2 ? arguments[2] : undefined;
        if (!this.config.serverConnection().isConnected) {
          throw {
            message: 'Server not connected',
            messageCode: 'noConnection'
          };
        }
        var result = this.config.serverConnection().fetch(`${this.readModelName}/${id}/events?withHistory=${withHistory ? '1' : '0'}`);
        if (result != null) return result;
        throw StdApplicationError_1.StdApplicationError.notFound(`${this.exampleEntity.entityName}notFound`);
      });
      function getEventsById(_x11) {
        return _getEventsById.apply(this, arguments);
      }
      return getEventsById;
    }()
  }, {
    key: "getByIdAt",
    value: function () {
      var _getByIdAt = (0, _asyncToGenerator2.default)(function* (id, time, _userId) {
        if (!this.config.serverConnection().isConnected) {
          throw {
            message: 'Server not connected',
            messageCode: 'noConnection'
          };
        }
        return this.repo['getByIdAt'](id, time);
      });
      function getByIdAt(_x12, _x13, _x14) {
        return _getByIdAt.apply(this, arguments);
      }
      return getByIdAt;
    }()
  }, {
    key: "reset",
    value: function () {
      var _reset = (0, _asyncToGenerator2.default)(function* () {
        this.config.serverConnection().onConnect.detach(this.onConnected);
        this.config.serverConnection().onDisconnect.detach(this.onDisconnected);
        if (this.config.serverConnection().isConnected && this.config.serverConnection().socket != null) {
          this.onDisconnected(this.config.serverConnection().socket);
        }
      });
      function reset() {
        return _reset.apply(this, arguments);
      }
      return reset;
    }()
  }, {
    key: "init",
    value: function () {
      var _init = (0, _asyncToGenerator2.default)(function* (config) {
        this.config = config;
        this.exampleEntity = config.exampleEntity;
        yield this.reset();
        ReadModels_1.ReadModels.registerReadModel(this);
        this.repo = this.config.remoteDB.getRepos(this.exampleEntity);
        this.config.serverConnection().onConnect.attach(this.onConnected);
        if (this.config.serverConnection().isConnected && this.config.serverConnection().socket != null) {
          this.onConnected(this.config.serverConnection().socket);
        }
        this.config.serverConnection().onDisconnect.attach(this.onDisconnected);
      });
      function init(_x15) {
        return _init.apply(this, arguments);
      }
      return init;
    }()
  }]);
  return ReadModelClientRemote;
}(ReadModelBase_1.ReadModelBase);
exports.ReadModelClientRemote = ReadModelClientRemote;