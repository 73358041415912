var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormInputLabelAnimation = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var FormInputLabelAnimation = exports.FormInputLabelAnimation = function () {
  function FormInputLabelAnimation(toValue) {
    var animationDuration = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 195;
    (0, _classCallCheck2.default)(this, FormInputLabelAnimation);
    this.animate = new _reactNativeWeb.Animated.Value(0);
    this.scale = new _reactNativeWeb.Animated.Value(1);
    this.animate = new _reactNativeWeb.Animated.Value(toValue.top);
    this.scale = new _reactNativeWeb.Animated.Value(toValue.scale);
    this.animationDuration = animationDuration;
    this.animations = this.createAnimations();
    this.labelAnimations = this.createLabelAnimations();
  }
  (0, _createClass2.default)(FormInputLabelAnimation, [{
    key: "createAnimations",
    value: function createAnimations() {
      return {
        top: this.animate
      };
    }
  }, {
    key: "createLabelAnimations",
    value: function createLabelAnimations() {
      return {
        top: this.animate,
        transform: [{
          scale: this.scale
        }],
        left: this.scale.interpolate({
          inputRange: [0.75, 1],
          outputRange: ['-12.5%', '0%']
        })
      };
    }
  }, {
    key: "getValues",
    value: function getValues() {
      return {
        scale: this.scale['_value'],
        top: this.animate['_value']
      };
    }
  }, {
    key: "toValue",
    value: function toValue(_toValue, onFinished) {
      _reactNativeWeb.Animated.parallel([_reactNativeWeb.Animated.timing(this.animate, {
        toValue: _toValue.top,
        duration: this.animationDuration,
        useNativeDriver: false
      }), _reactNativeWeb.Animated.timing(this.scale, {
        toValue: _toValue.scale,
        duration: this.animationDuration,
        useNativeDriver: false
      })]).start(function () {
        if (onFinished != null) {
          onFinished();
        }
      });
    }
  }, {
    key: "toValueWithoutDuration",
    value: function toValueWithoutDuration(toValue, onFinished) {
      _reactNativeWeb.Animated.parallel([_reactNativeWeb.Animated.timing(this.animate, {
        toValue: toValue.top,
        duration: 0,
        useNativeDriver: false
      }), _reactNativeWeb.Animated.timing(this.scale, {
        toValue: toValue.scale,
        duration: 0,
        useNativeDriver: false
      })]).start(function () {
        if (onFinished != null) {
          onFinished();
        }
      });
    }
  }]);
  return FormInputLabelAnimation;
}();