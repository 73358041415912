"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SupportClient = void 0;
var ReadModelChangedDefaultClientDispatcher_1 = require("cqrs-core/build/src/client/query/ReadModelChangedDefaultClientDispatcher");
var ReadRemoteStore_1 = require("cqrs-core/build/src/core/query/ReadRemoteStore");
var UserEntity_1 = require("upmesh-auth-core/build/src/client/query/entities/UserEntity");
var UserMailsEntity_1 = require("upmesh-auth-core/build/src/client/query/entities/UserMailsEntity");
var CompanyEntity_1 = require("upmesh-core/build/src/client/query/entities/CompanyEntity");
var CompanyMemberEntity_1 = require("upmesh-core/build/src/client/query/entities/CompanyMemberEntity");
var CompanyProjectEntity_1 = require("upmesh-core/build/src/client/query/entities/CompanyProjectEntity");
var CompanySettingsEntity_1 = require("upmesh-core/build/src/client/query/entities/CompanySettingsEntity");
var FolderEntity_1 = require("upmesh-core/build/src/client/query/entities/FolderEntity");
var LogBookEntity_1 = require("upmesh-core/build/src/client/query/entities/LogBookEntity");
var PlanEntity_1 = require("upmesh-core/build/src/client/query/entities/PlanEntity");
var PlanVersionEntity_1 = require("upmesh-core/build/src/client/query/entities/PlanVersionEntity");
var ProjectEntity_1 = require("upmesh-core/build/src/client/query/entities/ProjectEntity");
var ProjectRolesEntity_1 = require("upmesh-core/build/src/client/query/entities/ProjectRolesEntity");
var ProjectUserEntity_1 = require("upmesh-core/build/src/client/query/entities/ProjectUserEntity");
var StoredFileEntity_1 = require("upmesh-core/build/src/client/query/entities/StoredFileEntity");
var SubscriptionEntity_1 = require("upmesh-core/build/src/client/query/entities/SubscriptionEntity");
var TicketEntity_1 = require("upmesh-core/build/src/client/query/entities/TicketEntity");
var UpmeshPartnerEntity_1 = require("upmesh-core/build/src/client/query/entities/UpmeshPartnerEntity");
var Company_1 = require("./client/Company");
var CompanyMember_1 = require("./client/CompanyMember");
var CompanyPayment_1 = require("./client/CompanyPayment");
var CompanyProject_1 = require("./client/CompanyProject");
var CompanySettings_1 = require("./client/CompanySettings");
var ControlNotes_1 = require("./client/ControlNotes");
var Folder_1 = require("./client/Folder");
var LogBook_1 = require("./client/LogBook");
var Payments_1 = require("./client/Payments");
var Plan_1 = require("./client/Plan");
var PlanVersion_1 = require("./client/PlanVersion");
var Project_1 = require("./client/Project");
var ProjectRoles_1 = require("./client/ProjectRoles");
var ProjectUser_1 = require("./client/ProjectUser");
var StoredFile_1 = require("./client/StoredFile");
var Subscription_1 = require("./client/Subscription");
var SupportRoles_1 = require("./client/SupportRoles");
var Ticket_1 = require("./client/Ticket");
var UpmeshPartner_1 = require("./client/UpmeshPartner");
var UpmeshPartnerSettings_1 = require("./client/UpmeshPartnerSettings");
var User_1 = require("./client/User");
var UserMails_1 = require("./client/UserMails");
var CompanyPaymentEntity_1 = require("./entities/CompanyPaymentEntity");
var ControlNotesEntity_1 = require("./entities/ControlNotesEntity");
var PaymentsEntity_1 = require("./entities/PaymentsEntity");
var SupportRolesEntity_1 = require("./entities/SupportRolesEntity");
var UpmeshPartnerSettingsEntity_1 = require("./entities/UpmeshPartnerSettingsEntity");
var SupportClient = function () {
  function SupportClient() {
    (0, _classCallCheck2.default)(this, SupportClient);
    this._eventDispatcher = ReadModelChangedDefaultClientDispatcher_1.ReadModelChangedDefaultClientDispatcher.instance;
    if (SupportClient._instance != null) {
      return SupportClient._instance;
    }
  }
  (0, _createClass2.default)(SupportClient, [{
    key: "eventDispatcher",
    get: function get() {
      return this._eventDispatcher;
    }
  }, {
    key: "init",
    value: function () {
      var _init = (0, _asyncToGenerator2.default)(function* (apiUrl, serverConnection, commandStore) {
        var remoteDB = new ReadRemoteStore_1.ReadRemoteStore(commandStore);
        yield remoteDB.initDB();
        this.user = new User_1.User();
        yield this.user.init({
          serverConnection: serverConnection,
          remoteDB: remoteDB,
          eventDispatcher: this._eventDispatcher,
          exampleEntity: new UserEntity_1.UserEntity()
        });
        this.roles = new SupportRoles_1.SupportRoles();
        yield this.roles.init({
          serverConnection: serverConnection,
          remoteDB: remoteDB,
          eventDispatcher: this._eventDispatcher,
          exampleEntity: new SupportRolesEntity_1.SupportRolesEntity()
        });
        this.payments = new Payments_1.Payments();
        yield this.payments.init({
          serverConnection: serverConnection,
          remoteDB: remoteDB,
          eventDispatcher: this._eventDispatcher,
          exampleEntity: new PaymentsEntity_1.PaymentsEntity()
        });
        this.controlNotes = new ControlNotes_1.ControlNotes();
        yield this.controlNotes.init({
          serverConnection: serverConnection,
          remoteDB: remoteDB,
          eventDispatcher: this._eventDispatcher,
          exampleEntity: new ControlNotesEntity_1.ControlNotesEntity()
        });
        this.upmeshPartnerSettings = new UpmeshPartnerSettings_1.UpmeshPartnerSettings();
        yield this.upmeshPartnerSettings.init({
          serverConnection: serverConnection,
          remoteDB: remoteDB,
          eventDispatcher: this._eventDispatcher,
          exampleEntity: new UpmeshPartnerSettingsEntity_1.UpmeshPartnerSettingsEntity()
        });
        this.companyPayment = new CompanyPayment_1.CompanyPayment();
        yield this.companyPayment.init({
          serverConnection: serverConnection,
          remoteDB: remoteDB,
          eventDispatcher: this._eventDispatcher,
          exampleEntity: new CompanyPaymentEntity_1.CompanyPaymentEntity()
        });
        this.company = new Company_1.Company();
        yield this.company.init({
          serverConnection: serverConnection,
          remoteDB: remoteDB,
          eventDispatcher: this._eventDispatcher,
          exampleEntity: new CompanyEntity_1.CompanyEntity()
        });
        this.companyMember = new CompanyMember_1.CompanyMember();
        yield this.companyMember.init({
          serverConnection: serverConnection,
          remoteDB: remoteDB,
          eventDispatcher: this._eventDispatcher,
          exampleEntity: new CompanyMemberEntity_1.CompanyMemberEntity()
        });
        this.companySettings = new CompanySettings_1.CompanySettings();
        yield this.companySettings.init({
          serverConnection: serverConnection,
          remoteDB: remoteDB,
          eventDispatcher: this._eventDispatcher,
          exampleEntity: new CompanySettingsEntity_1.CompanySettingsEntity()
        });
        this.companyProject = new CompanyProject_1.CompanyProject();
        yield this.companyProject.init({
          serverConnection: serverConnection,
          remoteDB: remoteDB,
          eventDispatcher: this._eventDispatcher,
          exampleEntity: new CompanyProjectEntity_1.CompanyProjectEntity()
        });
        this.upmeshPartner = new UpmeshPartner_1.UpmeshPartner();
        yield this.upmeshPartner.init({
          serverConnection: serverConnection,
          remoteDB: remoteDB,
          eventDispatcher: this._eventDispatcher,
          exampleEntity: new UpmeshPartnerEntity_1.UpmeshPartnerEntity()
        });
        this.subscription = new Subscription_1.Subscription();
        yield this.subscription.init({
          serverConnection: serverConnection,
          remoteDB: remoteDB,
          eventDispatcher: this._eventDispatcher,
          exampleEntity: new SubscriptionEntity_1.SubscriptionEntity()
        });
        this.project = new Project_1.Project();
        yield this.project.init({
          serverConnection: serverConnection,
          remoteDB: remoteDB,
          eventDispatcher: this._eventDispatcher,
          exampleEntity: new ProjectEntity_1.ProjectEntity()
        });
        this.projectUser = new ProjectUser_1.ProjectUser();
        yield this.projectUser.init({
          serverConnection: serverConnection,
          remoteDB: remoteDB,
          eventDispatcher: this._eventDispatcher,
          exampleEntity: new ProjectUserEntity_1.ProjectUserEntity()
        });
        this.projectRoles = new ProjectRoles_1.ProjectRoles();
        yield this.projectRoles.init({
          serverConnection: serverConnection,
          remoteDB: remoteDB,
          eventDispatcher: this._eventDispatcher,
          exampleEntity: new ProjectRolesEntity_1.ProjectRolesEntity()
        });
        this.userMails = new UserMails_1.UserMails();
        yield this.userMails.init({
          serverConnection: serverConnection,
          remoteDB: remoteDB,
          eventDispatcher: this._eventDispatcher,
          exampleEntity: new UserMailsEntity_1.UserMailsEntity()
        });
        this.logBook = new LogBook_1.LogBook();
        yield this.logBook.init({
          serverConnection: serverConnection,
          remoteDB: remoteDB,
          eventDispatcher: this._eventDispatcher,
          exampleEntity: new LogBookEntity_1.LogBookEntity()
        });
        this.ticket = new Ticket_1.Ticket();
        yield this.ticket.init({
          serverConnection: serverConnection,
          remoteDB: remoteDB,
          eventDispatcher: this._eventDispatcher,
          exampleEntity: new TicketEntity_1.TicketEntity()
        });
        this.plan = new Plan_1.Plan();
        yield this.plan.init({
          serverConnection: serverConnection,
          remoteDB: remoteDB,
          eventDispatcher: this._eventDispatcher,
          exampleEntity: new PlanEntity_1.PlanEntity()
        });
        this.planVersion = new PlanVersion_1.PlanVersion();
        yield this.planVersion.init({
          serverConnection: serverConnection,
          remoteDB: remoteDB,
          eventDispatcher: this._eventDispatcher,
          exampleEntity: new PlanVersionEntity_1.PlanVersionEntity()
        });
        this.storedFile = new StoredFile_1.StoredFile();
        yield this.storedFile.init({
          serverConnection: serverConnection,
          remoteDB: remoteDB,
          eventDispatcher: this._eventDispatcher,
          exampleEntity: new StoredFileEntity_1.StoredFileEntity()
        });
        this.folder = new Folder_1.Folder();
        yield this.folder.init({
          serverConnection: serverConnection,
          remoteDB: remoteDB,
          eventDispatcher: this._eventDispatcher,
          exampleEntity: new FolderEntity_1.FolderEntity()
        });
        SupportClient._instance = this;
      });
      function init(_x, _x2, _x3) {
        return _init.apply(this, arguments);
      }
      return init;
    }()
  }], [{
    key: "eventDispatcher",
    get: function get() {
      return SupportClient.instance.eventDispatcher;
    }
  }, {
    key: "instance",
    get: function get() {
      if (SupportClient._instance == null) {
        throw new Error('init: use SupportClient.init() first');
      }
      return SupportClient._instance;
    }
  }]);
  return SupportClient;
}();
exports.SupportClient = SupportClient;