"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RemoveProjectUser = void 0;
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../access/rights/RightsManager");
var ProjectUserRemoved_1 = require("../../../server/events/user/ProjectUserRemoved");
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var TicketApproverChanged_1 = require("../../events/tickets/TicketApproverChanged");
var TicketApproverRemoved_1 = require("../../events/tickets/TicketApproverRemoved");
var TicketAssignedChanged_1 = require("../../events/tickets/TicketAssignedChanged");
var TicketAssignerRemoved_1 = require("../../events/tickets/TicketAssignerRemoved");
var ClientProjectCommands_1 = require("../_root/ClientProjectCommands");
var RemoveTicketWatcher_1 = require("../tickets/RemoveTicketWatcher");
var RemoveProjectUser = function (_ClientProjectCommand) {
  (0, _inherits2.default)(RemoveProjectUser, _ClientProjectCommand);
  var _super = _createSuper(RemoveProjectUser);
  function RemoveProjectUser(data, entityId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, RemoveProjectUser);
    _this = _super.call(this, data, entityId, token);
    _this.aggregate = 'project';
    _this.commandName = 'RemoveProjectUser';
    return _this;
  }
  (0, _createClass2.default)(RemoveProjectUser, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        this.pu = yield CommandReadModels_1.CommandReadModels.projectUser.getById(this.entityId);
        if (!this.data.force) {
          try {
            yield (0, _get2.default)((0, _getPrototypeOf2.default)(RemoveProjectUser.prototype), "checkProjectStatusAndGeneralAccess", this).call(this, this.pu.projectId, userId);
          } catch (e) {
            if (e['messageCode'] === 'projectMaximumSizeReached') {
              this.data.force = true;
            } else {
              throw e;
            }
          }
        }
        if (this.pu.status === 'deleted') {
          throw {
            message: 'Bereits gelöscht',
            messageCode: 'userAlreadyDeleted'
          };
        }
        if (userId !== this.pu.userId) {
          if (!(yield RightsManager_1.RightsManager.hasWriteRight(this.pu.projectId, userId, 'commandRemoveUser'))) {
            throw {
              message: 'Keine Berechtigung',
              messageCode: 'forbiddenCommand'
            };
          }
        }
        if (!this.data.force) {
          var openTickets = yield CommandReadModels_1.CommandReadModels.instance.ticket.count(`projectId eq '${this.pu.projectId}' and (assignedToUserId eq '${this.pu.userId}' or approverUserId eq '${this.pu.userId}')`);
          if (openTickets != null && openTickets > 0) {
            throw {
              message: 'Der Benutzer hat noch zugewiesene Tickets und kann deswegen nicht gelöscht werden',
              messageCode: 'cantDeleteHasAssigendTickets'
            };
          }
        }
        var watcherOnTickets = yield CommandReadModels_1.CommandReadModels.instance.ticket.get({
          filter: `projectId eq '${this.pu.projectId}' and watchers/userId eq '${this.pu.userId}'`
        });
        if (watcherOnTickets != null && watcherOnTickets.length > 0) {
          for (var ticket of watcherOnTickets) {
            var rem = new RemoveTicketWatcher_1.RemoveTicketWatcher({
              userId: this.pu.userId
            }, ticket.id, this.token);
            yield rem.execute();
          }
        }
        return true;
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var _this2 = this;
        var rm = yield CommandReadModels_1.CommandReadModels.projectUser.getById(this.entityId);
        var data = {
          accepted: false
        };
        var events = [];
        if (this.data.force) {
          var getOpenTickets = yield CommandReadModels_1.CommandReadModels.instance.ticket.get({
            filter: `projectId eq '${this.pu.projectId}' and (assignedToUserId eq '${this.pu.userId}' or approverUserId eq '${this.pu.userId}')`
          });
          var project = yield CommandReadModels_1.CommandReadModels.project.getById(this.pu.projectId);
          var owner = '';
          if (project.creator.startsWith('company')) {
            try {
              var companyId = project.creator.slice(7);
              var company = yield CommandReadModels_1.CommandReadModels.company.getById(companyId);
              var manager = yield CommandReadModels_1.CommandReadModels.projectUser.get({
                filter: `projectId eq '${this.pu.projectId}' and roleId eq '1' and status eq 'accepted'`
              });
              if (manager.length > 0) {
                var _loop = function* _loop(m) {
                  var _company$users;
                  var ind = (_company$users = company.users) == null ? void 0 : _company$users.findIndex(function (u) {
                    return u.userId === m.userId;
                  });
                  if (ind != null && ind > -1) {
                    owner = m.userId;
                    return 1;
                  }
                };
                for (var m of manager) {
                  if (yield* _loop(m)) break;
                }
              }
            } catch (e) {}
          } else {
            owner = project.creator;
          }
          getOpenTickets.forEach(function (t) {
            if (t.assignedToUserId === _this2.pu.userId) {
              events.push(new TicketAssignerRemoved_1.TicketAssignerRemoved(t.id, {
                userId: t.assignedToUserId
              }, rm.projectId));
              if (owner.length > 0) {
                events.push(new TicketAssignedChanged_1.TicketAssignedChanged(t.id, {
                  assignedToUserId: owner
                }, rm.projectId));
              }
            }
            if (t.approverUserId === _this2.pu.userId) {
              events.push(new TicketApproverRemoved_1.TicketApproverRemoved(t.id, {
                userId: t.approverUserId
              }, rm.projectId));
              if (owner.length > 0) {
                events.push(new TicketApproverChanged_1.TicketApproverChanged(t.id, {
                  approverUserId: owner
                }, rm.projectId));
              }
            }
          });
        }
        events.push(new ProjectUserRemoved_1.ProjectUserRemoved(this.entityId, data, rm.projectId));
        return events;
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {});
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
  return RemoveProjectUser;
}(ClientProjectCommands_1.ClientProjectCommands);
exports.RemoveProjectUser = RemoveProjectUser;