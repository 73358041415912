"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RemoveTicketPlanAffiliation = void 0;
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../access/rights/RightsManager");
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var TicketPlanAffiliationRemoved_1 = require("../../events/tickets/TicketPlanAffiliationRemoved");
var ClientProjectCommands_1 = require("../_root/ClientProjectCommands");
var RemoveTicketPlanAffiliation = function (_ClientProjectCommand) {
  (0, _inherits2.default)(RemoveTicketPlanAffiliation, _ClientProjectCommand);
  var _super = _createSuper(RemoveTicketPlanAffiliation);
  function RemoveTicketPlanAffiliation(data, entityId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, RemoveTicketPlanAffiliation);
    _this = _super.call(this, data, entityId, token);
    _this.aggregate = 'project';
    _this.commandName = 'RemoveTicketPlanAffiliation';
    return _this;
  }
  (0, _createClass2.default)(RemoveTicketPlanAffiliation, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var ticket = yield CommandReadModels_1.CommandReadModels.ticket.getById(this.entityId);
        yield (0, _get2.default)((0, _getPrototypeOf2.default)(RemoveTicketPlanAffiliation.prototype), "checkProjectStatusAndGeneralAccess", this).call(this, ticket.projectId, userId);
        if (ticket.creator === userId) {
          return true;
        }
        if (yield RightsManager_1.RightsManager.hasWriteRight(ticket.projectId, userId, 'canChangeAllTickets')) {
          return true;
        }
        if (yield RightsManager_1.RightsManager.hasWriteRight(ticket.projectId, userId, 'commandChangeTicketPlanAffiliation')) {
          return true;
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var tokenData = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token);
        var userId = tokenData._id;
        var rm = yield CommandReadModels_1.CommandReadModels.ticket.getById(this.entityId);
        return new TicketPlanAffiliationRemoved_1.TicketPlanAffiliationRemoved(this.entityId, Object.assign({}, this.data, {
          changedBy: userId
        }), rm.projectId);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {});
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
  return RemoveTicketPlanAffiliation;
}(ClientProjectCommands_1.ClientProjectCommands);
exports.RemoveTicketPlanAffiliation = RemoveTicketPlanAffiliation;