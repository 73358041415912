import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Form } from 'materialTheme/src/theme/components/forminput/Form';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { DefaultErrorHandler } from 'upmesh-client/src/components/DefaultErrorHandler';
import { AddCompany } from 'upmesh-support-core/src/commands/company/AddCompany';
import { ConnectionHandler } from '../../ConnectionHandler';
export class AddCompanyDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.close = () => {
            Dialog.instance?.close();
        };
        this.catchError = (e) => {
            DefaultErrorHandler.showDefaultErrorAlert(e);
            return true;
        };
        this.onSaved = () => {
            this.close();
        };
        this.state = {
            error: '',
        };
    }
    render() {
        const dataOptions = new Map();
        dataOptions.set('company', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Firma',
                autoFocus: true,
            },
        });
        if (!ConnectionHandler.isPartner) {
            dataOptions.set('partnerCustomerNumber', {
                formType: 'string',
                cols: 1,
                optional: true,
                props: {
                    labelText: 'Kundennummer',
                },
            });
        }
        else {
            dataOptions.set('customerNumber', {
                formType: 'string',
                cols: 1,
                optional: true,
                props: {
                    labelText: 'Kundennummer',
                },
            });
            dataOptions.set('billwerkCustomerNumber', {
                formType: 'string',
                cols: 1,
                optional: true,
                props: {
                    labelText: 'Billwerk Kundennummer',
                },
            });
        }
        dataOptions.set('contactName', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Kontaktname',
            },
        });
        dataOptions.set('email', {
            formType: 'email',
            cols: 1,
            props: {
                labelText: 'E-Mail Adresse',
            },
        });
        dataOptions.set('description', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Beschreibung',
            },
        });
        dataOptions.set('street', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Straße',
            },
        });
        dataOptions.set('streetNr', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Hausnummer',
            },
        });
        dataOptions.set('zip', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'PLZ',
            },
        });
        dataOptions.set('city', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Stadt',
            },
        });
        dataOptions.set('country', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Land',
            },
        });
        dataOptions.set('phone', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: 'Telefon',
            },
        });
        return (<Form baseCols={1} catchError={this.catchError} dataOptions={dataOptions} formHeaderProps={{
                formTitle: 'Firma hinzufügen',
            }} store={ConnectionHandler.getStore()} command={new AddCompany({}, ConnectionHandler.serverConnection.token)} additionalButtons={[
                <ContainedButton key="abort" onPress={this.close} title="abbrechen" backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
            ]} onSaved={this.onSaved} saveButtonLabel="erstellen" stickyHeader stickyActions serverConnection={ConnectionHandler.getConnection()}/>);
    }
}
