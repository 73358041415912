var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormInputPicker = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _ThemeManager = require("../../ThemeManager");
var _Menu = require("../Menu");
var _Measurement = require("../utils/Measurement");
var _FormInputFilled = require("./FormInputFilled");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var FormInputPicker = exports.FormInputPicker = function (_PureComponent) {
  (0, _inherits2.default)(FormInputPicker, _PureComponent);
  var _super = _createSuper(FormInputPicker);
  function FormInputPicker(props) {
    var _this;
    (0, _classCallCheck2.default)(this, FormInputPicker);
    _this = _super.call(this, props);
    _this.onCloseMenu = function () {
      var onCloseMenu = _this.props.onCloseMenu;
      _this.setState({
        menuIsOpen: false
      }, function () {
        if (onCloseMenu != null) {
          onCloseMenu();
        }
      });
    };
    _this.openMenu = function (_e) {
      var _this$props = _this.props,
        textColor = _this$props.textColor,
        selectedTextColor = _this$props.selectedTextColor,
        menuWidth = _this$props.menuWidth,
        elevation = _this$props.elevation,
        onFocus = _this$props.onFocus,
        onBlur = _this$props.onBlur,
        onOpenMenu = _this$props.onOpenMenu;
      if (_this.button == null) {
        return;
      }
      if (_reactNativeWeb.Keyboard != null) {
        _reactNativeWeb.Keyboard.dismiss();
      }
      var asyncNow = function () {
        var _ref = (0, _asyncToGenerator2.default)(function* () {
          var _Menu$instance;
          var s = yield _Measurement.Measurement.measure(_this.button);
          var items = _this.getList();
          var client = {
            height: s.height,
            width: menuWidth == null ? s.width : menuWidth,
            x: s.pageX,
            y: s.pageY - 20
          };
          (_Menu$instance = _Menu.Menu.instance) == null || _Menu$instance.open({
            elevation: elevation,
            client: client,
            onFocus: onFocus,
            onBlur: onBlur,
            items: items,
            onOpen: onOpenMenu,
            onClose: _this.onCloseMenu,
            textColor: textColor != null && textColor.length > 0 ? textColor : _ThemeManager.ThemeManager.style.primaryTextColor,
            selectedTextColor: selectedTextColor != null && selectedTextColor.length > 0 ? selectedTextColor : _ThemeManager.ThemeManager.style.primaryTextColor
          });
          _this.setState({
            menuIsOpen: true
          });
        });
        return function asyncNow() {
          return _ref.apply(this, arguments);
        };
      }();
      asyncNow().catch(function (err) {
        return void 0;
      });
    };
    _this.onPressListItem = function (_e, index) {
      var _Menu$instance2;
      var _this$props2 = _this.props,
        onChangePicker = _this$props2.onChangePicker,
        onChange = _this$props2.onChange;
      var list = _this.state.list;
      if (onChangePicker != null) {
        onChangePicker(index);
      }
      if (onChange != null) {
        onChange({
          index: index,
          value: list[index]
        });
      }
      _this.setState({
        currentIndex: index
      });
      (_Menu$instance2 = _Menu.Menu.instance) == null || _Menu$instance2.close();
    };
    _this.setButton = function (e) {
      _this.button = e;
    };
    _this.state = {
      currentIndex: props.selectedIndex,
      list: [],
      menuIsOpen: false
    };
    return _this;
  }
  (0, _createClass2.default)(FormInputPicker, [{
    key: "blur",
    value: function blur() {}
  }, {
    key: "clear",
    value: function clear() {}
  }, {
    key: "focus",
    value: function focus() {}
  }, {
    key: "getId",
    value: function getId() {
      return '';
    }
  }, {
    key: "getValue",
    value: function getValue() {
      return {
        value: this.props.list[this.state.currentIndex],
        index: this.state.currentIndex
      };
    }
  }, {
    key: "setValue",
    value: function setValue(value) {
      var list = this.state.list;
      if (value != null && list.length > 0) {
        var index = list.findIndex(function (u) {
          if (typeof u === 'string') return u === value;
          return u.value === value;
        });
        if (index > -1) this.setState({
          currentIndex: index
        });
      }
    }
  }, {
    key: "getList",
    value: function getList() {
      var selectedIndex = this.props.selectedIndex;
      var list = this.state.list;
      var items = [];
      for (var i = 0; i < list.length; i += 1) {
        var title = '';
        var props = list[i];
        if (typeof list[i] === 'string') {
          title = list[i].toString();
          props = null;
        } else if (list[i]['title']) {
          title = list[i]['title'];
        }
        items.push(Object.assign({}, props, {
          text: title,
          onPress: this.onPressListItem,
          selected: i === selectedIndex,
          checked: typeof list[i] !== 'string' && list[i]['checked'] != null ? list[i]['checked'] : false,
          subtitle: typeof list[i] !== 'string' && list[i]['subtitle'] ? list[i]['subtitle'] : null
        }));
      }
      return items;
    }
  }, {
    key: "render",
    value: function render() {
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        collapsable: false,
        ref: this.setButton,
        children: this.renderFI()
      });
    }
  }, {
    key: "renderFI",
    value: function renderFI() {
      var _this$props3 = this.props,
        selectedIndex = _this$props3.selectedIndex,
        labelText = _this$props3.labelText,
        disabled = _this$props3.disabled,
        helperText = _this$props3.helperText,
        error = _this$props3.error;
      var _this$state = this.state,
        list = _this$state.list,
        currentIndex = _this$state.currentIndex,
        menuIsOpen = _this$state.menuIsOpen;
      if (selectedIndex == null) {
        return null;
      }
      var title = '';
      if (currentIndex < list.length) {
        if (typeof list[currentIndex] === 'string') {
          title = list[currentIndex].toString();
        } else if (list[currentIndex]['title']) {
          title = list[currentIndex]['title'];
        }
      }
      return (0, _jsxRuntime.jsx)(_FormInputFilled.FormInputFilled, {
        value: title,
        clearButton: false,
        disabled: disabled,
        error: error,
        overrideOnPressFocus: this.openMenu,
        onFocus: this.openMenu,
        labelText: labelText,
        accessibilityLabel: this.props.accessibilityLabel,
        iconRight: {
          disabled: disabled,
          icon: menuIsOpen === true ? 'menu-up' : 'menu-down',
          toolTip: '',
          onPress: this.openMenu
        },
        helperText: helperText
      });
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        list: [],
        selectedIndex: 0,
        elevation: 8,
        disabled: false
      };
    }
  }, {
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, _prevState) {
      try {
        var replacer = function replacer(key, value) {
          if (key === 'thumbnail') {
            return undefined;
          }
          return value;
        };
        if (nextProps.selectedIndex !== _prevState.currentIndex || JSON.stringify(nextProps.list, replacer) !== JSON.stringify(_prevState.list, replacer)) return {
          currentIndex: nextProps.selectedIndex,
          list: nextProps.list
        };
      } catch (e) {}
      return null;
    }
  }]);
  return FormInputPicker;
}(_react.PureComponent);
FormInputPicker.defaultState = {
  currentIndex: 0,
  list: [],
  menuIsOpen: false
};