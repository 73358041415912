import ApexCharts from 'apexcharts';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { ConnectionHandler } from '../ConnectionHandler';
export class SubscriptionChart extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.loadLineData().catch((err) => console.debug('cant load Data', err));
    }
    getBarData(started, date) {
        const newLeader = [];
        const newCollaborator = [];
        const newTimeTracker = [];
        if (started.length > 0) {
            const startDate = new Date(started[0][date]);
            const today = new Date();
            const diff = new Date(today.getTime() - startDate.getTime());
            const month = diff.getMonth() + 12 * (diff.getFullYear() - 1970);
            const addForDay = (month, arr, licenceType) => {
                let r = 0;
                if (arr.length > 0) {
                    const a = arr[0];
                    if (a[date].getMonth() === month.month && a[date].getFullYear() === month.year) {
                        const c = arr.shift();
                        if (c != null &&
                            !c.testAccount &&
                            c.subscription === 'enterprise' &&
                            ((licenceType != null && c.licenceType === licenceType) || (!licenceType && c?.licenceType == null))) {
                            r += c.licenses || 1;
                        }
                        r += addForDay(month, arr, licenceType);
                    }
                }
                return r;
            };
            const started2 = [...started];
            const started3 = [...started];
            for (let i = 0; i <= month + 1; i += 1) {
                const thisMonth = new Date(startDate.getFullYear(), startDate.getMonth() + i);
                const leader = addForDay({ year: thisMonth.getFullYear(), month: thisMonth.getMonth() }, started, undefined);
                newLeader.push({ x: thisMonth.getTime(), y: leader });
                const col = addForDay({ year: thisMonth.getFullYear(), month: thisMonth.getMonth() }, started2, 'collaborator');
                newCollaborator.push({ x: thisMonth.getTime(), y: col });
                const tt = addForDay({ year: thisMonth.getFullYear(), month: thisMonth.getMonth() }, started3, 'timeTracking');
                newTimeTracker.push({ x: thisMonth.getTime(), y: tt });
            }
        }
        return { leader: newLeader, collaborator: newCollaborator, timeTracker: newTimeTracker };
    }
    getLineData(started, ended) {
        const newLeader = [];
        const newCollaborator = [];
        const newTimeTracker = [];
        const newPro = [];
        if (started.length > 0) {
            const startDate = new Date(started[0].startDate);
            const today = new Date();
            const diff = new Date(today.getTime() - startDate.getTime());
            const month = diff.getMonth() + 12 * (diff.getFullYear() - 1970);
            const addForDay = (month, arr, date, licenceType) => {
                let r = 0;
                if (arr.length > 0) {
                    const a = arr[0];
                    if (a[date].getMonth() === month.month && a[date].getFullYear() === month.year) {
                        const c = arr.shift();
                        if (c != null &&
                            !c.testAccount &&
                            c.subscription === 'enterprise' &&
                            ((licenceType != null && c.licenceType === licenceType) || (!licenceType && c?.licenceType == null))) {
                            r += c.licenses || 1;
                        }
                        else if (c != null && licenceType === 'pro' && c.subscription === 'pro') {
                            r += c.licenses || 1;
                        }
                        r += addForDay(month, arr, date, licenceType);
                    }
                }
                return r;
            };
            const started2 = [...started];
            const started3 = [...started];
            const started4 = [...started];
            const ended2 = [...ended];
            const ended3 = [...ended];
            const ended4 = [...ended];
            let leaderTotal = 0;
            let collaboratorTotal = 0;
            let timeTrackerTotal = 0;
            let proTotal = 0;
            for (let i = 0; i <= month + 1; i += 1) {
                const thisMonth = new Date(startDate.getFullYear(), startDate.getMonth() + i);
                const leaderPlus = addForDay({ year: thisMonth.getFullYear(), month: thisMonth.getMonth() }, started, 'startDate', undefined);
                leaderTotal += leaderPlus;
                const leaderMinus = addForDay({ year: thisMonth.getFullYear(), month: thisMonth.getMonth() }, ended, 'expireDate', undefined);
                leaderTotal -= leaderMinus;
                newLeader.push({ x: thisMonth.getTime(), y: leaderTotal });
                const colPlus = addForDay({ year: thisMonth.getFullYear(), month: thisMonth.getMonth() }, started2, 'startDate', 'collaborator');
                collaboratorTotal += colPlus;
                const colMinus = addForDay({ year: thisMonth.getFullYear(), month: thisMonth.getMonth() }, ended2, 'expireDate', 'collaborator');
                collaboratorTotal -= colMinus;
                newCollaborator.push({ x: thisMonth.getTime(), y: collaboratorTotal });
                const ttPlus = addForDay({ year: thisMonth.getFullYear(), month: thisMonth.getMonth() }, started3, 'startDate', 'timeTracking');
                timeTrackerTotal += ttPlus;
                const ttMinus = addForDay({ year: thisMonth.getFullYear(), month: thisMonth.getMonth() }, ended3, 'expireDate', 'timeTracking');
                timeTrackerTotal -= ttMinus;
                newTimeTracker.push({ x: thisMonth.getTime(), y: timeTrackerTotal });
                const proPlus = addForDay({ year: thisMonth.getFullYear(), month: thisMonth.getMonth() }, started4, 'startDate', 'pro');
                proTotal += proPlus;
                const proMinus = addForDay({ year: thisMonth.getFullYear(), month: thisMonth.getMonth() }, ended4, 'expireDate', 'pro');
                proTotal -= proMinus;
                newPro.push({ x: thisMonth.getTime(), y: proTotal });
            }
        }
        return { leader: newLeader, collaborator: newCollaborator, timeTracker: newTimeTracker, pro: newPro };
    }
    async loadBarData() {
        const client = ConnectionHandler.getClient();
        if (client != null) {
            const subs = await client.subscription.get({
                filter: `expireDate gt ${new Date().toISOString()} and (subscription eq 'pro' or subscription eq 'enterprise')`,
            });
            const started = [...subs];
            started.sort((a, b) => {
                if (a.startDate < b.startDate)
                    return -1;
                return 1;
            });
            const ended = [...subs];
            ended.sort((a, b) => {
                if (a.expireDate < b.expireDate)
                    return -1;
                return 1;
            });
            const data = this.getBarData([...started], 'startDate');
            const options = {
                chart: {
                    type: 'bar',
                    stacked: false,
                    width: ResizeEvent.current.contentWidth - 32,
                    height: ResizeEvent.current.contentHeight - 64,
                    zoom: {
                        type: 'x',
                        enabled: true,
                        autoScaleYaxis: true,
                    },
                    toolbar: {
                        show: true,
                        offsetX: -24,
                        offsetY: 0,
                        tools: {
                            download: false,
                            selection: true,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: true,
                            reset: true,
                            customIcons: [],
                        },
                        autoSelected: 'zoom',
                    },
                },
                title: {
                    text: 'Lizenzen pro Monat',
                    align: 'left',
                },
                yaxis: {
                    labels: {
                        formatter(val) {
                            return val ? val.toFixed(0) : 0;
                        },
                    },
                },
                xaxis: {
                    type: 'datetime',
                },
                series: [
                    {
                        name: 'Projektleiter',
                        data: data?.leader,
                    },
                    {
                        name: 'Mitarbeiter',
                        data: data?.collaborator,
                    },
                    {
                        name: 'Zeiterfasser',
                        data: data?.timeTracker,
                    },
                ],
                tooltip: {
                    shared: false,
                    y: {
                        formatter(val) {
                            return val.toFixed(0);
                        },
                    },
                    x: {
                        formatter(val) {
                            const d = new Date(val);
                            return `${d.getFullYear()}.${d.getMonth() + 1}`;
                        },
                    },
                },
            };
            const chart = new ApexCharts(document.querySelector('#chart'), options);
            chart.render().catch((err) => console.debug('cant render chart!', err));
        }
    }
    async loadLineData() {
        const client = ConnectionHandler.getClient();
        if (client != null) {
            const subs = await client.subscription.get({});
            const started = [...subs];
            started.sort((a, b) => {
                if (a.startDate < b.startDate)
                    return -1;
                return 1;
            });
            const ended = [...subs];
            ended.sort((a, b) => {
                if (a.expireDate < b.expireDate)
                    return -1;
                return 1;
            });
            const data = this.getLineData([...started], [...ended]);
            const options = {
                chart: {
                    type: 'line',
                    stacked: false,
                    width: ResizeEvent.current.contentWidth - 32,
                    height: ResizeEvent.current.contentHeight - 64,
                    zoom: {
                        type: 'x',
                        enabled: true,
                        autoScaleYaxis: true,
                    },
                    toolbar: {
                        show: true,
                        offsetX: -24,
                        offsetY: 0,
                        tools: {
                            download: false,
                            selection: true,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: true,
                            reset: true,
                            customIcons: [],
                        },
                        autoSelected: 'zoom',
                    },
                },
                title: {
                    text: 'Lizenzen pro Monat',
                    align: 'left',
                },
                yaxis: {
                    labels: {
                        formatter(val) {
                            return val ? val.toFixed(0) : 0;
                        },
                    },
                },
                xaxis: {
                    type: 'datetime',
                },
                series: [
                    {
                        name: 'Projektleiter',
                        data: data?.leader,
                    },
                    {
                        name: 'Mitarbeiter',
                        data: data?.collaborator,
                    },
                    {
                        name: 'Zeiterfasser',
                        data: data?.timeTracker,
                    },
                    {
                        name: 'Pro',
                        data: data?.pro,
                    },
                ],
                tooltip: {
                    shared: false,
                    y: {
                        formatter(val) {
                            return val.toFixed(0);
                        },
                    },
                    x: {
                        formatter(val) {
                            const d = new Date(val);
                            return `${d.getFullYear()}.${d.getMonth() + 1}`;
                        },
                    },
                },
            };
            const chart = new ApexCharts(document.querySelector('#chart'), options);
            chart.render().catch((err) => console.debug('cant render chart!', err));
        }
    }
    render() {
        return (<View>
        <View style={{ width: ResizeEvent.current.contentWidth, height: ResizeEvent.current.contentHeight, paddingTop: 24 }}>
          <div style={{ width: '100%', height: '100%' }} id="chart"/>
        </View>
      </View>);
    }
}
