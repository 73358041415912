"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProjectEntity = void 0;
var ReadModelEntity_1 = require("cqrs-core/build/src/core/query/ReadModelEntity");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var StdApplicationError_1 = require("odatarepos/build/src/entities/StdApplicationError");
var RightsManager_1 = require("../../../access/rights/RightsManager");
var I18n_1 = require("../../../i18n/I18n");
var ProjectEntity = function (_ReadModelEntity_1$Re) {
  (0, _inherits2.default)(ProjectEntity, _ReadModelEntity_1$Re);
  var _super = _createSuper(ProjectEntity);
  function ProjectEntity(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, ProjectEntity);
    _this = _super.call(this, obj);
    _this.entityName = 'Project';
    _this.filesEditableTickets = '24h';
    _this.messagesOverride = {};
    _this.template = false;
    _this.fill(obj);
    return _this;
  }
  (0, _createClass2.default)(ProjectEntity, [{
    key: "createOne",
    value: function createOne(obj) {
      return new ProjectEntity(obj);
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('title', new OdataEntity_1.OdataIndex());
      baseMap.set('projectType', new OdataEntity_1.OdataIndex());
      baseMap.set('description', new OdataEntity_1.OdataIndex());
      baseMap.set('color', new OdataEntity_1.OdataIndex());
      baseMap.set('archived', new OdataEntity_1.OdataIndex(true));
      baseMap.set('deleted', new OdataEntity_1.OdataIndex(true));
      baseMap.set('inCreation', new OdataEntity_1.OdataIndex(true));
      baseMap.set('deletedFromServer', new OdataEntity_1.OdataIndex(true));
      baseMap.set('userGroups', new OdataEntity_1.OdataIndex());
      baseMap.set('ticketLayouts', new OdataEntity_1.OdataIndex());
      baseMap.set('crafts', new OdataEntity_1.OdataIndex());
      baseMap.set('messagesOverride', new OdataEntity_1.OdataIndex());
      baseMap.set('fileTypes', new OdataEntity_1.OdataIndex());
      baseMap.set('locale', new OdataEntity_1.OdataIndex());
      baseMap.set('creator', new OdataEntity_1.OdataIndex(true));
      baseMap.set('ticketTypes', new OdataEntity_1.OdataIndex());
      baseMap.set('tagGroups', new OdataEntity_1.OdataIndex());
      baseMap.set('tags', new OdataEntity_1.OdataIndex());
      baseMap.set('filesEditableTickets', new OdataEntity_1.OdataIndex());
      baseMap.set('projectStart', new OdataEntity_1.OdataIndex());
      baseMap.set('projectEnd', new OdataEntity_1.OdataIndex());
      baseMap.set('constructionStart', new OdataEntity_1.OdataIndex());
      baseMap.set('constructionEnd', new OdataEntity_1.OdataIndex());
      baseMap.set('storageUsageInBytes', new OdataEntity_1.OdataIndex());
      baseMap.set('projectImageFileId', new OdataEntity_1.OdataIndex());
      baseMap.set('projectSubscription', new OdataEntity_1.OdataIndex(true));
      baseMap.set('projectStatus', new OdataEntity_1.OdataIndex(true));
      baseMap.set('locked', new OdataEntity_1.OdataIndex(true));
      baseMap.set('template', new OdataEntity_1.OdataIndex(true));
      return baseMap;
    }
  }], [{
    key: "getDefaultTicketTypes",
    value: function getDefaultTicketTypes(language) {
      var old = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      if (old) {
        return [I18n_1.I18n.m.getMessage('ticketTypeDefect', {
          lng: language
        }), I18n_1.I18n.m.getMessage('ticketTypeTask', {
          lng: language
        }), I18n_1.I18n.m.getMessage('ticketTypeInventory', {
          lng: language
        }), I18n_1.I18n.m.getMessage('ticketTypeWarrantyDeficiency', {
          lng: language
        }), I18n_1.I18n.m.getMessage('ticketTypeNote', {
          lng: language
        }), I18n_1.I18n.m.getMessage('ticketTypeAddendum', {
          lng: language
        })];
      }
      return [I18n_1.I18n.m.getMessage('ticketTypeTask', {
        lng: language
      }), I18n_1.I18n.m.getMessage('ticketTypeClientRequest', {
        lng: language
      }), I18n_1.I18n.m.getMessage('ticketTypeObstruction', {
        lng: language
      }), I18n_1.I18n.m.getMessage('ticketTypeDocumentation', {
        lng: language
      }), I18n_1.I18n.m.getMessage('ticketTypeIndication', {
        lng: language
      }), I18n_1.I18n.m.getMessage('ticketTypeDefect', {
        lng: language
      }), I18n_1.I18n.m.getMessage('ticketTypeClaim', {
        lng: language
      }), I18n_1.I18n.m.getMessage('ticketTypeWarrantyDeficiency', {
        lng: language
      }), I18n_1.I18n.m.getMessage('ticketTypeOutstandingWork', {
        lng: language
      }), I18n_1.I18n.m.getMessage('ticketTypeConditionAssessment', {
        lng: language
      })];
    }
  }, {
    key: "getDefaultCrafts",
    value: function getDefaultCrafts(language) {
      return [I18n_1.I18n.m.getMessage('craftDemolitionWork', {
        lng: language
      }), I18n_1.I18n.m.getMessage('craftConcrete', {
        lng: language
      }), I18n_1.I18n.m.getMessage('craftFlooring', {
        lng: language
      }), I18n_1.I18n.m.getMessage('craftRoofing', {
        lng: language
      }), I18n_1.I18n.m.getMessage('craftElectrical', {
        lng: language
      }), I18n_1.I18n.m.getMessage('craftEarthWork', {
        lng: language
      }), I18n_1.I18n.m.getMessage('craftFacade', {
        lng: language
      }), I18n_1.I18n.m.getMessage('craftWindows', {
        lng: language
      }), I18n_1.I18n.m.getMessage('craftTiles', {
        lng: language
      }), I18n_1.I18n.m.getMessage('craftStaging', {
        lng: language
      }), I18n_1.I18n.m.getMessage('craftHVAC', {
        lng: language
      }), I18n_1.I18n.m.getMessage('craftCablework', {
        lng: language
      }), I18n_1.I18n.m.getMessage('craftPlumbing', {
        lng: language
      }), I18n_1.I18n.m.getMessage('craftCommunication', {
        lng: language
      }), I18n_1.I18n.m.getMessage('craftLandscaping', {
        lng: language
      }), I18n_1.I18n.m.getMessage('craftPaint', {
        lng: language
      }), I18n_1.I18n.m.getMessage('craftMason', {
        lng: language
      }), I18n_1.I18n.m.getMessage('craftMetalWork', {
        lng: language
      }), I18n_1.I18n.m.getMessage('craftSpecialHeavyConstruction', {
        lng: language
      }), I18n_1.I18n.m.getMessage('craftSteelwork', {
        lng: language
      }), I18n_1.I18n.m.getMessage('craftFinishCarpenter', {
        lng: language
      }), I18n_1.I18n.m.getMessage('craftDrywall', {
        lng: language
      }), I18n_1.I18n.m.getMessage('craftDoors', {
        lng: language
      }), I18n_1.I18n.m.getMessage('craftCarpenter', {
        lng: language
      })];
    }
  }, {
    key: "checkProjectStatusAndGeneralAccess",
    value: function () {
      var _checkProjectStatusAndGeneralAccess = (0, _asyncToGenerator2.default)(function* (projectId, userId) {
        var igonoreArchive = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
        var projectExists = yield ReadModels_1.ReadModels.get('project').getById(projectId);
        if (userId !== 'all' && projectExists.creator !== userId) {
          var inProject = yield ReadModels_1.ReadModels.get('projectUser').get({
            filter: `userId eq '${userId}' and projectId eq '${projectId}' and status eq 'accepted'`
          });
          if (inProject.length === 0) throw StdApplicationError_1.StdApplicationError.forbidden();
          if (projectExists.projectSubscription === 'pro' || projectExists.projectSubscription === 'enterprise') {
            var _role$basedOnRoleId;
            var r = inProject[0];
            var role = yield ReadModels_1.ReadModels.get('ProjectRoles').getById(r.roleId);
            if (projectExists.projectSubscription === 'enterprise' && projectExists.template && ((_role$basedOnRoleId = role.basedOnRoleId) != null ? _role$basedOnRoleId : '3') !== '1' && r.roleId !== '1') {
              throw {
                message: 'Das Projekt ist als Vorlage markiert und kann nur von Projektleitern bearbeitet werden',
                messageCode: 'projectErrorIsMarkedAsTemplate'
              };
            }
            if (!(role.basedOnRoleId === '2' || r.roleId === '2' || r.roleId === '4')) {
              var myRight = yield RightsManager_1.RightsManager.hasVersion(userId);
              if (myRight === 'basic') {
                if (role.basedOnRoleId === '1' || r.roleId === '1') {
                  throw {
                    message: 'Für Ihre Rolle in dem Projekt benötigen Sie eine Projektleiterlizenz oder einen Pro-Account. Bitte' + ' wenden Sie sich an den Projekteigentümer, damit er Ihre Rolle ändert oder upgraden Sie Ihren Account!',
                    messageCode: 'projectErrorProOrEnterpriseNeededForRole'
                  };
                } else {
                  throw {
                    message: 'Für Ihre Rolle in dem Projekt benötigen Sie eine Mitarbeiterlizenz oder einen Pro-Account. Bitte' + ' wenden Sie sich an den Projekteigentümer, damit er Ihre Rolle ändert oder upgraden Sie Ihren Account!',
                    messageCode: 'projectErrorProOrCollaboratorNeededForRole'
                  };
                }
              } else if (myRight === 'enterprise') {
                if (role.basedOnRoleId === '1' || r.roleId === '1') {
                  var member = yield ReadModels_1.ReadModels.get('companyMember').get({
                    filter: `userId eq '${userId}' and deleted ne true`
                  });
                  if (member.length === 0 || member[0].role === 'collaborator') {
                    throw {
                      message: 'Für Ihre Rolle in dem Projekt benötigen Sie eine Projektleiterlizenz oder einen Pro-Account. Bitte' + ' wenden Sie sich an den Projekteigentümer, damit er Ihre Rolle ändert oder upgraden Sie Ihren Account!',
                      messageCode: 'projectErrorProOrEnterpriseNeededForRole'
                    };
                  }
                }
              }
            }
          }
        }
        if (projectExists.deleted != null && projectExists.deleted) {
          throw {
            message: 'Das Projekt existiert nicht mehr, bitte wenden Sie sich an den Eigentümer des Projektes',
            messageCode: 'projectDeleted'
          };
        }
        if (projectExists.inCreation != null && projectExists.inCreation) {
          throw {
            message: 'Das Projekt ist noch nicht vollständig kopiert',
            messageCode: 'projectInCreation'
          };
        }
        if (projectExists.deletedFromServer != null && projectExists.deletedFromServer) {
          throw {
            message: 'Das Projekt existiert nicht mehr, bitte wenden Sie sich an den Eigentümer des Projektes',
            messageCode: 'projectDeleted'
          };
        }
        if (projectExists.locked != null && projectExists.locked) {
          throw {
            message: 'Das Projekt ist gesperrt, bitte wenden Sie sich an den Eigentümer des Projektes',
            messageCode: 'projectLocked'
          };
        }
        if (!igonoreArchive && projectExists.archived != null && projectExists.archived) {
          throw {
            message: 'Das Projekt ist archiviert, bitte wenden Sie sich an den Eigentümer des Projektes',
            messageCode: 'projectArchived'
          };
        }
        if (projectExists.storageUsageInBytes != null && projectExists.storageUsageInBytes >= ProjectEntity.getMaxSize(projectExists.projectSubscription)) {
          throw {
            message: 'Das Projekt hat die maximale Größe erreicht',
            messageCode: 'projectMaximumSizeReached'
          };
        }
        return true;
      });
      function checkProjectStatusAndGeneralAccess(_x, _x2) {
        return _checkProjectStatusAndGeneralAccess.apply(this, arguments);
      }
      return checkProjectStatusAndGeneralAccess;
    }()
  }, {
    key: "getMaxFileSize",
    value: function getMaxFileSize(_version) {
      return 104857600;
    }
  }, {
    key: "getMaxSize",
    value: function getMaxSize(version) {
      if (version == null || version === 'basic') {
        return 524288000;
      }
      return 214748364800;
    }
  }]);
  return ProjectEntity;
}(ReadModelEntity_1.ReadModelEntity);
exports.ProjectEntity = ProjectEntity;