"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ReactivateProject = void 0;
var AuthClient_1 = require("upmesh-auth-core/build/src/client/AuthClient");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var ProjectDeletionChanged_1 = require("../../events/project/ProjectDeletionChanged");
var ClientProjectCommands_1 = require("../_root/ClientProjectCommands");
var ReactivateProject = function (_ClientProjectCommand) {
  (0, _inherits2.default)(ReactivateProject, _ClientProjectCommand);
  var _super = _createSuper(ReactivateProject);
  function ReactivateProject(data, projectId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ReactivateProject);
    _this = _super.call(this, data, projectId, token);
    _this.aggregate = 'project';
    _this.commandName = 'ReactivateProject';
    return _this;
  }
  (0, _createClass2.default)(ReactivateProject, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var project = yield CommandReadModels_1.CommandReadModels.project.getById(this.entityId);
        if (project.creator.startsWith('company')) {
          var companyId = project.creator.slice(7);
          yield CommandReadModels_1.CommandReadModels.company.getById(companyId);
        } else {
          var owner = yield AuthClient_1.AuthClient.instance.modals.user.getById(project.creator);
          if (owner.status != null && (owner.status === 'deleted' || owner.status === 'prepareDeletion')) {
            throw {
              message: 'Keine Berechtigung',
              messageCode: 'forbiddenCommand'
            };
          }
        }
        if (userId === 'all') return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return new ProjectDeletionChanged_1.ProjectDeletionChanged(this.entityId, {
          deleted: false
        });
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {});
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
  return ReactivateProject;
}(ClientProjectCommands_1.ClientProjectCommands);
exports.ReactivateProject = ReactivateProject;