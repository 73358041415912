"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddSubscriptionToCompany = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var AddSubscriptionToCompany = function (_CommandRoot_1$Comman) {
  (0, _inherits2.default)(AddSubscriptionToCompany, _CommandRoot_1$Comman);
  var _super = _createSuper(AddSubscriptionToCompany);
  function AddSubscriptionToCompany(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v1();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, AddSubscriptionToCompany);
    _this = _super.call(this, data, entityId, token);
    _this.commandName = 'AddSubscriptionToCompany';
    _this.aggregate = 'company';
    _this.data = data;
    return _this;
  }
  (0, _createClass2.default)(AddSubscriptionToCompany, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') {
          return true;
        }
        var companies = ReadModels_1.ReadModels.get('Company');
        var company = yield companies.getById(this.data.companyId);
        var partner = ReadModels_1.ReadModels.get('UpmeshPartner');
        var partners = yield partner.get({
          filter: `users/userId eq '${userId}'`
        });
        this.data.partnerId = company.partnerId;
        if (partners.length > 0 && company.partnerId === partners[0].id) {
          if (partners[0].hasRight('canManageLicenses', userId)) {
            return true;
          }
        }
        var admin = ReadModels_1.ReadModels.get('SupportRoles');
        var admins = yield admin.get({
          filter: `userId eq '${userId}' and deleted ne true`
        });
        if (admins.length > 0) {
          for (var adm of admins) {
            if (adm.role === 'Admin') {
              return true;
            }
          }
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {
        var errors = [];
        if (_key == null || _key === 'expireDate') {
          var ends = new Date();
          if (ends.getTime() > new Date(this.data.expireDate).getTime()) {
            errors.push({
              key: 'ends',
              message: 'Ablaufdatum kleiner als heute',
              messageCode: 'badRequest'
            });
          }
        }
        if (errors.length > 0) throw errors;
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return null;
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }]);
  return AddSubscriptionToCompany;
}(CommandRoot_1.CommandRoot);
exports.AddSubscriptionToCompany = AddSubscriptionToCompany;