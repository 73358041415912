import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Form } from 'materialTheme/src/theme/components/forminput/Form';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { DefaultErrorHandler } from 'upmesh-client/src/components/DefaultErrorHandler';
import { ChangeUpmeshPartnerUser, } from 'upmesh-support-core/src/commands/partner/ChangeUpmeshPartnerUser';
import { ConnectionHandler } from '../../ConnectionHandler';
export class ChangePartnerUserDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.init = async () => {
            const client = ConnectionHandler.getClient();
            const { data } = this.props;
            if (client != null && data != null && data.partnerId != null) {
                try {
                    const partner = await client.upmeshPartner.getById(data.partnerId);
                    const index = partner.users?.findIndex((u) => u.userId === data.userId);
                    if (index > -1) {
                        const user = await client.user.getById(data.userId);
                        const initial = partner.users[index];
                        this.setState({ partner, user, initial });
                    }
                    else {
                        this.setState({ error: 'Benutzer nicht gefunden' });
                    }
                }
                catch (e) {
                    console.debug('cant get Partner Settings', e);
                    this.setState({ error: e });
                }
            }
        };
        this.close = (_e) => {
            Dialog.instance?.close();
        };
        this.beforeSave = async (_e) => {
            console.log('beforeSaveForm');
            return { canSave: false };
        };
        this.catchError = (e) => {
            DefaultErrorHandler.showDefaultErrorAlert(e);
            return true;
        };
        this.onSaved = () => {
            this.close();
        };
        this.state = {
            error: '',
        };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug('cant init Dialog', err));
    }
    render() {
        const { initial, error, user } = this.state;
        console.log('RENDER', initial, error);
        if (initial == null || user == null)
            return <Spinner />;
        const dataOptions = new Map();
        dataOptions.set('canManageCompanies', {
            formType: 'boolean',
            cols: 1,
            props: {
                labelText: 'Firmendaten verwalten',
            },
        });
        dataOptions.set('canManageLicenses', {
            formType: 'boolean',
            cols: 1,
            props: {
                labelText: 'Lizenzen verwalten',
            },
        });
        dataOptions.set('canManagePrices', {
            formType: 'boolean',
            cols: 1,
            props: {
                labelText: 'Standardpreise verwalten',
            },
        });
        dataOptions.set('canManagePayment', {
            formType: 'boolean',
            cols: 1,
            props: {
                labelText: 'Zahlungsdaten verwalten',
            },
        });
        dataOptions.set('canManagePartnerData', {
            formType: 'boolean',
            cols: 1,
            props: {
                labelText: 'Partnerdaten bearbeiten',
            },
        });
        return (<Form baseCols={1} catchError={this.catchError} dataOptions={dataOptions} formHeaderProps={{
                formTitle: `${user.getFullName()} bearbeiten`,
            }} store={ConnectionHandler.getStore()} command={new ChangeUpmeshPartnerUser({ ...initial }, this.props.data.partnerId, ConnectionHandler.serverConnection.token)} additionalButtons={[
                <ContainedButton key="abort" onPress={this.close} title="abbrechen" backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
            ]} onSaved={this.onSaved} saveButtonLabel="speichern" stickyHeader stickyActions resetButton serverConnection={ConnectionHandler.getConnection()}/>);
    }
}
ChangePartnerUserDialog.openDialog = (partnerId, userId) => (e) => {
    const openPosition = { x: e ? e.nativeEvent.pageX : 0, y: e ? e.nativeEvent.pageY : 0 };
    Dialog.instance?.open({
        openPosition,
        fullscreenResponsive: true,
        scrollable: false,
        contentPadding: false,
        content: <ChangePartnerUserDialog data={{ partnerId, userId }}/>,
    });
};
