var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DateRangeInput = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _MaterialCommunityIcons = _interopRequireDefault(require("react-native-vector-icons/MaterialCommunityIcons"));
var _CurrentUser = require("upmesh-auth-core/build/src/client/CurrentUser");
var _DateLocale = require("upmesh-i18n/build/src/i18n/DateLocale");
var _I18n = require("../../i18n/I18n");
var _ThemeManager = require("../../ThemeManager");
var _Datepicker = require("../datepickerv2/Datepicker");
var _Icon = require("../Icon");
var _MaterialText = require("../text/MaterialText");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var DateRangeInput = exports.DateRangeInput = function (_PureComponent) {
  (0, _inherits2.default)(DateRangeInput, _PureComponent);
  var _super = _createSuper(DateRangeInput);
  function DateRangeInput(props) {
    var _this;
    (0, _classCallCheck2.default)(this, DateRangeInput);
    _this = _super.call(this, props);
    _this.changeText = function (date) {
      if (date instanceof Date) return;
      _this.setState({
        selectedDate: {
          from: new Date(date.from),
          to: new Date(date.to)
        }
      }, function () {
        if (_this.props.onChange != null) {
          _this.props.onChange(date);
        }
      });
    };
    _this.clearText = function () {
      _this.setState({
        selectedDate: undefined
      }, function () {
        if (_this.props.onChange != null) {
          _this.props.onChange(undefined);
        }
      });
    };
    _this.openDatePicker = function (e) {
      var _this$props = _this.props,
        selectTime = _this$props.selectTime,
        coloredDates = _this$props.coloredDates,
        quickSelections = _this$props.quickSelections,
        timeQuickSelections = _this$props.timeQuickSelections,
        preSelectedDate = _this$props.preSelectedDate;
      var selectedDate = _this.state.selectedDate;
      _Datepicker.Datepicker.open({
        onChange: _this.changeText,
        range: true,
        timeFormat: _this.props.timeFormat,
        mode: selectTime ? 'both' : 'date',
        coloredDates: coloredDates,
        selectedDate: selectedDate == null && preSelectedDate != null ? preSelectedDate : selectedDate,
        timeQuickSelections: timeQuickSelections,
        quickSelections: quickSelections
      }, e);
    };
    var timeFormat = _this.props.timeFormat != null ? _this.props.timeFormat : _CurrentUser.CurrentUser.settings.timeFormat;
    var locale = _CurrentUser.CurrentUser.entity != null && _CurrentUser.CurrentUser.entity.locale != null ? _CurrentUser.CurrentUser.entity.locale : 'de';
    _this.locale = new _DateLocale.DateLocale(locale, timeFormat);
    _this.state = {
      selectedDate: props.selectedDate
    };
    return _this;
  }
  (0, _createClass2.default)(DateRangeInput, [{
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps, _prevState, _snapshot) {
      if (prevProps.selectedDate !== this.props.selectedDate) {
        this.setState({
          selectedDate: this.props.selectedDate
        });
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _this$props2 = this.props,
        textColor = _this$props2.textColor,
        disabled = _this$props2.disabled,
        hasDeleteIcon = _this$props2.hasDeleteIcon;
      var selectedDate = this.state.selectedDate;
      var iconColor = _ThemeManager.ThemeManager.style.defaultIconColor;
      if (textColor != null && textColor !== _ThemeManager.ThemeManager.style.black87) {
        iconColor = textColor;
      }
      var iconType = 'calendar-range';
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          overflow: 'visible',
          flexDirection: 'row',
          height: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(48),
          alignItems: 'center',
          justifyContent: 'flex-start'
        },
        children: [(0, _jsxRuntime.jsxs)(_reactNativeWeb.TouchableOpacity, {
          style: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            height: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(48)
          },
          onPress: this.openDatePicker,
          disabled: disabled === true,
          children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            accessibilityLabel: "Date Picker",
            style: {
              borderColor: iconColor,
              borderWidth: _ThemeManager.ThemeManager.style.borderWidth,
              borderRadius: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(18),
              borderStyle: selectedDate != null ? 'solid' : 'dashed',
              width: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(this.props.iconWidth != null ? this.props.iconWidth : 36),
              height: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(this.props.iconHeight != null ? this.props.iconHeight : 36),
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'center'
            },
            children: (0, _jsxRuntime.jsx)(_MaterialCommunityIcons.default, {
              name: iconType,
              size: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(24),
              color: iconColor
            })
          }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              width: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(6)
            }
          }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            color: selectedDate === undefined ? _ThemeManager.ThemeManager.style.secondaryTextColor : textColor,
            centeredText: true,
            centeredBox: true,
            type: _MaterialText.MaterialTextTypes.Body1,
            children: this.createTextValue(selectedDate)
          }), disabled !== true ? (0, _jsxRuntime.jsx)(_MaterialCommunityIcons.default, {
            name: "menu-down",
            size: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(24),
            color: _ThemeManager.ThemeManager.style.black87
          }) : null]
        }), disabled !== true && this.createTextValue(selectedDate) !== this.props.placeholder && hasDeleteIcon === true ? (0, _jsxRuntime.jsx)(_Icon.Icon, {
          toolTip: _I18n.I18n.m.getMessage('clear'),
          icon: "close-circle",
          onPress: this.clearText
        }) : null]
      });
    }
  }, {
    key: "createTextValue",
    value: function createTextValue(date) {
      if (date == null) return this.props.placeholder != null ? this.props.placeholder : '';
      var t1 = this.createSingleTextValue(date.from);
      var t2 = this.createSingleTextValue(date.to);
      return `${t1} - ${t2}`;
    }
  }, {
    key: "createSingleTextValue",
    value: function createSingleTextValue(date) {
      var selectTime = this.props.selectTime;
      if (date == null) return '';
      var text = `${this.locale.localeDateString(date)}`;
      if (text === 'Invalid date') {
        return 'invalidDate';
      }
      if (selectTime) {
        var time = this.locale.getLocalTimeString(date);
        text = `${text} ${time}`;
      }
      return text;
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        placeholder: 'Datumbereich',
        selectTime: false,
        selectedDate: undefined,
        textColor: _ThemeManager.ThemeManager.style.black87,
        datePickerColor: _ThemeManager.ThemeManager.style.datePickerColor,
        datePickerTextColor: _ThemeManager.ThemeManager.style.datePickerTextColor,
        selectTimeMinutesIntervall: 5,
        labelText: '',
        iconWidth: 36,
        iconHeight: 36,
        hasDeleteIcon: true,
        disabled: false,
        showKW: _CurrentUser.CurrentUser.settings.showKW
      };
    }
  }]);
  return DateRangeInput;
}(_react.PureComponent);