var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ScrollHandler = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _tsEvents = require("ts-events");
var ScrollHandler = exports.ScrollHandler = function () {
  function ScrollHandler() {
    (0, _classCallCheck2.default)(this, ScrollHandler);
  }
  (0, _createClass2.default)(ScrollHandler, null, [{
    key: "disableAll",
    value: function disableAll() {
      ScrollHandler.scrollEnableEvent.post({
        enabled: false
      });
    }
  }, {
    key: "enableAll",
    value: function enableAll() {
      ScrollHandler.scrollEnableEvent.post({
        enabled: true
      });
    }
  }, {
    key: "getScrollEvent",
    value: function getScrollEvent(containerId) {
      var contId = containerId != null ? containerId : ScrollHandler.getLastAddedScrollContainer();
      if (contId != null) {
        if (ScrollHandler.scrolledEvents[contId] != null) {
          return ScrollHandler.scrolledEvents[contId];
        }
      }
      return undefined;
    }
  }, {
    key: "getScrollPosition",
    value: function () {
      var _getScrollPosition = (0, _asyncToGenerator2.default)(function* (containerId) {
        var contId = containerId != null ? containerId : ScrollHandler.getLastAddedScrollContainer();
        if (contId != null) {
          return new Promise(function (resolve) {
            ScrollHandler.scrollPosition.once(function (s) {
              resolve(s);
            });
            ScrollHandler.containerScrollPosition.post(contId);
          });
        }
        return {
          x: 0,
          y: 0
        };
      });
      function getScrollPosition(_x) {
        return _getScrollPosition.apply(this, arguments);
      }
      return getScrollPosition;
    }()
  }, {
    key: "scrollTo",
    value: function scrollTo(options) {
      ScrollHandler.scrollEvent.post(options);
    }
  }, {
    key: "scrollToEnd",
    value: function scrollToEnd(options) {
      ScrollHandler.scrollToEndEvent.post(options);
    }
  }, {
    key: "getLastAddedScrollContainer",
    value: function getLastAddedScrollContainer() {
      if (ScrollHandler.currentScrollContainer.length > 0) {
        return ScrollHandler.currentScrollContainer[ScrollHandler.currentScrollContainer.length - 1];
      }
      return undefined;
    }
  }]);
  return ScrollHandler;
}();
ScrollHandler.containerScrollPosition = new _tsEvents.AsyncEvent();
ScrollHandler.currentScrollContainer = [];
ScrollHandler.currentScrollEvents = [];
ScrollHandler.scrollEvent = new _tsEvents.AsyncEvent();
ScrollHandler.scrollToEndEvent = new _tsEvents.AsyncEvent();
ScrollHandler.scrollPosition = new _tsEvents.AsyncEvent();
ScrollHandler.scrolledEvent = new _tsEvents.AsyncEvent();
ScrollHandler.scrolledEvents = {
  containerId: 'any'
};
ScrollHandler.scrollEnableEvent = new _tsEvents.AsyncEvent();