var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ModalBottomSheet = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-support/node_modules/react-native-web");
var _ResizeEvent = require("../ResizeEvent");
var _ThemeManager = require("../ThemeManager");
var _BottomSheet = require("./BottomSheet");
var _ListItem = require("./ListItem");
var _MenuRaw = require("./MenuRaw");
var _jsxRuntime = require("../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var MODALBOTTOMSHEET_CLOSED = 'closed';
var ModalBottomSheet = exports.ModalBottomSheet = function (_PureComponent) {
  (0, _inherits2.default)(ModalBottomSheet, _PureComponent);
  var _super = _createSuper(ModalBottomSheet);
  function ModalBottomSheet(props) {
    var _this;
    (0, _classCallCheck2.default)(this, ModalBottomSheet);
    _this = _super.call(this, props);
    _this.opened = 'nothing';
    _this.close = function () {
      if (_this.opened === 'bottomsheet') {
        var _BottomSheet$instance;
        (_BottomSheet$instance = _BottomSheet.BottomSheet.instance) == null || _BottomSheet$instance.close();
      }
      if (_this.opened === 'menu') {
        var _MenuRaw$instance;
        (_MenuRaw$instance = _MenuRaw.MenuRaw.instance) == null || _MenuRaw$instance.close();
      }
    };
    _this.open = function (state) {
      var openImmediately = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      _reactNativeWeb.Keyboard.dismiss();
      if (_ResizeEvent.ResizeEvent.current.windowHeight > _ThemeManager.ThemeManager.style.breakpointM && _ResizeEvent.ResizeEvent.current.contentWidth > _ThemeManager.ThemeManager.style.breakpointM) {
        var _MenuRaw$instance2;
        (_MenuRaw$instance2 = _MenuRaw.MenuRaw.instance) == null || _MenuRaw$instance2.open(Object.assign({}, state, {
          items: state.listItems
        }));
        _this.opened = 'menu';
      } else {
        var _BottomSheet$instance2;
        (_BottomSheet$instance2 = _BottomSheet.BottomSheet.instance) == null || _BottomSheet$instance2.open(Object.assign({}, state, {
          bottomsheetState: state.modalBottomsheetState,
          content: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              paddingBottom: _ResizeEvent.ResizeEvent.current.contentBottom
            },
            children: _this.renderBottomSheetContent(state.listItems)
          }),
          height: _this.height + _ResizeEvent.ResizeEvent.current.contentBottom,
          width: state.client != null ? state.client.width : undefined
        }), openImmediately);
        _this.opened = 'bottomsheet';
      }
    };
    _this.renderBottomSheetContent = function (listItems) {
      if (listItems.length === 0) {
        return null;
      }
      _this.height = 0;
      var renderedListItems = [];
      for (var i = 0; i < listItems.length; i += 1) {
        renderedListItems.push((0, _jsxRuntime.jsx)(_ListItem.ListItem, Object.assign({}, listItems[i]), `ModalBSListItem${i}`));
        _this.height += _ListItem.ListItem.getHeight(listItems[i]);
        if (listItems[i].cutLine === true) {
          _this.height += 16;
          renderedListItems.push((0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              marginTop: 8,
              marginBottom: 8,
              marginRight: _reactNativeWeb.Platform.OS.toString() === 'web' ? 8 : 0,
              borderColor: _ThemeManager.ThemeManager.style.borderColor,
              borderBottomWidth: 1
            }
          }, i + 0.5));
        }
      }
      return renderedListItems;
    };
    _this.state = ModalBottomSheet.defaultState;
    return _this;
  }
  (0, _createClass2.default)(ModalBottomSheet, [{
    key: "bottomsheetState",
    get: function get() {
      return this.state.modalBottomsheetState;
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      ModalBottomSheet._instance = this;
    }
  }, {
    key: "render",
    value: function render() {
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {});
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        animationDuration: 150,
        windowWidth: 1,
        windowHeight: 1,
        haveOverlay: true,
        closeFunction: function closeFunction() {},
        open: false
      };
    }
  }, {
    key: "defaultState",
    get: function get() {
      return {
        closeFunction: function closeFunction() {
          var _ModalBottomSheet$ins;
          return (_ModalBottomSheet$ins = ModalBottomSheet.instance) == null ? void 0 : _ModalBottomSheet$ins.close();
        },
        modalBottomsheetState: MODALBOTTOMSHEET_CLOSED,
        showOverlay: true,
        maxWidth: 0.9,
        maxHeight: 0.9,
        listItems: [],
        closeOnTouchOutside: true,
        scrollable: false,
        client: {
          x: _ResizeEvent.ResizeEvent.current.windowWidth / 2,
          y: _ResizeEvent.ResizeEvent.current.windowHeight / 2,
          width: 0,
          height: 56,
          selected: 0
        },
        elevation: undefined,
        selectedElement: undefined
      };
    }
  }, {
    key: "instance",
    get: function get() {
      if (ModalBottomSheet._instance != null) {
        return ModalBottomSheet._instance;
      }
      return null;
    }
  }]);
  return ModalBottomSheet;
}(_react.PureComponent);