var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DragZone = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _ThemeManager = require("../../ThemeManager");
var _DragHandler = require("./DragHandler");
var _DropAreaHandler = require("./DropAreaHandler");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var DragZone = exports.DragZone = function (_PureComponent) {
  (0, _inherits2.default)(DragZone, _PureComponent);
  var _super = _createSuper(DragZone);
  function DragZone(props) {
    var _this;
    (0, _classCallCheck2.default)(this, DragZone);
    _this = _super.call(this, props);
    _this.startPosition = {
      x: 0,
      y: 0
    };
    _this.abort = function () {
      if (_this.abortTimeOut) {
        window.clearTimeout(_this.abortTimeOut);
      }
      _this.dragElement = undefined;
    };
    _this.startDrag = function (e) {
      if (e == null) {
        if (_this.abortTimeOut) {
          window.clearTimeout(_this.abortTimeOut);
        }
        _this.abortTimeOut = setTimeout(_this.abort, 195);
        _this.setState({
          dragElement: e
        });
        return;
      }
      _DropAreaHandler.DropAreaHandler.createPositions().catch(function (err) {
        return void 0;
      });
      _this.startPosition = {
        x: e.elementPosition.x + 2,
        y: e.elementPosition.y + 2
      };
      _this.state.pan.setValue(_this.startPosition);
      _this.state.opacity.setValue(e.opacity);
      _this.dragElement = e;
    };
    _this.state = {
      pan: new _reactNativeWeb.Animated.ValueXY(),
      opacity: new _reactNativeWeb.Animated.Value(0.5)
    };
    _this.createPanResponder();
    return _this;
  }
  (0, _createClass2.default)(DragZone, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      _DragHandler.DragHandler.dragEvent.attach(this.startDrag);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      _DragHandler.DragHandler.dragEvent.detach(this.startDrag);
    }
  }, {
    key: "createPanResponder",
    value: function createPanResponder() {
      var _this2 = this;
      this.panResponder = _reactNativeWeb.PanResponder.create({
        onPanResponderGrant: function onPanResponderGrant(_e, _gesture) {
          _DragHandler.DragHandler.lastStart = {
            x: _gesture.x0,
            y: _gesture.y0
          };
        },
        onMoveShouldSetPanResponderCapture: function onMoveShouldSetPanResponderCapture(_e, _gesture) {
          _DragHandler.DragHandler.lastStart = {
            x: _gesture.x0,
            y: _gesture.y0
          };
          return false;
        },
        onMoveShouldSetPanResponder: function onMoveShouldSetPanResponder(_e, _gesture) {
          return _this2.dragElement != null;
        },
        onStartShouldSetPanResponderCapture: function onStartShouldSetPanResponderCapture(_e, _gesture) {
          return false;
        },
        onStartShouldSetPanResponder: function onStartShouldSetPanResponder(_e, _gesture) {
          return false;
        },
        onPanResponderMove: function onPanResponderMove(_e, _gesture) {
          if (_this2.state.dragElement == null && _this2.dragElement != null) {
            if (_this2.dragElement != null && _this2.dragElement.onStartDrag != null) {
              _this2.dragElement.onStartDrag();
            }
            _this2.setState({
              dragElement: _this2.dragElement
            }, function () {
              if (_this2.dragElement != null && _this2.dragElement.onStartedDrag != null) {
                _this2.dragElement.onStartedDrag();
              }
            });
          } else if (_this2.state.dragElement != null) {
            _this2.dragElement = undefined;
            var pos = {
              x: _this2.startPosition.x + _gesture.dx,
              y: _this2.startPosition.y + _gesture.dy
            };
            _this2.state.pan.setValue(pos);
            var hitarea = _DropAreaHandler.DropAreaHandler.hitTest(Object.assign({}, _this2.state.dragElement.elementPosition, pos));
            if (hitarea != null) {
              if (_this2.lastHit != null && _this2.lastHit.id !== hitarea.id) {
                _this2.lastHit.toggleHighlight(false, _this2.state.dragElement.dropData);
              }
              if (_this2.lastHit == null || _this2.lastHit.id !== hitarea.id) {
                hitarea.toggleHighlight(true, _this2.state.dragElement.dropData);
                _this2.lastHit = hitarea;
              }
            } else {
              _this2.resetLastHit();
            }
          }
        },
        onPanResponderTerminate: function onPanResponderTerminate(_e, _gesture) {
          _this2.resetLastHit();
          _this2.setState({
            dragElement: undefined
          });
        },
        onPanResponderTerminationRequest: function onPanResponderTerminationRequest(_e, _gesture) {
          _this2.resetLastHit();
          _this2.setState({
            dragElement: undefined
          });
          return true;
        },
        onPanResponderRelease: function onPanResponderRelease(_e, _gesture) {
          _this2.resetLastHit();
          if (_this2.state.dragElement != null) {
            var pos = {
              x: _this2.state.pan.x._value,
              y: _this2.state.pan.y._value
            };
            _DragHandler.DragHandler.lastDrop = pos;
            var hitarea = _DropAreaHandler.DropAreaHandler.hitTest(Object.assign({}, _this2.state.dragElement.elementPosition, pos));
            var accepted = false;
            if (hitarea != null) {
              accepted = hitarea.filter(_this2.state.dragElement.dropData);
              if (accepted) {
                hitarea.action(_this2.state.dragElement.dropData).catch(function (err) {
                  return void 0;
                });
                if (_this2.state.dragElement.onDropped != null) {
                  _this2.state.dragElement.onDropped(hitarea, _this2.state.dragElement.dropData, {
                    x: pos.x - hitarea.areaPostionSync.x,
                    y: pos.y - hitarea.areaPostionSync.y
                  });
                }
                _reactNativeWeb.Animated.timing(_this2.state.opacity, {
                  toValue: 0,
                  duration: 495,
                  useNativeDriver: _reactNativeWeb.Platform.OS !== 'web'
                }).start(function () {
                  _this2.setState({
                    dragElement: undefined
                  });
                });
              }
            }
            if (!accepted) {
              if (_this2.state.dragElement.onDropNotInside != null) {
                _this2.state.dragElement.onDropNotInside(pos);
              }
              _reactNativeWeb.Animated.timing(_this2.state.pan, {
                toValue: _this2.startPosition,
                duration: 195,
                useNativeDriver: _reactNativeWeb.Platform.OS !== 'web'
              }).start(function () {
                _this2.setState({
                  dragElement: undefined,
                  opacity: new _reactNativeWeb.Animated.Value(0.75)
                });
              });
            }
          }
        }
      });
    }
  }, {
    key: "render",
    value: function render() {
      var _this$props = this.props,
        width = _this$props.width,
        height = _this$props.height;
      var dragElement = null;
      if (this.state.dragElement != null) {
        dragElement = this.state.dragElement.child;
      }
      var panStyle = {
        transform: this.state.pan.getTranslateTransform()
      };
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, Object.assign({}, this.panResponder.panHandlers, {
        pointerEvents: "auto",
        collapsable: false,
        style: {
          width: width,
          height: height,
          position: 'absolute',
          left: 0,
          zIndex: 4000,
          top: 0,
          backgroundColor: 'transparent'
        },
        children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: _ThemeManager.ThemeManager.style.absoluteStyle,
          collapsable: false,
          children: this.props.children
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.Animated.View, {
          collapsable: false,
          pointerEvents: "none",
          style: Object.assign({
            position: 'absolute'
          }, panStyle, {
            opacity: this.state.opacity
          }),
          children: dragElement
        })]
      }));
    }
  }, {
    key: "resetLastHit",
    value: function resetLastHit() {
      if (this.lastHit != null) {
        this.lastHit.toggleHighlight(false, undefined);
        this.lastHit = undefined;
      }
    }
  }]);
  return DragZone;
}(_react.PureComponent);