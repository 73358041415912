import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { DashBoardItem } from 'upmesh-client/src/components/project/board/DashBoardItem';
import * as uuid from 'uuid';
import { ConnectionHandler } from '../ConnectionHandler';
import { I18n } from '../i18n/I18n';
import { Page } from '../Page';
export class DashboardView extends PureComponent {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.init = async () => {
            const companies = await ConnectionHandler.getClient()?.company.count();
            const subs = await ConnectionHandler.getClient()?.subscription.get({
                filter: `(expireDate eq null or expireDate gt ${new Date().toISOString()}) and locked ne true`,
            });
            let subsPro = 0;
            let subsEnterprise = 0;
            let subsTime = 0;
            let subsEnterpriseTest = 0;
            let subsTimeTest = 0;
            let subsCollaborator = 0;
            let subsCollaboratorTest = 0;
            subs?.forEach((c) => {
                if (c.subscription === 'pro')
                    subsPro += 1;
                else if (c.licenceType != null && c.licenceType === 'collaborator') {
                    if (c.testAccount)
                        subsCollaboratorTest += c.licenses != null ? c.licenses : 1;
                    else
                        subsCollaborator += c.licenses != null ? c.licenses : 1;
                }
                else if (c.licenceType != null && c.licenceType === 'timeTracking') {
                    if (c.testAccount)
                        subsTimeTest += c.licenses != null ? c.licenses : 1;
                    else
                        subsTime += c.licenses != null ? c.licenses : 1;
                }
                else if (c.testAccount)
                    subsEnterpriseTest += c.licenses != null ? c.licenses : 1;
                else
                    subsEnterprise += c.licenses != null ? c.licenses : 1;
            });
            if (ConnectionHandler.isPartner) {
                return this.setState({
                    companies: companies != null ? companies : 0,
                    subsTime,
                    subsEnterprise,
                });
            }
            const users = await ConnectionHandler.getClient()?.user.count(`status ne 'deleted'`);
            const usersFromWebsite = await ConnectionHandler.getClient()?.user.count(`origin eq 'website' and status ne 'deleted'`);
            const usersFromInvitations = await ConnectionHandler.getClient()?.user.count(`origin eq 'invitation' and status ne 'deleted'`);
            const partners = await ConnectionHandler.getClient()?.upmeshPartner.count();
            const projects = await ConnectionHandler.getClient()?.project.count(`title ne 'Willkommens-Projekt 👋' and deleted ne true`);
            const tickets = await ConnectionHandler.getClient()?.ticket.count();
            const plans = await ConnectionHandler.getClient()?.plan.count();
            let usersOnline = 0;
            try {
                const f = await fetch(`${ConnectionHandler.apiUrl}/supportroles/online`, {
                    headers: { authorization: `Bearer ${ConnectionHandler.serverConnection.token}` },
                });
                const o = await f.json();
                if (typeof o === 'number')
                    usersOnline = o;
            }
            catch (e) {
                console.debug('cant get online users', e);
            }
            return this.setState({
                companies: companies != null ? companies : 0,
                users: users != null ? users : 0,
                usersFromWebsite: usersFromWebsite != null ? usersFromWebsite : 0,
                usersFromInvitation: usersFromInvitations != null ? usersFromInvitations : 0,
                subsTime,
                subsEnterprise,
                subsTimeTest,
                subsEnterpriseTest,
                subsPro,
                subsCollaborator,
                subsCollaboratorTest,
                partners: partners != null ? partners : 0,
                projects: projects != null ? projects : 0,
                tickets: tickets != null ? tickets : 0,
                plans: plans != null ? plans : 0,
                usersOnline,
            });
        };
        this.updateOnlineUser = () => {
            const asyncNow = async () => {
                try {
                    let usersOnline = 0;
                    const f = await fetch(`${ConnectionHandler.apiUrl}/supportroles/online`, {
                        headers: { authorization: `Bearer ${ConnectionHandler.serverConnection.token}` },
                    });
                    const o = await f.json();
                    if (typeof o === 'number')
                        usersOnline = o;
                    if (this.mounted)
                        this.setState({ usersOnline });
                }
                catch (e) {
                    console.debug('cant get online users', e);
                }
            };
            asyncNow().catch((err) => console.error(err));
        };
        this.state = {
            partners: 0,
            companies: 0,
            users: 0,
            usersFromWebsite: 0,
            usersFromInvitation: 0,
            usersOnline: 0,
            subsPro: 0,
            subsEnterprise: 0,
            subsTime: 0,
            subsCollaborator: 0,
            subsCollaboratorTest: 0,
            subsEnterpriseTest: 0,
            subsTimeTest: 0,
            projects: 0,
            tickets: 0,
            plans: 0,
        };
    }
    componentDidMount() {
        this.mounted = true;
        this.init().catch((err) => console.debug(err));
        this.onlineTo = setInterval(this.updateOnlineUser, 20000);
    }
    componentWillUnmount() {
        this.mounted = false;
        if (this.onlineTo)
            clearInterval(this.onlineTo);
    }
    renderItem(content, link) {
        const key = uuid.v1();
        const onPress = link != null ? (typeof link === 'string' ? Routing.instance.goToButton(link) : link) : undefined;
        return (<Ripple key={`link_${key}`} style={{
                margin: 16,
                minWidth: 320,
                borderStyle: 'solid',
                borderWidth: 1,
                borderRadius: ThemeManager.style.borderRadius,
                borderColor: ThemeManager.style.borderColor,
                ...ThemeManager.style.elevation2,
                backgroundColor: '#FFFFFF',
                flex: 1,
            }} onPress={onPress} disabled={link == null}>
        {content}
      </Ripple>);
    }
    renderUpmesh() {
        if (ConnectionHandler.isPartner)
            return null;
        const { users, partners, projects, tickets, plans, usersOnline, usersFromWebsite, usersFromInvitation, subsPro } = this.state;
        return [
            this.renderItem(<DashBoardItem title="Pro" counts={subsPro} icon="account-cash-outline" iconColor={ThemeManager.style.brandSecondary}/>, 'subscription?search=pro'),
            this.renderItem(<DashBoardItem title={I18n.m.getMessage('partner')} counts={partners} icon="domain-plus" iconColor={ThemeManager.style.brandDanger}/>, 'partner'),
            this.renderItem(<DashBoardItem title={I18n.m.getMessage('user')} counts={users} icon="account" iconColor={ThemeManager.style.brandWarning}/>, 'users?'),
            this.renderItem(<DashBoardItem title="über Website" counts={usersFromWebsite} icon="home-outline" iconColor={ThemeManager.style.brandWarning}/>),
            this.renderItem(<DashBoardItem title="über Einladungen" counts={usersFromInvitation} icon="card-outline" iconColor={ThemeManager.style.brandWarning}/>),
            this.renderItem(<DashBoardItem title="online" counts={usersOnline} icon="transit-connection-horizontal" iconColor={ThemeManager.style.brandWarning}/>, 'online'),
            this.renderItem(<DashBoardItem title={I18n.m.getMessage('projects')} counts={projects} icon="domain" iconColor={ThemeManager.style.defaultIconColor}/>, 'projects?'),
            this.renderItem(<DashBoardItem title={I18n.m.getMessage('tickets')} counts={tickets} icon="map-marker" iconColor={ThemeManager.style.defaultIconColor}/>),
            this.renderItem(<DashBoardItem title={I18n.m.getMessage('plans')} counts={plans} icon="map" iconColor={ThemeManager.style.defaultIconColor}/>),
        ];
    }
    render() {
        const { companies, subsEnterprise, subsTime, subsEnterpriseTest, subsTimeTest, subsCollaborator, subsCollaboratorTest, } = this.state;
        return (<Page pageTitle={I18n.m.getMessage('dashboard')}>
        <View style={{
                width: '100%',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'center',
                alignContent: 'center',
                marginTop: 24,
            }}>
          {this.renderItem(<DashBoardItem title={I18n.m.getMessage('companies')} counts={companies} icon="domain" iconColor={ThemeManager.style.brandPrimary}/>, 'companies')}
          {this.renderItem(<DashBoardItem title="Leiter / Test" counts={subsEnterprise} counts2={subsEnterpriseTest} icon="account-cash-outline" iconColor={ThemeManager.style.brandSecondary}/>, 'subscription?search=Projektleiterlizenz')}
          {this.renderItem(<DashBoardItem title="Mitarbeiter / Test" counts={subsCollaborator} counts2={subsCollaboratorTest} icon="account-cash-outline" iconColor={ThemeManager.style.brandSecondary}/>, 'subscription?search=Mitarbeiterlizenz')}
          {this.renderItem(<DashBoardItem title="Zeit / Test" counts={subsTime} counts2={subsTimeTest} icon="account-cash-outline" iconColor={ThemeManager.style.brandSecondary}/>, 'subscription?search=Zeiterfassung')}
          {this.renderUpmesh()}
          
        </View>
      </Page>);
    }
}
