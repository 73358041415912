"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Journal = void 0;
var ReadModelClientOfflineFirst_1 = require("cqrs-core/build/src/client/query/ReadModelClientOfflineFirst");
var JournalEntity_1 = require("./entities/JournalEntity");
var Journal = function (_ReadModelClientOffli) {
  (0, _inherits2.default)(Journal, _ReadModelClientOffli);
  var _super = _createSuper(Journal);
  function Journal() {
    var _this;
    (0, _classCallCheck2.default)(this, Journal);
    _this = _super.apply(this, arguments);
    _this.exampleEntity = new JournalEntity_1.JournalEntity();
    _this.onEvents = [{
      eventName: 'JournalSaved',
      affect: 'one',
      identifier: 'entityId'
    }];
    return _this;
  }
  (0, _createClass2.default)(Journal, [{
    key: "onJournalSaved",
    value: function () {
      var _onJournalSaved = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        if (e.data.forDay == null) return undefined;
        if (e.data.owner == null) return undefined;
        return entity;
      });
      function onJournalSaved(_x, _x2) {
        return _onJournalSaved.apply(this, arguments);
      }
      return onJournalSaved;
    }()
  }]);
  return Journal;
}(ReadModelClientOfflineFirst_1.ReadModelClientOfflineFirst);
exports.Journal = Journal;